import { useEffect } from 'react';

import {
  setBreadcrumbs,
} from '@app/stores';

import {
  PageBaseLayout,
  DepartmentListFeature,
} from '@app/components';

import { useAppDispatch, useAppSelector } from '@app/hooks';


export const DepartmentListPage = () => {
  const dispatch = useAppDispatch();
  const { breadcrumbs } = useAppSelector((s) => s.app);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { title: 'Главная', link: '/' },
      { title: 'Подразделения', link: '' },
    ]))
  }, [dispatch]);

  return (
    <PageBaseLayout breadcrumbs={breadcrumbs}>
      <DepartmentListFeature />
    </PageBaseLayout>
  );
};
