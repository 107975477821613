import {
  ModalComponent,
  HeaderComponent,
  FormComponent,
} from '..';


export const MainComponent = () => {

  return (
    <ModalComponent>
      <HeaderComponent />
      <FormComponent />
    </ModalComponent>
  );
};
