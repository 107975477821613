import cs from 'classnames';

import {
  ButtonsBrick,
  ButtonSaveBlock,
  ButtonCancelBlock,
  FieldNumberBlock,
} from '@app/components';

import styles from './form.module.scss';
import { useMainApi } from '../../context';


export const FormComponent = () => {
  const {
    formValue,
    formDirty,
    isSubmitting,
    onClose,
    onSubmit,
    formValueChange,
    errorSet,
  } = useMainApi();

  const formClassnames = cs(
    'row',
    'justify-content-md-center',
    styles['form']
  );

  return (
    <div className={formClassnames}>
      <div className="col col-md-6">
        <form onSubmit={onSubmit}>
          <FieldNumberBlock
            label="Cверху"
            value={formValue ? formValue.marginTop : NaN}
            name="marginTop"
            onChange={formValueChange}
            error={errorSet('marginTop')}
          />
          <FieldNumberBlock
            label="Справа"
            value={formValue ? formValue.marginRight : NaN}
            name="marginRight"
            onChange={formValueChange}
            error={errorSet('marginRight')}
          />
          <FieldNumberBlock
            label="Снизу"
            value={formValue ? formValue.marginBottom : NaN}
            name="marginBottom"
            onChange={formValueChange}
            error={errorSet('marginBottom')}
          />
          <FieldNumberBlock
            label="Слева"
            value={formValue ? formValue.marginLeft : NaN}
            name="marginLeft"
            onChange={formValueChange}
            error={errorSet('marginLeft')}
          />

          <ButtonsBrick>
            <ButtonSaveBlock
              disabled={!formDirty}
              loading={isSubmitting}
              onClick={onSubmit}
            />
            <ButtonCancelBlock onClick={() => onClose()}/>
          </ButtonsBrick>
        </form>
      </div>
    </div>
  );
};
