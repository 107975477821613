import {
  ButtonEditBlock,
  ButtonCancelBlock,
  CardDataComposition,
  ButtonConfirmComposition,
} from '@app/components';

import { useMainApi } from '../../context';
import styles from './general.module.scss';

export const GeneralComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    isEdited,
    isEditedSet,
    handbkDelete,
  } = useMainApi();

  return (
    <div className={styles['general']}>
      <CardDataComposition
        title="Справочник"
        extra={(<>
          {isEdited ? (
            <ButtonCancelBlock buttonIcon onClick={() => isEditedSet(false)} />
          ) : (<>
            <ButtonEditBlock onClick={() => isEditedSet(true)} />
            <ButtonConfirmComposition
              onClick={() => handbkDelete()}
              message="Текущий справочник и все связанные с ним данные будут удалены."
            />
          </>)}
        </>)}
      >
        {children}
      </CardDataComposition>
    </div>
  );
};
