import { AppBar, Toolbar } from '@mui/material';

import { ToolbarProps } from './toolbar.props';


export const ToolbarBrick = ({
  children,
}: ToolbarProps) => {
  return (
    <AppBar sx={{ position: 'relative' }} color="default">
      <Toolbar style={{ padding: 0 }}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {children}
            </div>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};
