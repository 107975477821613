import { useState } from 'react';
import moment from 'moment';
import { DateTimePicker } from '@mui/x-date-pickers';

import { DateTimePickerProps } from './date-time-picker.props';


export const DateTimePickerBrick = ({
  label,
  value,
  onChange,
  name,
  ampm,
  ampmInClock,
  disabled
}: DateTimePickerProps) => {
  const [validDate, setValidDate] = useState(true);
  const [fieldValue, setFieldValue] = useState(moment(value));

  const fieldChange = (e: any | null) => {
    if (e) {
      setValidDate(e._isValid)
      setFieldValue(e);
      onChange({ target: { name, value: e._d } });
    } else {
      setValidDate(true);
    }
  }

  return (
    <DateTimePicker
      label={label}
      value={fieldValue}
      onChange={fieldChange}
      ampm={ampm}
      ampmInClock={ampmInClock}
      disabled={disabled}
      slotProps={{ textField: { fullWidth: true, error: !validDate, variant: 'filled' } }}
    />
  );
};
