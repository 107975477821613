import React, { SyntheticEvent } from 'react';
import { useMainApi } from '..';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { ChangeFieldEventType, PrintFormType } from '@app/types';
import { errorTranslate } from '@app/helpers';
import { createPrintFormAction } from '@app/stores';


export interface CreateContextInterface {
  formCreateVisible: boolean;
  formValue: Omit<
    PrintFormType,
    'id' |
    'template' |
    'marginTop' |
    'marginRight' |
    'marginBottom' |
    'marginLeft' |
    'documentConfigId' |
    'docxTemplateKey'
  >;
  formDirty: boolean;
  isSubmitting: boolean;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e?: React.SyntheticEvent) => Promise<void>;
  setError: (fieldname: string) => string | null;
};

const initialFormValue = {
  name: '',
  description: '',
  type: ('' as 'html' | 'editor' | 'docx'),
};

export const useCreateHook = (
): CreateContextInterface => {
  const documentConfigId = useAppSelector((s) => s.printForm.documentConfigId);
  const errors = useAppSelector((s) => s.printForm.errors);
  const isSubmitting = useAppSelector((s) => s.printForm.status === 'submitting');
  const dispatch = useAppDispatch();

  const {
    printFormIdCurrent,
    printFormAccess,
    printFormIdSet,
  } = useMainApi();

  const [formValue, setFormValue] = React.useState({...initialFormValue});
  const [sendedForm, setSendedForm] = React.useState(false);
  const [formDirty, setFormDirty] = React.useState(false);

  const formCreateVisible = React.useMemo(() => {
    return printFormIdCurrent === 'create'
    && printFormAccess === true;
  }, [
    printFormAccess,
    printFormIdCurrent,
  ]);

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    setFormDirty(true);
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  }, []);


  const setError = React.useCallback((fieldName: string) => {
    return sendedForm
      ? errors && errors[fieldName] && errorTranslate(errors[fieldName][0])
      : '';
  }, [
    errors,
    sendedForm,
  ]);

  const onSubmit = React.useCallback(async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();
    if (!documentConfigId) return;

    setSendedForm(true);

    const result = await dispatch(createPrintFormAction({
      printForm: {
        documentConfigId,
        ...formValue,
      }
    }));

    if (result.type === '@@print-form/create/fulfilled') {
      setFormDirty(false);
      setSendedForm(false);
      setFormValue(initialFormValue);
      printFormIdSet((result.payload as PrintFormType).id);
    }
  }, [
    dispatch,
    formValue,
    printFormIdSet,
    documentConfigId,
  ]);

  return React.useMemo(() => ({
    formCreateVisible,
    formValue,
    formDirty,
    isSubmitting,
    onSubmit,
    changeFormValue,
    setError,
  }), [
    formCreateVisible,
    formValue,
    formDirty,
    isSubmitting,
    onSubmit,
    changeFormValue,
    setError,
  ]);
};
