import { MainComponent } from './components';
import { DocumentSearchProvider } from './context';
import { DocumentSearchProps } from './document-search.props';


export const DocumentSearchFeature = ({
  opened,
  closeModal,
  canBeUsed,
}: DocumentSearchProps) => {
  return (
    <DocumentSearchProvider
      opened={opened}
      closeModal={closeModal}
      canBeUsed={canBeUsed}
    >
      <MainComponent />
    </DocumentSearchProvider>
  );
}
