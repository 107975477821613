import React from 'react';
import cs from 'classnames';
import { useTheme } from '@mui/material';

import { ChangeFieldEventType } from '@app/types';

import {
  ArrowDownIcon,
  AsteriskBrick,
  CheckboxBrick,
  ChipBrick,
  FormFieldBrick,
  InputBrick,
  MenuItemBrick,
  TypographyBrick,
} from '@app/components';

import { FieldMultiselectProps, FieldSelectItem } from './field-multiselect.props';
import styles from './field-multiselect.module.scss';


const ITEMS_COUNT = 15;

export const FieldMultiselectBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  items,
  hideError,
  required,
  counted,
  prompt,
  disabled,
  enableSearch,
  enablePagination,
  dropdownMaxHeight = 400,
  extra,
}: FieldMultiselectProps) => {
  const [opened, setOpened] = React.useState(false);
  const [filteredItems, setFilteredItems] = React.useState<FieldSelectItem[]>([]);
  const [filterQuery, setFilterQuery] = React.useState('');
  const [loadedCount, setLoadedCount] = React.useState(ITEMS_COUNT);
  const [topValue, setTopValue] = React.useState('100%');
  const [isPositionTop, setIsPositionTop] = React.useState(false);
  const { palette } = useTheme();

  const inputRef = React.useRef(null);

  const displayedItems = React.useMemo(() => {
    if (enablePagination) {
      return filteredItems.slice(0, loadedCount)
    }

    return filteredItems;
  }, [
    filteredItems,
    enablePagination,
    loadedCount,
  ]);

  const updatePositionTop = React.useCallback(() => {
    const dropdown = document.getElementById(`field-multiselect__dropdown--${name}`);
    if (!dropdown || !(inputRef as any).current) {
      setIsPositionTop(false);
      return;
    }

    const inputTop: number = (inputRef as any).current.getBoundingClientRect().top;
    const dropdownHeight = dropdown.getBoundingClientRect().height;
    const screenHeight = window.innerHeight;
    const isTop = (inputTop + dropdownHeight + 100) > screenHeight;

    setIsPositionTop(isTop);
  }, [
    name,
  ]);

  const updateTopValue = React.useCallback(() => {
    if (!opened) return;
    if (!isPositionTop) {
      setTopValue('100%');
      return;
    }

    const dropdown = document.getElementById(`field-multiselect__dropdown--${name}`);
    if (!dropdown) return;

    setTopValue('-' + dropdown.getBoundingClientRect().height + 'px');
  }, [
    isPositionTop,
    name,
    opened,
  ]);

  const loadHandler = React.useCallback(() => {
    setLoadedCount(loadedCount + ITEMS_COUNT);
  }, [
    loadedCount,
  ]);

  const openHandler = React.useCallback(() => {
    if (!disabled) setOpened(true);
  }, [
    disabled,
  ]);

  const closeHandler = React.useCallback(() => {
    setOpened(false);
    setLoadedCount(ITEMS_COUNT);
    setFilterQuery('');
    setFilteredItems(items);
  }, [
    items,
  ]);

  const toggleHandler = React.useCallback(() => {
    if (opened) closeHandler();
    else openHandler();
  }, [
    opened,
    closeHandler,
    openHandler,
  ]);

  const filterItems = React.useCallback((query: string) => {
    const newList = items.filter((item) => {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });

    setFilteredItems(newList);
  }, [
    items,
  ]);

  const filterQueryChangeHandler = React.useCallback((e: ChangeFieldEventType) => {
    setFilterQuery((e.target.value as string));
    filterItems((e.target.value as string));
    setLoadedCount(ITEMS_COUNT);
  }, [
    filterItems,
  ]);
  
  const outsideClickHandler = React.useCallback((e: any) => {
    if (inputRef.current && !(inputRef as any).current.contains(e.target)) {
      closeHandler();
    }
  }, [
    closeHandler,
  ]);

  const selectValue = React.useCallback((selectedValue: string | number) => {
    if (Array.isArray(value) && value.includes(selectedValue)) {
      const newValue = value.filter((v: number | string) => v !== selectedValue);
      onChange({
        target: {
          name,
          value: newValue,
        },
      });
      return;
    }

    if (Array.isArray(value)) {
      onChange({
        target: {
          name,
          value: [...value, selectedValue],
        },
      });
      return;
    }

    onChange({
      target: {
        name,
        value: [selectedValue],
      },
    });
  }, [
    name,
    onChange,
    value
  ])

  React.useEffect(() => {
    document.addEventListener("mousedown", outsideClickHandler);
    return () => {
      document.removeEventListener("mousedown", outsideClickHandler);
    };
  });

  React.useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  React.useEffect(() => {
    setTimeout(() => {
      if (opened) {
        updateTopValue();
        updatePositionTop();
      }
    }, 0);
  }, [
    opened,
    updateTopValue,
    updatePositionTop,
  ]);

  const classnames = cs(
    styles['field-multiselect'],
    { [styles['field-multiselect--opened']]: opened },
    { [styles['field-multiselect--dark']]: palette.mode === 'dark' },
    { [styles['field-multiselect--not-empty']]: Array.isArray(value) && value.length },
  );

  return (
    <div className={classnames}
      ref={inputRef}
    >
      <FormFieldBrick
        error={error}
        hideError={hideError}
        prompt={prompt}
        extra={extra}
      >
          <div className={styles['field-multiselect__input']}
            onClick={toggleHandler}
          >
            <div className={styles['field-multiselect__label']}>
              {label}<AsteriskBrick required={required} counted={counted} />
            </div>
            <div className={styles['field-multiselect__chips']}>
              {(value && value.length) ? (
                value.map((v) => {
                  const item = items.find((item) => item.value === v);
                  if (!item) return '';
                  
                  return (
                    <ChipBrick key={item.value} label={item.label} />
                  );
                })
              ): null}
            </div>
            <div className={styles['field-multiselect__dropdown-icon']}>
              <ArrowDownIcon />
            </div>
          </div>
          {opened && (
            <div className={styles['field-multiselect__dropdown']}
              id={`field-multiselect__dropdown--${name}`}
              style={{ top: topValue }}
            >
              {enableSearch && (
                <div className={styles['field-multiselect__search']}>
                  <InputBrick
                    value={filterQuery}
                    onChange={filterQueryChangeHandler}
                    name="search"
                    label="Поиск"
                    size="small"
                    autoFocus
                  />
                </div>
              )}
              <div className={styles['field-multiselect__items']}
                style={{ maxHeight: (dropdownMaxHeight - (enableSearch ? 72 : 0)) + 'px'}}
              >
                {displayedItems.map((item) => {
                  const selected = Array.isArray(value) && value.includes(item.value);
                  return (
                    <MenuItemBrick
                      onClick={() => selectValue(item.value)}
                      key={item.value}
                      selected={selected}
                    >
                      <CheckboxBrick checked={selected} />
                      <TypographyBrick variant="body1" >{item.label}</TypographyBrick>
                    </MenuItemBrick>
                  );
                })}
                {(enablePagination && loadedCount < filteredItems.length) && (
                  <MenuItemBrick
                    className={styles['field-multiselect__button-load']}
                    onClick={loadHandler}
                  >
                    <ArrowDownIcon />
                  </MenuItemBrick>
                )}
              </div>
              {filteredItems.length === 0 && (
                <div className={styles['field-multiselect__nothing']}>Ничего нет</div>
              )}
            </div>
          )}
      </FormFieldBrick>
    </div>
  );
};
