import React from 'react';
import { useNavigate } from 'react-router-dom';

import { callNotifyAction } from '@app/stores';

import {
  LinkBrick,
  TableContainerBrick,
  TableBrick,
  TableHeadBrick,
  TableBodyBrick,
  TableRowBrick,
  TableCellBrick,
  ButtonCreateBlock,
  CardDataComposition,
} from '@app/components';

import { dateTranslate } from '@app/helpers';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

import { DepartmentType } from '@app/types';
import { DepartmentApi } from '@app/api';


export const DepartmentListFeature = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [departmentList, departmentListSet] = React.useState<DepartmentType[]>([]);
  const [departmentListIsLoading, departmentListIsLoadingSet ] = React.useState(false);
  const hasAccessCreate = useAppSelector((state) => state.auth.user?.role.DEPARTMENT_CREATE !== 'NEVER');

  const getDepartmentList = React.useCallback(async () => {
    departmentListIsLoadingSet(true);
    const response = await DepartmentApi.getList();

    if (!response.success) {
      dispatch(callNotifyAction({
        type: 'error',
        message: 'Не удалось загрузить подразделения',
      }));
      departmentListIsLoadingSet(false);
      return;
    }

    departmentListSet(response.data.departments);
    departmentListIsLoadingSet(false);
  }, [
    dispatch,
  ]);

  React.useEffect(() => {
    getDepartmentList();
  }, [
    getDepartmentList,
  ]);

  return (
    <CardDataComposition
      title="Подразделения"
      extra={
        <>
          {hasAccessCreate && <ButtonCreateBlock buttonIcon onClick={() => navigate('/department-list/department-create')} />}
        </>
      }
      loading={departmentListIsLoading}
      noData={departmentList.length === 0}
    >
      <TableContainerBrick>
        <TableBrick aria-label="departments">
          <TableHeadBrick>
            <TableRowBrick>
              <TableCellBrick>ID</TableCellBrick>
              <TableCellBrick>Название</TableCellBrick>
              <TableCellBrick>Дата создания</TableCellBrick>
              <TableCellBrick>Дата обновления</TableCellBrick>
            </TableRowBrick>
          </TableHeadBrick>
          <TableBodyBrick>
            {departmentList.map((item) => (
              <TableRowBrick
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={item.id}
              >
                <TableCellBrick component="th" scope="row">
                  {item.id}
                </TableCellBrick>
                <TableCellBrick>
                  <LinkBrick
                    to={'/department-list/' + item.id}
                    label={item.name}
                  />
                </TableCellBrick>
                <TableCellBrick>{dateTranslate(item.createdAt)}</TableCellBrick>
                <TableCellBrick>{dateTranslate(item.updatedAt)}</TableCellBrick>
              </TableRowBrick>
            ))}
          </TableBodyBrick>
        </TableBrick>
      </TableContainerBrick>
    </CardDataComposition>
  );
};
