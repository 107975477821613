import {
  TableContainerBrick,
  TableBrick,
  TableHeadBrick,
  TableBodyBrick,
  TableRowBrick,
  TableCellBrick,
} from '@app/components';

import { useMainApi } from '../../context';


export const GeneralTableComponent = () => {
  const {
    isEdited,
    handbk,
  } = useMainApi();

  if (!handbk || isEdited) return null;

  return (
    <TableContainerBrick>
      <TableBrick aria-label="departments">
        <TableHeadBrick>
          <TableRowBrick>
            <TableCellBrick>ID</TableCellBrick>
            <TableCellBrick>Название</TableCellBrick>
            <TableCellBrick>Описание</TableCellBrick>
          </TableRowBrick>
        </TableHeadBrick>
        <TableBodyBrick>
        <TableRowBrick
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCellBrick component="th" scope="row">{handbk.handbkId}</TableCellBrick>
          <TableCellBrick>{handbk.name}</TableCellBrick>
          <TableCellBrick>{handbk.description}</TableCellBrick>
        </TableRowBrick>
        </TableBodyBrick>
      </TableBrick>
    </TableContainerBrick>
  );
}