import {
  TransitionOpacityBrick,
  TableBrick,
  TableBodyBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from '@app/components';

import {
  useMainApi,
} from '../../context';

import {
  accessTranslate,
} from '@app/helpers';

import styles from './document-access-table.module.scss';


export const DocumentAccessTableComponent = () => {
  const {
    roleCurrent,
    documentConfigList,
    isEditingDocumentAccess,
  } = useMainApi();

  if (roleCurrent === null) return null;

  return (
    <>
      {roleCurrent && !isEditingDocumentAccess && (
        <TransitionOpacityBrick>
          <TableContainerBrick className={styles['document-access-table__table-container']}>
            <TableBrick aria-label="role-detail">
              <TableHeadBrick>
                <TableRowBrick>
                  <TableCellBrick>Таблица</TableCellBrick>
                  <TableCellBrick>Создание</TableCellBrick>
                  <TableCellBrick>Чтение</TableCellBrick>
                  <TableCellBrick>Обновление</TableCellBrick>
                  <TableCellBrick>Удаление</TableCellBrick>
                </TableRowBrick>
              </TableHeadBrick>
              <TableBodyBrick>
                {documentConfigList.map((documentConfig) => (
                  <TableRowBrick
                    key={documentConfig.key}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCellBrick component="th" scope="row">{documentConfig.label.singular}</TableCellBrick>
                    <TableCellBrick>{accessTranslate(roleCurrent[documentConfig.key + '_CREATE'])}</TableCellBrick>
                    <TableCellBrick>{accessTranslate(roleCurrent[documentConfig.key + '_READ'])}</TableCellBrick>
                    <TableCellBrick>{accessTranslate(roleCurrent[documentConfig.key + '_UPDATE'])}</TableCellBrick>
                    <TableCellBrick>{accessTranslate(roleCurrent[documentConfig.key + '_DELETE'])}</TableCellBrick>
                  </TableRowBrick>
                ))}
              </TableBodyBrick>
            </TableBrick>
          </TableContainerBrick>
        </TransitionOpacityBrick>
      )}
    </>
  );
};
  