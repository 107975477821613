import { createAsyncThunk } from '@reduxjs/toolkit';

import { callNotifyAction } from '@app/stores';

import {
  DocumentStateInterface,
  DocumentItemType,
} from '@app/types';

import {
  DocumentApi,
  DocumentCreateRequest,
  DocumentDeleteRequest,
  DocumentDeleteResponse,
  DocumentGetListRequest,
  DocumentGetListResponse,
  DocumentGetOneRequest,
  DocumentGetOneResponse,
  DocumentUpdateRequest,
} from '@app/api';


export const createDocumentAction = createAsyncThunk<
  DocumentItemType,
  DocumentCreateRequest,
  { state: { document: DocumentStateInterface } }
>(
  '@@document/create',
  async (payload: DocumentCreateRequest, { rejectWithValue, dispatch }) => {
    const response = await DocumentApi.create(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data.document;
  },
  {
    condition: (request, { getState }) => {
      const { document } = getState();

      if (document[request.key].status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const updateDocumentAction = createAsyncThunk<
  DocumentItemType,
  DocumentUpdateRequest,
  { state: { document: DocumentStateInterface } }
>(
  '@@document/update',
  async (payload: DocumentUpdateRequest, { rejectWithValue, dispatch }) => {
    const response = await DocumentApi.update(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data.document;
  },
  {
    condition: (request, { getState }) => {
      const { document } = getState();

      if (document[request.key].status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const getListDocumentAction = createAsyncThunk<
  DocumentGetListResponse,
  DocumentGetListRequest,
  { state: { document: DocumentStateInterface } }
>(
  '@@document/get-list',
  async (payload: DocumentGetListRequest, { rejectWithValue, dispatch }) => {
    const response = await DocumentApi.getList(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка чтения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data;
  },
  {
    condition: (request, { getState }) => {
      const { document } = getState();

      if (document[request.query.key].status === 'loading') {
        return false;
      }

      return true;
    }
  },
);

export const getOneDocumentAction = createAsyncThunk<
  DocumentGetOneResponse,
  DocumentGetOneRequest,
  { state: { document: DocumentStateInterface } }
>(
  '@@document/get-one',
  async (payload: DocumentGetOneRequest, { rejectWithValue, dispatch }) => {
    const response = await DocumentApi.getOne(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка чтения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data;
  },
  {
    condition: (request, { getState }) => {
      const { document } = getState();

      if (document[request.key].status === 'loading') {
        return false;
      }

      return true;
    }
  },
);

export const deleteDocumentAction = createAsyncThunk<
  DocumentDeleteResponse,
  DocumentDeleteRequest,
  { state: { document: DocumentStateInterface } }
>(
  '@@document/delete',
  async (payload: DocumentDeleteRequest, { rejectWithValue, dispatch }) => {
    dispatch(callNotifyAction({ type: 'info', message: 'loading' }));
    const response = await DocumentApi.delete(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка чтения данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успешно удалены!' }));

    return response.data;
  },
);
