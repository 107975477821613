import {
  FormFieldBrick,
  AsteriskBrick,
  InputBrick,
} from '@app/components';

import { FieldTextProps } from './field-text.props';


export const FieldTextBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  required,
  counted,
  prompt,
  disabled,
}: FieldTextProps) => {
  return (
    <FormFieldBrick error={error} prompt={prompt}>
      <InputBrick
        label={<>{label}<AsteriskBrick required={required} counted={counted} /></>}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </FormFieldBrick>
  );
};
