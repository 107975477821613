import cs from 'classnames';

import {
  CardActionsBrick,
  CardBrick,
  CardContentBrick,
  DividerHorizontalBrick,
  ProgressLinearBrick,
  TypographyBrick,
} from '@app/components';

import { CardDataProps } from './card-data.props';
import styles from './card-data.module.scss';


const CardDataComposition = ({
  children,
  title,
  extra,
  loading,
  noData,
  actions,
  dragHandle,
  mb12,
}: CardDataProps) => {
  const classnames = cs(
    styles['data-card'],
    { [styles['data-card--mb12']]: mb12 },
  );

  return (
    <CardBrick className={classnames}>
      <CardContentBrick className={styles['data-card__header']}>
        <div className={styles['data-card__title']}><TypographyBrick variant="h5">{dragHandle} {title}</TypographyBrick></div>
        <div className={styles['data-card__extra']}>
          {extra}
        </div>
      </CardContentBrick>
      
      <DividerHorizontalBrick />
      {loading && <div className={styles['data-card__loader']}><ProgressLinearBrick /></div>}
      {!loading && children}
      {noData && !loading && <div className={styles['data-card__no-data']}>Нет данных для отображения.</div>}

      {actions && !loading && (
        <>
          <DividerHorizontalBrick />
          <CardActionsBrick>
            {actions}
          </CardActionsBrick>
        </>
      )}
    </CardBrick>
  );
};

export { CardDataComposition };
