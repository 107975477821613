import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import {
  ColumnValueFeature,
} from '@app/components';

import {
  dateTranslate,
  buildColumnValue,
} from '@app/helpers';

import { useAppSelector } from '@app/hooks';

import { DocumentTableProps } from './document-table.props';
import styles from './document-table.module.scss';


const DocumentTableComponent = ({
  documentConfig,
  documentCollection,
  handbkList,
}: DocumentTableProps) => {
  const configurationState = useAppSelector((s) => s.configuration);

  return (
    <div className={styles['document-table']}>
      <TableContainer className={styles['document-table__container']}>
        <Table aria-label={documentConfig.key + '-detail'}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>

              {documentConfig.sortableFields.map((fieldId) => {
                const fieldConfig = documentConfig.fields.find((fieldConfig) => fieldConfig.fieldConfigId === fieldId);

                if (!fieldConfig || !fieldConfig.display.one) {
                  return null;
                }

                return <TableCell key={fieldConfig.key}>{fieldConfig.label}</TableCell>;
              })}
              
              <TableCell>Создал</TableCell>
              <TableCell>Дата создания</TableCell>
              <TableCell>Обновил</TableCell>
              <TableCell>Дата обновления</TableCell>
              <TableCell>Uuid</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              {documentCollection.current && (
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">{documentCollection.current?.id}</TableCell>

                  {documentConfig.sortableFields.map((fieldId) => {
                    const fieldConfig = documentConfig.fields.find((fieldConfig) => fieldConfig.fieldConfigId === fieldId);

                    if (!fieldConfig || !fieldConfig.display.one) {
                      return null;
                    }

                    const value = documentCollection.current ? documentCollection.current[fieldConfig.key] : '';
                    const columnValue = buildColumnValue(fieldConfig, value, configurationState, handbkList);
                    return (
                      <ColumnValueFeature
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        documentKey={documentConfig.key}
                        documentId={documentCollection.current!.id}
                        columnValue={columnValue}
                      />
                    );
                  })}
                  
                  <TableCell>{documentCollection.current?.creator.firstName} {documentCollection.current?.creator.lastName}</TableCell>
                  <TableCell>{dateTranslate(documentCollection.current?.createdAt)}</TableCell>
                  <TableCell>{documentCollection.current?.updater.firstName} {documentCollection.current?.creator.lastName}</TableCell>
                  <TableCell>{dateTranslate(documentCollection.current?.updatedAt)}</TableCell>
                  <TableCell>{documentCollection.current?.uuid}</TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export { DocumentTableComponent };