import { DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';

import {
  TableContainerBrick,
  TableBrick,
  TableHeadBrick,
  TableBodyBrick,
  TableRowBrick,
  TableCellBrick,
  ButtonCreateBlock,
  CardDataComposition,
} from '@app/components';

import { VariantOneComponent } from '../variant-one';

import { useMainApi, useVariantListApi } from '../../context';


export const VariantListComponent = () => {
  const {
    handbk,
    createFormOpenedSet,
    selectedVariantSet,
  } = useMainApi();

  const {
    onDragEnd,
  } = useVariantListApi();

  if (!handbk) return null;

  return (
    <CardDataComposition
      title="Варианты"
      extra={<ButtonCreateBlock buttonIcon onClick={() => createFormOpenedSet(true)} />}
      noData={!handbk.variants.length}
    >
      <DndContext onDragEnd={onDragEnd}>
        <SortableContext items={handbk.sortable}>
          <TableContainerBrick>
            <TableBrick aria-label="handbk-variant">
              <TableHeadBrick>
                <TableRowBrick>
                  <TableCellBrick></TableCellBrick>
                  <TableCellBrick>ID</TableCellBrick>
                  <TableCellBrick>Значение</TableCellBrick>
                  <TableCellBrick></TableCellBrick>
                </TableRowBrick>
              </TableHeadBrick>
              <TableBodyBrick>
                {handbk.sortable.map((variantId) => {
                  const variant = handbk.variants.find((v) => v.id === variantId);

                  if (!variant) return null;

                  return <VariantOneComponent
                          key={variant.id}
                          openUpdateDialog={(variant) => selectedVariantSet(variant)}
                          handbkVariant={variant}
                         />;
                })}
              </TableBodyBrick>
            </TableBrick>
          </TableContainerBrick>
        </SortableContext>
      </DndContext>
    </CardDataComposition>
  );
}
