import {
  DocumentConfigType,
  FieldConfigType,
} from '@app/types';


export const checkVisibleField = (
  documentConfig: DocumentConfigType,
  fieldConfig: FieldConfigType,
  formValue: { [key: string]: any }
): boolean => {
  if (!fieldConfig.visibleRule) {
    return true;
  }

  const { fieldId, type, value } = fieldConfig.visibleRule;
  const targetFieldConfig = documentConfig.fields.find((fConfig) => fConfig.fieldConfigId === fieldId);
  
  if (!targetFieldConfig) return true;
  
  const fieldValue = formValue[targetFieldConfig.key];

  if (type === 'equal' && value && value.length && value[0] === fieldValue) {
    return true;
  }

  if (type === 'contains' && value && value.length && value.every((conVal) => fieldValue ? fieldValue.indexOf(conVal) !== -1 : false)) {
    return true;
  }

  if (type === 'one-of' && value && value.length && value.some((val) => {
    if (Array.isArray(fieldValue)) {
      return fieldValue.indexOf(val) !== -1 || fieldValue.indexOf(String(val)) !== -1;
    }

    return val === fieldValue;
  })) {
    return true;
  }

  if (type === 'not-null') {
    if (fieldValue === undefined) {
      return false;
    }

    if (fieldValue === null) {
      return false;
    }

    if (fieldValue === '') {
      return false;
    }

    if (Array.isArray(fieldValue) && fieldValue.length === 0) {
      return false;
    }

    return true;
  }

  return false;
}