import { monthTranslateHelper } from './month-translate.helper';

export const monthYearTranslateHelper = (date: Date | undefined): string => {
  if (!date) {
    return '';
  }

  
  const dateObj = new Date(date);
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();

  return `${monthTranslateHelper(month)} ${year}`;
}
