import React from 'react';
import { useParams } from 'react-router-dom';

import { callNotifyAction } from '@app/stores';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

import {
  DocumentConfigType,
  RoleType,
  BackendErrorsType,
} from '@app/types';

import { RoleApi } from '@app/api';


export interface MainContextInterface {
  isEditingGeneral: boolean;
  isEditingUserAccess: boolean;
  isEditingDocumentAccess: boolean;
  roleCurrent: RoleType | null;
  hasUpdateAccess: boolean;
  id: string | undefined;
  documentConfigList: DocumentConfigType[];
  roleDetailIsSubmitting: boolean;
  roleDetailIsLoading: boolean;
  errors: BackendErrorsType | null;
  isEditingGeneralSet: (value: boolean) => void;
  isEditingUserAccessSet: (value: boolean) => void;
  isEditingDocumentAccessSet: (value: boolean) => void;
  roleDetailIsSubmittingSet: (value: boolean) => void;
  roleCurrentUpdate: (role: RoleType) => void;
};

export const useMainHook = (
): MainContextInterface => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const hasUpdateAccess = useAppSelector((s) => s.auth.user?.role.ROLE_UPDATE !== 'NEVER');
  const documentConfigList = useAppSelector((s) => s.configuration.documents);

  const [ roleCurrent, roleCurrentSet ] = React.useState<RoleType | null>(null);
  const [ roleDetailIsLoading, roleDetailIsLoadingSet ] = React.useState(false);
  const [ roleDetailIsSubmitting, roleDetailIsSubmittingSet ] = React.useState(false);
  const [ errors, errorsSet ] = React.useState<BackendErrorsType | null>(null);
  const [isEditingGeneral, isEditingGeneralSet] = React.useState(false);
  const [isEditingUserAccess, isEditingUserAccessSet] = React.useState(false);
  const [isEditingDocumentAccess, isEditingDocumentAccessSet] = React.useState(false);

  const roleCurrentUpdate = React.useCallback((role: RoleType) => {
    roleCurrentSet(role);
  }, []);

  const getRoleOne = React.useCallback(async () => {
    roleDetailIsLoadingSet(true);
    const response = await RoleApi.getOne((Number(id)));

    if (!response.success) {
      dispatch(callNotifyAction({
        type: 'error',
        message: 'Не удалось загрузить роль',
      }));

      errorsSet(response.errors as BackendErrorsType);
      return;
    }

    roleCurrentSet(response.data.role);
    roleDetailIsLoadingSet(false);
  }, [
    dispatch,
    id,
  ]);

  React.useEffect(() => {
    getRoleOne()
  }, [
    getRoleOne,
  ]);

  return React.useMemo(() => ({
    isEditingGeneral,
    isEditingUserAccess,
    isEditingDocumentAccess,
    roleCurrent,
    hasUpdateAccess,
    id,
    documentConfigList,
    errors,
    roleDetailIsSubmitting,
    roleDetailIsLoading,
    isEditingGeneralSet,
    isEditingUserAccessSet,
    isEditingDocumentAccessSet,
    roleDetailIsSubmittingSet,
    roleCurrentUpdate,
  }), [
    isEditingGeneral,
    isEditingUserAccess,
    isEditingDocumentAccess,
    roleCurrent,
    hasUpdateAccess,
    id,
    documentConfigList,
    errors,
    roleDetailIsSubmitting,
    roleDetailIsLoading,
    isEditingGeneralSet,
    isEditingUserAccessSet,
    isEditingDocumentAccessSet,
    roleDetailIsSubmittingSet,
    roleCurrentUpdate,
  ]);
};
