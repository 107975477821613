import {
  MainComponent,
} from './components';
import { MainProvider } from './context';


export const PrintFormEditorFeature = () => {
  return (
    <MainProvider>
      <MainComponent />
    </MainProvider>
  );
};
