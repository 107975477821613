import { openDocumentSearchModalAction } from '@app/stores';

import {
  SearchDocumentIcon,
  ButtonFabBrick,
} from '@app/components';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

import styles from './fast-control.module.scss';


export const FastControlPart = () => {
  const dispatch = useAppDispatch();
  const openDialog = () => dispatch(openDocumentSearchModalAction());
  const isLoggedIn = useAppSelector((s) => s.auth.isLoggedIn);

  return (
    <div className={styles['fast-control']}>
      {isLoggedIn && (
        <ButtonFabBrick
          onClick={openDialog}
          color="primary"
        >
          <SearchDocumentIcon />
        </ButtonFabBrick>
      )}
    </div>
  );
};
