import { DialogBrick } from '@app/components';
import { useMainApi } from '../../context';

export const ModalComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    printFormEditorOpened,
    printFormEditorClose,
  } = useMainApi();
  return (
    <DialogBrick
      size="fullscreen"
      opened={printFormEditorOpened}
      onClose={printFormEditorClose}
    >
      {children}
    </DialogBrick>
  );
};
