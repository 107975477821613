import { DocumentConfigType } from '@app/types';

export const getPathsKeys = (documentConfigs: DocumentConfigType[]): string[][] => {
  return documentConfigs
    .filter((documentConfig) => documentConfig.type === 'form-list')
    .map((documentConfig) => {
      const path: string[] = [documentConfig.key];
      let parentKey = documentConfig.parentKey;

      while(true) {
        if (parentKey === 'root') {
          break;
        }

        path.unshift(parentKey);
        parentKey = documentConfigs.find((entity) => entity.key === parentKey)!.parentKey; // eslint-disable-line
      }

      return path;
    });
}

export const buildPaths = (documentConfigs: DocumentConfigType[]): string[] => {
  const paths: string[] = [];
  const pathsKeys = getPathsKeys(documentConfigs);
  const rootKeys = documentConfigs
    .filter((documentConfig) => documentConfig.parentKey === 'root')
    .map((documentConfig) => documentConfig.key);

  rootKeys.forEach((rootKey) => {
    const rootDocumentConfig = documentConfigs.find((documentConfig) => documentConfig.key === rootKey)
    const rootPath = rootDocumentConfig!.type === 'form-list' ? `/${rootDocumentConfig!.key}-list` : `/${rootDocumentConfig!.key}-one`;
    paths.push(rootPath);

    pathsKeys.forEach((pathKeys) => {
      const keyIndex = pathKeys.indexOf(rootKey);

      if (keyIndex !== -1) {
        const keysByIndex = pathKeys.slice(keyIndex);
        const path = rootPath + keysByIndex.reduce((acc, el) => acc + `/${el}-:${el.replaceAll('-', '_')}Id`, '');
        paths.push(path);
      }
    });
  });

  return paths;
}
