import { TextField } from '@mui/material';

import { InputProps } from './input.props';


export const InputBrick = ({
  label,
  name,
  value,
  size = 'medium',
  disabled,
  refEl,
  autoFocus,
  type = 'text',
  hiddenLabel,
  id,
  placeholder,
  onChange,
  onFocus,
  onBlur,
}: InputProps) => {
  return (
    <TextField
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      variant="filled"
      onFocus={onFocus}
      onBlur={onBlur}
      size={size}
      fullWidth
      disabled={disabled}
      ref={refEl}
      autoComplete="off"
      autoFocus={autoFocus}
      hiddenLabel={hiddenLabel}
      id={id}
      placeholder={placeholder}
    />
  );
};
