import {
  ButtonBackBlock,
  ButtonSaveBlock,
  CardDataComposition,
  FieldTextBlock,
  FormCenteredBlock,
} from '@app/components';

import {
  useMainApi,
} from '../../context';


export const MainComponent = () => {
  const {
    formValue,
    formDirty,
    formIsSubmitting,
    changeFormValue,
    onSubmit,
    setError,
  } = useMainApi();

  return (
    <CardDataComposition
      title="Добавить роль"
      extra={<ButtonBackBlock buttonIcon />}
    >
      <FormCenteredBlock
        onSubmit={onSubmit}
        buttons={
          <>
            <ButtonSaveBlock
              loading={formIsSubmitting}
              onClick={onSubmit}
              disabled={!formDirty}
            />
            <ButtonBackBlock />
          </>
        }
      >
        <FieldTextBlock
          name="name"
          label="Название"
          value={formValue.name}
          onChange={changeFormValue}
          error={setError('name')}
          required
        />
      </FormCenteredBlock>
    </CardDataComposition>
  )
}
