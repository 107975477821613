import { useNavigate } from 'react-router-dom';

import { callNotifyAction } from '@app/stores';

import {
  LinkBrick,
  TableContainerBrick,
  TableBrick,
  TableHeadBrick,
  TableBodyBrick,
  TableRowBrick,
  TableCellBrick,
  ButtonCreateBlock,
  CardDataComposition,
} from '@app/components';

import { dateTranslate } from '@app/helpers';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';
import React from 'react';
import { RoleApi } from '@app/api';
import { RoleType } from '@app/types';


export const RoleListFeature = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [ roleList, roleListSet ] = React.useState<RoleType[]>([]);
  const [ roleListIsLoading, roleListIsLoadingSet ] = React.useState(false);

  const hasAccessCreate = useAppSelector((state) => state.auth.user?.role.ROLE_CREATE !== 'NEVER');

  const getRoleList = React.useCallback(async () => {
    roleListIsLoadingSet(true);
    const response = await RoleApi.getList();

    if (!response.success) {
      dispatch(callNotifyAction({
        type: 'error',
        message: 'Ошибка получения данных!',
      }));
      roleListIsLoadingSet(false);
      return;
    }

    roleListSet(response.data.roleList);
    roleListIsLoadingSet(false);
  }, [
    dispatch,
  ]);

  React.useEffect(() => {
    getRoleList();
  }, [
    getRoleList,
  ]);

  return (
    <CardDataComposition
      title="Роли"
      extra={
        <>
          {hasAccessCreate && <ButtonCreateBlock buttonIcon onClick={() => navigate('/role-list/role-create')} />}
        </>
      }
      loading={roleListIsLoading}
      noData={roleList.length === 0}
    >
      <TableContainerBrick>
        <TableBrick aria-label="roles">
          <TableHeadBrick>
            <TableRowBrick>
              <TableCellBrick>ID</TableCellBrick>
              <TableCellBrick>Название</TableCellBrick>
              <TableCellBrick>Дата создания</TableCellBrick>
              <TableCellBrick>Дата обновления</TableCellBrick>
            </TableRowBrick>
          </TableHeadBrick>
          <TableBodyBrick>
            {roleList.map((item) => (
              <TableRowBrick
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={item.id}
              >
                <TableCellBrick component="th" scope="row">
                  {item.id}
                </TableCellBrick>
                <TableCellBrick>
                  <LinkBrick
                    to={'/role-list/' + item.id}
                    label={item.name}
                  />
                </TableCellBrick>
                <TableCellBrick>{dateTranslate(item.createdAt)}</TableCellBrick>
                <TableCellBrick>{dateTranslate(item.updatedAt)}</TableCellBrick>
              </TableRowBrick>
            ))}
          </TableBodyBrick>
        </TableBrick>
      </TableContainerBrick>
    </CardDataComposition>
  );
};
