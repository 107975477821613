import { useTheme } from '@mui/material';
import cs from 'classnames';

import {
  modalHanbkCreateOpenAction,
  modalHanbkDetailOpenAction,
} from '@app/stores';

import {
  TableContainerBrick,
  TableBrick,
  TableHeadBrick,
  TableBodyBrick,
  TableRowBrick,
  TableCellBrick,
  LinkBrick,
  ButtonCreateBlock,
  CardDataComposition,
} from '@app/components';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

import styles from './handbk-list.module.scss';


export const HandbkListFeature = () => {
  const dispatch = useAppDispatch();
  const handbk = useAppSelector((s) => s.configuration.handbk);
  const { palette } = useTheme();

  const classnames = cs(
    styles['handbk-list'],
    { [styles['handbk-list--dark']]: palette.mode === 'dark' },
  );

  return (
    <div className={classnames}>
      <CardDataComposition
        title="Справочники"
        extra={<ButtonCreateBlock buttonIcon onClick={() => dispatch(modalHanbkCreateOpenAction())} />}
        loading={false}
        noData={handbk.length === 0}
      >
        <TableContainerBrick>
          <TableBrick aria-label="handbk-list">
            <TableHeadBrick>
              <TableRowBrick>
                <TableCellBrick>ID</TableCellBrick>
                <TableCellBrick>Название</TableCellBrick>
                <TableCellBrick>Описание</TableCellBrick>
              </TableRowBrick>
            </TableHeadBrick>
            <TableBodyBrick>
              {handbk.map((item) => (
                <TableRowBrick
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={item.handbkId}
                >
                  <TableCellBrick component="th" scope="row">
                    {item.handbkId}
                  </TableCellBrick>
                  <TableCellBrick>
                    <LinkBrick
                      label={item.name}
                      onClick={() => dispatch(modalHanbkDetailOpenAction(item.handbkId))}
                    />
                  </TableCellBrick>
                  <TableCellBrick>{item.description}</TableCellBrick>
                </TableRowBrick>
              ))}
            </TableBodyBrick>
          </TableBrick>
        </TableContainerBrick>
      </CardDataComposition>
    </div>
  );
};
