import React from 'react';

import { DocxContextInterface, useDocxHook } from './docx.hook';


const DocxContext = React.createContext<DocxContextInterface | undefined>(undefined);

export const useDocxApi = () => {
  const context = React.useContext(DocxContext);

  if (context === undefined) {
    throw new Error('useDocxContextApi must be used within DocxProvider');
  }

  return context;
}

export const DocxProvider = (
  props: React.HTMLAttributes<HTMLDivElement>
) => {
  const api = useDocxHook();
  
  return (
    <DocxContext.Provider value={api}>
      {props.children}
    </DocxContext.Provider>
  )
}
