import * as React from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { ChangeFieldEventType, PrintFormType } from '@app/types';
import { updatePrintFormMarginsAction } from '@app/stores';
import { errorTranslate } from '@app/helpers';


export type MainContextInterface = {
  opened: boolean,
  formValue: PrintFormType | null;
  formDirty: boolean;
  isSubmitting: boolean;
  onClose: () => void,
  onSubmit: (e?: React.SyntheticEvent) => void,
  formValueChange: (e: ChangeFieldEventType) => void,
  errorSet: (fieldName: string) => string | null;
};

export const useMainHook = (
  opened: boolean,
  onClose: () => void,
): MainContextInterface => {
  const printForm = useAppSelector((s) => s.printForm.currentPrintForm);
  const errors = useAppSelector((s) => s.printForm.errors);
  const isSubmitting = useAppSelector((s) => s.printForm.status === 'submitting');
  
  const dispatch = useAppDispatch();
  
  const [formValue, formValueSet] = React.useState<PrintFormType | null>(null);
  const [formSended, formSendedSet] = React.useState(false);
  const [formDirty, formDirtySet] = React.useState(false);

  const formValueChange = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      if (state === null) return state;

      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const errorSet = React.useCallback((fieldName: string): string |null => {
    return formSended
      ? errors && errors[fieldName] && errorTranslate(errors[fieldName][0])
      : '';
  }, [
    errors,
    formSended,
  ]);

  const onSubmit = React.useCallback(async (e?: React.SyntheticEvent) => {
    if (e) e.preventDefault();
    formSendedSet(true);

    if (formValue === null) return;

    const result = await dispatch(updatePrintFormMarginsAction({
      printForm: {
        id: formValue.id!,
        marginTop: formValue.marginTop!,
        marginRight: formValue.marginRight!,
        marginBottom: formValue.marginBottom!,
        marginLeft: formValue.marginLeft!,
      }
    }));

    if (result.type === '@@print-form/update-margins/fulfilled') {
      formSendedSet(false);
      formDirtySet(false);
      onClose();
    }
  }, [
    dispatch,
    formValue,
    onClose,
  ]);

  React.useEffect(() => {
    if (printForm === null) return;

    formValueSet({ ...printForm });
  }, [
    printForm,
  ]);
  
  return React.useMemo(() => ({
    opened,
    formValue,
    formDirty,
    isSubmitting,
    onClose,
    onSubmit,
    formValueChange,
    errorSet,
  }), [
    opened,
    formValue,
    formDirty,
    isSubmitting,
    onClose,
    onSubmit,
    formValueChange,
    errorSet,
  ]);
};
