import cs from 'classnames';

import { closeDocumentSearchModalAction } from '@app/stores';

import {
  BreadcrumbsBlock,
  TransitionOpacityBrick,
  FastControlPart,
  DocumentSearchFeature,
} from '@app/components';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

import { AppBarComponent } from './components';
import { PageBaseProps } from './page-base.props';
import styles from './page-base.module.scss';


export const PageBaseLayout = ({
  children,
  breadcrumbs,
  fullwidth,
  col6,
}: PageBaseProps) => {
  const dispatch = useAppDispatch();
  const closeDocumentSearchModal = () => dispatch(closeDocumentSearchModalAction());
  const openedDocumentSearchModal = useAppSelector((s) => s.app.openedDocumentSearchModal);

  const csContent = cs(
    styles['page-base__content'],
    { [styles['page-base__content--fullwidth']]: fullwidth },
    // eslint-disable-next-line
    { ['container']: !fullwidth },
  );

  const csCol = cs(
    ['col'],
    // eslint-disable-next-line
    { ['col-lg-6']: col6 },
  );

  return (
    <div className={styles['page-base']}>
        <div className={styles['page-base__app-bar']}>
          <AppBarComponent />
        </div>
        <div className={csContent}>
          <div className="row justify-content-lg-center">
            <div className={csCol}>
              {breadcrumbs && (<div className={styles['page-base__breadcrumbs']}>
                <BreadcrumbsBlock items={breadcrumbs} />
              </div>)}
              <TransitionOpacityBrick>
                <div className={styles['page-base__children']}>
                  {children}
                </div>
              </TransitionOpacityBrick>
              <FastControlPart />
              <DocumentSearchFeature
                opened={openedDocumentSearchModal}
                closeModal={closeDocumentSearchModal}
              />
            </div>
          </div>
        </div>
    </div>
  );
};
