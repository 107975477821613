import {
  CloseIcon,
  ButtonBrick,
} from '@app/components';

import { ButtonClearProps } from './button-clear.props';


export const ButtonClearBlock = ({
  onClick,
  disabled,
}: ButtonClearProps) => {
  return (
      <ButtonBrick
        color="primary"
        variant="outlined"
        startIcon={<CloseIcon />}
        onClick={onClick}
        size="large"
        disabled={disabled}
      >
        Сбросить
      </ButtonBrick>
  );
};
