import { SyntheticEvent, useState } from 'react';

import {
  RotateRightIcon,
  ButtonBrick,
  ButtonIconBrick,
  InputAdornmentBrick,
  InputOutlinedBrick,
} from '@app/components';

import { PaginationProps } from './pagination.props';
import styles from './pagination.module.scss';


const buildPaginationList = (current: number, count: number) => {
  const paginationList = [];

  for (let i = 1; i <= count; i++) {
    if (
      i === 1
      || i === count
      || i === current
      || i === current + 1
      || i === current + 2
      || i === current - 1
      || i === current - 2
    ) {
      paginationList.push({
        page: i,
        disabled: i === current,
        dots: false,
      });
    }

    if (i === current + 3 || i === current - 3) {
      paginationList.push({
        page: Date.now() - i,
        disabled: true,
        dots: true,
      });
    }
  }

  return paginationList;
}

export const PaginationBlock = ({ current, count, setCurrent }: PaginationProps) => {
  const [currentPage, setCurrentPage] = useState(String(current));
  const paginationList = buildPaginationList(Number(current), count);

  return (
    <div className={styles['pagination']}>
      <div className={styles['pagination__form']}>
        <form
          onSubmit={(e: SyntheticEvent) => {
            e.preventDefault();

            if (Number(currentPage) > count || Number(currentPage) < 1 || Number(currentPage) === current) {
              return;
            }

            setCurrent(Number(currentPage));
          }}
        >
          <InputOutlinedBrick
            label='Перейти на страницу'
            type="number"
            value={currentPage}
            onChange={(e) => setCurrentPage(e.target.value)}
            name="pagination-search-input"
            endAdornment={
              <InputAdornmentBrick position="end">
                <ButtonIconBrick
                  onClick={() => setCurrent(Number(currentPage))}
                  disabled={Number(currentPage) > count || Number(currentPage) < 1 || Number(currentPage) === current}
                >
                  <RotateRightIcon />
                </ButtonIconBrick>
              </InputAdornmentBrick>
            }
          />
        </form>
      </div>
      <div className={styles['pagination__buttons']}>
        {paginationList.map((item) => (
          <ButtonBrick
            key={item.page}
            size={item.dots ? 'small' : 'large'}
            disabled={item.disabled}
            variant="text"
            onClick={() => {
              setCurrent(item.page);
              setCurrentPage(String(item.page));
            }}
          >
            {item.dots ? '..' : item.page}
          </ButtonBrick>
        ))}
      </div>
      <div className={styles['pagination__display']}>
        {current}/{count}
      </div>
    </div>
  );
}
