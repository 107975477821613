import {
  SelectBrick,
  FormFieldBrick,
  AsteriskBrick,
  MenuItemBrick,
} from '@app/components';

import { hadnbkMonthsGetHelper } from '@app/helpers';

import { FieldMonthProps } from './field-month.props';


const items = hadnbkMonthsGetHelper();

export const FieldMonthBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  hideError,
  required,
  counted,
  prompt,
  disabled
}: FieldMonthProps) => {
  return (
    <FormFieldBrick error={error} hideError={hideError} prompt={prompt}>
      <SelectBrick
        name={name}
        value={String(value)}
        onChange={onChange}
        disabled={disabled}
        label={<>{label}<AsteriskBrick required={required} counted={counted} /></>}
      >
        {items.map((item) => (
          <MenuItemBrick
            key={item.value}
            value={item.value}
          >
            {item.label}
          </MenuItemBrick>
        ))}
      </SelectBrick>
    </FormFieldBrick>
  );
};
