import React from 'react';

import { signinAction } from '@app/stores';
import { errorTranslate } from '@app/helpers';

import {
  CardContentBrick,
  CardActionsBrick,
  DividerHorizontalBrick,
  ButtonLoadingBrick,
  FieldTextBlock,
  FieldPasswordBlock,
  CardDataComposition,
} from '@app/components';

import {
  ChangeFieldEventType,
} from '@app/types';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

import styles from './signin-form.module.scss';


export const SigninFormFeature = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);

  const [formValue, setFormValue] = React.useState({ email: '', password: '' });

  const changeFormValue = (e: ChangeFieldEventType): void => {
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async () => {
    dispatch(signinAction(formValue));
  };

  const setError = (fieldName: string) => {
    return auth.errors && auth.errors[fieldName] && errorTranslate(auth.errors[fieldName][0]);
  }

  return (
    <div className={styles['signin-form']}>
      <CardDataComposition
        title='Авторизация'
      >
        <form onSubmit={(e: React.SyntheticEvent) => e.preventDefault()}>
          <CardContentBrick className={styles['signin-form__content']}>
            <FieldTextBlock
              label="Электронная почта"
              name="email"
              value={formValue.email}
              onChange={changeFormValue}
              error={setError('email')}
            />
            <FieldPasswordBlock
              label="Пароль"
              name="password"
              value={formValue.password}
              onChange={changeFormValue}
              error={setError('password')}
            />
          </CardContentBrick>
          <DividerHorizontalBrick />
          <CardActionsBrick>
            <ButtonLoadingBrick
              loading={auth.status === 'submitting'}
              type='submit'
              onClick={onSubmit}
              fullwidth
              size='large'
              variant='contained'
            >
              Авторизация
            </ButtonLoadingBrick>
          </CardActionsBrick>
        </form>
      </CardDataComposition>
    </div>
  );
};
