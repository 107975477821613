import React from 'react';

import {
  TypographyBrick,
  ButtonCancelBlock,
  FieldSelectBlock,
} from '@app/components';

import { useDocumentSearchApi } from '../../context';

import styles from './qr-scanner.module.scss';


export const QrScannerComponent = () => {
  const {
    devices,
    currentDeviceId,
    scanner,
    getDevices,
    setCurrentDeviceId,
    scannerStop,
  } = useDocumentSearchApi();

  React.useEffect(() => {
    getDevices();
  }, [
    getDevices,
  ]);

  return (
    <div className={styles['qr-scanner']}>
      {devices.length > 1 && (
        <FieldSelectBlock
          name="current-device"
          label="Устройство"
          value={currentDeviceId}
          items={devices.map((device) => ({
            label: device.label,
            value: device.id,
          }))}
          onChange={(e) => setCurrentDeviceId((e.target.value as string))}
        />
      )}
      {scanner ? (
        <div className={styles['qr-scanner__button-cancel']}>
          <ButtonCancelBlock onClick={scannerStop} variant="contained" />
        </div>
      ) : (
        <div className={styles['qr-scanner__init-message']}>
          <TypographyBrick variant="body1">Инициализация..</TypographyBrick>
        </div>
      )}
    </div>
  );
};
