import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthApi } from '@app/api';

import {
  callNotifyAction,
} from '@app/stores';

import {
  PersistanceService,
} from '@app/services';

import {
  AuthStateInterface,
  CurrentUserType,
} from '@app/types';

import { SigninRequest } from '@app/api';

export const signinAction = createAsyncThunk<
  CurrentUserType,
  SigninRequest,
  { state: { auth: AuthStateInterface } }
>(
  '@@auth/signin',
  async (payload: SigninRequest, { rejectWithValue, dispatch }) => {
    const response = await AuthApi.signin(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка авторизации!' }));
      return rejectWithValue(response.errors);
    }

    PersistanceService.setToken(response.data.user.token);

    return response.data.user;
  },
  {
    condition: (_, { getState }) => {
      const { auth } = getState();

      if (auth.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const currentUserAction = createAsyncThunk<
  CurrentUserType,
  undefined,
  { state: { auth: AuthStateInterface } }
>(
  '@@auth/current',
  async (_, { rejectWithValue }) => {
    const response = await AuthApi.current();
    
    if (!response.success) {
      return rejectWithValue(response.errors);
    }

    PersistanceService.setToken(response.data.user.token);

    return response.data.user;
  },
  {
    condition: (_, { getState }) => {
      const { auth } = getState();

      if (auth.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const signoutAction = createAsyncThunk<
  void,
  undefined,
  { state: { auth: AuthStateInterface } }
>(
  '@@auth/signout',
  async () => {
    PersistanceService.clearToken();
  },
);


