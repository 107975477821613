import React from 'react';

import {
  GeneralContextInterface,
  useGeneralHook,
} from './general.hook';

const GeneralContext = React.createContext<GeneralContextInterface | undefined>(undefined);

export const useGeneralApi = () => {
  const context = React.useContext(GeneralContext);

  if (context === undefined) {
    throw new Error('useGeneralApi must be used within GeneralProvider');
  }

  return context;
}

export const GeneralProvider = (
  props: React.HTMLAttributes<HTMLDivElement>
) => {
  const api = useGeneralHook();

  return (
    <GeneralContext.Provider value={api}>
      {props.children}
    </GeneralContext.Provider>
  );
};
