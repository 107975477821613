import { useEffect, useRef } from 'react';

import {
  FormFieldBrick,
  AsteriskBrick,
  InputBrick,
} from '@app/components';

import { FieldSnilsProps } from './field-snils.props';


export const FieldSnilsBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  required,
  counted,
  prompt,
  disabled,
}: FieldSnilsProps) => {
  const inputSnils = useRef<HTMLInputElement>(null);

  const handleChange = () => {
    if (!inputSnils.current) {
      return;
    }

    const input = inputSnils.current.querySelectorAll('input')[0];
    const currentFocus = input.selectionStart
    const inputValue = input.value
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);

    const newValue = !inputValue![2]
    ? inputValue![1]
    : `${inputValue![1]}-${inputValue![2]}${`${inputValue![3]
      ? `-${inputValue![3]}`
      : ''}`}${`${inputValue![4] ? ` ${inputValue![4]}` : ''}`}`;

    input.value = newValue

    const focusInText = currentFocus && currentFocus < newValue.length;
    
    if (focusInText) {
      if ((currentFocus === 4 || currentFocus === 8 || currentFocus === 12) && input.value.length > value.length ) {
        input.setSelectionRange(currentFocus + 1, currentFocus + 1);
      } else {
        input.setSelectionRange(currentFocus, currentFocus);
      }
    }

    onChange({ target: { name, value: newValue } });
  };

  useEffect(
    () => {
      handleChange();
    },
    // eslint-disable-next-line
    [value]
  );
  
  return (
    <FormFieldBrick error={error} prompt={prompt}>
      <InputBrick
        label={<>{label}<AsteriskBrick required={required} counted={counted} /></>}
        name={name}
        value={value}
        onChange={handleChange}
        refEl={inputSnils}
        disabled={disabled}
      />
    </FormFieldBrick>
  );
};
