import { FieldDecimalBlock } from '@app/components';
import { DecimalProps } from './decimal.props';


export const DecimalField = ({
  fieldConfig,
  value,
  onChange,
  error,
  disabled,
}: DecimalProps) => {
  return (
    <FieldDecimalBlock
      key={fieldConfig.key}
      name={fieldConfig.key}
      label={fieldConfig.label}
      value={value}
      onChange={onChange}
      error={error}
      counted={fieldConfig.counted}
      required={fieldConfig.required}
      prompt={fieldConfig.prompt}
      disabled={disabled}
    />
  );
};
