import { useState } from 'react';
import { DndContext } from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { documentConfigSortableFieldsAction } from '@app/stores';

import { FieldConfigType } from '@app/types';

import {
  DragIcon,
  LinkBrick,
  CardDataComposition,
} from '@app/components';

import {
  booleanTranslate,
  fieldTypeTranslateHelper,
} from '@app/helpers';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

import { DocumentConfigFieldCreateComponent } from '../document-config-field-create';
import { DocumentConfigFieldUpdateComponent } from '../document-config-field-update';
import { DocumentConfigFieldListProps } from './document-config-field-list.props';
import styles from './document-config-field-list.module.scss';


const Row = ({ fieldConfig, onClick }: { fieldConfig: FieldConfigType, onClick: () => void }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: fieldConfig.fieldConfigId});
  const configuration = useAppSelector((s) => s.configuration);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      {...attributes}
      style={{...style}}
      className={styles['document-config-field-list__row']}
    >
      <TableCell ref={setNodeRef} {...listeners}>
        <div className={styles['document-config-field-list__drag']}>
          <DragIcon />
        </div>
      </TableCell>
      <TableCell component="th" scope="row">{fieldConfig.fieldConfigId}</TableCell>
      <TableCell>
        <LinkBrick
          label={fieldConfig.label}
          onClick={onClick}
        />
      </TableCell>
      <TableCell>{fieldConfig.key}</TableCell>
      <TableCell>{fieldTypeTranslateHelper(fieldConfig, configuration)}</TableCell>
      <TableCell>{booleanTranslate(fieldConfig.required)}</TableCell>
      <TableCell>{booleanTranslate(fieldConfig.counted || fieldConfig.required)}</TableCell>
      <TableCell>{booleanTranslate(fieldConfig.search)}</TableCell>
    </TableRow>
  );
}

const DocumentConfigFieldListComponent = ({
  documentConfig
}: DocumentConfigFieldListProps) => {
  const dispatch = useAppDispatch();
  const [currentFieldConfig, setCurrentFieldConfig] = useState<FieldConfigType | null>(null);
  const onDragEnd = async (e: any) => {
    if (
      e.over === null ||
      e.active.data.current.sortable.index === e.over.data.current.sortable.index
    ) return;

    const sortable = [...documentConfig.sortableFields];

    const sortableToUp = e.active.data.current.sortable.index > e.over.data.current.sortable.index;
    const dragableIndex = e.active.data.current.sortable.index;
    const endIndex = e.over.data.current.sortable.index;

    sortable.splice(endIndex + (sortableToUp ? 0 : 1), 0, sortable[dragableIndex]);
    sortable.splice(dragableIndex + (sortableToUp ? 1 : 0), 1);

    await dispatch(documentConfigSortableFieldsAction({
      documentConfigId: documentConfig.documentConfigId,
      sortable,
    }));
  };

  return (
    <div className={styles['document-config-field-list']}>
      <DocumentConfigFieldUpdateComponent
        fieldConfig={currentFieldConfig}
        documentConfig={documentConfig}
        closeDialog={() => setCurrentFieldConfig(null)}
      />
      <CardDataComposition
        title="Поля коллекции"
        extra={<DocumentConfigFieldCreateComponent documentConfig={documentConfig} />}
        noData={documentConfig.fields.length === 0}
      >
        <DndContext onDragEnd={onDragEnd}>
          <SortableContext items={documentConfig.sortableFields}>
          <TableContainer>
            <Table aria-label="departments">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Название</TableCell>
                  <TableCell>Ключ</TableCell>
                  <TableCell>Тип</TableCell>
                  <TableCell>Обязтельное</TableCell>
                  <TableCell>Подсчет</TableCell>
                  <TableCell>Поиск</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  {documentConfig.sortableFields.map((fieldConfigId) => {
                    const fieldConfig = documentConfig.fields.find((fieldConfig) => fieldConfig.fieldConfigId === fieldConfigId);

                    if (!fieldConfig) {
                      return null;
                    }

                    return (
                      <Row
                        key={fieldConfig.fieldConfigId}
                        fieldConfig={fieldConfig}
                        onClick={() => setCurrentFieldConfig(fieldConfig)}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          </SortableContext>
        </DndContext>
      </CardDataComposition>
    </div>
  );
};

export { DocumentConfigFieldListComponent };
