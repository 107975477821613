import { FormControlLabel, Checkbox } from '@mui/material';

import { CheckboxWithLabelProps } from './checkbox-with-label.props';


export const CheckboxWithLabelBrick = ({
  label,
  checked,
  onChange,
  disabled,
}: CheckboxWithLabelProps) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
      }
      label={label}
    />
  );
}
