import {
  HttpService,
} from '@app/services';

import {
  BaseResponseInterface,
} from '@app/types';

import {
  DocumentConfigCreateRequest,
  DocumentConfigCreateResponse,
  DocumentConfigDeleteRequest,
  DocumentConfigDeleteResponse,
  DocumentConfigSortableChildrensRequest,
  DocumentConfigSortableChildrensResponse,
  DocumentConfigSortableFieldsRequest,
  DocumentConfigSortableFieldsResponse,
  DocumentConfigUpdateRequest,
  DocumentConfigUpdateResponse,
} from './types';


export class DocumentConfigApi {
  public static async create(
    payload: DocumentConfigCreateRequest,
  ): Promise<BaseResponseInterface<DocumentConfigCreateResponse>> {
    return await HttpService.sendRequest('POST', '/document-config/create', {
      documentConfig: payload,
    });
  };

  public static async update(
    payload: DocumentConfigUpdateRequest,
  ): Promise<BaseResponseInterface<DocumentConfigUpdateResponse>> {
    return await HttpService.sendRequest('PUT', '/document-config/update', {
      documentConfig: payload,
    });
  };

  public static async sortableFields(
    payload: DocumentConfigSortableFieldsRequest,
  ): Promise<BaseResponseInterface<DocumentConfigSortableFieldsResponse>> {
    return await HttpService.sendRequest('PUT', '/document-config/sortable-fields', payload);
  };

  public static async sortableChildrens(
    payload: DocumentConfigSortableChildrensRequest,
  ): Promise<BaseResponseInterface<DocumentConfigSortableChildrensResponse>> {
    return await HttpService.sendRequest('PUT', '/document-config/sortable-childrens', payload);
  };

  public static async delete(
    payload: DocumentConfigDeleteRequest,
  ): Promise<BaseResponseInterface<DocumentConfigDeleteResponse>> {
    return await HttpService.sendRequest('DELETE', '/document-config/delete', payload);
  };
};
