import { Button } from '@mui/material';

import { ButtonProps } from './button.props';


export const ButtonBrick = ({
  children,
  type,
  onClick,
  size = 'medium',
  startIcon,
  variant = 'outlined',
  color = 'primary',
  disabled,
  fullWidth,
  autoFocus,
}: ButtonProps) => {
  return (
    <Button
      type={type}
      onClick={onClick}
      size={size}
      startIcon={startIcon}
      variant={variant}
      color={color}
      disabled={disabled}
      fullWidth={fullWidth}
      autoFocus={autoFocus}
    >
      {children}
    </Button>
  );
}
