import {
  CardHeaderBrick,
  DialogBrick,
  ButtonCloseBlock,
} from '@app/components';

import { useAppSelector } from '@app/hooks';

import { useMainApi } from '../../context';
import { ModalProps } from './modal.props';


export const ModalComponent = ({
  children,
}: ModalProps) => {
  const printFormListModalOpened = useAppSelector((s) => s.printForm.printFormListModalOpened);
  const { printFormModalClose } = useMainApi();

  return (
    <DialogBrick
      opened={printFormListModalOpened}
      onClose={printFormModalClose}
      size="large"
    >
      <CardHeaderBrick
        title="Печатные формы"
        extra={<ButtonCloseBlock onClick={printFormModalClose} />}
      />
        {children}
    </DialogBrick>
  );
};
