import {
  ImageLibraryComponent,
  MarginsComponent,
  TextAreaComponent,
  ToolPanelComponent,
  VarsComponent,
} from '..';


export const MainComponent = () => {
  return (
    <>
      <ToolPanelComponent />
      <TextAreaComponent />

      <VarsComponent />
      <MarginsComponent />
      <ImageLibraryComponent />
    </>
  );
};
