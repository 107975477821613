import { ChangeFieldEventType } from '@app/types';

import {
  FormFieldBrick,
  AsteriskBrick,
  RadioGroupBrick,
  RadioBrick,
} from '@app/components';

import { FieldRadioProps } from './field-radio.props';


export const FieldRadioBlock = ({
  error,
  hideError,
  prompt,
  name,
  value,
  label,
  required,
  counted,
  onChange,
  items,
  // disabled
}: FieldRadioProps) => {
  const handleChange = (event: ChangeFieldEventType) => {
    onChange({
      target: {
        name: event.target.name,
        value: Number(event.target.value),
      },
    })
  };

  return (
    <FormFieldBrick error={error} hideError={hideError} prompt={prompt}>
      <RadioGroupBrick
        label={<>{label}<AsteriskBrick required={required} counted={counted} /></>}
        name={name}
        value={value}
        onChange={handleChange}
      >
        {items.map((item) => {
          return (
            <RadioBrick
              key={item.value}
              value={item.value}
              label={item.label}
            />
          );
        })}
      </RadioGroupBrick>
    </FormFieldBrick>
  );
};
