import {
  ButtonCloseBlock,
  CardHeaderBrick,
} from '@app/components';

import { useMainApi } from '../../context';


export const HeaderComponent = () => {
  const {
    onClose,
  } = useMainApi();

  return (
    <CardHeaderBrick
      title="Отступы"
      extra={<ButtonCloseBlock onClick={() => onClose()} />}
    />
  );
};
