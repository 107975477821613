import {
  FormFieldBrick,
  AsteriskBrick,
  CheckboxWithLabelBrick,
  MulticheckBrick,
} from '@app/components';

import { FieldMulticheckProps } from './field-multicheck.props';


export const FieldMulticheckBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  items,
  hideError,
  required,
  counted,
  prompt,
  disabled,
}: FieldMulticheckProps) => {
  const onChangeHandler = (id: number) => {
    if (value && value.indexOf(id) !== -1) {
      onChange({
        target: {
          name,
          value: value.filter((i) => i !== id),
        },
      });
    } else {
      onChange({
        target: {
          name,
          value: [...value, id],
        },
      });
    }
  }

  return (
    <FormFieldBrick
      error={error}
      hideError={hideError}
      prompt={prompt}
    >
      <MulticheckBrick
        name={name}
        label={<>{label}<AsteriskBrick required={required} counted={counted} /></>}
        disabled={disabled}
      >
        {items.map((item) => {
          const checked = Array.isArray(value) && value.indexOf(item.value) !== -1;

          return (
            <CheckboxWithLabelBrick
              key={item.value}
              label={item.label}
              checked={checked}
              onChange={() => onChangeHandler(item.value)}
            />
          );
        })}
      </MulticheckBrick>
    </FormFieldBrick>
  );
};
