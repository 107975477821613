import React from 'react';

import { callNotifyAction } from '@app/stores';
import { useAppDispatch } from '@app/hooks';

import {
  DepartmentType,
  ChangeFieldEventType,
  BackendErrorsType,
} from '@app/types';

import { errorTranslate } from '@app/helpers';
import { useMainApi } from '..';
import { DepartmentApi } from '@app/api';

export interface GeneralContextInterface {
  formValue: DepartmentType | null;
  formDirty: boolean;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onDone: () => void;
  onSubmit: (e?: React.SyntheticEvent) => Promise<void>;
  setError: (fieldName: string) => string | null;
};

export const useGeneralHook = (
): GeneralContextInterface => {
  const dispatch = useAppDispatch();
  const {
    departmentCurrent,
    errors,
    isEditingSet,
    departmentCurrentSet,
    departmentDetailIsSubmittingSet,
    errorsSet,
  } = useMainApi();

  const [formValue, formValueSet] = React.useState<DepartmentType | null>(null);
  const [formDirty, formDirtySet] = React.useState(false);

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      if (state === null) return null;

      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const onDone = React.useCallback(() => {
    isEditingSet(false);
  }, [
    isEditingSet,
  ]);

  const onSubmit = React.useCallback(async (e?: React.SyntheticEvent) => {
    departmentDetailIsSubmittingSet(true);
    if (e) e.preventDefault();
    if (formValue === null) return; 

    const response = await DepartmentApi.update(formValue);

    if (!response.success) {
      dispatch(callNotifyAction({
        type: 'error',
        message: 'Не удалось обновить подразделение',
      }));

      departmentDetailIsSubmittingSet(false);
      errorsSet(response.errors as BackendErrorsType);
      return;
    }

    dispatch(callNotifyAction({
      type: 'success',
      message: 'Подразделение успешно обновлено',
    }));

    departmentCurrentSet(response.data.department);

    departmentDetailIsSubmittingSet(false);
    onDone();
  }, [
    formValue,
    dispatch,
    onDone,
    departmentDetailIsSubmittingSet,
    departmentCurrentSet,
    errorsSet,
  ]);

  const setError = React.useCallback((fieldName: string) => {
    return errors && errors[fieldName] && errorTranslate(errors[fieldName][0]);
  }, [
    errors,
  ]);

  React.useEffect(() => {
    formValueSet(departmentCurrent);
  }, [
    departmentCurrent,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    changeFormValue,
    onDone,
    onSubmit,
    setError,
  }), [
    formValue,
    formDirty,
    changeFormValue,
    onDone,
    onSubmit,
    setError,
  ]);
};
