import { FormGroup, InputLabel } from '@mui/material';
import { MulticheckProps } from './multicheck.props';

export const MulticheckBrick = ({
  label,
  children,
  name,
  disabled,
}: MulticheckProps) => {
  return (
    <>
      <InputLabel disabled={disabled} id={name + '-label'}>{label}</InputLabel>
      <FormGroup>
        {children}
      </FormGroup>
    </>
  );
};
