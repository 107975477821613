import { GridProps } from './grid.props';
import styles from './grid.module.scss';


export const GridLayout = ({
  children,
}: GridProps) => {
  return (
    <div className={styles['grid']}>
      {children}
    </div>
  );
};
