import { ChangeEvent, useRef, useState } from 'react';
import cs from 'classnames';
import { useTheme } from '@mui/material';

import { FormFileApi } from '@app/api';

import {
  CheckIcon,
  DeleteIcon,
  SaveIcon,
  FormFieldBrick,
  AsteriskBrick,
  ListItemBrick,
  ButtonLoadingBrick,
  ButtonIconBrick,
  ListItemTextBrick,
  ListItemAvatarBrick,
  AvatarBrick,
} from '@app/components';

import { FieldFileProps } from './field-file.props';
import styles from './field-file.module.scss';


export const FieldFileFeature = ({
  value,
  onChange,
  disabled,
  size,
  variant,
  prompt,
  error,
  label,
  type,
  counted,
  required,
  name,
  fieldConfigId,
  documentConfigKey,
  documentId,
  fieldConfigKey,
}: FieldFileProps) => {
  const hiddenFileInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState('');
  const [dirty, setDirty] = useState(false);
  const { palette } = useTheme();

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    setLoading(true);
    
    const fileUploaded = e.target.files[0];
    const result = await FormFileApi.upload(
      fileUploaded,
      fieldConfigId,
    );

    if (result.success) {
      const id = result.data.formFile.id;

      onChange({
        target: {
          name,
          value: id,
        }
      });

      setFileError('');
      setDirty(true);
    }

    if (result.errors === 'Invalid Format') {
      setFileError('Некорректный формат файла')
    }

    if (result.errors === 'Invalid Size') {
      setFileError('Превышен размер файла')
    }

    setLoading(false);
  };

  const handleClick = () => {
    (hiddenFileInput.current as any).click();
  };

  const buildExtPrompt = (): string | null => {
    if (type === 'image') return '*.jpg, *.jpeg, *.png, *.webp';
    if (type === 'word') return '*.doc, *.docx, *.rtf';
    if (type === 'excel') return '*.xls, *.xlsx, *.csv';
    if (type === 'archive') return '*.rar, *.zip';
    if (type === 'txt') return '*.txt';
    if (type === 'pdf') return '*.pdf';

    return null;
  };

  const buildExtAccept = (): string => {
    if (type === 'image') return 'image/*';
    if (type === 'word') return '.doc, .docx, .rtf';
    if (type === 'excel') return '.xls, .xlsx, .csv';
    if (type === 'archive') return '.rar, .zip';
    if (type === 'txt') return '.txt';
    if (type === 'pdf') return 'application/pdf';

    return '*';
  }

  const deleteFile = () => {
    onChange({
      target: {
        name,
        value: null
      }
    });
  };

  const viewFile = async () => {
    if (value === null) {
      return;
    }

    const result = dirty
      ? await FormFileApi.getOneMyById({ id: value })
      : await FormFileApi.getOneMyByDocument({
        documentKey: documentConfigKey,
        fieldKey: fieldConfigKey,
        documentId: documentId!,
      });

    if (!result.success) {
      return;
    }

    const src = result.data.formFile.src;

    window.open(src);
  };

  const classnames = cs(
    styles['field-file'],
    { [styles['field-file--dark']]: palette.mode === 'dark' },
  );
 
  return (
    <FormFieldBrick error={fileError || error} prompt={prompt}>
      <div className={classnames}>
        <div className={styles['field-file__inner']}>
          <div className={styles['field-file__label']}>{label}<AsteriskBrick counted={counted} required={required} /></div>
          <div className={styles['field-file__list']}>
            {value ? (
              <ListItemBrick
                secondaryAction={
                  <ButtonIconBrick
                    edge="end"
                    aria-label="delete"
                    onClick={deleteFile}
                  >
                    <DeleteIcon />
                  </ButtonIconBrick>
                }
              >
                <ListItemAvatarBrick>
                  <AvatarBrick>
                    <CheckIcon />
                  </AvatarBrick>
                </ListItemAvatarBrick>
                <ListItemTextBrick
                  primary="Просмотр файла"
                  onClick={viewFile}
                  className={styles['field-file__item']}
                />
              </ListItemBrick>
            ) : (
              <>
                <div className={styles['field-file__button']}>
                  <ButtonLoadingBrick
                    onClick={handleClick}
                    size={size || 'large'}
                    startIcon={<SaveIcon />}
                    variant={variant || 'contained'}
                    color="primary"
                    disabled={disabled}
                    loading={loading}
                  >
                    Загрузить файл
                  </ButtonLoadingBrick>
                  <input
                    type="file"
                    onChange={handleChange}
                    ref={hiddenFileInput}
                    className={styles['field-file__input-file']} // Make the file input element invisible
                    accept={buildExtAccept()}
                  />
                </div>
                <div className={styles['field-file__prompt']}>{buildExtPrompt()}</div>
              </>
            )}
          </div>
        </div>
      </div>
    </FormFieldBrick>
  );
};
