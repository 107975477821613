import { MainComponent } from './components';
import { MainProvider } from './context';


export const DepartmentCreateFeature = () => {
  return (
    <MainProvider>
      <MainComponent />
    </MainProvider>
  );
}
