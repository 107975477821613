import { SyntheticEvent } from 'react';
import cs from 'classnames';

import { ButtonIconSquareProps } from './button-icon-square.props';
import styles from './button-icon-square.module.scss';
import { useTheme } from '@mui/material';


export const ButtonIconSquareBrick = ({
  icon,
  active,
  onClick,
  size = 'medium',
}: ButtonIconSquareProps) => {
  const { palette } = useTheme();

  const classnames = cs(
    styles['button-icon-square'],
    { [styles['button-icon-square--active']]: active },
    { [styles['button-icon-square--dark']]: palette.mode === 'dark' },
    { [styles[`button-icon-square--size-${size}`]]: true },
  );

  const onClickHandler = (e: SyntheticEvent) => {
    e.preventDefault();
    onClick();
  }

  return (
    <button
      onClick={onClickHandler}
      className={classnames}
    >
      {icon}
    </button>
  );
};
