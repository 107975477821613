import * as React from 'react';
import { ModalsContextInterface, useModalsHook } from './modals.hook';



const ModalsContext = React.createContext<ModalsContextInterface | undefined>(undefined);

export const useModalsApi = () => {
  const context = React.useContext(ModalsContext);
  if (context === undefined) {
    throw new Error('useModalsApi must be used within ModalsProvider');
  }
  
  return context;
}

export const ModalsProvider = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const modalsApi = useModalsHook();
  
  return (
    <ModalsContext.Provider value={modalsApi}>
      {children}
    </ModalsContext.Provider>
  );
}
