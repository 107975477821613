import {
  ThemeProviderPart,
  RouterOutletPart,
  NotifyPart,
  LoaderPart,
  StoreProviderPart,
  LocalizationProviderPart,
} from '@app/components';


export const AppComponent = (): JSX.Element => {
  return (
    <StoreProviderPart>
      <LocalizationProviderPart>
        <ThemeProviderPart>
          <RouterOutletPart />
          <NotifyPart />
          <LoaderPart />
        </ThemeProviderPart>
      </LocalizationProviderPart>
    </StoreProviderPart>
  );
};
