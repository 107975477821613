import { useState } from 'react';

import {
  CloseIcon,
  DialogBrick,
  DialogTitleBrick,
  DialogContentBrick,
  DialogContentTextBrick,
  DialogActionsBrick,
  ButtonBrick,
  ButtonSaveBlock,
  ButtonCancelBlock,
  ButtonCloseBlock,
} from '@app/components';

import { ButtonHasChangesProps } from './button-has-changes.props';
import styles from './button-has-changes.module.scss';


export const ButtonHasChangesComposition = ({
  hasChanges,
  onSave,
  onDiscard,
  message,
  loading,
}: ButtonHasChangesProps) => {
  const [opened, setOpened] = useState(false);

  const save = async () => {
    await onSave();
    setOpened(false);
  }
  const discard = () => {
    setOpened(false);
    onDiscard();
  }

  return (
    <div className={styles['button-has-changes']}>
      <ButtonCloseBlock onClick={() => {
        if (hasChanges) {
          setOpened(true)
        } else {
          onDiscard();
        }
      }} />

      <DialogBrick
        opened={opened}
        onClose={() => setOpened(false)}
        size="small"
      >
        <DialogTitleBrick id="alert-dialog-title">Документ был изменен.</DialogTitleBrick>
        <DialogContentBrick className={styles['button-has-changes__content']}>
          <DialogContentTextBrick id="alert-dialog-description">
            { message ? message : 'Хотите сохранить изменения?' }
          </DialogContentTextBrick>
        </DialogContentBrick>
        <DialogActionsBrick>
          <ButtonSaveBlock loading={loading} onClick={save} />
          <ButtonBrick
            size="large"
            variant="outlined"
            onClick={discard}
            startIcon={<CloseIcon />}
          >
            Не сохранять
          </ButtonBrick>
          <ButtonCancelBlock onClick={() => setOpened(false)} />
        </DialogActionsBrick>
      </DialogBrick>
    </div>
  );
};
