import { ConfigurationStateInterface, FieldConfigType } from '@app/types';

export const findFieldConfigHelper = (
  configurationState: ConfigurationStateInterface,
  documentConfigKey?: string,
  fieldConfigKey?: string,
): FieldConfigType | undefined => {
  return configurationState
      .documents
      .find((documentConfig) => documentConfig.key === documentConfigKey)!
      .fields
      .find((fieldConfig) => fieldConfig.key === fieldConfigKey);
}