import {
  HttpService,
} from '@app/services';

import { BaseResponseInterface } from '@app/types';

import {
  HandbkDocumentListRequest,
  HandbkDocumentListResponse,
} from './types';


export class HandbkDocumentApi {
  public static async list(
    payload: HandbkDocumentListRequest,
  ): Promise<BaseResponseInterface<HandbkDocumentListResponse>> {
    return await HttpService.sendRequest('POST', '/handbk-document/list', payload);
  };
};
