import { useEffect } from 'react';

import { setBreadcrumbs } from '@app/stores';

import {
  PageBaseLayout,
  DepartmentCreateFeature,
} from '@app/components';

import { useAppDispatch, useAppSelector } from '@app/hooks';


export const DepartmentCreatePage = () => {
  const dispatch = useAppDispatch();
  const { breadcrumbs } = useAppSelector((s) => s.app);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { title: 'Главная', link: '/' },
      { title: 'Подразделения', link: '/department-list' },
      { title: 'Добавить подразделение', link: '' },
    ]))
  }, [dispatch]);

  return (
    <PageBaseLayout breadcrumbs={breadcrumbs}>
      <DepartmentCreateFeature />
    </PageBaseLayout>
  );
};
