import React from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector } from '@app/hooks';
import { UserType } from '@app/types';


export interface MainContextInterface {
  id: string | undefined;
  userIsLoading: boolean;
  hasUpdateAccess: boolean;
  isEditing: boolean;
  modalChangePasswordOpened: boolean;
  userCurrent: UserType | null;
  editorClose: () => void;
  editorOpen: () => void;
  modalChangePasswordOpen: () => void;
  modalChangePasswordClose: () => void;
};

export const useMainHook = (
): MainContextInterface => {
  const { id } = useParams();
  const userIsLoading = useAppSelector((state) => state.user.status === 'loading');
  const hasUpdateAccess = useAppSelector((state) => state.auth.user?.role.USER_UPDATE !== 'NEVER');
  const userCurrent = useAppSelector((state) => state.user.current);
  const [isEditing, isEditingSet] = React.useState(false);
  const [modalChangePasswordOpened, modalChangePasswordOpenedSet] = React.useState(false);

  const editorClose = React.useCallback(() => {
    isEditingSet(false);
  }, [
    isEditingSet,
  ]);

  const editorOpen = React.useCallback(() => {
    isEditingSet(true);
  }, [
    isEditingSet,
  ]);

  const modalChangePasswordOpen = React.useCallback(() => {
    modalChangePasswordOpenedSet(true);
  }, [
    modalChangePasswordOpenedSet,
  ]);

  const modalChangePasswordClose = React.useCallback(() => {
    modalChangePasswordOpenedSet(false);
  }, [
    modalChangePasswordOpenedSet,
  ]);

  return React.useMemo(() => ({
    id,
    userIsLoading,
    hasUpdateAccess,
    isEditing,
    modalChangePasswordOpened,
    userCurrent,
    editorClose,
    editorOpen,
    modalChangePasswordOpen,
    modalChangePasswordClose,
  }), [
    id,
    userIsLoading,
    hasUpdateAccess,
    isEditing,
    modalChangePasswordOpened,
    userCurrent,
    editorClose,
    editorOpen,
    modalChangePasswordOpen,
    modalChangePasswordClose,
  ]);
};
