import React from 'react';
import { Menu } from '@mui/material';

import { MenuDropdownProps } from './menu-dropdown.props';


export const MenuDropdownBrick = ({
  children,
  button
}: MenuDropdownProps) => {
  const [ anchorEl, anchorElSet ] = React.useState<null | HTMLElement>(null);
  
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    anchorElSet(event.currentTarget);
  };

  const handleClose = () => {
    anchorElSet(null);
  };
  
  return (
    <>
      <div onClick={onClick}>
        {button}
      </div>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </>
  );
};
