import { useState } from 'react';

import {
  ShowIcon,
  HideIcon,
  FormFieldBrick,
  AsteriskBrick,
  InputFilledBrick,
  ButtonIconBrick,
  InputAdornmentBrick,
} from '@app/components';

import { FieldPasswordProps } from './field-password.props';


export const FieldPasswordBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  required,
  counted,
  prompt,
}: FieldPasswordProps) => {
  const [visiblePassword, setVisiblePassword] = useState(false);
  
  return (
    <FormFieldBrick error={error} prompt={prompt}>
      <InputFilledBrick
        labelField={<>{label}<AsteriskBrick required={required} counted={counted} /></>}
        type={visiblePassword ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        name={name ? name : 'password'}
        endAdornment={
          <InputAdornmentBrick position="end">
            <ButtonIconBrick
              onClick={() => setVisiblePassword((prev) => !prev)}
            >
              {visiblePassword ? <HideIcon /> : <ShowIcon />}
            </ButtonIconBrick>
          </InputAdornmentBrick>
        }
      />
    </FormFieldBrick>
  );
};
