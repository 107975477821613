import { SyntheticEvent, useState } from 'react';

import {
  ChangeFieldEventType,
} from '@app/types';

import {
  ButtonSearchBlock,
  ButtonClearBlock,
  FieldBooleanBlock,
  FieldSelectBlock,
  FieldTextBlock,
  FieldPhoneBlock,
  FieldSnilsBlock,
} from '@app/components';

import { useAppSelector } from '@app/hooks';

import { DocumentSearchProps } from './document-search.props';
import styles from './document-search.module.scss';


const DocumentSearchComponent = ({ documentConfig, onSearchHandler, searchQuery }: DocumentSearchProps) => {
  const columnsForSearch = documentConfig.fields.filter((column) => column.search);
  const initialFormValue: { [key: string]: any } = {
    creator: '',
  };
  
  Object.keys(searchQuery).forEach((key) => {
    const strongKey = key.split('_')[1];
    initialFormValue[strongKey] = searchQuery[key];
  });

  columnsForSearch.forEach((column) => {
    if (!initialFormValue[column.key]) {
      initialFormValue[column.key] = '';
    }
  });

  const [formValue, setFormValue] = useState(initialFormValue);
  const [formDirty, setFormDirty] = useState(false);
  const creators = useAppSelector((state) => state.user.dictionary);

  const changeFormValue = (e: ChangeFieldEventType | null): void => {
    setFormDirty(true);
    setFormValue((state) => {
      if (!e) return state;

      return {
        ...state,
        [e!.target.name]: e!.target.value,
      }
    });
  };

  const onSubmitForm = (e?: SyntheticEvent) => {
    if (e) e.preventDefault();

    const query: { [key: string]: any } = {};

    if (formValue.creator) {
      query.search_creator = formValue.creator;
    }

    Object.keys(formValue).forEach((key) => {
      if (formValue[key] && key !== 'creator') {
        query[`search_${key}`] = formValue[key];
      }
    });

    onSearchHandler(query);
  }

  return (
    <div className="row justify-content-lg-center">
      <div className="col col-lg-6">
        <div className={styles['entity-search']}>
          <form onSubmit={(e: SyntheticEvent) => onSubmitForm(e)}>
            {columnsForSearch.map((column) => {
              if (column.type === 'select') {
                return (
                  <FieldSelectBlock
                    key={column.key}
                    label={column.label}
                    name={column.key}
                    value={formValue[column.key]}
                    onChange={changeFormValue}
                    items={column.variants!.map((item) => ({ label: item, value: item }))}
                  />
                );
              } else if (column.type === 'snils') {
                return (
                  <FieldSnilsBlock
                    key={column.key}
                    label={column.label}
                    name={column.key}
                    value={formValue[column.key]}
                    onChange={changeFormValue}
                  />
                );
              } else if (column.type === 'boolean') {
                return (
                  <FieldBooleanBlock
                    key={column.key}
                    label={column.label}
                    name={column.key}
                    value={formValue[column.key]}
                    onChange={changeFormValue}
                  />
                );
              } else if (column.type === 'phone') {
                return (
                  <FieldPhoneBlock
                    key={column.key}
                    label={column.label}
                    name={column.key}
                    value={formValue[column.key]}
                    onChange={changeFormValue}
                  />
                );
              }

              return (
                <FieldTextBlock
                  key={column.key}
                  label={column.label}
                  name={column.key}
                  value={formValue[column.key]}
                  onChange={changeFormValue}
                />
              );
            })}
            <FieldSelectBlock
              label="Пользователь"
              name="creator"
              value={formValue.creator}
              onChange={changeFormValue}
              items={creators.map((creator) => ({ label: `${creator.firstName} ${creator.lastName}`, value: creator.id }))}
            />
            <ButtonSearchBlock
              onClick={onSubmitForm}
              disabled={!formDirty}
              contained
              type="submit"
            />
            <ButtonClearBlock disabled={Object.keys(searchQuery).length === 0} onClick={() => onSearchHandler({})} />
          </form>
        </div>
      </div>
    </div>
  );
};

export { DocumentSearchComponent };
