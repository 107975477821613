export const booleanTranslate = (value: boolean | null): string => {
  if (value === true) {
    return 'Да';
  }

  if (value === false) {
    return 'Нет';
  }

  return '';
}
