import React from 'react';

import { DocumentSearchApiInterface } from './types';


export const DocumentSearchContext = React.createContext<DocumentSearchApiInterface | undefined>(undefined);

export const useDocumentSearchApi = () => {
  const context = React.useContext(DocumentSearchContext);

  if (context === undefined) {
    throw new Error('useDocumentSearchApi must be used within DocumentSearchProvider');
  }

  return context;
}
