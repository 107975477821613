import { 
  PreviewButtonComponent,
  SaveButtonComponent,
  ToolbarComponent,
  EditorComponent,
  ModalComponent,
 } from '..';


 export const MainComponent = () => {
  return (
    <ModalComponent>
      <ToolbarComponent>
        <PreviewButtonComponent />
        <SaveButtonComponent />
      </ToolbarComponent>
      <EditorComponent />
    </ModalComponent>
  );
};
