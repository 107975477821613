import React from 'react';

import {
  MainContextInterface,
  useMainHook,
} from './main.hook';


const MainContext = React.createContext<MainContextInterface | undefined>(undefined);

export const useMainApi = () => {
  const context = React.useContext(MainContext);

  if (context === undefined) {
    throw new Error('useMainApi must be used within MainProvider');
  }

  return context;
}

export interface MainProviderProps {
  opened: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

export const MainProvider = ({
  opened,
  onClose,
  children,
}: MainProviderProps) => {
  const api = useMainHook(
    opened,
    onClose,
  );
  
  return (
    <MainContext.Provider value={api}>
      {children}
    </MainContext.Provider>
  );
}
