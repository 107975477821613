import {
  EditIcon,
  ButtonIconBrick,
  CardContentBrick,
  CardDataComposition,
  ButtonConfirmComposition,
  CardActionsBrick,
  DividerHorizontalBrick,
} from '@app/components';

import { useMainApi } from '../../context';
import styles from './detail.module.scss';


export const DetailComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    printFormCurrent,
    printFormAccess,
    printFormIsEdited,
    printFormIdCurrent,
    printFormIsEditedSet,
    printFormDelete,
  } = useMainApi();

  return (
    printFormCurrent &&
    printFormIsEdited === false &&
    printFormIdCurrent !== 'create'
  )
    ? (
      <div className={styles['detail']}>
        <div className={styles['detail__info-section']}>
          <CardDataComposition
            title={printFormCurrent.name}
            extra={
              <>
                {printFormAccess && (
                  <>
                    <ButtonIconBrick
                      onClick={() => printFormIsEditedSet(true)}
                    >
                      <EditIcon />
                    </ButtonIconBrick>
                    <ButtonConfirmComposition
                      onClick={printFormDelete}
                      message="Удаление печатной формы."
                    />
                  </>
                )}
              </>
            }
          >
            <>
              {printFormCurrent.description && (
                <CardContentBrick>
                  {printFormCurrent.description}
                </CardContentBrick>
              )}
              <DividerHorizontalBrick />
              <CardActionsBrick>
                {children}
              </CardActionsBrick>
            </>
          </CardDataComposition>
        </div>
      </div>
    )
    : null;
}
