import { FieldYearBlock } from '@app/components';
import { YearProps } from './year.props';


export const YearField = ({
  fieldConfig,
  value,
  onChange,
  error,
  disabled,
}: YearProps) => {
  return (
    <FieldYearBlock
      key={fieldConfig.key}
      name={fieldConfig.key}
      label={fieldConfig.label}
      value={value}
      onChange={onChange}
      error={error}
      counted={fieldConfig.counted}
      required={fieldConfig.required}
      prompt={fieldConfig.prompt}
      disabled={disabled}
    />
  );
};
