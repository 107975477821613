import { useAppDispatch } from '@app/hooks';
import { PrintFormApi } from '@app/api';
import { callNotifyAction } from '@app/stores';
import { PrintImageType } from '@app/types';
import * as React from 'react';


export type MainContextInterface = {
  opened: boolean;
  printImageListIsLoading: boolean;
  printImageIsUploading: boolean;
  printImageList: PrintImageType[];
  printImageDelete: (id: number) => void;
  printImageSelect: (id: number) => void;
  printImageUpload: (file: File) => void;
  onClose: () => void;
}

export const useMainHook = (
  opened: boolean,
  onSelect: (id: number) => void,
  onClose: () => void,
): MainContextInterface => {
  const dispatch = useAppDispatch();
  const [printImageList, printImageListSet] = React.useState<PrintImageType[]>([]);
  const [printImageListIsLoading, printImageListIsLoadingSet] = React.useState(true);
  const [printImageIsUploading, printImageIsUploadingSet] = React.useState(false);
  
  const printImageListGet = React.useCallback(async () => {
    const response = await PrintFormApi.getPrintImageList();
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка получения данных!' }));
      return;
    }

    printImageListIsLoadingSet(false);
    printImageListSet(response.data.printImageList);
  }, [
    dispatch,
  ]);

  const printImageDelete = React.useCallback(async (id: number) => {
    dispatch(callNotifyAction({ type: 'info', message: 'loading' }));
    const response = await PrintFormApi.deletePrintImage({ id });
    
    if (!response.success) {
      const hasExistOnForm = (
        typeof response.errors === 'string'
        && response.errors.indexOf('Print Image has exist of print form') !== -1
      );

      dispatch(callNotifyAction({
        type: 'error',
        message: hasExistOnForm ? 'Данное изображение используется в печатной форме!' : 'Ошибка удаления данных!',
      }));

      return;
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успешно удалены!' }));
    printImageListSet((list) => list.filter((printImage) => printImage.id !== response.data.id));
    return;
  }, [
    dispatch,
  ]);

  const printImageSelect = React.useCallback((id: number) => {
    onSelect(id);
    onClose();
  }, [
    onSelect,
    onClose,
  ]);

  const printImageUpload = React.useCallback(async (file: File) => {
    printImageIsUploadingSet(true);
    const response = await PrintFormApi.uploadPrintImage(file);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return;
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));
    printImageListSet((s) => [response.data.printImage, ...s]);
    printImageIsUploadingSet(false);
    return response.data.printImage;
  }, [
    dispatch,
  ]);

  React.useEffect(() => {
    if (!opened) return;

    printImageListGet();
  }, [
    opened,
    printImageListGet,
  ]);

  return React.useMemo(() => ({
    opened,
    printImageListIsLoading,
    printImageIsUploading,
    printImageList,
    printImageDelete,
    printImageSelect,
    printImageUpload,
    onClose,
  }), [
    opened,
    printImageListIsLoading,
    printImageIsUploading,
    printImageList,
    printImageDelete,
    printImageSelect,
    printImageUpload,
    onClose,
  ]);
}
