import { ConfigurationStateInterface } from '@app/types';

export const findDocumentHandbkIds = (
  configurationState: ConfigurationStateInterface,
  documentConfigId: number,
): number[] => {
  const documentConfig =
    configurationState.documents
      .find((documentConfig) => documentConfig.documentConfigId === documentConfigId);

    

  if (documentConfig === undefined) {
    console.log('DocumentConfig not found')
    return [];
  }

  const ids = documentConfig.fields.filter((fieldConfig) => (
    (fieldConfig.type === 'document-select' || fieldConfig.type === 'document-multiselect')
    && fieldConfig.documentConfigLinkId !== null
  )).map((fieldConfig) => (fieldConfig.documentConfigLinkId as number));

  return ids;
};