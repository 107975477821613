import {
  DialogBrick,
  DialogContentBrick,
  ButtonsBrick,
  CardHeaderBrick,
  ButtonCancelBlock,
  ButtonSaveBlock,
  FieldTextBlock,
} from '@app/components';

import {
  useMainApi,
  useVariantUpdateApi,
} from '../../context';


export const VariantUpdateComponent = () => {
  const {
    selectedVariant,
    configurationIsSubmitting,
    selectedVariantSet,
  } = useMainApi();

  const {
    formValue,
    formDirty,
    changeFormValue,
    closeForm,
    onSubmit,
    setError,
  } = useVariantUpdateApi();

  if  (formValue === null) return null;

  return (
    <DialogBrick
      size="large"
      opened={!!selectedVariant}
      onClose={() => selectedVariantSet(null)}
    >
      <CardHeaderBrick
        title="Редактировать вариант"
        extra={<ButtonCancelBlock buttonIcon onClick={closeForm} />}
      />
      <DialogContentBrick>
        <div className="row justify-content-lg-center">
          <div className="col col-lg-6">
            <form onSubmit={onSubmit}>
              <FieldTextBlock
                name="label"
                label="Значение"
                value={formValue.label}
                onChange={changeFormValue}
                error={setError('label')}
                required
              />
              <ButtonsBrick>
                <ButtonSaveBlock
                  loading={configurationIsSubmitting}
                  onClick={onSubmit}
                  disabled={!formDirty}
                />
                <ButtonCancelBlock onClick={closeForm} />
              </ButtonsBrick>
            </form>
          </div>
        </div>
      </DialogContentBrick>
    </DialogBrick>
  );
}
