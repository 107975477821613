import React from 'react';

import { callNotifyAction } from '@app/stores';

import {
  useMainApi,
} from '..';

import {
  RoleType,
  ChangeFieldEventType,
} from '@app/types';

import { useAppDispatch } from '@app/hooks';
import { RoleApi } from '@app/api';


export interface UserAccessContextInterface {
  formValue: RoleType | null;
  formDirty: boolean;
  allowAllDisabled: boolean;
  disallowAllDisabled: boolean;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onDone: () => void;
  onSubmit: (e?: React.SyntheticEvent) => Promise<void>;
  allowAll: () => void;
  disallowAll: () => void;
};

export const useUserAccessHook = (
): UserAccessContextInterface => {
  const dispatch = useAppDispatch();
  
  const {
    roleCurrent,
    isEditingUserAccessSet,
    roleDetailIsSubmittingSet,
    roleCurrentUpdate,
  } = useMainApi();

  const [formValue, formValueSet] = React.useState<RoleType | null>(null);
  const [formDirty, formDirtySet] = React.useState(false);

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      if (state === null) return null;
      
      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const onDone = React.useCallback(() => {
    isEditingUserAccessSet(false);
  }, [
    isEditingUserAccessSet,
  ]);

  const onSubmit = React.useCallback(async (e?: React.SyntheticEvent) => {
    roleDetailIsSubmittingSet(true);
    if (e) e.preventDefault();
    if (formValue === null) return;

    const response = await RoleApi.update(formValue);

    if (!response.success) {
      dispatch(callNotifyAction({
        type: 'error',
        message: 'Не удалось обновить роль',
      }));
      return;
    }

    dispatch(callNotifyAction({
      type: 'success',
      message: 'Роль успешно обновлена',
    }));

    roleCurrentUpdate(response.data.role);

    roleDetailIsSubmittingSet(false);
    onDone();
  }, [
    formValue,
    dispatch,
    roleDetailIsSubmittingSet,
    onDone,
    roleCurrentUpdate,
  ]);

  const allowAllDisabled = React.useMemo<boolean>(() => {
    if (formValue === null) return true;

    const keys: string[] = Object.keys(formValue).filter((value) => (
      value.indexOf('DEPARTMENT_') === 0
      || value.indexOf('ROLE_') === 0
      || value.indexOf('USER_') === 0
    ));

    return keys.every((key) => formValue[key] === 'ALL')
  }, [
    formValue,
  ]);

  const allowAll = React.useCallback(() => {
    if (formValue === null) return;

    const keys: string[] = Object.keys(formValue).filter((value) => (
      value.indexOf('DEPARTMENT_') === 0
      || value.indexOf('ROLE_') === 0
      || value.indexOf('USER_') === 0
    ));

    const allowData: {[key: string]: any} = {};

    keys.forEach((key) => {
      allowData[key] = 'ALL';
    });

    formValueSet((state) => {
      if (state === null) return null;

      return {
        ...state,
        ...allowData,
      };
    });
    formDirtySet(true);
  }, [
    formValue,
  ]);

  const disallowAllDisabled = React.useMemo<boolean>(() => {
    if (formValue === null) return true;

    const keys: string[] = Object.keys(formValue).filter((value) => (
      value.indexOf('DEPARTMENT_') === 0
      || value.indexOf('ROLE_') === 0
      || value.indexOf('USER_') === 0
    ));

    return keys.every((key) => formValue[key] === 'NEVER')
  }, [
    formValue,
  ]);
  
  const disallowAll = React.useCallback(() => {
    if (formValue === null) return;

    const keys: string[] = Object.keys(formValue).filter((value) => (
      value.indexOf('DEPARTMENT_') === 0
      || value.indexOf('ROLE_') === 0
      || value.indexOf('USER_') === 0
    ));

    const disAllowData: {[key: string]: any} = {};

    keys.forEach((key) => {
      disAllowData[key] = 'NEVER';
    });

    formValueSet((state) => {
      if (state === null) return null;

      return {
        ...state,
        ...disAllowData,
      };
    });
    formDirtySet(true);
  }, [
    formValue,
  ]);

  React.useEffect(() => {
    formValueSet(roleCurrent);
  }, [
    roleCurrent,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    allowAllDisabled,
    disallowAllDisabled,
    changeFormValue,
    onDone,
    onSubmit,
    allowAll,
    disallowAll,
  }), [
    formValue,
    formDirty,
    allowAllDisabled,
    disallowAllDisabled,
    changeFormValue,
    onDone,
    onSubmit,
    allowAll,
    disallowAll,
  ]);
};
