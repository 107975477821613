import { ModalsProvider } from '../../context';
import {
  ToolPanelComponent,
  TextAreaComponent,
  VarsComponent,
  MarginsComponent,
  ImageLibraryComponent,
} from '..';


export const MainComponent = () => {
  return (
    <ModalsProvider>
      <ToolPanelComponent />
      <TextAreaComponent />

      <VarsComponent />
      <MarginsComponent />
      <ImageLibraryComponent/>
    </ModalsProvider>
  );
};
