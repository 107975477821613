import {
  FieldTextBlock,
  FieldLongTextBlock,
  ButtonSaveBlock,
  FieldSelectBlock,
  CardBrick,
  CardContentBrick,
  DividerHorizontalBrick,
  CardActionsBrick,
} from '@app/components';

import { useCreateApi } from '../../context';


export const CreateComponent = () => {
  const {
    formCreateVisible,
    formValue,
    formDirty,
    isSubmitting,
    onSubmit,
    changeFormValue,
    setError,
  } = useCreateApi();

  return (
    <>
      {formCreateVisible && (
        <CardBrick>
          <form onSubmit={onSubmit}>
            <CardContentBrick style={{ paddingBottom: 0}}>
              <FieldTextBlock
                name="name"
                label="Название"
                value={formValue.name}
                onChange={changeFormValue}
                error={setError('name')}
                required
              />

              <FieldSelectBlock
                name="type"
                label="Тип"
                value={formValue.type}
                onChange={changeFormValue}
                error={setError('type')}
                items={[
                  { label: 'Визуальный редактор', value: 'editor' },
                  { label: 'HTML Редактор', value: 'html' },
                  { label: 'Docx Шаблон', value: 'docx' },
                ]}
                required
              />

              <FieldLongTextBlock
                name="description"
                label="Описание"
                value={formValue.description || ''}
                onChange={changeFormValue}
                error={setError('description')}
              />
            </CardContentBrick>
            <DividerHorizontalBrick />
            <CardActionsBrick>
              <ButtonSaveBlock
                disabled={!formDirty}
                onClick={onSubmit}
                loading={isSubmitting}
              />
            </CardActionsBrick>
          </form>
        </CardBrick>
      )}
    </>
  );
}
