import Immutable from 'immutable';
import { DefaultDraftBlockRenderMap } from 'draft-js';

export enum BlockType {
  h1 = 'header-one',
  h2 = 'header-two',
  h3 = 'header-three',
  h4 = 'header-four',
  h5 = 'header-five',
  h6 = 'header-six',
  blockquote = 'blockquote',
  list = 'unordered-list-item',
  orderList = 'ordered-list-item',
  default = 'unstyled',
  code = 'code-block',
  cite = 'cite',
}

export enum InlineStyle {
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
  UNDERLINE = 'UNDERLINE',
  ACCENT = 'ACCENT',
}

export enum EntityType {
  image = 'image',
  link = 'link',
}

const CUSTOM_BLOCK_RENDER_MAP = Immutable.Map({
  [BlockType.cite]: {
    element: 'cite',
  },
});

export const CUSTOM_STYLE_MAP = {
  [InlineStyle.ACCENT]: {
    backgroundColor: '#F7F6F3',
    color: '#A41E68',
  },
};

export const BLOCK_RENDER_MAP = DefaultDraftBlockRenderMap.merge(
  CUSTOM_BLOCK_RENDER_MAP,
);
