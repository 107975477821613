import { MainComponent } from './components';
import { MainProvider } from './context';


export const HandbkDetailFeature = () => {
  return (
    <MainProvider>
      <MainComponent />
    </MainProvider>
  );
};
