import { FormFileApi } from '@app/api';

import {
  LinkBrick,
  TableCellBrick,
  TooltipBrick,
} from '@app/components';

import {
  ColumnValueProps,
  FileColumnValueProps,
} from './column-value.props';

import styles from './column-value.module.scss';


const FileColumnValue = ({
  fieldConfig,
  documentKey,
  documentId,
}: FileColumnValueProps) => {
  return (
    <LinkBrick
      onClick={async () => {
        const result = await FormFileApi.getOneMyByDocument({
          documentKey,
          fieldKey: fieldConfig.key,
          documentId,
        })

        if (!result.success) return;

        const src = result.data.formFile.src;

        window.open(src);
      }}
      label={`${fieldConfig.fileType} - файл`}
    />
  );
}

const ColumnValueWithTooltip = ({
  columnValue,
}: { columnValue: any }) => {
  if (String(columnValue).length > 35) {
    return <TooltipBrick title={columnValue}><span>{columnValue.substr(0, 35) + '...'}</span></TooltipBrick>;
  }

  return columnValue;
}

export const ColumnValueFeature = ({
  fieldConfig,
  documentKey,
  documentId,
  columnValue,
  mayBeLinkToDetail,
}: ColumnValueProps) => {
  const isFieldFile = fieldConfig.type === 'file';

  return (
    <TableCellBrick className={styles['column-value']}>
      {(isFieldFile && columnValue) ? (
        <FileColumnValue
          documentKey={documentKey}
          fieldConfig={fieldConfig}
          documentId={documentId}
        />
      ) : (
        (fieldConfig.display.isLink && mayBeLinkToDetail) ? (
          <LinkBrick
            to={documentKey + '-' + documentId}
            label={<ColumnValueWithTooltip columnValue={columnValue} />}
          />
            
        ) : (
          <ColumnValueWithTooltip columnValue={columnValue} />
        )
      )}
    </TableCellBrick>
  );
}
