import { Badge } from '@mui/material';

import { BadgeProps } from './badge.props';

export const BadgeBrick = ({
  children,
  color = 'secondary',
  badgeContent,
}: BadgeProps) => {

  return (
    <Badge
      color={color}
      badgeContent={badgeContent}
    >
      {children}
    </Badge>
  );
}
