import { SyntheticEvent, useState } from 'react';

import { documentConfigUpdateAction } from '@app/stores';

import {
  ChangeFieldEventType,
} from '@app/types';

import {
  ButtonCancelBlock,
  ButtonSaveBlock,
  FieldTextBlock,
} from '@app/components';

import { errorTranslate } from '@app/helpers';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

import { DocumentConfigUpdateFormProps } from './document-config-update-form.props';
import styles from './document-config-update-form.module.scss';

const DocumentConfigUpdateFormComponent = ({
  documentConfig,
  setOpened,
}: DocumentConfigUpdateFormProps) => {
  const [formValue, setFormValue] = useState({
    singularLabel: documentConfig.label.singular,
    pluralLabel: documentConfig.label.plural,
    genitiveLabel: documentConfig.label.genitive,
  });
  const [formDirty, setFormDirty] = useState(false);
  const dispatch = useAppDispatch();
  const configuration = useAppSelector((state) => state.configuration);

  const changeFormValue = (e: ChangeFieldEventType): void => {
    setFormDirty(true);
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };
  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();

    const result = await dispatch(documentConfigUpdateAction({
      id: documentConfig.documentConfigId,
      ...formValue
    }));

    if (result.type === '@@configuration/document-config-update/fulfilled') {
      setFormDirty(false);
      setOpened(false);
    }
  }

  const setError = (fieldName: string) => {
    return configuration.errors && configuration.errors[fieldName] && errorTranslate(configuration.errors[fieldName][0]);
  }

  return (
    <div className="row justify-content-lg-center">
      <div className="col col-lg-6">
        <form
          className={styles['document-config-update-form']}
          onSubmit={(e: SyntheticEvent) => onSubmit(e)}
        >
          <FieldTextBlock
            name="singularLabel"
            label="Название единственном числе"
            value={formValue.singularLabel}
            onChange={changeFormValue}
            error={setError('singularLabel')}
          />

          {documentConfig.type === 'form-list' && <FieldTextBlock
            name="pluralLabel"
            label="Название множественном числе"
            value={formValue.pluralLabel}
            onChange={changeFormValue}
            error={setError('pluralLabel')}
          />}

          {documentConfig.type === 'form-list' && <FieldTextBlock
            name="genitiveLabel"
            label="Название родительном падеже"
            value={formValue.genitiveLabel}
            onChange={changeFormValue}
            error={setError('genitiveLabel')}
          />}

          <ButtonSaveBlock
            loading={configuration.status === 'submitting'}
            onClick={onSubmit}
            disabled={!formDirty}
          />

          <ButtonCancelBlock onClick={() => setOpened(false)} />
        </form>
      </div>
    </div>
  );
}

export { DocumentConfigUpdateFormComponent };
