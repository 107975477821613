import { saveAs } from 'file-saver';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { createReport } from '@app/libs/docx-templates.lib';
// import createReport from 'docx-templates';

import {
  buildColumnValue,
  buildPrintQueriesHelper,
  findFieldConfigHelper,
  keysParseHelper,
} from '@app/helpers';

import {
  DocumentStateInterface,
  PrintFormType,
  ConfigurationStateInterface,
  HandbkDocumentType,
  FieldConfigType
} from '@app/types';

import { PrintQueryVarType } from '@app/api';

import { HandbkDocumentApi, PrintFormApi } from '@app/api';
import { findDocumentHandbkIds } from '@app/helpers/find-document-handbk-ids.helper';


export class PrintFormDocxService {
  private static async loadDocx(
    url: string,
  ): Promise<Docxtemplater<PizZip>> {
    return new Promise((resolve, reject) => {
      PizZipUtils.getBinaryContent(url, (error, content) => {
        if (error) {
          reject(error);
        }

        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
          // modules: [new ImageModule(imageOptions)],modules
        });

        resolve(doc);
      });
    });
  }

  private static prepareDoc(
    doc: Docxtemplater<PizZip>,
    printDataList: PrintQueryVarType[],
    configurationState: ConfigurationStateInterface,
    handbkList: HandbkDocumentType[],
  ): Docxtemplater<PizZip> {
    const preparedData: {[key: string]: any} = {};

    printDataList.forEach((printData) => {
      if (printData.type === 'var') {
        const fieldConfig = findFieldConfigHelper(
          configurationState,
          printData.documentConfigKey,
          printData.fieldConfigKey,
        );
  
        const value = printData.value === null ? '---' : buildColumnValue(
          fieldConfig ? fieldConfig : ({ type: 'number' } as FieldConfigType),
          printData.value,
          configurationState,
          handbkList,
        );

        preparedData[printData.key] = value;
      }

      if (printData.type === 'func_qr-link') {

      }
    });

    doc.setData(preparedData);

    return doc
  }

  private static renderDoc(
    doc: Docxtemplater<PizZip>,
  ) {
    try {
      doc.render();
    } catch (error) {
      throw error;
    }

    var out = doc.getZip().generate({
      type: 'blob',
      mimeType:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    }); //Output the document using Data-URI
    saveAs(out, 'output.docx');
  }

  private static async getTextTemplate(url: string) {
    const doc = await this.loadDocx(url);
    return doc.getFullText();
  }

  private static async getImageSize(
    src: string,
  ): Promise<[number, number]> {
    return new Promise((resolve, reject) => {
      const image = document.createElement('img');

      image.addEventListener( 'load', function() {
        resolve([image.width, image.height])
      });

      image.src = 'data:image;base64,' + src;
    });
  }

  private static async prepareData(
    keys: string[],
    printDataList: PrintQueryVarType[],
    configurationState: ConfigurationStateInterface,
    handbkList: HandbkDocumentType[],
  ): Promise<{[key: string]: any}> {
    const preparedData: {[key: string]: any} = {};
    keys.forEach((key) => preparedData[key] = '');

    const chain = (arr: PrintQueryVarType[]) => arr.reduce((acc, printData: PrintQueryVarType) => acc.then(
      async () => {
        if (printData.type === 'var') {
          const fieldConfig = findFieldConfigHelper(
            configurationState,
            printData.documentConfigKey,
            printData.fieldConfigKey,
          );
    
          const value = printData.value === null ? '---' : buildColumnValue(
            fieldConfig ? fieldConfig : ({ type: 'number' } as FieldConfigType),
            printData.value,
            configurationState,
            handbkList,
          );
  
          preparedData[printData.key] = value;
        }
  
        if (printData.type === 'func_qr-link') {
          const data = printData.value.slice('data:image/png;base64,'.length);
          preparedData[printData.key] = { width: 2, height: 2, data, extension: '.png' };
        }
  
        if (printData.type === 'func_document-image') {
          if (!printData.value) {
            const placeholder = await fetch('https://agsite.hb.ru-msk.vkcs.cloud/placeholder.png')
              .then(res => res.arrayBuffer())
  
            let binary = '';
            const bytes = new Uint8Array( placeholder );
            const len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
              binary += String.fromCharCode( bytes[ i ] );
            }
            const base = window.btoa( binary );
            
            preparedData[printData.key] = {
              width: 4,
              height: 4,
              data: base,
              extension: '.jpg',
            };

          } else {
            const [width, height] = await this.getImageSize(printData.value)
            preparedData[printData.key] = {
              width: width / 38 / 2,
              height: height / 38 / 2,
              data: printData.value,
              extension: '.jpg',
            };
          }
          
        }
      },
    ),
    Promise.resolve(),
    );

    await chain(printDataList);

    return preparedData
  }

  static async build(
    printForm: PrintFormType,
    url: string,
    documentState: DocumentStateInterface,
    configurationState: ConfigurationStateInterface,
  ) {
    const template = await this.getTextTemplate(url);
    const keys = keysParseHelper(template, ['{', '}'])
      .map((key) => key.replace('INS ', '').replace('IMAGE ', ''));
    const printQueryList = buildPrintQueriesHelper(keys, documentState, true);
    const responseQueryList = await PrintFormApi.buildTemplate({
      printForm: {...printForm, template},
      printDataQueries: printQueryList,
    });

    if (!responseQueryList.success) {
      console.log('Error get var list', responseQueryList.errors);
      return;
    }

    const printDataList = responseQueryList.data.queries;

    const ids = findDocumentHandbkIds(
      configurationState,
      printForm.documentConfigId,
    );

    const responseHandbk = await HandbkDocumentApi.list({
      documentConfigIdList: ids,
    });

    if (!responseHandbk.success) {
      console.log('Error get document handbk list list', responseQueryList.errors);
      return;
    }

    const preparedData = await this.prepareData(
      keys,
      printDataList,
      configurationState,
      responseHandbk.data.handbkList,
    );
    const templateDocx = await fetch(url).then(res => res.arrayBuffer());
    const report = await createReport({
      template: Buffer.from(templateDocx),
      cmdDelimiter: ['{', '}'],
      data: preparedData,
    });

    const finalFile = new Blob([report], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

    saveAs(finalFile, 'output.docx');
    // console.log(finalFile);
  }

  // static async build(
  //   printForm: PrintFormType,
  //   url: string,
  //   documentState: DocumentStateInterface,
  //   configurationState: ConfigurationStateInterface,
  // ) {
  //   const doc = await this.loadDocx(url);
  //   const template = doc.getFullText()
  //   const keys = keysParseHelper(template, ['{', '}']);
  //   const printQueryList = buildPrintQueriesHelper(keys, documentState);
  //   const responseQueryList = await PrintFormApi.buildTemplate({
  //     printForm: {...printForm, template},
  //     printDataQueries: printQueryList,
  //   });

  //   if (!responseQueryList.success) {
  //     console.log('Error get var list', responseQueryList.errors);
  //     return;
  //   }

  //   const printDataList = responseQueryList.data.queries;

  //   const ids = findDocumentHandbkIds(
  //     configurationState,
  //     printForm.documentConfigId,
  //   );

  //   const responseHandbk = await HandbkDocumentApi.list({
  //     documentConfigIdList: ids,
  //   });

  //   if (!responseHandbk.success) {
  //     console.log('Error get document handbk list list', responseQueryList.errors);
  //     return;
  //   }

  //   const preparedDoc = this.prepareDoc(
  //     doc,
  //     printDataList,
  //     configurationState,
  //     responseHandbk.data.handbkList,
  //   );

  //   this.renderDoc(preparedDoc);
  // }
};
