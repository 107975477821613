import { useEffect, useRef } from 'react';

import {
  FormFieldBrick,
  AsteriskBrick,
  InputBrick,
} from '@app/components';

import { FieldPassportProps } from './field-passport.props';


export const FieldPassportBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  required,
  counted,
  prompt,
  disabled,
}: FieldPassportProps) => {
  const inputPassport = useRef<HTMLInputElement>(null);

  const handleChange = () => {
    if (!inputPassport.current) {
      return;
    }

    const input = inputPassport.current.querySelectorAll('input')[0];
    const currentFocus = input.selectionStart
    const inputValue = input.value
      .replace(/\D/g, '')
      .match(/(\d{0,4})(\d{0,6})/);

    const newValue = !inputValue![2]
    ? inputValue![1]
    : `${inputValue![1]}-${inputValue![2]}`;

    input.value = newValue

    const focusInText = currentFocus && currentFocus < newValue.length;
    
    if (focusInText) {
      if ((currentFocus === 5) && input.value.length > value.length ) {
        input.setSelectionRange(currentFocus + 1, currentFocus + 1);
      } else {
        input.setSelectionRange(currentFocus, currentFocus);
      }
    }

    onChange({ target: { name, value: newValue } });
  };

  useEffect(
    () => {
      handleChange();
    },
    // eslint-disable-next-line
    [value]
  );
  
  return (
    <FormFieldBrick error={error} prompt={prompt}>
      <InputBrick
        label={<>{label}<AsteriskBrick required={required} counted={counted} /></>}
        name={name}
        value={value}
        onChange={handleChange}
        refEl={inputPassport}
        disabled={disabled}
      />
    </FormFieldBrick>
  );
};
