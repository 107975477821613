import React, { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router';
import { Html5Qrcode, CameraDevice } from 'html5-qrcode';

import {
  HttpService,
} from '@app/services';

import {
  BaseResponseInterface,
  DocumentSearchOneByUuidResponse,
  ChangeFieldEventType,
  DocumentSearchOneByUuidRequest,
} from '@app/types';

import {
  DocumentFindedType,
  DocumentSearchApiInterface,
  ModeType,
} from './types';


const documentSearch = async (
  payload: DocumentSearchOneByUuidRequest,
): Promise<BaseResponseInterface<DocumentSearchOneByUuidResponse>> => {
  return await HttpService.sendRequest('GET', '/document/search-one-by-uuid', payload);
};

const initialformValue = {
  uuid: '',
};

export const useDocumentSearch = (
  openedDocumentSearchModal: boolean,
  closeModal: (value?: number) => void,
  canBeUsed?: boolean,
): DocumentSearchApiInterface => {
  const navigate = useNavigate();

  const [formValue, setFormValue] = React.useState(initialformValue);
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [error, setError] = React.useState('');
  const [mode, setMode] = React.useState<ModeType>('form');
  const [findedDocument, setFindedDocument] = React.useState<DocumentFindedType | null>(null);
  const [devices, setDevices] = React.useState<CameraDevice[]>([]);
  const [currentDeviceId, setCurrentDeviceId] = React.useState<string | null>(null);
  const [scanner, setScanner] = React.useState<Html5Qrcode | null>(null);
  const [uuidReady, setUuidReady] = React.useState(false);

  const resetForm = React.useCallback(() => {
    setFormValue(initialformValue);
    setError('');
    setMode('form');
  }, [])

  const changeForm = React.useCallback((e: ChangeFieldEventType): void => {
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const onSubmit = React.useCallback(async (e?: SyntheticEvent): Promise<void> => {
    if (e) e.preventDefault();
    
    setLoadingSearch(true);
    const result = await documentSearch(formValue);
    setLoadingSearch(false);
    
    if (!result.success) {
      if (result.errors === 'Not Found') {
        setError('Такой документ не найден');
      } else if (result.errors === 'Bad credentials') {
        setError('Укажите корректный uuid');
      }
      return;
    }

    setMode('result');
    setFindedDocument(result.data);
  }, [
    formValue,
  ]);

  const activateQrCodeScanner = React.useCallback((): void => {
    setMode('scan');
  }, []);

  const getDevices = React.useCallback(async () => {
    const devices = await Html5Qrcode.getCameras();
    if (devices.length === 0) {
      console.log('not-found-cameras');
      return;
    }

    const deviceIndex =
      devices
        .length === 1
          ? 0
          : 1;

    setCurrentDeviceId(devices[deviceIndex].id);

    setDevices(devices);
  }, []);

  const scannerStop = React.useCallback(() => {
    if (scanner === null) {
      return;
    }

    scanner
      .stop()
      .then(() => {
        scanner.clear();
        setScanner(null);
        setMode('form');
      });
  }, [
    scanner,
  ]);

  const closeDialog = React.useCallback((value?: number): void => {
    closeModal(value)
    scannerStop();
    resetForm();
  }, [
    resetForm,
    scannerStop,
    closeModal,
  ]);

  const goToDocument = React.useCallback((): void  => {
    if (!findedDocument) {
      return;
    }

    navigate(findedDocument.path);
    closeDialog();
  }, [
    findedDocument,
    navigate,
    closeDialog,
  ]);

  const parseData = React.useCallback((
    decodedText: string,
  ) => {
    const parseUrl = (
      url: string,
    ): string | null => {
      const position = url.indexOf('document-search/');
      if (position === -1) {
        console.log('invalid-url', url);
        return null;
      }

      return url.slice(position + 16);
    }
    const uuid = decodedText.length === 14 ? decodedText : parseUrl(decodedText);

    if (uuid === null || uuid.length !== 14) {
      console.log('invalid uuid', decodedText);
      return;
    }

    setFormValue({
      uuid,
    });
    setUuidReady(true);
  }, [
  ]);

  const scannerStart = React.useCallback(() => {
    if (currentDeviceId === null) return;

    const html5QrCode = new Html5Qrcode('document-search-scanner');

    html5QrCode.start(
      currentDeviceId, 
      {
        fps: 10,    // Optional, frame per seconds for qr code scanning
        qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
      },
      parseData,
      () => {}
    ).then(() => {
      setScanner(html5QrCode);
    }).catch((err) => {
      // Start failed, handle it.
    });
  }, [
    currentDeviceId,
    parseData,
  ]);

  React.useEffect(() => {
    if (uuidReady === false) return;

    setUuidReady(false);
    scannerStop();
    onSubmit();
  }, [
    mode,
    uuidReady,
    scannerStop,
    onSubmit,
  ]);

  React.useEffect(() => {
    if (currentDeviceId === null || mode !== 'scan') return;
    scannerStart();
  }, [
    currentDeviceId,
    mode,
    scannerStart,
  ]);

  return React.useMemo(() => ({
    openedDocumentSearchModal,
    mode,
    formValue,
    error,
    findedDocument,
    loadingSearch,
    devices,
    currentDeviceId,
    scanner,
    canBeUsed,
    setMode,
    onSubmit,
    changeForm,
    activateQrCodeScanner,
    goToDocument,
    resetForm,
    closeDialog,
    getDevices,
    setCurrentDeviceId,
    scannerStop,
  }), [
    openedDocumentSearchModal,
    mode,
    formValue,
    error,
    findedDocument,
    loadingSearch,
    devices,
    currentDeviceId,
    scanner,
    canBeUsed,
    setMode,
    onSubmit,
    changeForm,
    activateQrCodeScanner,
    goToDocument,
    resetForm,
    closeDialog,
    getDevices,
    scannerStop,
  ]);
};
