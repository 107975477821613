import React from 'react';
import cs from 'classnames';

import {
  Card,
} from '@mui/material';

import CardImageProps from './card-image.props';
import styles from './card-image.module.scss';


export const CardImageBrick = ({
  src,
  extra,
}: CardImageProps) => {
  const [srcLoading, setSrcLoading] = React.useState(true);

  React.useEffect(() => {
    const imageLoader = new Image();
    imageLoader.src = src;

    imageLoader.onload = () => {
      setSrcLoading(false);
    }
  }, [src]);

  const cardImageCs = cs(
    styles['card-image'],
    { [styles['card-image--loading']]: srcLoading },
  );

  return (
    <Card>
      <div
        className={cardImageCs}
        style={{backgroundImage: `url(${src})`}}
      >
        {(extra) && (
          <div className={styles['card-image__control-panel']}>
            <div className={styles['card-image__buttons-panel']}>
              {extra}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}
