import * as React from 'react';
import copy from 'copy-to-clipboard';

import { callNotifyAction } from '@app/stores';
import { useAppDispatch } from '@app/hooks';
import { FieldConfigType } from '@app/types';


export type MainContextInterface = {
  value: string;
  addImage: (id: number) => void;
  onChange: (value: string) => void;
  selectVar: (key: string, fieldConfig?: FieldConfigType) => void;
};

export const useMainHook = (
  value: string,
  onChange: (value: string) => void,
): MainContextInterface => {
  const dispatch = useAppDispatch();

  const addImage = React.useCallback((id: number) => {
    copy(`<img src="{{~func_print-image:${id}~}}" />`)
    dispatch(callNotifyAction({ type: 'info', message: 'Код скопирован в буфер обмена.' }));
  }, [
    dispatch,
  ]);

  const selectVar = React.useCallback((
    key: string,
    fieldConfig?: FieldConfigType,
  ) => {
    let result = `{{~${key}~}}`;
    const isQrLinkSrc = key.indexOf('func_qr-link:') !== -1;
    const isDocumentImage = fieldConfig && fieldConfig.type === 'file';
    
    if (isQrLinkSrc) result = `<img src="{{~${key}~}}" />`;
    if (isDocumentImage) result = `<img src="{{~func_document-image:${key}~}}" />`;
    copy(result)
    dispatch(callNotifyAction({ type: 'info', message: 'Ключ скопирован в буфер обмена.' }));
  }, [
    dispatch,
  ]);

  return React.useMemo(() => ({
    value,
    addImage,
    onChange,
    selectVar,
  }), [
    value,
    addImage,
    onChange,
    selectVar,
  ]);
};
