import React from 'react';
import { useNavigate } from 'react-router-dom';

import { createUserAction } from '@app/stores';

import {
  UserType,
  RoleType,
  DepartmentType,
  ChangeFieldEventType,
} from '@app/types';

import { errorTranslate } from '@app/helpers';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

type FormValueType = {
  lastName: string;
  firstName: string;
  secondName: string;
  email: string;
  password: string;
  roleId: string;
  departmentId: string;
};

export interface MainContextInterface {
  formValue: FormValueType;
  formDirty: boolean;
  userIsSubmitting: boolean;
  roleDictionary: RoleType[];
  departmentDictionary: DepartmentType[];
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e?: React.SyntheticEvent) => Promise<void>;
  setError: (fieldName: string) => string | null;
};

const initialFormValue = {
  lastName: '',
  firstName: '',
  secondName: '',
  email: '',
  password: '',
  roleId: '',
  departmentId: '',
};

export const useMainHook = (
): MainContextInterface => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [formValue, formValueSet] = React.useState<FormValueType>(initialFormValue);
  const [formDirty, formDirtySet] = React.useState(false);
  const userIsSubmitting = useAppSelector((state) => state.user.status === 'submitting');
  const roleDictionary = useAppSelector((state) => state.role.dictionary);
  const departmentDictionary = useAppSelector((state) => state.department.dictionary);
  const errors = useAppSelector((s) => s.user.errors);

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      return {
        ...state,
        [e.target.name]: e.target.value,
      }
    });
  }, []);

  const onSubmit = React.useCallback(async (e?: React.SyntheticEvent) => {
    if (e) e.preventDefault();
    if (formValue === null) return; 

    const result = await dispatch(createUserAction(formValue));

    if (result.type === '@@user/create/fulfilled') {
      navigate('/user-list/' + (result.payload as UserType).id);
    }
  }, [
    formValue,
    dispatch,
    navigate,
  ]);

  const setError = React.useCallback((fieldName: string) => {
    return errors && errors[fieldName] && errorTranslate(errors[fieldName][0]);
  }, [
    errors,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    userIsSubmitting,
    roleDictionary,
    departmentDictionary,
    changeFormValue,
    onSubmit,
    setError,
  }), [
    formValue,
    formDirty,
    userIsSubmitting,
    roleDictionary,
    departmentDictionary,
    changeFormValue,
    onSubmit,
    setError,
  ]);
};
