import cs from 'classnames';
import { useTheme } from '@mui/material';

import { ProgressCircularBrick } from '@app/components';
import { useAppSelector } from '@app/hooks';

import styles from './loader.module.scss';


export const LoaderPart = () => {
  const { loading } = useAppSelector((state) => state.app);
  const configLoading = useAppSelector((state) => state.configuration.status === 'loading');
  const { palette } = useTheme();

  const classnames = cs(
    styles['loader'],
    { [styles['loader--dark']]: palette.mode === 'dark' },
  );

  return (loading || configLoading) ? (
    <div className={classnames}>
      <ProgressCircularBrick />
    </div>
  ) : null;
}
