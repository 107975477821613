import { InputAdornment } from '@mui/material';

import { InputAdornmentProps } from './input-adornment.props';


export const InputAdornmentBrick = ({
  children,
  position,
}: InputAdornmentProps) => {
  return (
    <InputAdornment position={position}>
        {children}
    </InputAdornment>
  );
};
