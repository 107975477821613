import { useEffect } from 'react';

import {
  setBreadcrumbs,
  getDepartmentDictionaryAction,
} from '@app/stores';

import {
  ButtonBackBlock,
  PageBaseLayout,
  UserCreateFeature,
  CardDataComposition,
} from '@app/components';

import { useAppDispatch, useAppSelector } from '@app/hooks';


export const UserCreatePage = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => {
    return state.department.status === 'loading' || state.role.status === 'loading';
  });
  const { breadcrumbs } = useAppSelector((s) => s.app);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { title: 'Главная', link: '/' },
      { title: 'Пользователи', link: '/user-list' },
      { title: 'Добавить пользователя', link: '' },
    ]))

    dispatch(getDepartmentDictionaryAction());
  }, [dispatch])

  return (
    <PageBaseLayout breadcrumbs={breadcrumbs}>
      <div className="row">
        <div className="col-sm-12">
          <CardDataComposition
            title="Добавить пользователя"
            extra={<ButtonBackBlock buttonIcon />}
            loading={loading}
          >
            <div className="row justify-content-lg-center">
              <div className="col col-lg-6">
                <UserCreateFeature />
              </div>
            </div>
          </CardDataComposition>
        </div>
      </div>
    </PageBaseLayout>
  );
};
