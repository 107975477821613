import { FieldSelectBlock } from '@app/components';
import { SelectProps } from './select.props';


export const SelectField = ({
  fieldConfig,
  value,
  onChange,
  error,
  disabled,
}: SelectProps) => {
  return (
    <FieldSelectBlock
      key={fieldConfig.key}
      name={fieldConfig.key}
      label={fieldConfig.label}
      value={value}
      onChange={onChange}
      error={error}
      items={fieldConfig.variants!.map((item) => ({ value: item, label: item }))}
      counted={fieldConfig.counted}
      required={fieldConfig.required}
      prompt={fieldConfig.prompt}
      disabled={disabled}
    />
  );
};
