import { SyntheticEvent, useState } from 'react';

import {
  handbkCreateAction,
  modalHanbkCreateCloseAction,
} from '@app/stores';

import {
  ChangeFieldEventType,
} from '@app/types';

import {
  DialogBrick,
  DialogContentBrick,
  ButtonsBrick,
  CardHeaderBrick,
  ButtonCancelBlock,
  ButtonSaveBlock,
  FieldTextBlock,
  FieldLongTextBlock,
} from '@app/components';

import { errorTranslate } from '@app/helpers';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';


export const HandbkCreateFeature = () => {
  const initialformValue = {
    name: '',
    description: '',
  };

  const dispatch = useAppDispatch();
  const configurationIsSubmitting = useAppSelector((s) => s.configuration.status === 'submitting');
  const opened = useAppSelector((s) => s.configuration.modalHandbkCreateIsOpened);
  const errors = useAppSelector((s) => s.configuration.errors);

  const [formDirty, formDirtySet] = useState(false);
  const [sendedForm, sendedFormSet] = useState(false);
  const [formValue, formValueSet] = useState(initialformValue);


  const changeFormValue = (e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const closeForm = () => {
    dispatch(modalHanbkCreateCloseAction());
    formValueSet(initialformValue);
    sendedFormSet(false);
    formDirtySet(false);
  }

  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();
    
    sendedFormSet(true);

    const result = await dispatch(handbkCreateAction(formValue));

    if (result.type === '@@configuration/handbk-create/fulfilled') {
      closeForm();
    }
  }

  const setError = (fieldName: string) => {
    return sendedForm
      ? errors && errors[fieldName] && errorTranslate(errors[fieldName][0])
      : '';
  }

  return (
    <DialogBrick
      size="large"
      opened={opened}
      onClose={closeForm}
    >
      <CardHeaderBrick
        title="Добавить справочник"
        extra={<ButtonCancelBlock buttonIcon onClick={() => closeForm()} />}
      />
      <DialogContentBrick>
        <div className="row justify-content-lg-center">
          <div className="col col-lg-6">
            <form onSubmit={(e: SyntheticEvent) => onSubmit(e)}>
              <FieldTextBlock
                name="name"
                label="Название"
                value={formValue.name}
                onChange={changeFormValue}
                error={setError('name')}
                required
              />
              <FieldLongTextBlock
                name="description"
                label="Описание"
                value={formValue.description}
                onChange={changeFormValue}
                error={setError('description')}
              />
              <ButtonsBrick>
                <ButtonSaveBlock
                  loading={configurationIsSubmitting}
                  onClick={onSubmit}
                  disabled={!formDirty}
                />
                <ButtonCancelBlock onClick={() => closeForm()} />
              </ButtonsBrick>
            </form>
          </div>
        </div>
      </DialogContentBrick>
    </DialogBrick>
  );
}
