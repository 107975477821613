import React from 'react';

import { 
  handbkSortableAction,
  handbkVariantDeleteAction,
 } from '@app/stores';

 import { useAppDispatch } from '@app/hooks';
 import { HandbkVariantType } from '@app/types';
 import { useMainApi } from '../../context';

export interface VariantListContextInterface {
  onDragEnd: (e: any) => Promise<null | undefined>;
  handleClick: (variant: HandbkVariantType) => void;
};

export const useVariantListHook = (
): VariantListContextInterface => {
  const {
    handbk,
  } = useMainApi();

  const dispatch = useAppDispatch();

  const handleClick = React.useCallback((variant: HandbkVariantType) => {
    return dispatch(handbkVariantDeleteAction({ id: variant.id }));
  }, [
    dispatch,
  ]);
  
  const onDragEnd = React.useCallback(async (e: any) => {
    if (
      e.over === null ||
      e.active.data.current.sortable.index === e.over.data.current.sortable.index
    ) return;

    if (!handbk) return null;

    const sortable = [...handbk.sortable];

    const sortableToUp = e.active.data.current.sortable.index > e.over.data.current.sortable.index;
    const dragableIndex = e.active.data.current.sortable.index;
    const endIndex = e.over.data.current.sortable.index;

    sortable.splice(endIndex + (sortableToUp ? 0 : 1), 0, sortable[dragableIndex]);
    sortable.splice(dragableIndex + (sortableToUp ? 1 : 0), 1);

    await dispatch(handbkSortableAction({
      id: handbk.handbkId,
      sortable,
    }));
  }, [
    dispatch,
    handbk,
  ]);

  return React.useMemo(() => ({
    onDragEnd,
    handleClick,
  }), [
    onDragEnd,
    handleClick,
  ]);
};
