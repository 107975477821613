import {
  ButtonSaveBlock,
  ButtonCancelBlock,
  FieldBooleanBlock,
  FieldTextBlock,
  TransitionOpacityBrick,
} from '@app/components';

import {
  useMainApi,
  useGeneralApi,
} from '../../context';

import styles from './general-update.module.scss';


export const GeneralUpdateComponent = () => {
  const {
    roleDetailIsSubmitting,
    roleCurrent,
    isEditingGeneral,
  } = useMainApi();

  const {
    formValue,
    formDirty,
    changeFormValue,
    onDone,
    onSubmit,
    setError,
  } = useGeneralApi();

  if (formValue === null) return null;

  return (
    <>
      {roleCurrent && isEditingGeneral && (
        <TransitionOpacityBrick>
          <div className={styles['general-update']}>
            <form onSubmit={onSubmit}>
              <div className="row justify-content-lg-center">
                <div className="col col-lg-6">
                  <div className={styles['general-update__form']}>
                    <FieldTextBlock
                      name="name"
                      label="Название"
                      value={formValue.name}
                      onChange={changeFormValue}
                      error={setError('name')}
                      required
                    />
                    <FieldBooleanBlock
                      name="CONFIGURATOR_ACCESS"
                      label="Доступ к конфигуратору"
                      value={formValue.CONFIGURATOR_ACCESS}
                      onChange={changeFormValue}
                      error={setError('CONFIGURATOR_ACCESS')}
                      required
                    />
                    <FieldBooleanBlock
                      name="PRINT_FORM_ACCESS"
                      label="Доступ к конструктору форм"
                      value={formValue.PRINT_FORM_ACCESS}
                      onChange={changeFormValue}
                      error={setError('PRINT_FORM_ACCESS')}
                      required
                    />
                    <FieldBooleanBlock
                      name="HANDBK_ACCESS"
                      label="Конфигурация справочников"
                      value={formValue.HANDBK_ACCESS}
                      onChange={changeFormValue}
                      error={setError('HANDBK_ACCESS')}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className={styles['general-update__buttons']}>
                <ButtonSaveBlock
                  loading={roleDetailIsSubmitting}
                  onClick={onSubmit}
                  disabled={!formDirty}
                />
                <ButtonCancelBlock onClick={() => onDone()} />
              </div>
            </form>
          </div>
        </TransitionOpacityBrick>
      )}
    </>
  );
};
  