import {
  SearchDocumentIcon,
  ButtonIconSquareBrick,
  FieldMultiselectBlock,
} from '@app/components';
import { DocumentMultiselectProps } from './document-multiselect.props';


export const DocumentMultiselectField = ({
  fieldConfig,
  handbk,
  value,
  onChange,
  error,
  openModal,
  disabled,
}: DocumentMultiselectProps) => {
  if (handbk === undefined) return (<p key={fieldConfig.key}>Ошибка словаря</p>);
  const listIsLong = handbk.variants && handbk.variants.length > 30;

  return (
    <FieldMultiselectBlock
      key={fieldConfig.key}
      name={fieldConfig.key}
      label={fieldConfig.label}
      value={value}
      onChange={onChange}
      error={error}
      items={handbk.variants}
      counted={fieldConfig.counted}
      required={fieldConfig.required}
      prompt={fieldConfig.prompt}
      enableSearch={listIsLong}
      enablePagination={listIsLong}
      extra={<ButtonIconSquareBrick
        icon={<SearchDocumentIcon />}
        onClick={() => openModal(fieldConfig.key, fieldConfig.type)}
        size='large'
      />}
      disabled={disabled}
    />
  );
};
