import {
  FormFieldBrick,
  AsteriskBrick,
  DatePickerBrick,
} from '@app/components';

import { FieldDateProps } from './field-date.props';


export const FieldDateBlock = ({
  label,
  value,
  onChange,
  name,
  error,
  prompt,
  required,
  counted,
  disabled,
}: FieldDateProps) => {

  return (
    <FormFieldBrick error={error} prompt={prompt}>
      <DatePickerBrick
        label={<>{label}<AsteriskBrick required={required} counted={counted} /></>}
        value={value}
        onChange={onChange}
        name={name}
        disabled={disabled}
      />
    </FormFieldBrick>
  );
};
