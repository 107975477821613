import {
  ButtonBackBlock,
  ButtonEditBlock,
  ButtonCancelBlock,
  CardDataComposition,
} from '@app/components';

import {
  useMainApi,
} from '../../context';


export const GeneralComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    id,
    hasUpdateAccess,
    roleDetailIsLoading,
    isEditingGeneral,
    isEditingGeneralSet,
  } = useMainApi();

  return (
    <CardDataComposition
      title={'Роль №' + id}
      extra={
        <>
          {isEditingGeneral
            ? (
              <ButtonCancelBlock buttonIcon onClick={() => isEditingGeneralSet(false)} />
            )
            : hasUpdateAccess &&(
              <ButtonEditBlock onClick={() => isEditingGeneralSet(true)} />
            )
          }
          {!isEditingGeneral && <ButtonBackBlock buttonIcon />}
        </>
      }
      loading={roleDetailIsLoading}
    >
      {children}
    </CardDataComposition>
  );
};
