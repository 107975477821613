import { MainProvider } from './context';
import { MainComponent } from './components';
import { PrintFormVarsProps } from './print-form-vars.props';


export const PrintFormVarsSmart = ({
  opened,
  onClose,
  onSelect,
}: PrintFormVarsProps) => {
  return (
    <MainProvider
      opened={opened}
      onClose={onClose}
      onSelect={onSelect}
    >
      <MainComponent />
    </MainProvider>
  );
}
