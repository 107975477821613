import {
  ButtonBrick,
  ButtonEditBlock,
  ButtonCancelBlock,
  CardDataComposition,
} from '@app/components';

import {
  useMainApi, useUserAccessApi,
} from '../../context';


export const UserAccessComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    roleDetailIsLoading,
    hasUpdateAccess,
    isEditingUserAccess,
    isEditingUserAccessSet,
  } = useMainApi();

  const {
    allowAllDisabled,
    disallowAllDisabled,
    allowAll,
    disallowAll,
  } = useUserAccessApi();

  return (
    <CardDataComposition
      title='Матрица доступа к настройкам пользователя'
      extra={
        <>
          {isEditingUserAccess
            ? (
              <>
                <ButtonBrick
                  variant="text"
                  onClick={allowAll}
                  disabled={allowAllDisabled}
                >
                  Разрешить все
                </ButtonBrick>
                <ButtonBrick
                  variant="text"
                  onClick={disallowAll}
                  disabled={disallowAllDisabled}
                >
                  Запретить все
                </ButtonBrick>
                <ButtonCancelBlock buttonIcon onClick={() => isEditingUserAccessSet(false)} />
              </>
            )
            : hasUpdateAccess &&(
              <ButtonEditBlock onClick={() => isEditingUserAccessSet(true)} />
            )
          }
        </>
      }
      loading={roleDetailIsLoading}
    >
      {children}
    </CardDataComposition>
  );
};
  