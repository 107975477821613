import React from 'react';
import { handbkVariantUpdateAction } from '@app/stores';

import {
  ChangeFieldEventType,
} from '@app/types';

import { errorTranslate } from '@app/helpers';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

import { useMainApi } from '../../context';

const initialformValue = {
  label: '',
};

export interface VariantUpdateContextInterface {
  formValue: InitialFormValue | null;
  formDirty: boolean;
  changeFormValue: (e: ChangeFieldEventType) => void;
  closeForm: () => void;
  onSubmit: (e?: React.SyntheticEvent) => Promise<void>;
  setError: (fieldname: string) => string | null;
};

type InitialFormValue = {
  label: string;
}

export const useVariantUpdateHook = (
): VariantUpdateContextInterface => {
  const {
    selectedVariant,
    selectedVariantSet,
  } = useMainApi();

  const dispatch = useAppDispatch();
  const [formValue, formValueSet] = React.useState<InitialFormValue | null>(null);
  const [formDirty, formDirtySet] = React.useState(false);
  const [sendedForm, sendedFormSet] = React.useState(false);
  const errors = useAppSelector((s) => s.configuration.errors);

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      if (state === null) return null;

      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const closeForm = React.useCallback(() => {
    selectedVariantSet(null);
    formValueSet(initialformValue);
    sendedFormSet(false);
    formDirtySet(false);
  }, [
    selectedVariantSet,
  ]);

  const onSubmit = React.useCallback(async (e?: React.SyntheticEvent) => {
    if (e) e.preventDefault();
    if (formValue === null) return; 
    
    sendedFormSet(true);

    const result = await dispatch(handbkVariantUpdateAction({
      id: selectedVariant!.id,
      label: formValue.label,
    }));

    if (result.type === '@@configuration/handbk-variant-update/fulfilled') {
      closeForm();
    }
  }, [
    formValue,
    selectedVariant,
    dispatch,
    sendedFormSet,
    closeForm,
  ]);

  const setError = React.useCallback((fieldName: string) => {
    return sendedForm
      ? errors && errors[fieldName] && errorTranslate(errors[fieldName][0])
      : '';
  }, [
    errors,
    sendedForm,
  ]);

  React.useEffect(() => {
    if (selectedVariant === null) {
      formValueSet(null);
      return;
    }

    formValueSet({ label: selectedVariant.label });

  }, [
    selectedVariant,
  ])

  return React.useMemo(() => ({
    formValue,
    formDirty,
    changeFormValue,
    closeForm,
    onSubmit,
    setError,
  }), [
    formValue,
    formDirty,
    changeFormValue,
    closeForm,
    onSubmit,
    setError,
  ]);
};
