import React from 'react';
import { useParams } from 'react-router-dom';

import { callNotifyAction } from '@app/stores';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

import {
  DepartmentType,
  BackendErrorsType,
} from '@app/types';

import { DepartmentApi } from '@app/api';


export interface MainContextInterface {
  id: string | undefined;
  departmentCurrent: DepartmentType | null;
  departmentDetailIsSubmitting: boolean;
  departmentDetailIsLoading: boolean;
  hasUpdateAccess: boolean;
  isEditing: boolean;
  errors: BackendErrorsType | null;
  departmentCurrentSet: React.Dispatch<React.SetStateAction<DepartmentType | null>>;
  isEditingSet: (value: boolean) => void;
  departmentDetailIsSubmittingSet: React.Dispatch<React.SetStateAction<boolean>>;
  errorsSet: React.Dispatch<React.SetStateAction<BackendErrorsType | null>>;
};

export const useMainHook = (
): MainContextInterface => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const hasUpdateAccess = useAppSelector((state) => state.auth.user?.role.DEPARTMENT_UPDATE !== 'NEVER');

  const [departmentCurrent, departmentCurrentSet] = React.useState<DepartmentType | null>(null);
  const [departmentDetailIsSubmitting, departmentDetailIsSubmittingSet] = React.useState(false);
  const [departmentDetailIsLoading, departmentDetailIsLoadingSet] = React.useState(false);
  const [isEditing, isEditingSet] = React.useState(false);
  const [errors, errorsSet] = React.useState<BackendErrorsType | null>(null);
  
  const getDepartmentOne = React.useCallback(async () => {
    departmentDetailIsLoadingSet(true);
    const response = await DepartmentApi.getOne((Number(id)));

    if (!response.success) {
      dispatch(callNotifyAction({
        type: 'error',
        message: 'Не удалось загрузить подразделение',
      }));

      errorsSet(response.errors as BackendErrorsType);
      return;
    }

    departmentCurrentSet(response.data.department);
    departmentDetailIsLoadingSet(false);
  }, [
    dispatch,
    id,
  ]);

  React.useEffect(() => {
    getDepartmentOne();
  }, [
    getDepartmentOne,
  ]);

  return React.useMemo(() => ({
    id,
    departmentCurrent,
    departmentDetailIsSubmitting,
    departmentDetailIsLoading,
    hasUpdateAccess,
    isEditing,
    errors,
    departmentCurrentSet,
    isEditingSet,
    departmentDetailIsSubmittingSet,
    errorsSet,
  }), [
    id,
    departmentCurrent,
    departmentDetailIsSubmitting,
    departmentDetailIsLoading,
    hasUpdateAccess,
    isEditing,
    errors,
    departmentCurrentSet,
    isEditingSet,
    departmentDetailIsSubmittingSet,
    errorsSet,
  ]);
};
