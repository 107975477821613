import { FieldMulticheckBlock } from '@app/components';
import { HandbkRemoteCheckProps } from './handbk-remote-check.props';


export const HandbkRemoteCheckField = ({
  fieldConfig,
  handbkRemote,
  value,
  onChange,
  error,
  disabled,
}: HandbkRemoteCheckProps) => {
  if (!handbkRemote) return (<p key={fieldConfig.key} >Ошибка словаря</p>);

  return (
    <FieldMulticheckBlock
      key={fieldConfig.key}
      name={fieldConfig.key}
      label={fieldConfig.label}
      value={value}
      onChange={onChange}
      error={error}
      items={handbkRemote.variants.map((v) => ({ value: v.id, label: v.label }))}
      counted={fieldConfig.counted}
      required={fieldConfig.required}
      prompt={fieldConfig.prompt}
      disabled={disabled}
    />
  );
};
