import {
  HttpService,
} from '@app/services';

import {
  CurrentUserType,
  BaseResponseInterface,
} from '@app/types';

import { SigninRequest } from './types';


export class AuthApi {
  public static async signin(
    payload: SigninRequest,
  ): Promise<BaseResponseInterface<{ user: CurrentUserType }>> {
    return await HttpService.sendRequest('POST', '/auth/signin', {
      user: payload,
    });
  };

  public static async current(): Promise<BaseResponseInterface<{ user: CurrentUserType }>> {
    return await HttpService.sendRequest('GET', '/auth/current');
  };
};
