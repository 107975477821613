import {
  DocumentConfigType,
  FieldConfigType,
} from '@app/types';

import { checkVisibleField } from './check-visible-field.helper';


export const calculateProgress = (
  formValue: { [key: string]: any } | null,
  fieldConfigs: FieldConfigType[],
  documentConfig: DocumentConfigType
): [number, number] => {
  let totalCompletedFields = 0;
  let totalReqFields = 0;

  const requiredCheck = (fieldConfig: FieldConfigType): boolean => {
    const calculatedField = fieldConfig.required || fieldConfig.counted;

    if (calculatedField && !fieldConfig.visibleRule) {
      return true;
    }

    if (!formValue) {
      return false;
    }

    const visibleField = checkVisibleField(documentConfig, fieldConfig, formValue);

    if (calculatedField && visibleField) {
      return true
    }
    
    return false;
  }

  fieldConfigs.forEach((fieldConfig) => {
    const isRequiredField = requiredCheck(fieldConfig);

    if (isRequiredField) {
      totalReqFields += 1;

      const filled = formValue
        && formValue[fieldConfig.key] !== undefined
        && formValue[fieldConfig.key] !== null
        && formValue[fieldConfig.key] !== ''
        && (Array.isArray(formValue[fieldConfig.key]) ? formValue[fieldConfig.key].length > 0 : true)

      if (filled) {
        totalCompletedFields += 1;
      }
    }
  });

  return [totalCompletedFields, totalReqFields];
}
