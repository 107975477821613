import cs from 'classnames';

import { AsteriskProps } from './asterisk.props';
import styles from './asterisk.module.scss';


export const AsteriskBrick = ({ required, counted }: AsteriskProps) => {
  const asteriskClassnames = cs(
    styles['asterisk'],
    { [styles['asterisk--required']]: required },
    { [styles['asterisk--counted']]: counted },
  );

  return (required || counted) ? (
    <span className={asteriskClassnames}>*</span>
  ) : null;
}
