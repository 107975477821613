import { HttpService } from '@app/services';

import {
  BaseResponseInterface,
} from '@app/types';

import {
  PrintFormBuildDocxRequest,
  PrintFormBuildDocxResponse,
  PrintFormBuildTemplateRequest,
  PrintFormBuildTemplateResponse,
  PrintFormCreateRequest,
  PrintFormCreateResponse,
  PrintFormDeleteRequest,
  PrintFormDeleteResponse,
  PrintFormListRequest,
  PrintFormListResponse,
  PrintFormRefreshTemplateImagesResponse,
  PrintFormUpdateRequest,
  PrintFormUploadDocxResponse,
  PrintFormResponse,
  PrintImageDeleteRequest,
  PrintImageDeleteResponse,
  PrintImageListResponse,
  PrintImageOneResponse,
  PrintImageUploadResponse,
  UpdateMarginsRequest,
  UpdateTemplateRequest,
} from './types';


export class PrintFormApi {
  public static async getListByDocumentConfig(
    payload: PrintFormListRequest,
  ): Promise<BaseResponseInterface<PrintFormListResponse>> {
    return await HttpService.sendRequest('GET', '/print-form/get-by-document-config-id', {...payload.query });
  };

  public static async create(
    payload: PrintFormCreateRequest,
  ): Promise<BaseResponseInterface<PrintFormCreateResponse>> {
    return await HttpService.sendRequest('POST', '/print-form/create', payload);
  };

  public static async update(
    payload: PrintFormUpdateRequest,
  ): Promise<BaseResponseInterface<PrintFormResponse>> {
    return await HttpService.sendRequest('POST', '/print-form/update', payload);
  };

  public static async updateMargins(
    payload: UpdateMarginsRequest,
  ): Promise<BaseResponseInterface<PrintFormResponse>> {
    return await HttpService.sendRequest('POST', '/print-form/update-margins', payload);
  };

  public static async updateTemplate(
    payload: UpdateTemplateRequest,
  ): Promise<BaseResponseInterface<PrintFormResponse>> {
    return await HttpService.sendRequest('POST', '/print-form/update-template', payload);
  };

  public static async delete(
    payload: PrintFormDeleteRequest,
  ): Promise<BaseResponseInterface<PrintFormDeleteResponse>> {
    return await HttpService.sendRequest('DELETE', '/print-form/delete', payload);
  };

  public static async refreshTemplateImages(
    template: string,
  ): Promise<BaseResponseInterface<PrintFormRefreshTemplateImagesResponse>> {
    return await HttpService.sendRequest('POST', '/print-form/refresh-template-images', { template });
  }

  public static async buildTemplate(
    payload: PrintFormBuildTemplateRequest,
  ): Promise<BaseResponseInterface<PrintFormBuildTemplateResponse>> {
    return await HttpService.sendRequest('POST', '/print-form/build-template', {
      template: payload.printForm.template,
      printDataQueries: payload.printDataQueries,
    });
  }

  public static async buildDocx(
    payload: PrintFormBuildDocxRequest,
  ): Promise<BaseResponseInterface<PrintFormBuildDocxResponse>> {
    return await HttpService.sendRequest('GET', '/print-form/build-docx', payload);
  }

  public static async uploadPrintImage(
    file: File,
  ): Promise<BaseResponseInterface<PrintImageUploadResponse>> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return await HttpService.sendRequest('POST-FILE', '/print-image/upload', formData);
  }

  public static async uploadDocxTemplate(
    printFormId: number,
    file: File,
  ): Promise<BaseResponseInterface<PrintFormUploadDocxResponse>> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('printFormId', String(printFormId));

    return await HttpService.sendRequest('POST-FILE', '/print-form/upload-docx-template', formData);
  }

  public static async downloadDocxTemplate(
    printFormId: number,
  ): Promise<BaseResponseInterface<{ url: string }>> {
    return await HttpService.sendRequest('GET', '/print-form/download-docx-template', { printFormId });
  }

  public static async getPrintImageList(
  ): Promise<BaseResponseInterface<PrintImageListResponse>> {
    return await HttpService.sendRequest('GET', '/print-image/list');
  }

  public static async deletePrintImage(
    payload: PrintImageDeleteRequest,
  ): Promise<BaseResponseInterface<PrintImageDeleteResponse>> {
    return await HttpService.sendRequest('DELETE', '/print-image/delete', payload);
  }

  public static async getPrintImageOneById(
    id: number,
  ): Promise<BaseResponseInterface<PrintImageOneResponse>> {
    return await HttpService.sendRequest('GET', '/print-image/one/' + id);
  }

  public static async getPrintImageOneFileById(
    id: number,
  ): Promise<BaseResponseInterface<PrintImageOneResponse>> {
    return await HttpService.sendRequest('GET', '/print-image/one-file/' + id);
  }
};
