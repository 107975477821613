import {
  ButtonCancelBlock,
  ButtonSaveBlock,
  FieldTextBlock,
  TransitionOpacityBrick,
} from '@app/components';

import {
  useMainApi,
  useGeneralApi,
} from '../../context';

import styles from './update.module.scss';


export const UpdateComponent = () => {
  const {
    departmentDetailIsSubmitting,
    isEditing,
  } = useMainApi();

  const {
    formValue,
    formDirty,
    changeFormValue,
    onDone,
    onSubmit,
    setError,
  } = useGeneralApi();

  if  (formValue === null) return null;

  return (
    <>
      {isEditing && (
        <TransitionOpacityBrick>
          <div className="row justify-content-lg-center">
            <div className="col col-lg-6">
              <div className={styles['update']}>
                <form onSubmit={onSubmit}>
                  <FieldTextBlock
                    name="name"
                    label="Название"
                    value={formValue.name}
                    onChange={changeFormValue}
                    error={setError('name')}
                    required
                  />

                  <ButtonSaveBlock
                    loading={departmentDetailIsSubmitting}
                    onClick={onSubmit}
                    disabled={!formDirty}
                  />
                  <ButtonCancelBlock onClick={onDone} />
                </form>
              </div>
            </div>
          </div>
        </TransitionOpacityBrick>
      )}
    </>
  );
};
