import {
  ToolbarBrick,
  ButtonCloseBlock,
} from '@app/components';

import { ToolbarProps } from './toolbar.props';
import styles from './toolbar.module.scss';

export const ToolbarComposition = ({
  children,
  extra,
  onClose,
}: ToolbarProps) => {
  return (
    <div className={styles['toolbar']}>
      <ToolbarBrick>
        <div className={styles['toolbar__container']}>
          <div className={styles['toolbar__left']}>
            {onClose && <ButtonCloseBlock onClick={onClose} />}
            {children}
          </div>
          <div className={styles['toolbar__right']}>
            {extra}
          </div>
        </div>
      </ToolbarBrick>
    </div>
  );
};
