import {
  ButtonSaveBlock,
} from '@app/components';

import { useAppSelector } from '@app/hooks';

import { useMainApi } from '../../context';


export const SaveButtonComponent = () => {
  const {
    printFormSave,
    editorDirty,
  } = useMainApi();
  const loading = useAppSelector((state) => state.printForm.status === 'submitting');

  return (
    <ButtonSaveBlock
      size="small"
      onClick={printFormSave}
      variant="outlined"
      disabled={!editorDirty}
      loading={loading}
    />
  );
};
