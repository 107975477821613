import {
  GeneralProvider,
  UserAccessProvider,
  DocumentAccessProvider,
} from '../../context';

import {
  GeneralComponent,
  GeneralTableComponent,
  GeneralUpdateComponent,
  UserAccessComponent,
  UserAccessTableComponent,
  UserAccessUpdateComponent,
  DocumentAccessComponent,
  DocumentAccessTableComponent,
  DocumentAccessUpdateComponent,
} from '..';

import styles from './main.module.scss';


export const MainComponent = () => {
	return (
    <div className={styles['main']}>
      <div className={styles['main__card']}>
        <GeneralProvider>
          <GeneralComponent>
            <GeneralTableComponent />
            <GeneralUpdateComponent />
          </GeneralComponent>
        </GeneralProvider>
      </div>
      <div className={styles['main__card']}>
        <UserAccessProvider>
          <UserAccessComponent>
            <UserAccessTableComponent />
            <UserAccessUpdateComponent />
          </UserAccessComponent>
        </UserAccessProvider>
      </div>
      <div className={styles['main__card']}>
        <DocumentAccessProvider>
          <DocumentAccessComponent>
            <DocumentAccessTableComponent />
            <DocumentAccessUpdateComponent />
          </DocumentAccessComponent>
        </DocumentAccessProvider>
      </div>
    </div>
	);
};
