import { useEffect } from 'react';

import { setBreadcrumbs } from '@app/stores';

import {
  PageBaseLayout,
  RoleCreateFeature,
} from '@app/components';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';


export const RoleCreatePage = () => {
  const dispatch = useAppDispatch();
  const { breadcrumbs } = useAppSelector((s) => s.app);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { title: 'Главная', link: '/' },
      { title: 'Роли', link: '/role-list' },
      { title: 'Добавить роль', link: '' },
    ]));
  }, [dispatch])

  return (
    <PageBaseLayout
      breadcrumbs={breadcrumbs}
    >
      <RoleCreateFeature />
    </PageBaseLayout>
  );
};
