import {
  useDocumentSearchApi,
} from '../../context';

import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';

export const ResultComponent = () => {
  const {
    findedDocument,
    canBeUsed,
    goToDocument,
    resetForm,
    closeDialog,
  } = useDocumentSearchApi();
  return (
    <div>
      {findedDocument && (
        <Card>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Найден документ
            </Typography>
            <Typography variant="body1" component="div">
              {findedDocument.label}
            </Typography>
          </CardContent>
          <CardActions>
            <Button onClick={goToDocument}>Перейти</Button>
            <Button onClick={resetForm}>Новый поиск</Button>
            {canBeUsed && <Button onClick={() => closeDialog(findedDocument.id)}>Использовать</Button>}
          </CardActions>
        </Card>
      )}
    </div>
  );
};