import {
  ToolbarComposition,
  ButtonHasChangesComposition,
} from '@app/components';

import { useAppSelector } from '@app/hooks';

import { useMainApi } from '../../context';


export const ToolbarComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const printFormName = useAppSelector((s) => s.printForm.currentPrintForm?.name);
  const isSubmitting = useAppSelector((s) => s.printForm.status === 'submitting');
  const {
    editorDirty,
    printFormSave,
    printFormEditorClose,
  } = useMainApi();

  const saveAndCloseEditor = async () => {
    await printFormSave();
    printFormEditorClose();
  }

  return (
    <ToolbarComposition
      extra={children}
    >
      <ButtonHasChangesComposition
        onSave={saveAndCloseEditor}
        onDiscard={printFormEditorClose}
        loading={isSubmitting}
        hasChanges={editorDirty}
      />
      <h6>{printFormName || ''}</h6>
    </ToolbarComposition>
  );
};
