import { ContentState } from 'draft-js';
import * as React from 'react';
import { useMainApi } from '../context/main/main.provider';

type LinkEntityProps = {
  children: React.ReactNode;
  contentState: ContentState;
  entityKey: string;
}

export const LinkEntity = ({
  contentState,
  entityKey,
  children,
}: LinkEntityProps) => {
  const { setEntityData } = useMainApi();
  const { url } = contentState.getEntity(entityKey).getData();
  const handlerClick = () => {
    const newUrl = prompt('URL:', url);
    if (newUrl) {
      setEntityData(entityKey, { url: newUrl });
    }
  }

  return (
    <a href={url} onClick={handlerClick}>
      {children}
    </a>
  );
}
