import { PrintFormApi } from '@app/api';
import { callNotifyAction, uploadDocxTemplateAction } from '@app/stores';
import copy from 'copy-to-clipboard';
import React from 'react';
import { useMainApi } from '..';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { PrintFormDocxService } from '@app/services';
import { FieldConfigType } from '@app/types';


export interface DocxContextInterface {
  varsModalOpened: boolean;
  templateIsUploading: boolean;
  templateIsDownloading: boolean;
  templateIsBuildinging: boolean;
  varsModalOpen: () => void;
  varsModalClose: () => void;
  keySelect: (key: string, fieldConfig?: FieldConfigType) => void;
  uploadDocxTemplate: (file: File) => void;
  downloadDocxTemplate: () => void;
  printDocx: () => void;
};

export const useDocxHook = (
): DocxContextInterface => {
  const dispatch = useAppDispatch();
  const documentState = useAppSelector((s) => s.document);
  const configurationState = useAppSelector((s) => s.configuration);
  const [varsModalOpened, varsModalOpenedSet] = React.useState(false);
  const [templateIsUploading, templateIsUploadingSet] = React.useState(false);
  const [templateIsDownloading, templateIsDownloadingSet] = React.useState(false);
  const [templateIsBuildinging, templateIsBuildingingSet] = React.useState(false);
  const { printFormCurrent } = useMainApi();

  const varsModalOpen = React.useCallback(() => {
    varsModalOpenedSet(true);
  }, []);

  const varsModalClose = React.useCallback(() => {
    varsModalOpenedSet(false);
  }, []);

  const keySelect = React.useCallback((
    key: string,
    fieldConfig?: FieldConfigType,
  ) => {
    const isDocumentImage = fieldConfig && fieldConfig.type === 'file' && fieldConfig.fileType === 'image';
    if (isDocumentImage) key = 'fdi__' + key;
    if (key.includes('func_var-table')) key = key.slice(0, -1);

    key = key
      .replaceAll('func_qr-link', 'fqr')
      .replaceAll('func_var-table', 'fvt')
      .replaceAll('-', '_')
      .replaceAll(':', '__')
      .replaceAll('(', '___')
      .replaceAll(',', '__');

    if (key.includes('fqr')) key = 'IMAGE ' + key
    else if (key.includes('fdi')) key = 'IMAGE ' + key
    else key = 'INS ' + key;
    
    copy(`{${key}}`);
    dispatch(callNotifyAction({ type: 'info', message: 'Ключ скопирован в буфер обмена.' }));
  }, [
    dispatch,
  ]);

  const uploadDocxTemplate = React.useCallback(async (
    file: File,
  ) => {
    if (!printFormCurrent) return;
    templateIsUploadingSet(true);
    await dispatch(uploadDocxTemplateAction({
      printFormId: printFormCurrent.id,
      file,
    }));
    templateIsUploadingSet(false);
  }, [
    dispatch,
    printFormCurrent,
  ]);

  const downloadDocxTemplate = React.useCallback(async () => {
    if (!printFormCurrent) return;
    templateIsDownloadingSet(true);
    const response = await PrintFormApi.downloadDocxTemplate(printFormCurrent.id);
    templateIsDownloadingSet(false);
    
    if (!response.success) return;

    window.open(response.data.url);
  }, [
    printFormCurrent,
  ]);

  const printDocx = React.useCallback(async () => {
    templateIsBuildingingSet(true);
    
    if (!printFormCurrent) {
      templateIsBuildingingSet(false);
      return;
    }
    
    const response = await PrintFormApi.downloadDocxTemplate(printFormCurrent.id);
    
    templateIsBuildingingSet(false);
    
    if (!response.success) {
      return;
    }

    PrintFormDocxService.build(
      printFormCurrent,
      response.data.url,
      documentState,
      configurationState,
    );
  }, [
    printFormCurrent,
    documentState,
    configurationState,
  ]);

  return React.useMemo(() => ({
    varsModalOpened,
    templateIsUploading,
    templateIsDownloading,
    templateIsBuildinging,
    varsModalOpen,
    varsModalClose,
    keySelect,
    uploadDocxTemplate,
    downloadDocxTemplate,
    printDocx,
  }), [
    varsModalOpened,
    templateIsUploading,
    templateIsDownloading,
    templateIsBuildinging,
    varsModalOpen,
    varsModalClose,
    keySelect,
    uploadDocxTemplate,
    downloadDocxTemplate,
    printDocx,
  ]);
};
