import {
  TransitionOpacityBrick,
  TableContainerBrick,
  TableBrick,
  TableHeadBrick,
  TableBodyBrick,
  TableRowBrick,
  TableCellBrick,
} from '@app/components';

import {
  dateTranslate,
} from '@app/helpers';

import {
  useMainApi,
} from '../../context';


export const TableComponent = () => {
  const {
    isEditing,
    userCurrent,
  } = useMainApi();

  if (userCurrent === null || isEditing) return null;

  return (
    <TransitionOpacityBrick>
      <TableContainerBrick>
        <TableBrick aria-label="user-detail">
          <TableHeadBrick>
            <TableRowBrick>
              <TableCellBrick>ID</TableCellBrick>
              <TableCellBrick>Пользователь</TableCellBrick>
              <TableCellBrick>Электронная почта</TableCellBrick>
              <TableCellBrick>Роль</TableCellBrick>
              <TableCellBrick>Подразделение</TableCellBrick>
              <TableCellBrick>Дата создания</TableCellBrick>
              <TableCellBrick>Дата обновления</TableCellBrick>
            </TableRowBrick>
          </TableHeadBrick>
          <TableBodyBrick>
            <TableRowBrick
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCellBrick component="th" scope="row">
                {userCurrent.id}
              </TableCellBrick>
              <TableCellBrick>{userCurrent.firstName} {userCurrent.secondName} {userCurrent.lastName}</TableCellBrick>
              <TableCellBrick>{userCurrent.email}</TableCellBrick>
              <TableCellBrick>{userCurrent.role.name}</TableCellBrick>
              <TableCellBrick>{userCurrent.department.name}</TableCellBrick>
              <TableCellBrick>{dateTranslate(userCurrent.createdAt)}</TableCellBrick>
              <TableCellBrick>{dateTranslate(userCurrent.updatedAt)}</TableCellBrick>
            </TableRowBrick>
          </TableBodyBrick>
        </TableBrick>
      </TableContainerBrick>
    </TransitionOpacityBrick>
  );
}
