import React from 'react';

import {
  VariantCreateContextInterface,
  useVariantCreateHook,
} from './variant-create.hook';

const VariantCreateContext = React.createContext<VariantCreateContextInterface | undefined>(undefined);

export const useVariantCreateApi = () => {
  const context = React.useContext(VariantCreateContext);

  if (context === undefined) {
    throw new Error('useVariantCreateApi must be used within VariantCreateProvider');
  }

  return context;
}

export const VariantCreateProvider = (
  props: React.HTMLAttributes<HTMLDivElement>
) => {
  const api = useVariantCreateHook();

  return (
    <VariantCreateContext.Provider value={api}>
      {props.children}
    </VariantCreateContext.Provider>
  );
};
