import {
  CloseIcon,
  ToolbarBrick,
  ButtonIconBrick,
} from '@app/components';

import { useMainApi } from '../../context';


export const HeaderComponent = () => {
  const {
    closeDialog,
  } = useMainApi();

  return (
    <ToolbarBrick>
      <ButtonIconBrick
        edge="start"
        color="inherit"
        onClick={closeDialog}
        aria-label="close"
      >
        <CloseIcon />
      </ButtonIconBrick>
    </ToolbarBrick>
  );
};
