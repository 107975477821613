import * as React from 'react';


export type ModalsContextInterface = {
  varsDialogOpened: boolean;
  varsDialogOpen: () => void;
  varsDialogClose: () => void;
  marginsDialogOpened: boolean;
  marginsDialogOpen: () => void;
  marginsDialogClose: () => void;
  imageLibraryDialogOpened: boolean;
  imageLibraryDialogOpen: () => void;
  imageLibraryDialogClose: () => void;
}

export const useModalsHook = (): ModalsContextInterface => {
  const [varsDialogOpened, varsDialogOpenedSet] = React.useState<boolean>(false);
  const varsDialogOpen = React.useCallback(() => varsDialogOpenedSet(true), [varsDialogOpenedSet]);
  const varsDialogClose = React.useCallback(() => varsDialogOpenedSet(false), [varsDialogOpenedSet]);

  const [marginsDialogOpened, marginsDialogOpenedSet] = React.useState<boolean>(false);
  const marginsDialogOpen = React.useCallback(() => marginsDialogOpenedSet(true), [marginsDialogOpenedSet]);
  const marginsDialogClose = React.useCallback(() => marginsDialogOpenedSet(false), [marginsDialogOpenedSet]);
  
  const [imageLibraryDialogOpened, imageLibraryDialogOpenedSet] = React.useState<boolean>(false);
  const imageLibraryDialogOpen = React.useCallback(() => imageLibraryDialogOpenedSet(true), [imageLibraryDialogOpenedSet]);
  const imageLibraryDialogClose = React.useCallback(() => imageLibraryDialogOpenedSet(false), [imageLibraryDialogOpenedSet]);
  
  return React.useMemo(() => ({
    varsDialogOpened,
    varsDialogOpen,
    varsDialogClose,
    marginsDialogOpened,
    marginsDialogOpen,
    marginsDialogClose,
    imageLibraryDialogOpened,
    imageLibraryDialogOpen,
    imageLibraryDialogClose,
  }), [
    varsDialogOpened,
    varsDialogOpen,
    varsDialogClose,
    marginsDialogOpened,
    marginsDialogOpen,
    marginsDialogClose,
    imageLibraryDialogOpened,
    imageLibraryDialogOpen,
    imageLibraryDialogClose,
  ]);
}
