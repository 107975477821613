import {
  KeyIcon,
  ButtonIconBrick,
  ButtonCancelBlock,
  ButtonEditBlock,
  ButtonBackBlock,
  CardDataComposition,
} from '@app/components';

import {
  useMainApi,
} from '../../context';

export const GeneralComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    id,
    hasUpdateAccess,
    isEditing,
    userIsLoading,
    editorClose,
    editorOpen,
    modalChangePasswordOpen,
  } = useMainApi();

  return (
    <CardDataComposition
      title={'Пользователь №' + id}
      extra={
        <>
          {isEditing
            ? (
              <ButtonCancelBlock buttonIcon onClick={editorClose} />
            )
            : hasUpdateAccess && (
              <>
                <ButtonIconBrick
                  onClick={modalChangePasswordOpen}
                  aria-label="delete"
                  size="large"
                >
                  <KeyIcon />
                </ButtonIconBrick>
                <ButtonEditBlock onClick={editorOpen} />
              </>
            )
          }
          {!isEditing && <ButtonBackBlock buttonIcon />}
        </>
      }
      loading={userIsLoading}
    >
      {children}
    </CardDataComposition>
  );
};
