import {
  ButtonBrick,
  ButtonLoadingBrick,
  ButtonPrintBlock,
  ButtonUploadBlock,
  ButtonsBrick,
  DownloadIcon,
  FlashIcon,
  PrintFormVarsSmart,
} from '@app/components';
import { useDocxApi, useMainApi } from '../../context';

export const ControlDocxComponent = () => {
  const {
    printFormCurrent,
    printFormAccess,
  } = useMainApi();

  const {
    varsModalOpened,
    templateIsUploading,
    templateIsDownloading,
    templateIsBuildinging,
    varsModalOpen,
    varsModalClose,
    keySelect,
    uploadDocxTemplate,
    downloadDocxTemplate,
    printDocx,
  } = useDocxApi();

  const visible = (
    printFormAccess &&
    printFormCurrent &&
    printFormCurrent.type === 'docx'
  );

  return (
    <>
      {visible && (
        <ButtonsBrick>
          <ButtonPrintBlock
            onClick={printDocx}
            loading={templateIsBuildinging}
          />

          <ButtonUploadBlock
            handleFile={uploadDocxTemplate}
            accept=".doc, .docx"
            variant="text"
            loading={templateIsUploading}
            label={printFormCurrent.docxTemplateKey ? 'Обновить шаблон' : 'Загрузить шаблон'}
          />

          {printFormCurrent.docxTemplateKey && (
            <ButtonLoadingBrick
              onClick={downloadDocxTemplate}
              variant="text"
              size="large"
              startIcon={<DownloadIcon />}
              loading={templateIsDownloading}
            >
              Скачать шаблон
            </ButtonLoadingBrick>
          )}

          <ButtonBrick
            onClick={varsModalOpen}
            variant="text"
            size="large"
            startIcon={<FlashIcon />}
          >
            Переменные
          </ButtonBrick>
          
          <PrintFormVarsSmart
            opened={varsModalOpened}
            onClose={varsModalClose}
            onSelect={keySelect}
          />
        </ButtonsBrick>
      )}
    </>
  );
};
