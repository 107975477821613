import React from 'react';
import cs from 'classnames';

import { Dialog, Slide, useTheme } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import { DialogProps } from './dialog.props';
import styles from './dialog.module.scss';
import { greyColor } from '@app/helpers';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogBrick = ({
  opened,
  onClose,
  size = 'fullscreen',
  children,
}: DialogProps) => {
  const theme = useTheme();
  const backgroundColor = 
    theme.palette.mode === 'light'
      ? greyColor[200]
      : greyColor[1000];
  const maxWidth =
    size === 'large'
      ? 'lg'
      : size === 'medium'
        ? 'md'
        : size === 'small'
          ? 'sm'
          : undefined;

  const csInner = cs(
    styles['dialog__inner'],
    {[styles['dialog__inner--small']]: size === 'small'},
  );

  return (
    <Dialog
      fullScreen={size === 'fullscreen'}
      fullWidth={size === 'medium' || size === 'large'}
      maxWidth={maxWidth}
      TransitionComponent={Transition}
      open={opened}
      onClose={onClose}
      className={styles['dialog']}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {opened && (
        <div
          className={csInner}
          style={{ backgroundColor }}
        >
          {children}
        </div>
      )}
    </Dialog>
  );
};
