import {
  HandbkDetailDialogComponent,
  GeneralComponent,
  GeneralTableComponent,
  GeneralUpdateComponent,
  VariantListComponent,
  VariantCreateComponent,
  VariantUpdateComponent,
} from '..';

import {
  GeneralProvider,
  VariantListProvider,
  VariantCreateProvider,
  VariantUpdateProvider,
} from '../../context';


export const MainComponent = () => {
  return (
    <>
      <HandbkDetailDialogComponent>
        <GeneralProvider>
          <GeneralComponent>
            <GeneralUpdateComponent />
            <GeneralTableComponent />
          </GeneralComponent>
        </GeneralProvider>
        
        <VariantListProvider>
          <VariantListComponent />
        </VariantListProvider>
      </HandbkDetailDialogComponent>

      <VariantCreateProvider>
        <VariantCreateComponent />
      </VariantCreateProvider>

      <VariantUpdateProvider>
        <VariantUpdateComponent />
      </VariantUpdateProvider>
    </>
  );
}
