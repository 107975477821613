import { TextField } from '@mui/material';

import { TextareaProps } from './textarea.props';


export const TextareaBrick = ({
  label,
  name,
  value,
  size = 'medium',
  onChange,
  onFocus,
  onBlur,
  rows = 4,
  disabled,
}: TextareaProps) => {
  return (
    <TextField
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      type="text"
      variant="filled"
      onFocus={onFocus}
      onBlur={onBlur}
      size={size}
      fullWidth
      multiline
      rows={rows}
      disabled={disabled}
    />
  );
};
