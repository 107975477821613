import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';

import {
  ButtonIconSquareBrick,
} from '@app/components';

import { useModalsApi } from '../../../../context';



export const ButtonImageComponent = () => {
  const { imageLibraryDialogOpen } = useModalsApi();

  return (
    <ButtonIconSquareBrick
      icon={<PhotoSizeSelectActualIcon />}
      onClick={imageLibraryDialogOpen}
    />
  );
};
