import { LoadingButton } from '@mui/lab';

import {
  QrCodeScannerIcon,
  SearchDocumentIcon,
  ButtonIconSquareBrick,
  ButtonsBrick,
  ButtonCancelBlock,
  FieldUuidBlock,
} from '@app/components';

import { useDocumentSearchApi } from '../../context';


export const UuidFormComponent = () => {
  const {
    formValue,
    error,
    loadingSearch,
    onSubmit,
    changeForm,
    activateQrCodeScanner,
    closeDialog,
  } = useDocumentSearchApi();


  return (
    <form onSubmit={onSubmit}>
      <FieldUuidBlock
        name="uuid"
        label="Uuid"
        value={formValue.uuid}
        onChange={changeForm}
        error={error}
        extra={<ButtonIconSquareBrick
          icon={<QrCodeScannerIcon />}
          onClick={activateQrCodeScanner}
          size='large'
        />}
        required
      />

      <ButtonsBrick>
        <LoadingButton
          loading={loadingSearch}
          type="submit"
          onClick={onSubmit}
          size={'large'}
          startIcon={<SearchDocumentIcon />}
          variant={'contained'}
          color="primary"
        >
          Найти
        </LoadingButton>
        <ButtonCancelBlock onClick={closeDialog} />
      </ButtonsBrick>
    </form>
  );
};
