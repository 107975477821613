import { LinearProgress } from '@mui/material';

import {
  CardImageBrick,
  GridLayout,
} from '@app/components';

import { ButtonsComponent } from './components';
import { useMainApi } from '../../context';


export const PrintImageListComponent = () => {
  const {
    printImageListIsLoading,
    printImageList,
  } = useMainApi();

  return (
    <>
      {printImageListIsLoading ? (
        <LinearProgress />
      ) : (
        <GridLayout>
          {printImageList.map((printImage) => (
            <CardImageBrick
              src={printImage.src}
              key={printImage.id}
              extra={<ButtonsComponent printImageId={printImage.id} />}
            />
          ))}
          {printImageList.length === 0 && 'Библиотека изображений пуста.'}
        </GridLayout>
      )}
    </>
  );
};
