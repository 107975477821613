import React from 'react';

import {
  ChangePasswordContextInterface,
  useChangePasswordHook,
} from './change-password.hook';

const ChangePasswordContext = React.createContext<ChangePasswordContextInterface | undefined>(undefined);

export const useChangePasswordApi = () => {
  const context = React.useContext(ChangePasswordContext);

  if (context === undefined) {
    throw new Error('useChangePasswordApi must be used within ChangePasswordProvider');
  }

  return context;
}

export const ChangePasswordProvider = (
  props: React.HTMLAttributes<HTMLDivElement>
) => {
  const api = useChangePasswordHook();

  return (
    <ChangePasswordContext.Provider value={api}>
      {props.children}
    </ChangePasswordContext.Provider>
  );
};
