import * as React from 'react';
import { useAppSelector } from '@app/hooks';
import { DocumentConfigType, FieldConfigType } from '@app/types';
import { useLocation } from 'react-router-dom';


export type MainContextInterface = {
  opened: boolean;
  varList: DocumentConfigType[] | null;
  getChildrenList: (documentConfig: DocumentConfigType) => DocumentConfigType[];
  onClose: () => void;
  keySelect: (key: string, fieldConfig?: FieldConfigType,) => void; 
};

export const useMainHook = (
  opened: boolean,
  onClose: () => void,
  onSelect: (key: string, fieldConfig?: FieldConfigType) => void,
): MainContextInterface => {
  const location = useLocation();
  const documentConfigList = useAppSelector((s) => s.configuration.documents);
  const documentConfigIdCurrent = useAppSelector((s) => s.printForm.documentConfigId);

  const varList = React.useMemo(() => {
    const currentDocumentConfig = documentConfigList.find((document) => document.documentConfigId === documentConfigIdCurrent);

    if (!currentDocumentConfig) {
      return null;
    }

    const chips = [currentDocumentConfig];

    const addToChips = (documentConfig: DocumentConfigType, pos: 'up' | 'down') => {
      const finded = chips.find((document) => document!.documentConfigId === documentConfig.documentConfigId);
      if (finded) return;

      if (pos === 'up') chips.unshift(documentConfig);
      else chips.push(documentConfig);
    }

    location
      .pathname
      .split('/')
      .slice(2)
      .map((key) => key.slice(0, key.lastIndexOf('-')))
      .map((key) => documentConfigList.find((document) => document.key === key))
      .reverse()
      .forEach((documentConfig) => {
        addToChips(documentConfig!, 'up')
      });
    
    if (currentDocumentConfig!.parentKey !== 'root') {
      const parentDocumentConfig = documentConfigList.find((document) => document.key === currentDocumentConfig!.parentKey);
      addToChips(parentDocumentConfig!, 'up');
    }

    documentConfigList.forEach((documentConfig) => {
      if (documentConfig.parentKey === currentDocumentConfig!.key && documentConfig.type === 'form-one') {
        addToChips(documentConfig, 'down')
      }
    });

    return chips;
  }, [
    documentConfigIdCurrent,
    documentConfigList,
    location.pathname,
  ]);

  const getChildrenList = React.useCallback((
    documentConfig: DocumentConfigType,
  ) => {
    const childrens = documentConfig
      .sortableChildrens
      .map((id) => documentConfigList.find((documentConfig) => documentConfig.documentConfigId === id))
      .filter((children) => !!children);

    return (childrens as DocumentConfigType[]);
  }, [
    documentConfigList,
  ]);

  const keySelect = React.useCallback((
    key: string,
    fieldConfig?: FieldConfigType,
  ) => {
    onSelect(`${key}`, fieldConfig);
  }, [
    onSelect,
  ]);
  
  return React.useMemo(() => ({
    opened,
    varList,
    getChildrenList,
    onClose,
    keySelect,
  }), [
    opened,
    varList,
    getChildrenList,
    onClose,
    keySelect,
  ]);
};
