import { useEffect, useState } from 'react';

import {
  SnackbarBrick,
  ProgressLinearBrick,
  AlertBrick,
  EmojiStatusBrick,
} from '@app/components';

import { useAppSelector } from '@app/hooks';

import styles from './notify.module.scss';


export const NotifyPart = () => {
  const [open, setOpen] = useState(false);
  const { notify } = useAppSelector((state) => state.app);

  useEffect(() => {
    if (notify.message) {
      setOpen(true);
    }
  }, [notify])

  return (
    <SnackbarBrick
      open={open}
      autoHideDuration={6000}
      transitionDuration={0}
      onClose={() => setOpen(false)}
    >
      <AlertBrick
        icon={false}
        onClose={() => setOpen(false)}
        severity={notify.type}
        sx={{ width: '100%' }}
      >
        <div className={styles['notify']}>
          {notify.message === 'loading' ? (
            <div className={styles['notify__loader']}>
              <ProgressLinearBrick />
            </div>
          ) : (
            <div className={styles['notify__message-wrap']}>
              <span className={styles['notify__emoji']}><EmojiStatusBrick type={notify.type} /></span>
              <span>{notify.message}</span>
            </div>
          )}
        </div>
      </AlertBrick>
    </SnackbarBrick>
  );
};
