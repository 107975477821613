import { useTheme } from '@mui/material';

import { WidgetLinkProps } from './widget-link.props';
import styles from './widget-link.module.scss';


export const WidgetLinkBrick = ({
  title,
  icon,
  onClick,
}: WidgetLinkProps) => {
  const { palette } = useTheme();
  return (
    <div
      className={styles['widget-link']}
      style={{ backgroundColor: palette.primary.main }}
      onClick={onClick}
    >
      <div className={styles['widget-link__title']}>{title}</div>
      <div className={styles['widget-link__icon']}>{icon}</div>
    </div>
  );
};
