import {
  PlusIcon,
  ButtonBrick,
  ButtonIconBrick,
} from '@app/components';

import { ButtonCreateProps } from './button-create.props';


export const ButtonCreateBlock = ({
  onClick,
  buttonIcon,
}: ButtonCreateProps) => {
  if (buttonIcon) return (
    <ButtonIconBrick
      onClick={onClick}
    >
      <PlusIcon />
    </ButtonIconBrick>
  );

  return (
    <ButtonBrick
      color="primary"
      variant="outlined"
      startIcon={<PlusIcon />}
      onClick={onClick}
      size="large"
    >
      Добавить
    </ButtonBrick>
  );
};
