import { useEffect } from 'react';
import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { currentUserAction } from '@app/stores';
import { useAppSelector, useAppDispatch } from '@app/hooks';
import { greyColor } from '@app/helpers';

import { ThemeProviderProps } from './theme-provider.props';


export const ThemeProviderPart = ({
  children,
}: ThemeProviderProps) => {
  const themeMode = useAppSelector((s) => s.app.themeMode);
  const dispatch = useAppDispatch();
  const theme = createTheme({
    typography: {
      h1: {
        fontWeight: 500,
      }
    },
    palette: {
      mode: themeMode,
      primary: {
        main: '#556cd6',
      },
      secondary: {
        main: '#19857b',
      },
      error: {
        main: red.A400,
      },
      background: {
        default: themeMode === 'light' ? greyColor[200] : greyColor[1000],
      },
    },
  });

  useEffect(() => {
    dispatch(currentUserAction());
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
