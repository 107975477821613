import { useState } from 'react';

import { ChangeFieldEventType } from '@app/types';

import {
  FormFieldBrick,
  AsteriskBrick,
  InputFilledBrick,
  InputAdornmentBrick,
} from '@app/components';

import { FieldPhoneProps } from './field-phone.props';


export const FieldPhoneBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  required,
  counted,
  prompt,
  disabled,
}: FieldPhoneProps) => {
  const handleChange = (e: ChangeFieldEventType) => {
    if ((e.target.value as string).length > 10) {
      return;
    }
    
    e.target.value = (e.target.value as string).replace(/\D/g, '');

    setModValue(e.target.value);

    if (e.target.value.indexOf('+7') === -1 && e.target.value) {
      e.target.value = '+7' + e.target.value;
    }

    onChange(e);
  };

  const [modValue, setModValue] = useState(value ? value.slice(2) : '');
  
  return (
    <FormFieldBrick error={error} prompt={prompt}>
      <InputFilledBrick
        labelField={<>{label}<AsteriskBrick required={required} counted={counted} /></>}
        type="text"
        value={modValue}
        onChange={handleChange}
        name={name}
        startAdornment={<InputAdornmentBrick position="start">+7</InputAdornmentBrick>}
        disabled={disabled}
      />
    </FormFieldBrick>
  );
};
