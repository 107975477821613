import { PrintFormMarginsProps } from './print-form-margins.props';
import { MainProvider } from './context';
import { MainComponent } from './components';


export const PrintFormMarginsSmart = ({
  opened,
  onClose,
}: PrintFormMarginsProps) => {
  return (
    <MainProvider
      opened={opened}
      onClose={onClose}
    >
      <MainComponent />
    </MainProvider>
  );
}
