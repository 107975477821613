import { motion } from 'framer-motion';

import { TransitionOpacityProps } from './transition-opacity.props';


export const TransitionOpacityBrick = ({
  children,
}: TransitionOpacityProps) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: .3 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
};
