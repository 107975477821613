import { configureStore, combineReducers } from '@reduxjs/toolkit';

import authReducer from './auth/auth.slice';
import appReducer from './app/app.slice';
import departmentReducer from './department/department.slice';
import roleReducer from './role/role.slice';
import userReducer from './user/user.slice';
import configurationReducer from './configuration/configuration.slice';
import documentReducer from './document/document.slice';
import printFormReducer from './print-form/print-form.slice';


const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  department: departmentReducer,
  role: roleReducer,
  user: userReducer,
  configuration: configurationReducer,
  document: documentReducer,
  printForm: printFormReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export * from './app';
export * from './auth';
export * from './configuration';
export * from './department';
export * from './document';
export * from './print-form';
export * from './role';
export * from './user';
