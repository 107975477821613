import {
  EditIcon,
  ButtonIconBrick,
} from '@app/components';

import { ButtonEditProps } from './button-edit.props';


export const ButtonEditBlock = ({
  onClick,
}: ButtonEditProps) => {
  return (
    <ButtonIconBrick onClick={onClick}>
      <EditIcon />
    </ButtonIconBrick>
  );
};
