import React from 'react';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HandbkDocumentApi } from '@app/api';
import { createDocumentAction } from '@app/stores';

import {
  DocumentItemType,
  HandbkDocumentType,
  ChangeFieldEventType,
} from '@app/types';

import {
  ButtonsBrick,
  BooleanCheckField,
  BooleanField,
  DateField,
  DatetimeField,
  DecimalField,
  DocumentMultiselectField,
  DocumentSelectField,
  FileField,
  GenderField,
  HandbkMultiselectField,
  HandbkRadioField,
  HandbkRemoteCheckField,
  HandbkRemoteMultiselectField,
  HandbkRemoteRadioField,
  HandbkRemoteSelectField,
  HandbkSelectField,
  LongTextField,
  MonthField,
  MonthYearField,
  MulticheckField,
  MultiselectField,
  NumberField,
  PassportField,
  PhoneField,
  SelectField,
  SnilsField,
  TextField,
  TimeField,
  TimeSecondsField,
  YearField,
  ButtonBackBlock,
  ButtonSaveBlock,
  CardDataComposition,
  DocumentConfigUpdateFeature,
  DocumentSearchFeature,
} from '@app/components';

import {
  checkVisibleField,
  errorTranslate,
  prepareFormValue,
} from '@app/helpers';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

import { DocumentCreateProps } from './document-create.props';
import styles from './document-create.module.scss';


export const DocumentCreateFeature = ({
  documentConfig,
  parentId,
}: DocumentCreateProps) => {
  const initialFormValue: { [key: string]: any } = {};
  const currentRole = useAppSelector((state) => state.auth.user!.role);

  documentConfig.fields.forEach((fieldConfig) => {
    if (fieldConfig.display.create) {
      if (
        fieldConfig.type === 'boolean' ||
        fieldConfig.type === 'date' ||
        fieldConfig.type === 'datetime' ||
        fieldConfig.type === 'file'
      ) {
        initialFormValue[fieldConfig.key] = null;
      } else if (fieldConfig.type === 'boolean-check') {
        initialFormValue[fieldConfig.key] = false;
      } else if (
        fieldConfig.type === 'multiselect' ||
        fieldConfig.type === 'handbk-multiselect' ||
        fieldConfig.type === 'handbk-check' ||
        fieldConfig.type === 'handbk-remote-multiselect' ||
        fieldConfig.type === 'handbk-remote-check' ||
        fieldConfig.type === 'document-multiselect'
      ) {
        initialFormValue[fieldConfig.key] = [];
      } else {
        initialFormValue[fieldConfig.key] = '';
      }
    }
  });
  
  const dispatch = useAppDispatch();
  const collection = useAppSelector((state) => state.document[documentConfig.key]);
  const handbkStore = useAppSelector((s) => s.configuration.handbk);
  const handbkRemoteStore = useAppSelector((s) => s.configuration.handbkRemote);
  const navigate = useNavigate();
  const location = useLocation();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [formDirty, setFormDirty] = useState(false);
  const [fieldKeyFlag, setFieldKeyFlag] = useState<[string, string] | null>(null);
  const [openedDocumentSearchModal, setOpenedDocumentSearchModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [handbkList, setHandbkList] = useState<HandbkDocumentType[]>([]);
  
  const changeFormValue = (e: ChangeFieldEventType | null): void => {
    setFormDirty(true);
    setFormValue((state) => {
      if (!e) return state;

      return {
        ...state,
        [e!.target.name]: e!.target.value,
      }
    });
  };

  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();

    const payload = {
      key: documentConfig.key,
      parentId,
      parentKey: documentConfig.parentKey,
      form: {
        ...prepareFormValue(formValue),
      },
    };

    const result = await dispatch(createDocumentAction(payload));

    if (result.type === '@@document/create/fulfilled') {
      const pathArr = location.pathname.split('/');
      pathArr[pathArr.length - 1] = documentConfig.key + '-' + (result.payload as DocumentItemType).id;

      navigate(pathArr.join('/'));
    }
  }

  const setError = (fieldName: string) => {
    return collection.errors && collection.errors[fieldName] && errorTranslate(collection.errors[fieldName][0]);
  }

  const openDocumentSearchModal = React.useCallback((name: string, type: string) => {
    setFieldKeyFlag([name, type]);
    setOpenedDocumentSearchModal(true);
  }, [
  ]);

  const closeDocumentSearchModal = React.useCallback((value?: number) => {
    const name = fieldKeyFlag && fieldKeyFlag[0];
    const type = fieldKeyFlag && fieldKeyFlag[1];
    const isValidValue = value && typeof value === 'number' && name && type;
    const setSelect = isValidValue && type === 'document-select';
    const setMultiselect = isValidValue && type === 'document-multiselect';

    if (setSelect) changeFormValue({ target: { name, value }});
    if (setMultiselect) changeFormValue({ target: { name, value: [...formValue[name], value] } });

    setOpenedDocumentSearchModal(false);
    setFieldKeyFlag(null);
  }, [
    fieldKeyFlag,
    formValue,
  ]);

  const getHandbkList = useCallback(async () => {
    const ids: number[] =
      documentConfig.fields
        .filter((fieldConfig) => (
          fieldConfig.documentConfigLinkId !== null
          && (
            fieldConfig.type === 'document-select'
            || fieldConfig.type === 'document-multiselect'
          )
        ))
        .map((fieldConfig) => (fieldConfig.documentConfigLinkId as number));

    if (ids.length === 0) {
      return;
    }

    setLoading(true);
    const result = await HandbkDocumentApi.list({
      documentConfigIdList: ids,
    });
    setLoading(false);

    setHandbkList(result.data.handbkList);
  }, [
    documentConfig.fields,
  ])

  useEffect(() => {
    getHandbkList();
  }, [
    getHandbkList,
  ])

  return (
    <CardDataComposition
      title={'Добавить ' + documentConfig.label.genitive.toLowerCase()}
      loading={loading}
      extra={
        <>
          <ButtonBackBlock buttonIcon />
          {currentRole.CONFIGURATOR_ACCESS && <DocumentConfigUpdateFeature documentConfig={documentConfig} />}
        </>
      }
    >
      <div className="row justify-content-lg-center">
        <div className="col col-lg-6">
          <div className={styles['entity-create']}>
            <form onSubmit={(e: SyntheticEvent) => onSubmit(e)}>
              <>
                {documentConfig.sortableFields.map((fieldId) => {
                  const fieldConfig = documentConfig.fields.find((fConfig) => fConfig.fieldConfigId === fieldId);
                  if (!fieldConfig) return null;

                  const invisibleField = !(fieldConfig.display.create && checkVisibleField(documentConfig, fieldConfig, formValue));
                
                  if (invisibleField) {
                    return null;
                  }
                  const disabled = (
                    fieldConfig.accessReadAndWriteRolesIds.length !== 0
                    && !fieldConfig.accessReadAndWriteRolesIds.includes(currentRole.id)
                  );

                  if (
                    (
                      (
                        fieldConfig.accessOnlyReadRolesIds.length !== 0
                        && !fieldConfig.accessOnlyReadRolesIds.includes(currentRole.id)
                      )
                      && fieldConfig.accessReadAndWriteRolesIds.length === 0
                    )
                    || (
                      fieldConfig.accessOnlyReadRolesIds.length !== 0
                      && !fieldConfig.accessOnlyReadRolesIds.includes(currentRole.id)
                      && fieldConfig.accessReadAndWriteRolesIds.length !== 0
                      && !fieldConfig.accessReadAndWriteRolesIds.includes(currentRole.id)
                    )
                  ) return null;

                  if (fieldConfig.type === 'boolean') {
                    return (
                      <BooleanField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'boolean-check') {
                    return (
                      <BooleanCheckField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'date') {
                    return (
                      <DateField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'datetime') {
                    return (
                      <DatetimeField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'time') {
                    return (
                      <TimeField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'time-seconds') {
                    return (
                      <TimeSecondsField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'multiselect') {
                    return (
                      <MultiselectField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'select') {
                    return (
                      <SelectField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'document-select') {
                    const handbk = handbkList.find((hb) => hb.documentConfigId === fieldConfig.documentConfigLinkId);

                    return (
                      <DocumentSelectField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        handbk={handbk}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                        openModal={openDocumentSearchModal}
                      />
                    );
                  } else if (fieldConfig.type === 'document-multiselect') {
                    const handbk = handbkList.find((hb) => hb.documentConfigId === fieldConfig.documentConfigLinkId);

                    return (
                      <DocumentMultiselectField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        handbk={handbk}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        openModal={openDocumentSearchModal}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'handbk-select') {
                    const handbk = handbkStore.find((h) => h.handbkId === fieldConfig.handbkId);

                    return (
                      <HandbkSelectField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        handbk={handbk}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'handbk-multiselect') {
                    const handbk = handbkStore.find((h) => h.handbkId === fieldConfig.handbkId);

                    return (
                      <HandbkMultiselectField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        handbk={handbk}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'handbk-radio') {
                    const handbk = handbkStore.find((h) => h.handbkId === fieldConfig.handbkId);

                    return (
                      <HandbkRadioField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        handbk={handbk}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'handbk-check') {
                    const handbk = handbkStore.find((h) => h.handbkId === fieldConfig.handbkId);

                    return (
                      <MulticheckField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        handbk={handbk}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'handbk-remote-select') {
                    const handbkRemote = handbkRemoteStore.find((h) => h.id === fieldConfig.handbkRemoteId);

                    return (
                      <HandbkRemoteSelectField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        handbkRemote={handbkRemote}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'handbk-remote-multiselect') {
                    const handbkRemote = handbkRemoteStore.find((h) => h.id === fieldConfig.handbkRemoteId);

                    return (
                      <HandbkRemoteMultiselectField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        handbkRemote={handbkRemote}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'handbk-remote-radio') {
                    const handbkRemote = handbkRemoteStore.find((h) => h.id === fieldConfig.handbkRemoteId);

                    return (
                      <HandbkRemoteRadioField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        handbkRemote={handbkRemote}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'handbk-remote-check') {
                    const handbkRemote = handbkRemoteStore.find((h) => h.id === fieldConfig.handbkRemoteId);

                    return (
                      <HandbkRemoteCheckField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        handbkRemote={handbkRemote}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'number' || fieldConfig.type === 'number-string') {
                    return (
                      <NumberField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'year') {
                    return (
                      <YearField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'month') {
                    return (
                      <MonthField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'month-year') {
                    return (
                      <MonthYearField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'decimal' || fieldConfig.type === 'decimal-string') {
                    return (
                      <DecimalField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'big-int') {
                    return (
                      <NumberField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'phone') {
                    return (
                      <PhoneField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'gender') {
                    return (
                      <GenderField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'long-text') {
                    return (
                      <LongTextField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'snils') {
                    return (
                      <SnilsField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'passport') {
                    return (
                      <PassportField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else if (fieldConfig.type === 'file') {
                    return (
                      <FileField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        documentConfig={documentConfig}
                        onChange={changeFormValue}
                        value={formValue[fieldConfig.key]}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  } else {
                    return (
                      <TextField
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        value={formValue[fieldConfig.key]}
                        onChange={changeFormValue}
                        error={setError(fieldConfig.key)}
                        disabled={disabled}
                      />
                    );
                  }
                })}

                <ButtonsBrick>
                  <ButtonSaveBlock
                    loading={collection.status === 'submitting'}
                    onClick={onSubmit}
                    disabled={!formDirty}
                  />
                  <ButtonBackBlock />
                </ButtonsBrick>
              </>
            </form>
          </div>
          <DocumentSearchFeature
            opened={openedDocumentSearchModal}
            closeModal={closeDocumentSearchModal}
            canBeUsed
          />
        </div>
      </div>
    </CardDataComposition>
  );
}
