import { createAsyncThunk } from '@reduxjs/toolkit';

import { callNotifyAction } from '@app/stores';

import {
  ConfigurationStateInterface,
} from '@app/types';

import {
  HandbkApi,
  HandbkCreateRequest,
  HandbkCreateResponse,
  HandbkDeleteRequest,
  HandbkDeleteResponse,
  HandbkUpdateRequest,
  HandbkUpdateResponse,
  HandbkSortableRequest,
  HandbkSortableResponse,
  HandbkVariantCreateRequest,
  HandbkVariantCreateResponse,
  HandbkVariantDeleteRequest,
  HandbkVariantDeleteResponse,
  HandbkVariantUpdateRequest,
  HandbkVariantUpdateResponse,
} from '@app/api';


export const handbkCreateAction = createAsyncThunk<
  HandbkCreateResponse,
  HandbkCreateRequest,
  { state: { configuration: ConfigurationStateInterface } }
>(
  '@@configuration/handbk-create',
  async (payload: HandbkCreateRequest, { rejectWithValue, dispatch }) => {
    const response = await HandbkApi.create(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { configuration } = getState();

      if (configuration.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const handbkUpdateAction = createAsyncThunk<
  HandbkUpdateResponse,
  HandbkUpdateRequest,
  { state: { configuration: ConfigurationStateInterface } }
>(
  '@@configuration/handbk-update',
  async (payload: HandbkUpdateRequest, { rejectWithValue, dispatch }) => {
    const response = await HandbkApi.update(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { configuration } = getState();

      if (configuration.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const handbkSortableAction = createAsyncThunk<
  HandbkSortableResponse,
  HandbkSortableRequest,
  { state: { configuration: ConfigurationStateInterface } }
>(
  '@@configuration/handbk-sortable',
  async (payload: HandbkSortableRequest, { rejectWithValue, dispatch }) => {
    const response = await HandbkApi.sortable(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { configuration } = getState();

      if (configuration.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const handbkDeleteAction = createAsyncThunk<
  HandbkDeleteResponse,
  HandbkDeleteRequest,
  { state: { configuration: ConfigurationStateInterface } }
>(
  '@@configuration/handbk-delete',
  async (payload: HandbkDeleteRequest, { rejectWithValue, dispatch }) => {
    dispatch(callNotifyAction({ type: 'info', message: 'loading' }));
    const response = await HandbkApi.delete(payload);
    
    if (!response.success) {
      if (response.errors === 'Handbk not access delete')
        dispatch(callNotifyAction({ type: 'error', message: 'Невозможно удалить этот справочник.' }));
      else
        dispatch(callNotifyAction({ type: 'error', message: 'Ошибка удаления данных!' }));
      
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено удалены!' }));
    return response.data;
  },
);

export const handbkVariantCreateAction = createAsyncThunk<
  HandbkVariantCreateResponse,
  HandbkVariantCreateRequest,
  { state: { configuration: ConfigurationStateInterface } }
>(
  '@@configuration/handbk-variant-create',
  async (payload: HandbkVariantCreateRequest, { rejectWithValue, dispatch }) => {
    const response = await HandbkApi.variantCreate(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { configuration } = getState();

      if (configuration.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const handbkVariantUpdateAction = createAsyncThunk<
  HandbkVariantUpdateResponse,
  HandbkVariantUpdateRequest,
  { state: { configuration: ConfigurationStateInterface } }
>(
  '@@configuration/handbk-variant-update',
  async (payload: HandbkVariantUpdateRequest, { rejectWithValue, dispatch }) => {
    const response = await HandbkApi.variantUpdate(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { configuration } = getState();

      if (configuration.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const handbkVariantDeleteAction = createAsyncThunk<
  HandbkVariantDeleteResponse,
  HandbkVariantDeleteRequest,
  { state: { configuration: ConfigurationStateInterface } }
>(
  '@@configuration/handbk-variant-delete',
  async (payload: HandbkVariantDeleteRequest, { rejectWithValue, dispatch }) => {
    dispatch(callNotifyAction({ type: 'info', message: 'loading' }));
    const response = await HandbkApi.variantDelete(payload);
    
    if (!response.success) {
      if (response.errors === 'HandbkVariant not access delete')
        dispatch(callNotifyAction({ type: 'error', message: 'Невозможно удалить этот вариант ответа.' }));
      else
        dispatch(callNotifyAction({ type: 'error', message: 'Ошибка удаления данных!' }));
      
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено удалены!' }));
    return response.data;
  },
);


