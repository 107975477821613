import React from 'react';

import { TypographyBrick } from '@app/components';

import { WidgetsListProps } from './widgets-list.props';
import styles from './widgets-list.module.scss';

export const WidgetsListBlock = ({
  title,
  children,
}: WidgetsListProps) => {
  const childrenLenght =
    React.Children
      .toArray(children)
      .filter((item) => item !== null)
      .length;

  return (
    <div className={styles['widgets-list']}>
      {childrenLenght !== 0 && (
        <TypographyBrick variant="h5" className={styles['widgets-list__header']}>{title}</TypographyBrick>
      )}
      <div className={styles['widgets-list__items']}>
        {React.Children.map(children, (element) => {
          if (element === null) return null;
          return (
            <div className={styles['widgets-list__item']}>
              {element}
            </div>
          );
        })}
      </div>
    </div>
  );
};
