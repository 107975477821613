import jspdf from 'jspdf';

import {
  PrintFormType,
  DocumentStateInterface,
  ConfigurationStateInterface,
  FieldConfigType,
  HandbkDocumentType,
  DocumentItemType,
  PrintQueryType,
} from '@app/types';

import { PrintQueryVarType } from '@app/api';

import { buildColumnValue, buildPrintQueriesHelper, findFieldConfigHelper, keysParseHelper } from '@app/helpers';

import { robotoBold } from './fonts/roboto-bold';
import { robotoItalic } from './fonts/roboto-italic';
import { robotoRegular } from './fonts/roboto-regular';


export class PrintFormService {
  private static transpileTemplate(
    printForm: PrintFormType,
    printData: PrintQueryVarType[],
    configurationState: ConfigurationStateInterface,
    handbkList: HandbkDocumentType[],
  ) {
    let { template } = printForm;
    printData.forEach((query) => {
      if (query.type === 'func_qr-link'
      ) {
        const buffer = query.value || '';
        const data = printForm.type === 'editor' ? `<img src="${buffer}" />` : buffer;
        template = template.replaceAll(`{{~${query.key}~}}`, data);
      }

      if (
        query.type === 'func_document-image'
        || query.type === 'func_print-image'
      ) {
        const buffer = `data:image;base64, ${query.value || ''}`;
        const data = printForm.type === 'editor' ? `<img src="${buffer}" />` : buffer;
        template = template.replaceAll(`{{~${query.key}~}}`, data);
      }

      if (query.type === 'func_var-table') {
        const documentConfig = configurationState.documents.find((documentConfig) => documentConfig.key === query.documentConfigKey);

        if (!documentConfig) {
          template = template.replaceAll(`{{~${query.key}~}}`, '---');
        }

        let headerKeysStr = query.key.split('(')[1];
        if (headerKeysStr[headerKeysStr.length - 1] === ')') {
          headerKeysStr = headerKeysStr.slice(0, headerKeysStr.length - 1)
        }

        const headerKeys = headerKeysStr.split(',');
        const headerKeysTranslate = headerKeys.map((headerKey) => {
          if (!documentConfig) return null;
          const fieldConfig = documentConfig.fields.find((fC) => fC.key === headerKey);
          if (!fieldConfig) return null;

          return fieldConfig.label;
        }).filter((label) => !!label);

        const tableHeader = headerKeysTranslate.map((headerKey) => `<th>${headerKey}</th>`).join('');

        const value = (query.value as DocumentItemType[]);

        const tableBody = 
          value
            .map((doc) => {
              const row =
                headerKeys
                  .map((key) => {
                    const emptyRow = '<td></td>';
                    if (doc[key] === undefined || doc[key] === null || !documentConfig) return emptyRow;

                    const fieldConfig = documentConfig.fields.find((fC) => fC.key === key);
                    if (!fieldConfig) return emptyRow;

                    const preparedValue = buildColumnValue(
                      fieldConfig,
                      doc[key],
                      configurationState,
                      handbkList,
                    );

                    return `<td>${preparedValue}</td>`
                  })
                  .join('');

              return `
                <tr>
                  ${row}
                </tr>
              `;
            })
            .join('');

        const table = `
          <table class="var-table">
            <thead>
              <tr>
                ${tableHeader}
              </tr>
            </thead>
            <tbody>
              ${value.length ? tableBody : `
                <tr>
                  <td colspan="${headerKeys.length}">Нет данных для отображения</td>
                </tr>
              `}
            </tbody>
          </table>
        `;

        template = template.replaceAll(`{{~${query.key}~}}`, table);
      }

      if (query.type === 'var') {
        const fieldConfig = findFieldConfigHelper(
          configurationState,
          query.documentConfigKey,
          query.fieldConfigKey,
        );
  
        const value = query.value === null ? '---' : buildColumnValue(
          fieldConfig ? fieldConfig : ({ type: 'number' } as FieldConfigType),
          query.value,
          configurationState,
          handbkList,
        );
  
        template = template.replaceAll(`{{~${query.key}~}}`, value);
      }
    })

    return template;
  }

  public static parseTemplate(
    html: string,
    documentState: DocumentStateInterface,
  ): PrintQueryType[] {
    const keys = keysParseHelper(html, ['{{~', '~}}']);
    return buildPrintQueriesHelper(keys, documentState);
  }

  public static run(
    printForm: PrintFormType,
    margin: number[],
    printData: PrintQueryVarType[],
    configurationState: ConfigurationStateInterface,
    handbList: HandbkDocumentType[]
  ) {
    const transpiledTemplate = this.transpileTemplate(
      printForm,
      printData,
      configurationState,
      handbList,
    );

    const doc = new jspdf({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true
    });

    doc.addFileToVFS('Roboto-Regular-normal.ttf', robotoRegular);
    doc.addFont('Roboto-Regular-normal.ttf', 'Roboto', 'normal');
    doc.addFileToVFS('Roboto-Bold-normal.ttf', robotoBold);
    doc.addFont('Roboto-Bold-normal.ttf', 'Roboto', 'bold');
    doc.addFileToVFS('Roboto-Bold-normal.ttf', robotoItalic);
    doc.addFont('Roboto-Bold-normal.ttf', 'Roboto', 'italic');

    doc.html(`<div class="print-form">${transpiledTemplate}</div>`, {
      callback: function(doc) {
          // Save the PDF
          // doc.save('document-html.pdf');
          doc.output('dataurlnewwindow', { filename: `${printForm.name}.pdf` });
      },
      margin,
      autoPaging: 'text',
      x: 0,
      y: 0,
      width: 190, //target width in the PDF document
      windowWidth: 675 //window width in CSS pixels
  });
  }

  public static build(
    printForm: PrintFormType,
    printData: PrintQueryVarType[],
    configurationState: ConfigurationStateInterface,
    handbkList: HandbkDocumentType[],
  ) {
    this.run(
      printForm,
      [
        printForm.marginTop,
        printForm.marginRight,
        printForm.marginBottom,
        printForm.marginLeft,
      ],
      printData,
      configurationState,
      handbkList,
    );
  }
};
