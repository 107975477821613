import {
  HttpService,
} from '@app/services';

import {
  BaseResponseInterface,
} from '@app/types';

import {
  FormFileGetOneMyByIdRequest,
  FormFileGetOneByDocumentRequest,
  FormFileUploadResponse,
  FormFileGetOneResponse,
} from './types';


export class FormFileApi {
  public static async upload(
    file: File,
    fieldConfigId: number,
  ): Promise<BaseResponseInterface<FormFileUploadResponse>> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('fieldConfigId', String(fieldConfigId));

    return await HttpService.sendRequest('POST-FILE', '/form-file/upload', formData);
  }

  public static async getOneMyById(
    payload: FormFileGetOneMyByIdRequest,
  ): Promise<BaseResponseInterface<FormFileGetOneResponse>> {
    return await HttpService.sendRequest('GET', '/form-file/one-my-by-id', payload);
  }

  public static async getOneMyByDocument(
    payload: FormFileGetOneByDocumentRequest,
  ): Promise<BaseResponseInterface<FormFileGetOneResponse>> {
    return await HttpService.sendRequest('GET', '/form-file/one-by-document', payload);
  }
};
