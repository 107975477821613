import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getUserListAction } from '@app/stores';

import {
  LinkBrick,
  ButtonCreateBlock,
  CardDataComposition,
  TableBodyBrick,
  TableBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from '@app/components';

import {
  dateTranslate,
  booleanTranslate,
} from '@app/helpers';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';


const UserListFeature = () => {
  const dispatch = useAppDispatch();
  const userIsLoading = useAppSelector((state) => state.user.status === 'loading');
  const userList = useAppSelector((state) => state.user.list);
  const hasAccessCreate = useAppSelector((state) => state.auth.user?.role.USER_CREATE !== 'NEVER');
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserListAction());
  }, [dispatch])

  return (
    <CardDataComposition
      title="Пользователи"
      extra={
        <>
          {hasAccessCreate && <ButtonCreateBlock buttonIcon onClick={() => navigate('/user-list/user-create')} />}
        </>
      }
      loading={userIsLoading}
      noData={userList.length === 0}
    >
      <TableContainerBrick>
        <TableBrick aria-label="users">
          <TableHeadBrick>
            <TableRowBrick>
              <TableCellBrick>ID</TableCellBrick>
              <TableCellBrick>Пользователь</TableCellBrick>
              <TableCellBrick>Электронная почта</TableCellBrick>
              <TableCellBrick>Роль</TableCellBrick>
              <TableCellBrick>Подразделение</TableCellBrick>
              <TableCellBrick>Заблокирован</TableCellBrick>
              <TableCellBrick>Дата создания</TableCellBrick>
              <TableCellBrick>Дата обновления</TableCellBrick>
            </TableRowBrick>
          </TableHeadBrick>
          <TableBodyBrick>
            {userList.map((item) => (
              <TableRowBrick
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={item.id}
              >
                <TableCellBrick component="th" scope="row">
                  {item.id}
                </TableCellBrick>
                <TableCellBrick>
                  <LinkBrick
                    to={'/user-list/' + item.id}
                    label={`${item.firstName} ${item.secondName} ${item.lastName}`}
                  />
                </TableCellBrick>
                <TableCellBrick>{item.email}</TableCellBrick>
                <TableCellBrick>{item.role.name}</TableCellBrick>
                <TableCellBrick>{item.department.name}</TableCellBrick>
                <TableCellBrick>{booleanTranslate(item.blocked)}</TableCellBrick>
                <TableCellBrick>{dateTranslate(item.createdAt)}</TableCellBrick>
                <TableCellBrick>{dateTranslate(item.updatedAt)}</TableCellBrick>
              </TableRowBrick>
            ))}
          </TableBodyBrick>
        </TableBrick>
      </TableContainerBrick>
    </CardDataComposition>
  );
};

export { UserListFeature };
