import { FormControl, FormLabel, RadioGroup } from '@mui/material';

import { RadioGroupProps } from './radio-group.props';


export const RadioGroupBrick = ({
  children,
  name,
  value,
  label,
  onChange,
}: RadioGroupProps) => {

  return (
    <FormControl>
      <FormLabel id={name + '-label'}>{label}</FormLabel>
      <RadioGroup
        name={name}
        value={value}
        onChange={onChange}
      >
        {children}
      </RadioGroup>
    </FormControl>
  );
}
