import {
  PhotoIcon,
  ButtonIconSquareBrick,
} from '@app/components';

import { useModalsApi } from '../../../../context';


export const ButtonImageComponent = () => {
  const { imageLibraryDialogOpen } = useModalsApi();

  return (
    <ButtonIconSquareBrick
      icon={<PhotoIcon />}
      onClick={imageLibraryDialogOpen}
    />
  );
};
