import { ProgressCircularBrick } from '@app/components';

import styles from './progress.module.scss';
import { useMainApi } from '../../context';


export const ProgressComponent = () => {
  const { printFormListIsLoading } = useMainApi();
  
  if (!printFormListIsLoading) return null;
  
  return (
    <div className={styles['progress']}>
      <ProgressCircularBrick />
    </div>
  );
};
