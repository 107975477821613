import React from 'react';

import {
  handbkDeleteAction,
  modalHanbkDetailCloseAction,
 } from '@app/stores';

import {
  HandbkVariantType,
  HandbkConfigType,
} from '@app/types';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

export interface MainContextInterface {
  isEdited: boolean;
  handbk: HandbkConfigType | null;
  handbkId: number | null;
  configurationIsSubmitting: boolean;
  selectedVariant: HandbkVariantType | null;
  createFormOpened: boolean;
  isEditedSet: (value: boolean) => void;
  handbkDelete: () => Promise<null | undefined>;
  closeDialog: () => void;
  createFormOpenedSet: React.Dispatch<React.SetStateAction<boolean>>;
  selectedVariantSet: React.Dispatch<React.SetStateAction<HandbkVariantType | null>>;
};

export const useMainHook = (
): MainContextInterface => {
  const dispatch = useAppDispatch();
  const handbkId = useAppSelector((s) => s.configuration.handbkIdOpened);
  const handbk = useAppSelector((s) => {
    const item = s.configuration.handbk.find((h) => h.handbkId === handbkId);
    return item ? item : null;
  });
  const configurationIsSubmitting = useAppSelector((s) => s.configuration.status === 'submitting');
  const [isEdited, isEditedSet] = React.useState(false);
  const [selectedVariant, selectedVariantSet] = React.useState<HandbkVariantType | null>(null);
  const [createFormOpened, createFormOpenedSet] = React.useState(false);

  const closeDialog = React.useCallback(() => dispatch(modalHanbkDetailCloseAction()), [
    dispatch,
  ]);

  const handbkDelete = React.useCallback(async () => {
    if (!handbk) return null;
    
    const response = await dispatch(handbkDeleteAction({ id: handbk.handbkId }));

    if (response.type === '@@configuration/handbk-delete/fulfilled') {
      closeDialog();
    }
  }, [
    handbk,
    dispatch,
    closeDialog,
  ]);

  return React.useMemo(() => ({
    isEdited,
    handbk,
    handbkId,
    configurationIsSubmitting,
    selectedVariant,
    createFormOpened,
    isEditedSet,
    handbkDelete,
    closeDialog,
    selectedVariantSet,
    createFormOpenedSet,
  }), [
    isEdited,
    handbk,
    handbkId,
    configurationIsSubmitting,
    selectedVariant,
    createFormOpened,
    isEditedSet,
    handbkDelete,
    closeDialog,
    selectedVariantSet,
    createFormOpenedSet,
  ]);
};
