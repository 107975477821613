import {
  ButtonBackBlock,
  ButtonCancelBlock,
  ButtonEditBlock,
  CardDataComposition
} from '@app/components';

import { useMainApi } from '../../context';


export const GeneralComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    id,
    departmentDetailIsLoading,
    hasUpdateAccess,
    isEditing,
    isEditingSet,
  } = useMainApi();
  
  return (
    <CardDataComposition
      title={'Подразделение №' + id}
      extra={
        <>
          {isEditing
            ? (
              <ButtonCancelBlock buttonIcon onClick={() => isEditingSet(false)} />
            )
            : hasUpdateAccess && (
              <ButtonEditBlock onClick={() => isEditingSet(true)} />
            )
          }
          {!isEditing && <ButtonBackBlock buttonIcon />}
        </>
      }
      loading={departmentDetailIsLoading}
    >
      {children}
    </CardDataComposition>
  );
};
