import {
  TableComponent,
  UpdateComponent,
  GeneralComponent
} from '..';
import { GeneralProvider } from '../../context/general';


export const MainComponent = () => {
  return (
    <GeneralProvider>
      <GeneralComponent>
        <UpdateComponent />
        <TableComponent />
      </GeneralComponent>
    </GeneralProvider>
  );
};
