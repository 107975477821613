import {
  ButtonBrick,
  MoonIcon,
  SunIcon,
  ButtonGroupBrick,
} from '@app/components';

import { ThemeToggleProps } from './theme-toggle.props';
import styles from './theme-toggle.module.scss';

export const ThemeToggleBlock = ({
  themeMode,
  onDark,
  onLight,
}: ThemeToggleProps) => {
  return (
    <div className={styles['theme-toggle']}>
      <div className={styles['theme-toggle__label']}>Тема оформления</div>
      <ButtonGroupBrick variant="outlined" aria-label="outlined button group">
        <ButtonBrick
          variant={themeMode === 'light' ? 'contained' : 'outlined'}
          startIcon={<SunIcon />}
          onClick={onLight}
        >
          Светлая
        </ButtonBrick>
        <ButtonBrick
          variant={themeMode === 'dark' ? 'contained' : 'outlined'}
          startIcon={<MoonIcon />}
          onClick={onDark}
        >Тёмная</ButtonBrick>
      </ButtonGroupBrick>
    </div>
  );
};
