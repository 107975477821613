import { PrintFormVarsSmart } from '@app/components';

import { useMainApi, useModalsApi } from '../../context';
import { FieldConfigType } from '@app/types';


export const VarsComponent = () => {
  const {
    varsDialogOpened,
    varsDialogClose,
  } = useModalsApi();

  const { selectVar } = useMainApi();

  const onSelect = (key: string, fieldConfig?: FieldConfigType) => {
    selectVar(key, fieldConfig);
    varsDialogClose();
  };

  return (
    <PrintFormVarsSmart
      opened={varsDialogOpened}
      onClose={varsDialogClose}
      onSelect={onSelect}
    />
  );
}
