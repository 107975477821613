import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { currentUserAction } from '@app/stores';

import {
  PersistanceService,
} from '@app/services';

import {
  AppStateInterface,
  BreadcrumbType,
  NotifyType,
  ThemeModeType,
} from '@app/types';


const initialState: AppStateInterface = {
  loading: true,
  notify: {
    type: 'success',
    message: null,
  },
  breadcrumbs: [],
  themeMode: PersistanceService.getThemeMode(),
  openedDocumentSearchModal: false,
};

const appSlice = createSlice({
  name: '@@app',
  initialState,
  reducers: {
    callNotifyAction: (state, action: PayloadAction<NotifyType>) => ({
      ...state,
      notify: {
        type: action.payload.type,
        message: action.payload.message,
      },
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
    setBreadcrumbs: (state, action: PayloadAction<BreadcrumbType[]>) => ({
      ...state,
      breadcrumbs: action.payload,
    }),
    setThemeMode: (state, action: PayloadAction<ThemeModeType>) => {
      PersistanceService.setThemeMode(action.payload);
      return {
        ...state,
        themeMode: action.payload,
      };
    },
    openDocumentSearchModalAction: (state) => {
      return {
        ...state,
        openedDocumentSearchModal: true,
      };
    },
    closeDocumentSearchModalAction: (state) => {
      return {
        ...state,
        openedDocumentSearchModal: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(currentUserAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(currentUserAction.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(currentUserAction.rejected, (state) => {
        state.loading = false;
      });
  }
});

export default appSlice.reducer;

export const {
  callNotifyAction,
  setLoading,
  setBreadcrumbs,
  setThemeMode,
  openDocumentSearchModalAction,
  closeDocumentSearchModalAction,
} = appSlice.actions;
