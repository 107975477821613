import {
  CloseIcon,
  FormFieldBrick,
  CardBrick,
  CardContentBrick,
  TypographyBrick,
  InputBrick,
  ButtonIconBrick,
  ButtonBrick,
} from '@app/components';

import { FieldVariantsProps } from './field-variants.props';
import styles from './field-variants.module.scss';


export const FieldVariantsBlock = ({
  name,
  label,
  value,
  onChange,
  error,
}: FieldVariantsProps) => {
  const createVariant = () => {
    const newValue = [...value, ''];
    onChange({
      target: {
        name,
        value: newValue,
      }
    });
  }

  const onChangeValue = (itemValue: string, index: number) => {
    const newValue = [...value];
    newValue[index] = itemValue

    onChange({
      target: {
        name,
        value: newValue,
      }
    });
  }

  const onRemove = (index: number) => {
    const newValue = [...value];
    newValue.splice(index, 1);

    onChange({
      target: {
        name,
        value: newValue,
      }
    });
  }

  const disabledAddButton = !value[value.length - 1] && value[value.length - 1] === '';

  return (
    <div className={styles['field-variants']}>
      <FormFieldBrick error={error}>
        <CardBrick variant='outlined'>
          <CardContentBrick>
            <TypographyBrick
              sx={{ fontSize: 14 }}
              color="text.secondary"
              className={styles['field-variants__header']}
            >
              {label}
            </TypographyBrick>
            {value.map((item, index) => (
              <div
                key={index}
                className={styles['field-variants__item']}
              >
                <InputBrick
                  hiddenLabel
                  id="filled-hidden-label-small"
                  value={item}
                  placeholder="Вариант ответа"
                  size="small"
                  onChange={(e) => onChangeValue(e.target.value, index)}
                />
                <ButtonIconBrick
                  sx={{ p: '10px' }}
                  aria-label="close"
                  size="small"
                  onClick={() => onRemove(index)}
                >
                  <CloseIcon />
                </ButtonIconBrick>
              </div>
            ))}
            <ButtonBrick
              variant="outlined"
              size="small"
              fullWidth
              onClick={createVariant}
              disabled={disabledAddButton}
            >
              Добавить
            </ButtonBrick>
          </CardContentBrick>
        </CardBrick>
      </FormFieldBrick>
    </div>
  );
}
