import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  callNotifyAction,
} from '@app/stores';

import {
  RoleStateInterface,
  RoleType,
} from '@app/types';

import { RoleApi } from '@app/api';


export const getRoleDictionaryAction = createAsyncThunk<
  RoleType[],
  undefined,
  { state: { role: RoleStateInterface } }
>(
  '@@role/get-dictionary',
  async (_, { rejectWithValue, dispatch }) => {
    const response = await RoleApi.getDictionary();
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка получения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data.roleList;
  },
  {
    condition: (_, { getState }) => {
      const { role } = getState();

      if (role.status === 'loading') {
        return false;
      }

      return true;
    }
  },
);
