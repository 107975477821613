import { FieldMultiselectBlock } from '@app/components';
import { MultiselectProps } from './multiselect.props';


export const MultiselectField = ({
  fieldConfig,
  value,
  onChange,
  error,
  disabled,
}: MultiselectProps) => {
  return (
    <FieldMultiselectBlock
      key={fieldConfig.key}
      name={fieldConfig.key}
      label={fieldConfig.label}
      value={value}
      onChange={onChange}
      error={error}
      items={fieldConfig.variants!.map((item) => ({ value: item, label: item }))}
      counted={fieldConfig.counted}
      required={fieldConfig.required}
      prompt={fieldConfig.prompt}
      disabled={disabled}
    />
  );
};
