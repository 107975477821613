import { useNavigate } from 'react-router-dom';

import {
  EditIcon,
  ButtonBrick,
  ButtonIconBrick,
} from '@app/components';

import { ButtonFillProps } from './button-fill.props';


export const ButtonFillBlock = ({
  onClick,
  buttonIcon,
}: ButtonFillProps) => {
  const navigate = useNavigate();

  const handlerClick = () => {
    if (onClick) onClick();
    else navigate(-1);
  };

  if (buttonIcon) return (
    <ButtonIconBrick
      onClick={handlerClick}
    >
      <EditIcon />
    </ButtonIconBrick>
  );

  return (
    <ButtonBrick
      color="primary"
      variant="outlined"
      startIcon={<EditIcon />}
      onClick={onClick}
      size="large"
    >
      Заполнить
    </ButtonBrick>
  );
};
