import { createSlice } from '@reduxjs/toolkit';

import {
  RoleStateInterface,
} from '@app/types';

import {
  getRoleDictionaryAction,
} from './role.actions';


const initialState: RoleStateInterface = {
  list: [],
  dictionary: [],
  current: null,
  status: 'idle',
  errors: null,
}

const roleSlice = createSlice({
  name: '@@role',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoleDictionaryAction.pending, (state) => {
        state.status = 'loading';
        state.errors = null;
        state.list = [];
      })
      .addCase(getRoleDictionaryAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.dictionary = action.payload;
      })
      .addCase(getRoleDictionaryAction.rejected, (state) => {
        state.status = 'idle';
      })
  },
});

export default roleSlice.reducer;
