import { FieldMultiselectBlock } from '@app/components';
import { HandbkRemoteMultiselectProps } from './handbk-remote-multiselect.props';


export const HandbkRemoteMultiselectField = ({
  fieldConfig,
  handbkRemote,
  value,
  onChange,
  error,
  disabled,
}: HandbkRemoteMultiselectProps) => {
  if (!handbkRemote) return (<p key={fieldConfig.key} >Ошибка словаря</p>);
  const variants = handbkRemote.variants.map((v) => ({ value: v.id, label: v.label }));
  const listIsLong = variants && variants.length > 30;
  
  return (
    <FieldMultiselectBlock
      key={fieldConfig.key}
      name={fieldConfig.key}
      label={fieldConfig.label}
      value={value}
      onChange={onChange}
      error={error}
      items={variants}
      counted={fieldConfig.counted}
      required={fieldConfig.required}
      prompt={fieldConfig.prompt}
      enableSearch={listIsLong}
      enablePagination={listIsLong}
      disabled={disabled}
    />
  );
};
