import React from 'react';

import { callNotifyAction } from '@app/stores';

import {
  RoleType,
  ChangeFieldEventType,
  BackendErrorsType,
} from '@app/types';

import { errorTranslate } from '@app/helpers';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

import { RoleApi } from '@app/api';
import { useNavigate } from 'react-router-dom';

type RoleCreateType = Omit<RoleType, 'id' | 'createdAt' | 'updatedAt'> & { name: string };

export interface MainContextInterface {
  formValue: RoleCreateType;
  formDirty: boolean;
  formIsSubmitting: boolean;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e?: React.SyntheticEvent) => Promise<void>;
  setError: (fieldName: string) => string | null;
};

const initialFormValue: RoleCreateType = {
  name: '',
  CONFIGURATOR_ACCESS: false,
  PRINT_FORM_ACCESS: false,
  HANDBK_ACCESS: false,
  DEPARTMENT_CREATE: 'NEVER',
  DEPARTMENT_READ: 'NEVER',
  DEPARTMENT_UPDATE: 'NEVER',
  DEPARTMENT_DELETE: 'NEVER',
  ROLE_CREATE: 'NEVER',
  ROLE_READ: 'NEVER',
  ROLE_UPDATE: 'NEVER',
  ROLE_DELETE: 'NEVER',
  USER_CREATE: 'NEVER',
  USER_READ: 'NEVER',
  USER_UPDATE: 'NEVER',
  USER_DELETE: 'NEVER',
};

export const useMainHook = (
): MainContextInterface => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const documentConfigs = useAppSelector((state) => state.configuration.documents);
  const [ formIsSubmitting, formIsSubmittingSet ] = React.useState(false);
  const [ errors, errorsSet ] = React.useState<BackendErrorsType | null>(null);

  const [formValue, formValueSet] = React.useState<RoleCreateType>(initialFormValue);
  const [formDirty, formDirtySet] = React.useState(false);

  documentConfigs.forEach((documentConfig) => {
    initialFormValue[documentConfig.key + '_CREATE'] = 'NEVER';
    initialFormValue[documentConfig.key + '_READ'] = 'NEVER';
    initialFormValue[documentConfig.key + '_UPDATE'] = 'NEVER';
    initialFormValue[documentConfig.key + '_DELETE'] = 'NEVER';
  });

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const onSubmit = React.useCallback(async (e?: React.SyntheticEvent) => {
    formIsSubmittingSet(true);
    if (e) e.preventDefault();
    if (formValue === null) return;

    const response = await RoleApi.create(formValue);

    if (!response.success) {
      dispatch(callNotifyAction({
        type: 'error',
        message: 'Не удалось создать роль',
      }));

      formIsSubmittingSet(false);
      errorsSet(response.errors as BackendErrorsType);
      return;
    }

    dispatch(callNotifyAction({
      type: 'success',
      message: 'Роль успешно создана',
    }));

    navigate('/role-list/' + response.data.role.id);

    formIsSubmittingSet(false);
  }, [
    formValue,
    dispatch,
    navigate,
  ]);

  const setError = React.useCallback((fieldName: string) => {
    return errors && errors[fieldName] && errorTranslate(errors[fieldName][0]);
  }, [
    errors,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    formIsSubmitting,
    changeFormValue,
    onSubmit,
    setError,
  }), [
    formValue,
    formDirty,
    formIsSubmitting,
    changeFormValue,
    onSubmit,
    setError,
  ]);
};
