import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HandbkDocumentApi } from '@app/api';

import {
  getListDocumentAction,
  getUserDictionaryAction,
} from '@app/stores';

import { HandbkDocumentType } from '@app/types';

import {
  TransitionOpacityBrick,
  ButtonCreateBlock,
  ButtonBackBlock,
  ButtonSearchBlock,
  PaginationBlock,
  DocumentConfigUpdateFeature,
  CardDataComposition,
} from '@app/components';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

import { DocumentTableComponent } from './compontents/document-table';
import { DocumentSearchComponent } from './compontents/document-search';
import { DocumentListProps } from './documents-list.props';


const DocumentListFeature = ({ documentConfig, parentId, dragHandle }: DocumentListProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isSearch, setIsSearch] = useState(false);
  const role = useAppSelector((state) => state.auth.user!.role);
  const documentCollection = useAppSelector((state) => state.document[documentConfig.key]);
  const currentRole = useAppSelector((state) => state.auth.user!.role);
  const [loading, setLoading] = useState(false);
  const [handbkList, setHandbkList] = useState<HandbkDocumentType[]>([]);

  useEffect(() => {
    dispatch(getListDocumentAction({
      query: {
        key: documentConfig.key,
        // parentKey: entity.parent,
        parentId,
        page: 1,
      },
      searchQuery: {},
    }));
  }, [dispatch, documentConfig.key, parentId]);

  const getListByPage = (page: number) => {
    dispatch(getListDocumentAction({
      query: {
        key: documentConfig.key,
        // parentKey: entity.parent,
        // parentId,
        page,
      },
      searchQuery: documentCollection.searchQuery,
    }));
  }

  const getListByQuery = (query: { [key: string]: any }) => {
    setIsSearch(false);

    dispatch(getListDocumentAction({
      query: {
        key: documentConfig.key,
        page: 1,
      },
      searchQuery: query,
    }));
  }

  const getHandbkList = useCallback(async () => {
    const ids: number[] =
      documentConfig.fields
        .filter((fieldConfig) => (
          fieldConfig.documentConfigLinkId !== null
          && (
            fieldConfig.type === 'document-select'
            || fieldConfig.type === 'document-multiselect'
          )
        ))
        .map((fieldConfig) => (fieldConfig.documentConfigLinkId as number));

    if (ids.length === 0) {
      return;
    }

    setLoading(true);
    const result = await HandbkDocumentApi.list({
      documentConfigIdList: ids,
    });
    setLoading(false);

    setHandbkList(result.data.handbkList);
  }, [
    documentConfig.fields,
  ])

  useEffect(() => {
    getHandbkList();
  }, [
    getHandbkList,
  ])

  useEffect(() => {
    dispatch(getUserDictionaryAction());
  }, [dispatch])

  return (
    <CardDataComposition
      title={isSearch ? `Поиск ${documentConfig.label.genitive.toLowerCase()}` : documentConfig.label.plural}
      extra={
        <>
          {(!isSearch && role[`${documentConfig.key}_CREATE`] !== 'NEVER') && <ButtonCreateBlock buttonIcon onClick={() => navigate(`${documentConfig.key}-create`)} />}
          {isSearch && <ButtonBackBlock buttonIcon onClick={() => setIsSearch(false)} />}
          {!isSearch && (
            <ButtonSearchBlock
              contained={!!Object.keys(documentCollection.searchQuery).length}
              count={Object.keys(documentCollection.searchQuery).length}
              onClick={() => setIsSearch((prev) => !prev)}
              buttonIcon
            />
          )}
          {currentRole.CONFIGURATOR_ACCESS && <DocumentConfigUpdateFeature documentConfig={documentConfig} />}
        </>
      }
      loading={documentCollection.status === 'loading' || loading}
      noData={(!documentCollection.list || (Array.isArray(documentCollection.list) && !documentCollection.list.length)) && !isSearch}
      actions={!!documentCollection.pages && documentCollection.pages > 1 && !isSearch && (
        <PaginationBlock current={documentCollection.page!} count={documentCollection.pages} setCurrent={(page) => getListByPage(page)}/>
      )}
      dragHandle={dragHandle}
    >
      {isSearch && (
        <TransitionOpacityBrick>
          <DocumentSearchComponent documentConfig={documentConfig} onSearchHandler={getListByQuery} searchQuery={documentCollection.searchQuery} />
        </TransitionOpacityBrick>
      )}
      {!isSearch && (
        <TransitionOpacityBrick>
          <DocumentTableComponent
            documentConfig={documentConfig}
            collection={documentCollection}
            handbkList={handbkList}
          />
        </TransitionOpacityBrick>
      )}
    </CardDataComposition>
  );
}

export { DocumentListFeature };
