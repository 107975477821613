import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { documentTypeTranslate } from '@app/helpers';

import { DocumentConfigDetailTableProps } from './document-config-detail-table.props';


const DocumentConfigDetailTableComponent = ({
  documentConfig
}: DocumentConfigDetailTableProps) => {
  return (
    <TableContainer>
      <Table aria-label="departments">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Название</TableCell>
            <TableCell>Ключ</TableCell>
            <TableCell>Тип</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        <TableRow
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell component="th" scope="row">{documentConfig.documentConfigId}</TableCell>
          <TableCell>{documentConfig.label.singular}</TableCell>
          <TableCell>{documentConfig.key}</TableCell>
          <TableCell>{documentTypeTranslate(documentConfig.type)}</TableCell>
        </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { DocumentConfigDetailTableComponent };
