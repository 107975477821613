import {
  HttpService,
} from '@app/services';

import {
  BaseResponseInterface,
} from '@app/types';

import {
  HandbkCreateRequest,
  HandbkCreateResponse,
  HandbkDeleteRequest,
  HandbkDeleteResponse,
  HandbkSortableRequest,
  HandbkSortableResponse,
  HandbkUpdateRequest,
  HandbkUpdateResponse,
  HandbkVariantCreateRequest,
  HandbkVariantCreateResponse,
  HandbkVariantDeleteRequest,
  HandbkVariantDeleteResponse,
  HandbkVariantUpdateRequest,
  HandbkVariantUpdateResponse,
} from './types';


export class HandbkApi {
  public static async create(
    payload: HandbkCreateRequest,
  ): Promise<BaseResponseInterface<HandbkCreateResponse>> {
    return await HttpService.sendRequest('POST', '/handbk/create', {
      handbk: payload,
    });
  };

  public static async update(
    payload: HandbkUpdateRequest,
  ): Promise<BaseResponseInterface<HandbkUpdateResponse>> {
    return await HttpService.sendRequest('PUT', '/handbk/update', {
      handbk: payload,
    });
  };

  public static async sortable(
    payload: HandbkSortableRequest,
  ): Promise<BaseResponseInterface<HandbkSortableResponse>> {
    return await HttpService.sendRequest('PUT', '/handbk/sortable', {
      handbk: payload,
    });
  };

  public static async delete(
    payload: HandbkDeleteRequest,
  ): Promise<BaseResponseInterface<HandbkDeleteResponse>> {
    return await HttpService.sendRequest('DELETE', '/handbk/delete', payload);
  };

  public static async variantCreate(
    payload: HandbkVariantCreateRequest,
  ): Promise<BaseResponseInterface<HandbkVariantCreateResponse>> {
    return await HttpService.sendRequest('POST', '/handbk/variant-create', {
      handbkVariant: payload,
    });
  };

  public static async variantUpdate(
    payload: HandbkVariantUpdateRequest,
  ): Promise<BaseResponseInterface<HandbkVariantUpdateResponse>> {
    return await HttpService.sendRequest('PUT', '/handbk/variant-update', {
      handbkVariant: payload,
    });
  };

  public static async variantDelete(
    payload: HandbkVariantDeleteRequest,
  ): Promise<BaseResponseInterface<HandbkVariantDeleteResponse>> {
    return await HttpService.sendRequest('DELETE', '/handbk/variant-delete', payload);
  };
};
