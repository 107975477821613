import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton, Typography } from '@mui/material';
import { HandbkDocumentApi } from '@app/api';

import {
  createDocumentAction,
  deleteDocumentAction,
  getOneDocumentAction,
  updateDocumentAction,
  openPrintFormWindowAction,
} from '@app/stores';

import { HandbkDocumentType } from '@app/types';

import {
  PrintIcon,
  TransitionOpacityBrick,
  ButtonEditBlock,
  ButtonCancelBlock,
  ButtonFillBlock,
  DocumentConfigUpdateFeature,
  ButtonConfirmComposition,
  CardDataComposition,
} from '@app/components';

import {
  calculateProgress,
  prepareFormValue,
} from '@app/helpers';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

import { DocumentTableComponent } from './components/document-table';
import { DocumentFormComponent } from './components/document-form';
import { DocumentOneProps } from './document-one.props';


const DocumentOneFeature = ({ documentConfig, id, parentId, isChildren, dragHandle }: DocumentOneProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [collection, isLoading, isSubmitting] = useAppSelector((state) => [
    state.document[documentConfig.key],
    state.document[documentConfig.key].status === 'loading',
    state.document[documentConfig.key].status === 'submitting',
  ]);
  const currentRole = useAppSelector((state) => state.auth.user!.role);

  const hasAccessUpdate = useAppSelector((state) => {
    if (state.auth.user!.role[`${documentConfig.key}_UPDATE`] === 'NEVER') {
      return false;
    }

    if (state.auth.user!.role[`${documentConfig.key}_UPDATE`] === 'ONLY_MY') {
      return collection.current?.creator.id === state.auth.user?.id;
    }

    if (state.auth.user!.role[`${documentConfig.key}_UPDATE`] === 'ONLY_MY_DEPARTMENT') {
      return collection.current?.departmentId === state.auth.user?.department.id;
    }

    if (state.auth.user!.role[`${documentConfig.key}_UPDATE`] === 'ALL') {
      return true;
    }
  });

  const hasAccessCreate = useAppSelector((state) => {
    if (state.auth.user!.role[`${documentConfig.key}_CREATE`] === 'NEVER') {
      return false;
    }

    if (state.auth.user!.role[`${documentConfig.key}_CREATE`] === 'ALL') {
      return true;
    }
  });

  const hasAccessDelete = useAppSelector((state) => {
    if (state.auth.user!.role[`${documentConfig.key}_DELETE`] === 'NEVER') {
      return false;
    }

    if (state.auth.user!.role[`${documentConfig.key}_DELETE`] === 'ONLY_MY') {
      return collection.current?.creator.id === state.auth.user?.id;
    }

    if (state.auth.user!.role[`${documentConfig.key}_DELETE`] === 'ONLY_MY_DEPARTMENT') {
      return collection.current?.departmentId === state.auth.user?.department.id;
    }

    if (state.auth.user!.role[`${documentConfig.key}_DELETE`] === 'ALL') {
      return true;
    }
  });

  const [isEditing, setIsEditing] = useState(false);
  const [hanbkLoading, setHanbkLoading] = useState(false);
  const [handbkList, setHandbkList] = useState<HandbkDocumentType[]>([]);

  const onSubmit = async (formValue: { [key: string]: any }) => {
    if (!collection.current) {
      const result = await dispatch(createDocumentAction({
        key: documentConfig.key,
        parentId,
        form: prepareFormValue(formValue),
      }));

      if (result.type === '@@document/create/fulfilled') {
        setIsEditing(false);
      }
    } else {
      const result = await dispatch(updateDocumentAction({
        id: collection.current.id,
        key: documentConfig.key,
        form: prepareFormValue(formValue),
      }));

      if (result.type === '@@document/update/fulfilled') {
        setIsEditing(false);
      }
    }
  }

  const onDelete = async () => {
    const result = await dispatch(deleteDocumentAction({ key: documentConfig.key, id: collection.current!.id }))

    if (result.type === '@@document/delete/fulfilled' && documentConfig.type === 'form-list') {
      const newPathArr = location.pathname.split('/');
      const newPath = newPathArr.filter((_, i) => i < newPathArr.length - 1).join('/');
      navigate(newPath);
    }
  }

  const getHandbkList = useCallback(async () => {
    const ids: number[] =
      documentConfig.fields
        .filter((fieldConfig) => (
          fieldConfig.documentConfigLinkId !== null
          && (
            fieldConfig.type === 'document-select'
            || fieldConfig.type === 'document-multiselect'
          )
        ))
        .map((fieldConfig) => (fieldConfig.documentConfigLinkId as number));

    if (ids.length === 0) {
      return;
    }

    setHanbkLoading(true);
    const result = await HandbkDocumentApi.list({
      documentConfigIdList: ids,
    });
    setHanbkLoading(false);

    setHandbkList(result.data.handbkList);
  }, [
    documentConfig.fields,
  ])

  useEffect(() => {
    getHandbkList();
  }, [
    getHandbkList,
  ])

  useEffect(() => {
    if (isChildren) {
      dispatch(getOneDocumentAction({ key: documentConfig.key, parentId: Number(parentId) }))
    } else {
      dispatch(getOneDocumentAction({ key: documentConfig.key, id: Number(id) }))
    }

    setIsEditing(false);
  }, [dispatch, documentConfig.key, parentId, id, isChildren]);

  const progress = calculateProgress(collection.current, documentConfig.fields, documentConfig);

  return (
    <CardDataComposition
      title={documentConfig.label.singular}
      extra={
        <>
          {!isEditing && <Typography variant="subtitle2" gutterBottom mb="0">Заполнено: {progress[0]}/{progress[1]}</Typography>}
          {isEditing && <ButtonCancelBlock buttonIcon onClick={() => setIsEditing(false)}/>}
          {(!isEditing && collection.current && hasAccessUpdate) && <ButtonEditBlock onClick={() => setIsEditing(true)}/>}
          {(!isEditing && !collection.current && hasAccessCreate) && <ButtonFillBlock buttonIcon onClick={() => setIsEditing(true)}/>}
          {(!isEditing && collection.current && hasAccessDelete) && <ButtonConfirmComposition onClick={onDelete}/>}
          <IconButton onClick={() => dispatch(openPrintFormWindowAction({ query: { documentConfigId: documentConfig.documentConfigId } }))}>
            <PrintIcon />
          </IconButton>
          {currentRole.CONFIGURATOR_ACCESS && <DocumentConfigUpdateFeature documentConfig={documentConfig} />}
        </>
      }
      loading={isLoading || hanbkLoading}
      noData={!collection.current && !isEditing}
      dragHandle={dragHandle}
    >
      {isEditing && (
        <TransitionOpacityBrick>
          <DocumentFormComponent
            documentConfig={documentConfig}
            documentCollection={collection}
            submitting={isSubmitting}
            onSubmitHandler={onSubmit}
            onCancelHandler={() => setIsEditing(false)}
            handbkList={handbkList}
          />
        </TransitionOpacityBrick>
      )}
      {!isEditing && (
        <TransitionOpacityBrick>
          <DocumentTableComponent
            documentConfig={documentConfig}
            documentCollection={collection}
            handbkList={handbkList}
          />
        </TransitionOpacityBrick>
      )}
    </CardDataComposition>
  );
}

export { DocumentOneFeature };
