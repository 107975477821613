import { CardHeaderBrick } from '@app/components';

import {
  ModalComponent,
  ButtonsComponent,
  PrintImageListComponent,
} from '..';


export const MainComponent = () => {
  return (
    <ModalComponent>
      <CardHeaderBrick
        title="Библиотека изображений"
        extra={<ButtonsComponent />}
      />

      <PrintImageListComponent />
    </ModalComponent>
  );
};
