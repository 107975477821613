import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ConfigurationType,
  DocumentCollectionType,
  DocumentStateInterface,
  BackendErrorsType
} from '@app/types';

import {
  createDocumentAction,
  deleteDocumentAction,
  getListDocumentAction,
  getOneDocumentAction,
  updateDocumentAction,
} from './document.actions';


const initialState: DocumentStateInterface = {};

const documentSlice = createSlice({
  name: '@@document',
  initialState,
  reducers: {
    setDocumentsStores: (state, action: PayloadAction<ConfigurationType>) => {
      const newState: {[key: string]: DocumentCollectionType } = {};

      action.payload.documents.forEach((documentConfig) => {
        newState[documentConfig.key] = {
          list: [],
          current: null,
          status: 'idle',
          errors: null,
          page: null,
          pages: null,
          searchQuery: {},
        };
      });
      
      return {
        ...newState,
      }
    },
    createDocumentStore: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        [action.payload]: {
          list: [],
          current: null,
          status: 'idle',
          errors: null,
          page: null,
          pages: null,
          searchQuery: {},
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDocumentAction.pending, (state, action) => {
        const key = action.meta.arg.key;

        state[key].status = 'submitting';
        state[key].errors = null;
      })
      .addCase(createDocumentAction.fulfilled, (state, action) => {
        const key = action.meta.arg.key;

        state[key].current = action.payload;
        state[key].status = 'idle';
      })
      .addCase(createDocumentAction.rejected, (state, action) => {
        const key = action.meta.arg.key;

        state[key].status = 'error';
        state[key].errors = action.payload as BackendErrorsType;
      })

      .addCase(updateDocumentAction.pending, (state, action) => {
        const key = action.meta.arg.key;

        state[key].status = 'submitting';
        state[key].errors = null;
      })
      .addCase(updateDocumentAction.fulfilled, (state, action) => {
        const key = action.meta.arg.key;

        state[key].status = 'idle';
        state[key].current = action.payload;
      })
      .addCase(updateDocumentAction.rejected, (state, action) => {
        const key = action.meta.arg.key;

        state[key].status = 'error';
        state[key].errors = action.payload as BackendErrorsType;
      })
      
      .addCase(getListDocumentAction.pending, (state, action) => {
        const key = action.meta.arg.query.key;

        state[key].status = 'loading';
        state[key].errors = null;
        state[key].list = [];

        if (action.meta.arg.searchQuery) {
          state[key].searchQuery = action.meta.arg.searchQuery;
        }
      })
      .addCase(getListDocumentAction.fulfilled, (state, action) => {
        const key = action.meta.arg.query.key;

        state[key].status = 'idle';
        state[key].list = action.payload.documents;
        state[key].page = action.payload.page;
        state[key].pages = action.payload.pages;
      })
      .addCase(getListDocumentAction.rejected, (state, action) => {
        const key = action.meta.arg.query.key;

        state[key].status = 'error';
        state[key].errors = action.payload as BackendErrorsType;
      })
      
      .addCase(getOneDocumentAction.pending, (state, action) => {
        const key = action.meta.arg.key;

        state[key].status = 'loading';
        state[key].errors = null;
        state[key].list = [];
      })
      .addCase(getOneDocumentAction.fulfilled, (state, action) => {
        const key = action.meta.arg.key;

        state[key].status = 'idle';
        state[key].current = action.payload.document;
      })
      .addCase(getOneDocumentAction.rejected, (state, action) => {
        const key = action.meta.arg.key;

        state[key].status = 'error';
        state[key].errors = action.payload as BackendErrorsType;
      })
      
      .addCase(deleteDocumentAction.pending, (state, action) => {
        const key = action.meta.arg.key;

        state[key].status = 'loading';
        state[key].errors = null;
      })
      .addCase(deleteDocumentAction.fulfilled, (state, action) => {
        const key = action.meta.arg.key;

        state[key].status = 'idle';
      })
      .addCase(deleteDocumentAction.rejected, (state, action) => {
        const key = action.meta.arg.key;

        state[key].status = 'error';
        state[key].errors = action.payload as BackendErrorsType;
      });
  },
});

export default documentSlice.reducer;

export const {
  setDocumentsStores,
  createDocumentStore,
} = documentSlice.actions;
