import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

import {
  ButtonIconSquareBrick,
} from '@app/components';

import { useMainApi } from '../../../../context';
import { BlockType } from '../../../../config';


export const ButtonOlComponent = () => {
  const {
    currentBlockType,
    toggleBlockType,
  } = useMainApi();

  const active = currentBlockType === BlockType.orderList;
  
  return (
    <ButtonIconSquareBrick
      icon={<FormatListNumberedIcon />}
      onClick={() => toggleBlockType(BlockType.orderList)}
      active={active}
    />
  );
};
