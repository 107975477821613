import { Typography } from '@mui/material';

import {
  LinkBrick,
  PageCenteringLayout,
} from '@app/components';

import { useAppSelector } from '@app/hooks';

import styles from './not-found.module.scss';


export const NotFoundPage = () => {
  const auth = useAppSelector((state) => state.auth);

  return (
    <PageCenteringLayout>
      <div className={styles['not-found-page']}>
        <div className={styles['not-found-page__content']}>
          <div className={styles['not-found-page__emoji']}></div>
          <Typography variant="h1">🤷‍♂️ Упс!</Typography>
          <Typography variant="body1">Такой страницы не существует в текущей реальности..</Typography>
          <div className={styles['not-found-page__links']}></div>
          {auth.isLoggedIn ? (
            <LinkBrick
              to="/"
              label={'На главную'}
            />
          ) : (
            <LinkBrick
              to="/signin"
              label={'Авторизация'}
            />
          )}
        </div>
      </div>
    </PageCenteringLayout>
  );
};
