import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import {
  ButtonIconSquareBrick,
} from '@app/components';

import { BlockType } from '../../../../config';
import { useMainApi } from '../../../../context';


export const ButtonUlComponent = () => {
  const {
    currentBlockType,
    toggleBlockType,
  } = useMainApi();

  const active = currentBlockType === BlockType.list;
  
  return (
    <ButtonIconSquareBrick
      icon={<FormatListBulletedIcon />}
      onClick={() => toggleBlockType(BlockType.list)}
      active={active}
    />
  );
};
