import React from 'react';

import { updatePrintFormAction } from '@app/stores';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  ChangeFieldEventType,
  PrintFormType,
} from '@app/types';
import { useMainApi } from '../main';
import { errorTranslate } from '@app/helpers';


export interface UpdateContextInterface {
  formValue: PrintFormType | null;
  formDirty: boolean;
  onSubmit: (e?: React.SyntheticEvent) => Promise<void>;
  changeFormValue: (e: ChangeFieldEventType) => void;
  setError: (fieldname: string) => string | null;
  closeForm: () => void;
};

export const useUpdateHook = (
): UpdateContextInterface => {
  const dispatch = useAppDispatch();

  const errors = useAppSelector((state) => state.printForm.errors);

  const {
    printFormIsEdited,
    printFormCurrent,
    printFormIsEditedSet,
  } = useMainApi();

  const [formValue, setFormValue] = React.useState<PrintFormType | null>(null);
  const [formDirty, setFormDirty] = React.useState(false);
  const [sendedForm, setSendedForm] = React.useState(false);

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    setFormDirty(true);
    setFormValue((state) => {
      if (!state) return null;

      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const closeForm = React.useCallback(() => {
    printFormIsEditedSet(false);
    setFormValue(printFormCurrent);
    setFormDirty(false);
    setSendedForm(false);
  }, [
    printFormCurrent,
    printFormIsEditedSet,
  ]);

  const onSubmit = React.useCallback(async (e?: React.SyntheticEvent) => {
    if (e) e.preventDefault();
    if (!formValue) return;
    
    setSendedForm(true);

    const result = await dispatch(updatePrintFormAction({
      printForm: {
        ...formValue,
      },
    }));

    if (result.type === '@@print-form/update/fulfilled') {
      closeForm();
    }
  }, [
    formValue,
    dispatch,
    closeForm,
  ]);

  const setError = React.useCallback((fieldName: string): string | null => {
    return sendedForm
      ? errors && errors[fieldName] && errorTranslate(errors[fieldName][0])
      : '';
  }, [
    sendedForm,
    errors,
  ]);

  React.useEffect(() => {
    if (printFormIsEdited && printFormCurrent) {
      setFormValue(printFormCurrent);
    }
  }, [
    printFormIsEdited,
    printFormCurrent,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    changeFormValue,
    onSubmit,
    setError,
    closeForm,
  }), [
    formValue,
    formDirty,
    changeFormValue,
    onSubmit,
    setError,
    closeForm,
  ]);
};
