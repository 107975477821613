import { LoadingButton } from '@mui/lab';

import {
  ShowIcon,
} from '@app/components';

import { useAppSelector } from '@app/hooks';

import { useMainApi } from '../../context';


export const PreviewButtonComponent = () => {
  const { printFormPreview } = useMainApi();
  const loading = useAppSelector((s) => s.printForm.status === 'building')

  return (
    <LoadingButton
      loading={loading}
      disabled={loading}
      size="small"
      variant="outlined"
      onClick={printFormPreview}
      startIcon={<ShowIcon />}
      color="primary"
    >
      Просмотр
    </LoadingButton>
  );
};
