import React  from 'react';
import { useNavigate } from 'react-router-dom';

import { callNotifyAction } from '@app/stores';

import {
  ChangeFieldEventType,
  BackendErrorsType,
} from '@app/types';

import { errorTranslate } from '@app/helpers';
import { useAppDispatch } from '@app/hooks';
import { DepartmentApi } from '@app/api';


export interface MainContextInterface {
  formValue: InitialFormValue;
  formDirty: boolean;
  formIsSubmitting: boolean;
  changeFormValue: (e: ChangeFieldEventType) => void;
  setError: (fieldName: string) => string | null;
  onSubmit: (e?: React.SyntheticEvent) => Promise<void>;
};

const initialFormValue: InitialFormValue = {
  name: '',
};

type InitialFormValue = {
  name: string;
}

export const useMainHook = (
): MainContextInterface => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [formValue, formValueSet] = React.useState<InitialFormValue>(initialFormValue);
  const [formDirty, formDirtySet] = React.useState(false);
  const [formIsSubmitting, formIsSubmittingSet] = React.useState(false);
  const [errors, errorsSet] = React.useState<BackendErrorsType | null>(null);

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const onSubmit = React.useCallback(async (e?: React.SyntheticEvent) => {
    formIsSubmittingSet(true);
    if (e) e.preventDefault();
    if (formValue === null) return;

    const response = await DepartmentApi.create(formValue);

    if (!response.success) {
      dispatch(callNotifyAction({
        type: 'error',
        message: 'Не удалось создать подразделение',
      }));

      formIsSubmittingSet(false);
      errorsSet(response.errors as BackendErrorsType);
      return;
    }

    dispatch(callNotifyAction({
      type: 'success',
      message: 'Подразделение успешно создано',
    }));

    navigate('/department-list/' + response.data.department.id);

    formIsSubmittingSet(false);
  }, [
    dispatch,
    formValue,
    navigate,
  ]);

  const setError = React.useCallback((fieldName: string) => {
    return errors && errors[fieldName] && errorTranslate(errors[fieldName][0]);
  }, [
    errors,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    formIsSubmitting,
    changeFormValue,
    setError,
    onSubmit,
  }), [
    formValue,
    formDirty,
    formIsSubmitting,
    changeFormValue,
    setError,
    onSubmit,
  ]);
};
