import {
  ModalComponent,
  VarListComponent,
} from '..';

export const MainComponent = () => {
  return (
    <ModalComponent>
      <VarListComponent />
    </ModalComponent>
  );
};
