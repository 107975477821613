import { useEffect, useRef } from 'react';

import {
  FormFieldBrick,
  AsteriskBrick,
  InputBrick,
} from '@app/components';

import { FieldUuidProps } from './field-uuid.props';


const insertSymbol = (
  str: string,
  substr: string,
  pos: number,
): string => {
  const array = str.split('');
  array.splice(pos, 0, substr);
  return array.join('');
}


export const FieldUuidBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  required,
  counted,
  prompt,
  extra,
}: FieldUuidProps) => {
  const inputUuid = useRef<HTMLInputElement>(null);

  const handleChange = () => {
    if (!inputUuid.current) {
      return;
    }

    const input = inputUuid.current.querySelectorAll('input')[0];
    const currentFocus = input.selectionStart;
    let inputValue = input.value.replace(/\-/g, '');  // eslint-disable-line

    if (inputValue.length > 4) inputValue = insertSymbol(inputValue, '-', 4);
    if (inputValue.length > 9) inputValue = insertSymbol(inputValue, '-', 9);
    if (inputValue.length > 14) inputValue = value;

    input.value = inputValue;
    
    if ((currentFocus === 5 || currentFocus === 10) && input.value.length > value.length) {
      input.setSelectionRange(currentFocus + 1, currentFocus + 1);
    } else {
      input.setSelectionRange(currentFocus, currentFocus);
    }

    onChange({ target: { name, value: inputValue } });
  };

  useEffect(
    () => {
      handleChange();
    },
    // eslint-disable-next-line
    [value]
  );
  
  return (
    <FormFieldBrick
      error={error}
      prompt={prompt}
      extra={extra}
    >
      <InputBrick
        label={<>{label}<AsteriskBrick required={required} counted={counted} /></>}
        name={name}
        value={value}
        onChange={handleChange}
        refEl={inputUuid}
      />
    </FormFieldBrick>
  );
};
