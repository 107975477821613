import {
  ButtonBrick,
  ButtonPrintBlock,
  ButtonsBrick,
  EditIcon,
} from '@app/components';
import { useMainApi } from '../../context';
import { useAppSelector } from '@app/hooks';

export const ControlTemplateComponent = () => {
  const {
    printFormCurrent,
    printFormAccess,
    printFormEditorModalOpen,
    printFormBuild,
  } = useMainApi();

  const visible = (
    printFormAccess &&
    printFormCurrent &&
    (
      printFormCurrent.type === 'editor' ||
      printFormCurrent.type === 'html'
    )
  );

  const isBuilding = useAppSelector((s) => s.printForm.status === 'building');

  return (
    <>
      {visible && (
        <ButtonsBrick>
          <ButtonPrintBlock
            onClick={printFormBuild}
            loading={isBuilding}
          />
          <ButtonBrick
            size="large" 
            startIcon={<EditIcon />}
            onClick={printFormEditorModalOpen}
            variant="text"
          >
            Редактировать Шаблон
          </ButtonBrick>
        </ButtonsBrick>
      )}
    </>
  );
};
