import { FieldMultiselectBlock } from '@app/components';
import { HandbkMultiselectProps } from './handbk-multiselect.props';


export const HandbkMultiselectField = ({
  fieldConfig,
  handbk,
  value,
  onChange,
  error,
  disabled,
}: HandbkMultiselectProps) => {
  if (!handbk) return (<p key={fieldConfig.key}>Ошибка словаря</p>);

  const variants = handbk.sortable.map((variantId) => {
    const variant = handbk.variants.find((v) => v.id === variantId);
    return {
      value: variant!.id,
      label: variant!.label,
    };
  });
  const listIsLong = variants && variants.length > 30;

  return (
    <FieldMultiselectBlock
      key={fieldConfig.key}
      name={fieldConfig.key}
      label={fieldConfig.label}
      value={value}
      onChange={onChange}
      error={error}
      items={variants}
      counted={fieldConfig.counted}
      required={fieldConfig.required}
      prompt={fieldConfig.prompt}
      enableSearch={listIsLong}
      enablePagination={listIsLong}
      disabled={disabled}
    />
  );
};
