import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import {
  ColumnValueFeature,
} from '@app/components';

import {
  dateTranslate,
  buildColumnValue,
} from '@app/helpers';

import { useAppSelector } from '@app/hooks';

import { DocumentTableProps } from './document-table.props';
import styles from './document-table.module.scss';


const DocumentTableComponent = ({
  documentConfig,
  collection,
  handbkList,
}: DocumentTableProps) => {
  const configurationState = useAppSelector((s) => s.configuration);

  return (
    <div className={styles['document-table']}>
      <TableContainer className={styles['document-table__container']}>
        <Table aria-label="user-detail">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>

              {documentConfig.sortableFields.map((fieldId) => {
                const fieldConfig = documentConfig.fields.find((fieldConfig) => fieldConfig.fieldConfigId === fieldId);

                if (!fieldConfig || !fieldConfig.display.list) {
                  return null;
                }

                return <TableCell key={fieldConfig.key}>{fieldConfig.label}</TableCell>
              })}
              
              <TableCell>Создал</TableCell>
              <TableCell>Дата создания</TableCell>
              <TableCell>Обновил</TableCell>
              <TableCell>Дата обновления</TableCell>
              <TableCell>Uuid</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {collection.list && collection.list.map((item) => (
                <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">{item.id}</TableCell>
                  {documentConfig.sortableFields.map((fieldId) => {
                    const fieldConfig = documentConfig.fields.find((fieldConfig) => fieldConfig.fieldConfigId === fieldId);
                    
                    if (!fieldConfig || !fieldConfig.display.list) {
                      return null;
                    }

                    const columnValue = buildColumnValue(fieldConfig, item[fieldConfig.key], configurationState, handbkList);

                    return (
                      <ColumnValueFeature
                        key={fieldConfig.key}
                        fieldConfig={fieldConfig}
                        documentKey={documentConfig.key}
                        documentId={item.id}
                        columnValue={columnValue}
                        mayBeLinkToDetail
                      />
                    );
                  })}
                  
                  <TableCell>{item.creator.firstName} {item.creator.lastName}</TableCell>
                  <TableCell>{dateTranslate(item.createdAt)}</TableCell>
                  <TableCell>{item.updater.firstName} {item.updater.lastName}</TableCell>
                  <TableCell>{dateTranslate(item.updatedAt)}</TableCell>
                  <TableCell>{item.uuid}</TableCell>
                </TableRow>
              ))}
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export { DocumentTableComponent };
