import { FC } from 'react';
import cs from 'classnames';
import { Tooltip } from '@mui/material';

import {
  HelpIcon,
  DividerVerticalBrick,
} from '@app/components';

import { FormFieldProps } from './form-field.props';
import styles from './form-field.module.scss';


export const FormFieldBrick: FC<FormFieldProps> = ({
  children,
  error,
  hideError,
  prompt,
  extra,
}) => {
  const formFieldClassname = cs(
    styles['form-field'],
    { [styles['form-field--not-error']]: hideError },
  );

  return (
    <div className={formFieldClassname}>
      {prompt && (
        <div className={styles['form-field__prompt']}>
          <Tooltip title={prompt}>
            <HelpIcon className={styles['form-field__icon-help']} />
          </Tooltip>
        </div>
      )}
      <div className={styles['form-field__input']}>
        {extra && (
          <div className={styles['form-field__extra']}>
            {extra}
            <DividerVerticalBrick />
          </div>
        )}
        <div className={styles['form-field__children']}>
          {children}
          {error && !hideError && (
            <div className={styles['form-field__error']}>{error}</div>
          )}
        </div>
      </div>
    </div>
  )
}
