import {
  FieldConfigType,
  ConfigurationStateInterface,
  HandbkDocumentType,
} from '@app/types';

import { booleanTranslate } from './boolean-translate.helper';
import { dateTranslate } from './date-translate.helper';
import { datetimeTranslate } from './datetime-translate.helper';
import { monthTranslateHelper } from './month-translate.helper';
import { monthYearTranslateHelper } from './month-year-translate.helper';
import { genderTranslateHelper } from './gender-translate.helper';


export const buildColumnValue = (
  column: FieldConfigType,
  value: any,
  configurationState: ConfigurationStateInterface,
  handbkList: HandbkDocumentType[],
): any => {
  if (column.type === 'date') {
    return dateTranslate(value);
  }

  if (column.type === 'datetime') {
    return datetimeTranslate(value);
  }

  if (column.type === 'boolean') {
    return booleanTranslate(value);
  }

  if (column.type === 'boolean-check') {
    return booleanTranslate(value);
  }

  if (column.type === 'month') {
    return monthTranslateHelper(value);
  }

  if (column.type === 'month-year') {
    return monthYearTranslateHelper(value);
  }

  if (column.type === 'multiselect') {
    return Array.isArray(value) ? value.join(', ') : value;
  }

  if (column.type === 'handbk-select' || column.type === 'handbk-radio') {
    if (!value) return '';

    const handbk = configurationState.handbk.find((h) => h.handbkId === column.handbkId);

    if (!handbk) return 'error';

    const variant = handbk.variants.find((v) => v.id === value);

    if (!variant) return 'error';

    return variant.label;
  }

  if (column.type === 'handbk-multiselect' || column.type === 'handbk-check') {
    if (!Array.isArray(value)) return '';

    const handbk = configurationState.handbk.find((h) => h.handbkId === column.handbkId);

    if (!handbk) return 'error';

    return value.map((variantId) => {
      const variant = handbk.variants.find((v) => v.id === variantId);

      if (!variant) return 'error';

      return variant.label;
    }).join(', ')
  }

  if (column.type === 'handbk-remote-select' || column.type === 'handbk-remote-radio') {
    if (!value) return '';

    const handbk = configurationState.handbkRemote.find((h) => h.id === column.handbkRemoteId);

    if (!handbk) return 'error';

    const variant = handbk.variants.find((v) => v.id === value);

    if (!variant) return 'error';

    return variant.label;
  }

  if (column.type === 'handbk-remote-multiselect' || column.type === 'handbk-remote-check') {
    if (!Array.isArray(value)) return '';

    const handbk = configurationState.handbkRemote.find((h) => h.id === column.handbkRemoteId);

    if (!handbk) return 'error';

    return value.map((variantId) => {
      const variant = handbk.variants.find((v) => v.id === variantId);

      if (!variant) return 'error';

      return variant.label;
    }).join(', ')
  }

  if (column.type === 'gender') {
    return genderTranslateHelper(value);
  }

  if (column.type === 'decimal') {
    if (value === null) return '';
    return String(value).replace('.', ',');
  }

  if (column.type === 'document-select') {
    if (!value) return '';

    const handbk = handbkList.find((hb) => hb.documentConfigId === column.documentConfigLinkId);
    if (handbk === undefined) return 'Ошибка словаря';
    
    const variant = handbk.variants.find((variant) => variant.value === value);
    if (variant === undefined) return 'Ошибка словаря';

    return variant.label;
  }

  if (column.type === 'document-multiselect') {
    const handbk = handbkList.find((hb) => hb.documentConfigId === column.documentConfigLinkId);
    if (handbk === undefined) return 'Ошибка словаря';

    const variants = handbk.variants
      .filter((variant) => Array.isArray(value) && (value as number[]).indexOf(variant.value) !== -1)
      .map((variant) => variant.label);
    
    return variants.join(', ');
  }


  return value;
}
