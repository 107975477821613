import { hadnbkMonthsGetHelper } from './get-months-dict.helper';

export const monthTranslateHelper = (monthNumber: number | null): string => {
  if (monthNumber === null) return '';

  const months = hadnbkMonthsGetHelper();

  const month = months.find((month) => month.value === monthNumber);

  if (month) return month.label;
  
  return String(monthNumber);
};
