import {
  ButtonsBrick,
  ButtonCancelBlock,
  ButtonSaveBlock,
  FieldTextBlock,
  FieldLongTextBlock,
} from '@app/components';

import { useGeneralApi, useMainApi } from '../../context';

import styles from './general-update.module.scss';


export const GeneralUpdateComponent = () => {
  const {
    isEdited,
    configurationIsSubmitting,
  } = useMainApi();

  const {
    formValue,
    formDirty,
    onSubmit,
    changeFormValue,
    closeForm,
    setError,
  } = useGeneralApi();

  if  (formValue === null || !isEdited) return null;

  return (
    <div className="row justify-content-lg-center">
      <div className="col col-lg-6">
        <form
          className={styles['general-update']}
          onSubmit={onSubmit}
        >
          <FieldTextBlock
            name="name"
            label="Название"
            value={formValue.name}
            onChange={changeFormValue}
            error={setError('name')}
            required
          />
          <FieldLongTextBlock
            name="description"
            label="Описание"
            value={formValue.description}
            onChange={changeFormValue}
            error={setError('description')}
          />
          <ButtonsBrick>
            <ButtonSaveBlock
              loading={configurationIsSubmitting}
              onClick={onSubmit}
              disabled={!formDirty}
            />
            <ButtonCancelBlock onClick={closeForm} />
          </ButtonsBrick>
        </form>
      </div>
    </div>
  );
}
