import { DocumentStateInterface } from '@app/types';


export const getCurrentDocumentIdHelper = (
  documentConfigKey: string,
  documentState: DocumentStateInterface,
) => {
  const pathIds: {[key: string]: number} = window
      .location
      .pathname
      .split('/')
      .slice(2)
      .reduce((acc, item) => {
        const i = item.lastIndexOf('-');
        return {
          ...acc,
          [item.substring(0, i)]: Number(item.substring(i + 1)),
        }
      }, {});

    const getDocumentId = (): number | null => {
    const documentId = (
      pathIds[documentConfigKey]
        ? pathIds[documentConfigKey]
        : documentState[documentConfigKey].current
          ? documentState[documentConfigKey].current!.id
          : null
    );

    return documentId;
  }

  return getDocumentId();
};
