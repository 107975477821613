import {
  HttpService,
} from '@app/services';

import { BaseResponseInterface } from '@app/types';
import { ConfigurationBuildResponse } from './types';


export class ConfigurationApi {
  public static async build(
  ): Promise<BaseResponseInterface<ConfigurationBuildResponse>> {
    return await HttpService.sendRequest('GET', '/configuration/build');
  };
};
