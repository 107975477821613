import { Dialog, DialogContent } from '@mui/material';

import {
  CardHeaderBrick,
  ButtonCancelBlock,
} from '@app/components';

import { useDocumentSearchApi } from '../../context';
import { QrScannerComponent } from '../qr-scanner';
import { UuidFormComponent } from '../uuid-form';
import { ResultComponent } from '../result';
import styles from './main.module.scss';


const OutletComponent = () => {
  const {
    mode,
  } = useDocumentSearchApi();

  if (mode === 'form') {
    return <UuidFormComponent />;
  } else if (mode === 'scan') {
    return <QrScannerComponent />
  } else {
    return <ResultComponent />
  }
};

export const MainComponent = () => {
  const {
    openedDocumentSearchModal,
    closeDialog,
  } = useDocumentSearchApi();

  return (
    <div className={styles['document-search']}>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={openedDocumentSearchModal}
        onClose={() => closeDialog()}
      >
        <CardHeaderBrick
          title="Поиск документа"
          extra={<ButtonCancelBlock buttonIcon onClick={closeDialog} />}
        />
        <DialogContent>
          <div className="row justify-content-lg-center">
            <div className="col col-lg-6">
              <OutletComponent />
              <div id="document-search-scanner" />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

