import { HTMLAttributes } from 'react';
import { DocumentSearchContext } from '../document-search.context';
import { useDocumentSearch } from '../document-search.hook';

export interface DocuemntSearchProviderProps extends HTMLAttributes<HTMLDivElement> {
  opened: boolean;
  closeModal: (value?: number) => void;
  canBeUsed?: boolean;
};



export const DocumentSearchProvider = ({
  children,
  opened,
  closeModal,
  canBeUsed,
}: DocuemntSearchProviderProps) => {
  const documentSearchApi = useDocumentSearch(opened, closeModal, canBeUsed);
  
  return (
    <DocumentSearchContext.Provider value={documentSearchApi}>
      {children}
    </DocumentSearchContext.Provider>
  )
}