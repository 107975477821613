import { BlockType } from '../../../../config';
import { useMainApi } from '../../../../context';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ChangeFieldEventType } from '@app/types';

export const BlockTypeSelectComponent = () => {
  const {
    currentBlockType,
    toggleBlockType,
  } = useMainApi();
  const handleChange = (e: ChangeFieldEventType) => {
    const newValue = (e.target.value as BlockType);
    toggleBlockType(newValue);
  };

  return (
    <FormControl sx={{ minWidth: 140 }} size="small">
      <InputLabel id="block-type-select-input-label">Тип блока</InputLabel>
      <Select
        labelId="block-type-select-input-label"
        id="block-type-select-input"
        value={currentBlockType}
        label="Тип блока"
        onChange={handleChange}
        size="small"
      >
        <MenuItem value={BlockType.h1}>Заголовок 1</MenuItem>
        <MenuItem value={BlockType.h2}>Заголовок 2</MenuItem>
        <MenuItem value={BlockType.h3}>Заголовок 3</MenuItem>
        <MenuItem value={BlockType.h4}>Заголовок 4</MenuItem>
        <MenuItem value={BlockType.h5}>Заголовок 5</MenuItem>
        <MenuItem value={BlockType.h6}>Заголовок 6</MenuItem>
        <MenuItem value={BlockType.default}>Абзац</MenuItem>
      </Select>
    </FormControl>
  );
};
