import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  ConfigurationStateInterface,
  BackendErrorsType,
} from '@app/types';

import {
  ConfigurationBuildResponse,
  DocumentConfigCreateResponse,
  DocumentConfigUpdateResponse,
  DocumentConfigDeleteResponse,
  FieldConfigCreateResponse,
  FieldConfigUpdateResponse,
  FieldConfigDeleteResponse,
  HandbkCreateResponse,
  HandbkUpdateResponse,
  HandbkVariantCreateResponse,
  HandbkVariantUpdateResponse,
  HandbkVariantDeleteResponse,
  HandbkDeleteResponse,
} from '@app/api';

import {
  documentConfigCreateAction,
  documentConfigDeleteAction,
  documentConfigSortableChildrensAction,
  documentConfigSortableFieldsAction,
  documentConfigUpdateAction,
} from './document-config.actions';

import {
  fieldConfigCreateAction,
  fieldConfigDeleteAction,
  fieldConfigUpdateAction,
} from './field-config.actions';

import {
  handbkCreateAction,
  handbkDeleteAction,
  handbkSortableAction,
  handbkUpdateAction,
  handbkVariantCreateAction,
  handbkVariantDeleteAction,
  handbkVariantUpdateAction,
} from './handbk.actions';

import { configurationBuildAction } from './configuration.actions';


const initialState: ConfigurationStateInterface = {
  status: 'idle',
  documents: [],
  handbk: [],
  handbkRemote: [],
  errors: null,

  modalHandbkCreateIsOpened: false,
  handbkIdOpened: null,
};

const configurationSlice = createSlice({
  name: '@@configuration',
  initialState,
  reducers: {
    modalHanbkCreateOpenAction: (state) => {
      return {
        ...state,
        modalHandbkCreateIsOpened: true,
      };
    },
    modalHanbkCreateCloseAction: (state) => {
      return {
        ...state,
        modalHandbkCreateIsOpened: false,
      };
    },
    modalHanbkDetailOpenAction: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        handbkIdOpened: action.payload,
      };
    },
    modalHanbkDetailCloseAction: (state) => {
      return {
        ...state,
        handbkIdOpened: null,
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(configurationBuildAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(configurationBuildAction.fulfilled, (
        state,
        action: PayloadAction<ConfigurationBuildResponse>,
      ) => {
        state.documents = action.payload.configuration.documents;
        state.handbk = action.payload.configuration.handbk;
        state.handbkRemote = action.payload.configuration.handbkRemote;
        state.status = 'idle';
      })
      .addCase(configurationBuildAction.rejected, (state) => {
        state.status = 'idle';
      })
      
      .addCase(documentConfigCreateAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(documentConfigCreateAction.fulfilled, (
        state,
        action: PayloadAction<DocumentConfigCreateResponse>,
      ) => {
        if (action.payload.documentConfig.parentKey !== 'root') {
          const parentDocumentConfig = state.documents.find((documentConfig) => documentConfig.key === action.payload.documentConfig.parentKey);
          
          if (!parentDocumentConfig) {
            return;
          }

          parentDocumentConfig.sortableChildrens.push(action.payload.documentConfig.documentConfigId);
        }

        state.documents.unshift(action.payload.documentConfig);
        state.status = 'idle';
      })
      .addCase(documentConfigCreateAction.rejected, (state, action) => {
        state.status = 'idle';
        state.errors = (action.payload as BackendErrorsType)
      })
      
      .addCase(documentConfigUpdateAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(documentConfigUpdateAction.fulfilled, (
        state,
        action: PayloadAction<DocumentConfigUpdateResponse>,
      ) => {
        state.documents = state.documents.map((document) => {
          if (document.documentConfigId === action.payload.documentConfig.documentConfigId) {
            return { ...action.payload.documentConfig, fields: document.fields };
          }
          else {
            return document;
          }
        });
        state.status = 'idle';
      })
      .addCase(documentConfigUpdateAction.rejected, (state, action) => {
        state.status = 'idle';
        state.errors = action.payload as BackendErrorsType;
      })

      .addCase(documentConfigDeleteAction.pending, (state) => {
        // state.status = 'submitting';
      })
      .addCase(documentConfigDeleteAction.fulfilled, (
        state,
        action: PayloadAction<DocumentConfigDeleteResponse>,
      ) => {
        const { documentConfigIds } = action.payload;

        state.documents = state.documents.filter((documentConfig) => {
          return documentConfigIds.indexOf(documentConfig.documentConfigId) === -1; 
        });

        state.status = 'idle';
      })
      .addCase(documentConfigDeleteAction.rejected, (state, action) => {
        state.status = 'idle';
      })
      
      .addCase(fieldConfigCreateAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(fieldConfigCreateAction.fulfilled, (
        state,
        action: PayloadAction<FieldConfigCreateResponse>,
      ) => {
        const { fieldConfig, documentConfigId } = action.payload;
        
        state.documents = state.documents.map((config) => {
          if (config.documentConfigId === documentConfigId) {
            config.sortableFields.push(fieldConfig.fieldConfigId)
            config.fields.push(fieldConfig);
          }

          return config;
        });

        state.status = 'idle';
      })
      .addCase(fieldConfigCreateAction.rejected, (state, action) => {
        state.status = 'idle';
        state.errors = action.payload as BackendErrorsType;
      })

      .addCase(fieldConfigUpdateAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(fieldConfigUpdateAction.fulfilled, (
        state,
        action: PayloadAction<FieldConfigUpdateResponse>,
      ) => {
        const { fieldConfig, documentConfigId } = action.payload;
        
        const documentConfig = state.documents.find((documentConfig) => documentConfig.documentConfigId === documentConfigId);
        if (!documentConfig) return;

        documentConfig.fields = documentConfig.fields.map((config) => {
          if (config.fieldConfigId === fieldConfig.fieldConfigId) {
            return fieldConfig;
          }

          return config;
        })

        state.status = 'idle';
      })
      .addCase(fieldConfigUpdateAction.rejected, (state, action) => {
        state.status = 'idle';
        state.errors = action.payload as BackendErrorsType;
      })
      
      .addCase(documentConfigSortableFieldsAction.pending, (
        state,
        action,
      ) => {
        const payload = action.meta.arg;

        const documentConfig = state.documents.find((documentConfig) => documentConfig.documentConfigId === payload.documentConfigId);
        if (!documentConfig) {
          return;
        }

        documentConfig.sortableFields = payload.sortable;
      })
      .addCase(documentConfigSortableFieldsAction.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(documentConfigSortableFieldsAction.rejected, (state, action) => {
        state.status = 'idle';
      })

      .addCase(documentConfigSortableChildrensAction.pending, (state, action) => {
        const payload = action.meta.arg;

        const documentConfig = state.documents.find((documentConfig) => documentConfig.documentConfigId === payload.documentConfigId);
        if (!documentConfig) {
          return;
        }

        documentConfig.sortableChildrens = payload.sortable;
      })
      .addCase(documentConfigSortableChildrensAction.fulfilled, (state, action) => {
        state.status = 'idle';
      })
      .addCase(documentConfigSortableChildrensAction.rejected, (state, action) => {
        state.status = 'idle';
      })
      
      .addCase(fieldConfigDeleteAction.pending, (state) => {
        // state.status = 'submitting';
      })
      .addCase(fieldConfigDeleteAction.fulfilled, (
        state,
        action: PayloadAction<FieldConfigDeleteResponse>,
      ) => {
        const { fieldConfigId, documentConfigId } = action.payload;

        const documentConfig = state.documents.find((documentConfig) => documentConfig.documentConfigId === documentConfigId);
        if (!documentConfig) {
          return;
        }
        
        documentConfig.fields = documentConfig.fields.filter((fieldConfig) => fieldConfig.fieldConfigId !== fieldConfigId);
        documentConfig.sortableFields = documentConfig.sortableFields.filter((id) => id !== fieldConfigId);

        state.status = 'idle';
      })
      .addCase(fieldConfigDeleteAction.rejected, (state, action) => {
        state.status = 'idle';
      })
      
      .addCase(handbkCreateAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(handbkCreateAction.fulfilled, (
        state,
        action: PayloadAction<HandbkCreateResponse>,
      ) => {
        const handbk = action.payload.handbk;

        state.handbk.unshift(handbk);

        state.status = 'idle';
      })
      .addCase(handbkCreateAction.rejected, (state, action) => {
        state.status = 'idle';
        state.errors = action.payload as BackendErrorsType;
      })
      
      .addCase(handbkUpdateAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(handbkUpdateAction.fulfilled, (
        state,
        action: PayloadAction<HandbkUpdateResponse>,
      ) => {
        const handbk = action.payload.handbk;

        state.handbk = state.handbk.map((h) => {
          return h.handbkId === handbk.handbkId
            ? { ...h, name: handbk.name, description: handbk.description }
            : h
        });

        state.status = 'idle';
      })
      .addCase(handbkUpdateAction.rejected, (state, action) => {
        state.status = 'idle';
        state.errors = action.payload as BackendErrorsType;
      })

      .addCase(handbkDeleteAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(handbkDeleteAction.fulfilled, (
        state,
        action: PayloadAction<HandbkDeleteResponse>,
      ) => {
        const handbkId = action.payload.id;

        state.handbk = state.handbk.filter((h) => h.handbkId !== handbkId);

        state.status = 'idle';
      })
      .addCase(handbkDeleteAction.rejected, (state, action) => {
        state.status = 'idle';
        state.errors = action.payload as BackendErrorsType;
      })

      .addCase(handbkSortableAction.pending, (state, action) => {
        state.status = 'submitting';
        const payload = action.meta.arg;
        const handbk = state.handbk.find((h) => h.handbkId === payload.id);

        if (!handbk) return;

        handbk.sortable = payload.sortable;
        state.errors = null;
      })
      .addCase(handbkSortableAction.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(handbkSortableAction.rejected, (state, action) => {
        state.status = 'idle';
        state.errors = action.payload as BackendErrorsType;
      })
      
      .addCase(handbkVariantCreateAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(handbkVariantCreateAction.fulfilled, (
        state,
        action: PayloadAction<HandbkVariantCreateResponse>
      ) => {
        state.status = 'idle';

        const { handbkId, variants } = action.payload;
        const handbk = state.handbk.find((h) => h.handbkId === handbkId);

        if (!handbk) return;

        variants.forEach((v) => {
          handbk.sortable.push(v.id);
          handbk.variants.push(v);
        });
      })
      .addCase(handbkVariantCreateAction.rejected, (state, action) => {
        state.status = 'idle';
        state.errors = action.payload as BackendErrorsType;
      })
      
      .addCase(handbkVariantUpdateAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(handbkVariantUpdateAction.fulfilled, (
        state,
        action: PayloadAction<HandbkVariantUpdateResponse>
      ) => {
        state.status = 'idle';

        const { handbkId, handbkVariant } = action.payload;

        const handbk = state.handbk.find((h) => h.handbkId === handbkId);

        if (!handbk) return;

        const oldVariant = handbk.variants.find((v) => v.id === handbkVariant.id);

        if (!oldVariant) return;

        oldVariant.label = handbkVariant.label;
      })
      .addCase(handbkVariantUpdateAction.rejected, (state, action) => {
        state.status = 'idle';
        state.errors = action.payload as BackendErrorsType;
      })
      
      .addCase(handbkVariantDeleteAction.pending, (state) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(handbkVariantDeleteAction.fulfilled, (
        state,
        action: PayloadAction<HandbkVariantDeleteResponse>
      ) => {
        state.status = 'idle';

        const { id, handbkId } = action.payload;

        const handbk = state.handbk.find((h) => h.handbkId === handbkId);

        if (!handbk) return;
        
        handbk.sortable = handbk.sortable.filter((vId) => vId !== id);
        handbk.variants = handbk.variants.filter((v) => v.id !== id);
      })
      .addCase(handbkVariantDeleteAction.rejected, (state, action) => {
        state.status = 'idle';
        state.errors = action.payload as BackendErrorsType;
      });


  },
});

export default configurationSlice.reducer;

export const {
  modalHanbkCreateOpenAction,
  modalHanbkCreateCloseAction,
  modalHanbkDetailOpenAction,
  modalHanbkDetailCloseAction,
} = configurationSlice.actions;
