import { EmojiStatusProps } from './emoji-status.props';

export const EmojiStatusBrick = ({
  type,
}: EmojiStatusProps) => {
  
  const emojiError: string[] = ['🤯', '🙄', '😿', '🤷‍♂️', '🤦‍♂️', '🥴', '🤕', '😔', '😟', '🥺', '😧', '😵‍💫'];
  const emojiSuccess: string[] = ['😎', '🔥', '🚀', '💪', '👍', '😍', '😉', '🤗', '😼', '🦹‍♂️', '🫶', '👏', '🙌', '👌', '🥳', '🙂', '🎉', '🎊'];
  const emojiWarning : string[]= ['⚠️', '🚫', '📌', '📢'];
  const emojiInfo: string[] = ['❗️', '💯', '🌀', '🔅', '🍀', '💁‍♂️'];
  
  const random = (arr: string[]): string => {
    return arr[Math.floor(Math.random() * arr.length)];
  }

  const emojiBuild = () => {
    if (type === 'error') return random(emojiError);
    if (type === 'warning') return random(emojiWarning);
    if (type === 'success') return random(emojiSuccess);
    if (type === 'info') return random(emojiInfo);
  }

  return (
    <span>
      {emojiBuild()}
    </span>
  );
};
