import { Checkbox } from '@mui/material';

import { CheckboxProps } from './checkbox.props';


export const CheckboxBrick = ({
  checked,
  onChange,
}: CheckboxProps) => {
  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
    />
  );
}
