import React from 'react';
import { SyntheticEvent, useCallback, useState } from 'react';

import {
  ChangeFieldEventType,
} from '@app/types';

import {
  ButtonsBrick,
  BooleanCheckField,
  BooleanField,
  DateField,
  DatetimeField,
  DecimalField,
  DocumentMultiselectField,
  DocumentSelectField,
  FileField,
  GenderField,
  HandbkMultiselectField,
  HandbkRadioField,
  HandbkRemoteCheckField,
  HandbkRemoteMultiselectField,
  HandbkRemoteRadioField,
  HandbkRemoteSelectField,
  HandbkSelectField,
  LongTextField,
  MonthField,
  MonthYearField,
  MulticheckField,
  MultiselectField,
  NumberField,
  PassportField,
  PhoneField,
  SelectField,
  SnilsField,
  TextField,
  TimeField,
  TimeSecondsField,
  YearField,
  ButtonCancelBlock,
  ButtonSaveBlock,
  DocumentSearchFeature,
} from '@app/components';

import {
  checkVisibleField,
  errorTranslate,
} from '@app/helpers';

import { useAppSelector } from '@app/hooks';

import styles from './document-form.module.scss';
import { DocumentFormProps } from './document-form.props';


export const DocumentFormComponent = ({
  documentConfig,
  onSubmitHandler,
  documentCollection,
  onCancelHandler,
  submitting,
  handbkList,
}: DocumentFormProps) => {
  const isUpdate = !!documentCollection.current;
  const buildInitialFormValue = useCallback(
    () => {
      const initialFormValue: { [key: string]: any } = {};

      if (documentCollection.current) {
        documentConfig.fields.forEach((column) => {
          if (column.display.update) {
            if ((column.type === 'select' || column.type === 'handbk-select' || column.type === 'handbk-radio') && documentCollection.current![column.key] === null) {
              initialFormValue[column.key] = '';
            } else if (
                (
                  column.type === 'multiselect' ||
                  column.type === 'handbk-multiselect' ||
                  column.type === 'handbk-check' ||
                  column.type === 'handbk-remote-multiselect' ||
                  column.type === 'handbk-remote-check'
                ) && documentCollection.current![column.key] === null
              ) {
              initialFormValue[column.key] = [];
            } else if ((column.type === 'decimal' || column.type === 'number') && documentCollection.current![column.key] === null) {
              initialFormValue[column.key] = '';
            } else if (column.type === 'boolean-check') {
              initialFormValue[column.key] = documentCollection.current![column.key];
            } else if (documentCollection.current![column.key] === null) {
              initialFormValue[column.key] = '';
            } else {
              initialFormValue[column.key] = documentCollection.current![column.key];
            }
          }
        });
      } else {
        documentConfig.fields.forEach((column) => {
          if (isUpdate ? column.display.update : column.display.create) {
            if (column.type === 'boolean' || column.type === 'date' || column.type === 'datetime') {
              initialFormValue[column.key] = null;
            } else if (column.type === 'multiselect') {
              initialFormValue[column.key] = [];
            } else if (column.type === 'boolean-check') {
              initialFormValue[column.key] = false;
            } else {
              initialFormValue[column.key] = '';
            }
          }
        });
      }

      return initialFormValue;
    },
    [documentCollection, documentConfig.fields, isUpdate],
  );

  const [formValue, setFormValue] = useState(buildInitialFormValue());
  const [formDirty, setFormDirty] = useState(false);
  const [fieldKeyFlag, setFieldKeyFlag] = useState<[string, string] | null>(null);
  const [openedDocumentSearchModal, setOpenedDocumentSearchModal] = useState(false);

  const handbkStore = useAppSelector((s) => s.configuration.handbk);
  const handbkRemoteStore = useAppSelector((s) => s.configuration.handbkRemote);
  const currentRole = useAppSelector((s) => s.auth.user!.role);

  const onSubmitForm = (e?: SyntheticEvent) => {
    if (e) e.preventDefault();
    onSubmitHandler(formValue);
  }

  const changeFormValue = (e: ChangeFieldEventType | null): void => {
    setFormDirty(true);
    setFormValue((state) => {
      if (!e) return state;

      return {
        ...state,
        [e!.target.name]: e!.target.value,
      }
    });
  };

  const setError = (columnKey: string) => {
    return documentCollection.errors && documentCollection.errors[columnKey] && errorTranslate(documentCollection.errors[columnKey][0]);
  }

  const openDocumentSearchModal = React.useCallback((name: string, type: string) => {
    setFieldKeyFlag([name, type]);
    setOpenedDocumentSearchModal(true);
  }, [
  ]);

  const closeDocumentSearchModal = React.useCallback((value?: number) => {
    const name = fieldKeyFlag && fieldKeyFlag[0];
    const type = fieldKeyFlag && fieldKeyFlag[1];
    const isValidValue = value && typeof value === 'number' && name && type;
    const setSelect = isValidValue && type === 'document-select';
    const setMultiselect = isValidValue && type === 'document-multiselect';

    if (setSelect) changeFormValue({ target: { name, value }});
    if (setMultiselect) changeFormValue({ target: { name, value: [...formValue[name], value] } });

    setOpenedDocumentSearchModal(false);
    setFieldKeyFlag(null);
  }, [
    fieldKeyFlag,
    formValue,
  ]);

  return (
    <div className="row justify-content-lg-center">
      <div className="col col-lg-6">
        <div className={styles['update-form']}>
          <form onSubmit={(e: SyntheticEvent) => onSubmitForm(e)}>
            <>
              {documentConfig.sortableFields.map((fieldId) => {
                const fieldConfig = documentConfig.fields.find((fieldConfig) => fieldConfig.fieldConfigId === fieldId);

                if (!fieldConfig) {
                  return null;
                }

                const invisibleField = !(
                  (isUpdate ? fieldConfig.display.update : fieldConfig.display.create) 
                  && checkVisibleField(documentConfig, fieldConfig, formValue)
                );

                if (invisibleField) {
                  return null;
                }

                const disabled = (
                  fieldConfig.accessReadAndWriteRolesIds.length !== 0
                  && !fieldConfig.accessReadAndWriteRolesIds.includes(currentRole.id)
                );
                
                if (
                  (
                    (
                      fieldConfig.accessOnlyReadRolesIds.length !== 0
                      && !fieldConfig.accessOnlyReadRolesIds.includes(currentRole.id)
                    )
                    && fieldConfig.accessReadAndWriteRolesIds.length === 0
                  )
                  || (
                    fieldConfig.accessOnlyReadRolesIds.length !== 0
                    && !fieldConfig.accessOnlyReadRolesIds.includes(currentRole.id)
                    && fieldConfig.accessReadAndWriteRolesIds.length !== 0
                    && !fieldConfig.accessReadAndWriteRolesIds.includes(currentRole.id)
                  )
                ) return null

                if (fieldConfig.type === 'boolean') {
                  return (
                    <BooleanField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'boolean-check') {
                  return (
                    <BooleanCheckField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'date') {
                  return (
                    <DateField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'datetime') {
                  return (
                    <DatetimeField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'time') {
                  return (
                    <TimeField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'time-seconds') {
                  return (
                    <TimeSecondsField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'multiselect') {
                  return (
                    <MultiselectField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'select') {
                  return (
                    <SelectField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'document-select') {
                  const handbk = handbkList.find((hb) => hb.documentConfigId === fieldConfig.documentConfigLinkId);

                  return (
                    <DocumentSelectField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      handbk={handbk}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      openModal={openDocumentSearchModal}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'document-multiselect') {
                  const handbk = handbkList.find((hb) => hb.documentConfigId === fieldConfig.documentConfigLinkId);

                  return (
                    <DocumentMultiselectField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      handbk={handbk}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      openModal={openDocumentSearchModal}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'handbk-select') {
                  const handbk = handbkStore.find((h) => h.handbkId === fieldConfig.handbkId);

                  return (
                    <HandbkSelectField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      handbk={handbk}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'handbk-radio') {
                  const handbk = handbkStore.find((h) => h.handbkId === fieldConfig.handbkId);

                  return (
                    <HandbkRadioField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      handbk={handbk}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'handbk-multiselect') {
                  const handbk = handbkStore.find((h) => h.handbkId === fieldConfig.handbkId);

                  return (
                    <HandbkMultiselectField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      handbk={handbk}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'handbk-check') {
                  const handbk = handbkStore.find((h) => h.handbkId === fieldConfig.handbkId);

                  return (
                    <MulticheckField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      handbk={handbk}
                      value={formValue[fieldConfig.key] || []}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'handbk-remote-select') {
                  const handbkRemote = handbkRemoteStore.find((h) => h.id === fieldConfig.handbkRemoteId);

                  return (
                    <HandbkRemoteSelectField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      handbkRemote={handbkRemote}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'handbk-remote-multiselect') {
                  const handbkRemote = handbkRemoteStore.find((h) => h.id === fieldConfig.handbkRemoteId);

                  return (
                    <HandbkRemoteMultiselectField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      handbkRemote={handbkRemote}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'handbk-remote-radio') {
                  const handbkRemote = handbkRemoteStore.find((h) => h.id === fieldConfig.handbkRemoteId);

                  return (
                    <HandbkRemoteRadioField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      handbkRemote={handbkRemote}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'handbk-remote-check') {
                  const handbkRemote = handbkRemoteStore.find((h) => h.id === fieldConfig.handbkRemoteId);

                  return (
                    <HandbkRemoteCheckField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      handbkRemote={handbkRemote}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'number' || fieldConfig.type === 'number-string') {
                  return (
                    <NumberField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'year') {
                  return (
                    <YearField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'month') {
                  return (
                    <MonthField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'month-year') {
                  return (
                    <MonthYearField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'big-int') {
                  return (
                    <NumberField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'decimal' || fieldConfig.type === 'decimal-string') {
                  return (
                    <DecimalField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'phone') {
                  return (
                    <PhoneField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'gender') {
                  return (
                    <GenderField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      value={formValue[fieldConfig.key] || ''}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'long-text') {
                  return (
                    <LongTextField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'snils') {
                  return (
                    <SnilsField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'passport') {
                  return (
                    <PassportField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else if (fieldConfig.type === 'file') {
                  return (
                    <FileField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      documentConfig={documentConfig}
                      onChange={changeFormValue}
                      value={formValue[fieldConfig.key]}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                } else {
                  return (
                    <TextField
                      key={fieldConfig.key}
                      fieldConfig={fieldConfig}
                      value={formValue[fieldConfig.key]}
                      onChange={changeFormValue}
                      error={setError(fieldConfig.key)}
                      disabled={disabled}
                    />
                  );
                }
              })}

              <ButtonsBrick>
                <ButtonSaveBlock
                  loading={submitting}
                  onClick={onSubmitForm}
                  disabled={!formDirty}
                />
                <ButtonCancelBlock onClick={onCancelHandler}/>
              </ButtonsBrick>
            </>
          </form>
        </div>
        <DocumentSearchFeature
          opened={openedDocumentSearchModal}
          closeModal={closeDocumentSearchModal}
          canBeUsed
        />
      </div>
    </div>
 );
} 
