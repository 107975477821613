export const errorTranslate = (error: string) => {
  if (error.includes('should not be empty')) {
    return 'Поле обязательно для заполнения';
  }

  if (error.includes('must be an email')) {
    return 'Укажите корректную электронную почту';
  }

  if (error.includes('incorrect username or password')) {
    return 'Некорректные имя пользователя или пароль';
  }

  if (error.includes('not valid phone')) {
    return 'Укажите корректный телефон';
  }

  if (error.includes('not valid snils')) {
    return 'Укажите корректный СНИЛС';
  }

  if (error.includes('not valid year')) {
    return 'Укажите корректный год';
  }

  if (error.includes('not valid month')) {
    return 'Укажите корректный месяц';
  }

  if (error.includes('not valid passport')) {
    return 'Укажите корректные серию и номер паспорта';
  }

  if (error.includes('document already exist!')) {
    return 'Документ с таким ключом уже существует';
  }

  if (error.includes('field already exist!')) {
    return 'Поле с таким ключом уже существует';
  }

  if (error.includes('key must be shortly')) {
    return 'Ключ не должен превышать 50 символов';
  }

  if (error.includes('integer out of range')) {
    return 'Число выходит за допустимые пределы';
  }

  if (error.includes('decimal out of range')) {
    return 'Число выходит за допустимые пределы';
  }

  if (error.includes('big-int out of range')) {
    return 'Число выходит за допустимые пределы';
  }

  if (error.includes('not valid time-seconds')) {
    return 'Укажите корректные время и секунды';
  }

  if (error.includes('not valid time')) {
    return 'Укажите корректное время';
  }

  if (error.includes('should be unique')) {
    return 'Такое значение уже существует';
  }

  return error;
};
