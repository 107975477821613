import { SyntheticEvent } from 'react';

import {
  TransitionOpacityBrick,
  ButtonSaveBlock,
  ButtonCancelBlock,
  FieldSelectBlock,
  TableBrick,
  TableBodyBrick,
  TableCellBrick,
  TableContainerBrick,
  TableRowBrick,
} from '@app/components';

import {
  useMainApi,
  useDocumentAccessApi,
} from '../../context';

import styles from './document-access-update.module.scss';


export const DocumentAccessUpdateComponent = () => {
  const {
    roleDetailIsSubmitting,
    roleCurrent,
    documentConfigList,
    isEditingDocumentAccess,
  } = useMainApi();

  const {
    accessOptions,
    formValue,
    formDirty,
    changeFormValue,
    onSubmit,
    onDone,
  } = useDocumentAccessApi();

  if (formValue === null) return null;

  return (
    <>
      {roleCurrent && isEditingDocumentAccess && (
        <TransitionOpacityBrick>
          <div className={styles['document-access-update']}>
            <form onSubmit={(e: SyntheticEvent) => onSubmit(e)}>
              <TableContainerBrick className={styles['document-access-update__table-container']}>
                <TableBrick aria-label="department-detail">
                  <TableBodyBrick>
                    {documentConfigList.map((documentConfig) => (
                      <TableRowBrick key={documentConfig.key}>
                        <TableCellBrick component="th" scope="row">{documentConfig.label.singular}</TableCellBrick>
                        <TableCellBrick>
                          <FieldSelectBlock
                            value={formValue[documentConfig.key + '_CREATE']}
                            name={documentConfig.key + '_CREATE'}
                            label="Создание"
                            onChange={changeFormValue}
                            items={[
                              { value: 'NEVER', label: 'Нет' },
                              { value: 'ALL', label: 'Да' },
                            ]}
                            hideError
                          />
                        </TableCellBrick>
                        <TableCellBrick>
                          <FieldSelectBlock
                            value={formValue[documentConfig.key + '_READ']}
                            name={documentConfig.key + '_READ'}
                            label="Чтение"
                            onChange={changeFormValue}
                            items={accessOptions}
                            hideError
                          />
                        </TableCellBrick>
                        <TableCellBrick>
                          <FieldSelectBlock
                            value={formValue[documentConfig.key + '_UPDATE']}
                            name={documentConfig.key + '_UPDATE'}
                            label="Обновление"
                            onChange={changeFormValue}
                            items={accessOptions}
                            hideError
                          />
                        </TableCellBrick>
                        <TableCellBrick>
                          <FieldSelectBlock
                            value={formValue[documentConfig.key + '_DELETE']}
                            name={documentConfig.key + '_DELETE'}
                            label="Удаление"
                            onChange={changeFormValue}
                            items={accessOptions}
                            hideError
                          />
                        </TableCellBrick>
                      </TableRowBrick>
                    ))}
                  </TableBodyBrick>
                </TableBrick>
              </TableContainerBrick>
              <div className={styles['document-access-update__buttons']}>
                <ButtonSaveBlock
                  loading={roleDetailIsSubmitting}
                  onClick={onSubmit}
                  disabled={!formDirty}
                />
                <ButtonCancelBlock onClick={() => onDone()} />
              </div>
            </form>
          </div>
        </TransitionOpacityBrick>
      )}
    </>
  );
};
