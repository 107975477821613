import { SyntheticEvent, useState } from 'react';
import { AppBar, Button, DialogContent, IconButton, Toolbar } from '@mui/material';

import { documentConfigCreateAction } from '@app/stores';

import {
  ChangeFieldEventType,
} from '@app/types';

import {
  PlusIcon,
  CloseIcon,
  WidgetLinkBrick,
  ButtonSaveBlock,
  ButtonCancelBlock,
  FieldSelectBlock,
  FieldTextBlock,
  CardDataComposition,
  DialogBrick,
} from '@app/components';

import {
  errorTranslate,
  translitTextHelper,
} from '@app/helpers';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

import styles from './document-config-create.module.scss';
import { DocumentConfigCreateProps } from './document-config-create.props';


export const DocumentConfigCreateFeature = ({ parentKey }: DocumentConfigCreateProps) => {
  const initialFormValue = {
    singularLabel: '',
    pluralLabel: '',
    genitiveLabel: '',
    key: '',
    parentKey,
    type: parentKey === 'root' ? 'form-list' : '',
    parentId: 1,
  };
  const [opened, setOpened] = useState(false);
  const [formValue, setFormValue] = useState({...initialFormValue});
  const [sendedForm, setSendedForm] = useState(false);
  const [formDirty, setFormDirty] = useState(false);
  const dispatch = useAppDispatch();
  const configuration = useAppSelector((state) => state.configuration);

  const changeFormValue = (e: ChangeFieldEventType): void => {
    setFormDirty(true);
    setFormValue((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name === 'singularLabel') {
      setFormValue((state) => ({
        ...state,
        key: translitTextHelper((e.target.value as string)),
      }));
    }

    if (e.target.name === 'key') {
      setFormValue((state) => ({
        ...state,
        key: (e.target.value as string).replace(/[^a-zA-Z0-9-]/g, ''),
      }));
    }
  };

  const closeForm = () => {
    setOpened(false);
    setFormDirty(false);
    setFormValue({...initialFormValue});
    setSendedForm(false);
  }

  const onSubmit = async (e?: SyntheticEvent) => {
    if (e) e.preventDefault();
    setSendedForm(true);
    const result = await dispatch(documentConfigCreateAction(formValue));

    if (result.type === '@@configuration/document-config-create/fulfilled') {
      closeForm();
    }
  }

  const setError = (fieldName: string) => {
    return sendedForm
      ? configuration.errors && configuration.errors[fieldName] && errorTranslate(configuration.errors[fieldName][0])
      : '';
  }

  return (
    <>
      {parentKey === 'root' && (
        <WidgetLinkBrick
          title="Новый документ"
          onClick={() => setOpened(true)}
          icon={<PlusIcon />}
        />
      )}

      {parentKey !== 'root' && (
        <div className={styles['button-create']}>
          <Button
            variant="outlined"
            startIcon={<PlusIcon />}
            onClick={() => setOpened(true)}
          >
            Добавить
          </Button>
        </div>
      )}

      <DialogBrick
        size="fullscreen"
        opened={opened}
        onClose={() => setOpened(false)}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpened(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Новая коллекция документов
            </Typography> */}
          </Toolbar>
        </AppBar>
          <div className={styles['document-create']}>
            <DialogContent>
              <div className="container">
                <CardDataComposition title="Новая коллекция документов">
                  <div className="row justify-content-lg-center">
                    <div className="col col-lg-6">
                      <form
                        className={styles['document-create__form']}
                        onSubmit={(e: SyntheticEvent) => onSubmit(e)}
                      >
                        <FieldSelectBlock
                          name="type"
                          label="Тип"
                          value={formValue.type}
                          onChange={changeFormValue}
                          error={setError('type')}
                          items={[{ value: 'form-one', label: 'Форма' }, { value: 'form-list', label: 'Коллекция форм' }]}
                          disabled={parentKey === 'root'}
                        />

                        <FieldTextBlock
                          name="singularLabel"
                          label="Название единственном числе"
                          value={formValue.singularLabel}
                          onChange={changeFormValue}
                          error={setError('singularLabel')}
                        />

                        {formValue.type === 'form-list' && <FieldTextBlock
                          name="pluralLabel"
                          label="Название множественном числе"
                          value={formValue.pluralLabel}
                          onChange={changeFormValue}
                          error={setError('pluralLabel')}
                        />}

                        {formValue.type === 'form-list' && <FieldTextBlock
                          name="genitiveLabel"
                          label="Название родительном падеже"
                          value={formValue.genitiveLabel}
                          onChange={changeFormValue}
                          error={setError('genitiveLabel')}
                        />}

                        <FieldTextBlock
                          name="key"
                          label="Ключ"
                          value={formValue.key}
                          onChange={changeFormValue}
                          error={setError('key')}
                        />

                        <ButtonSaveBlock
                          loading={configuration.status === 'submitting'}
                          onClick={onSubmit}
                          disabled={!formDirty}
                        />

                        <ButtonCancelBlock onClick={() => closeForm()} />
                      </form>
                    </div>
                  </div>
                </CardDataComposition>
              </div>
            </DialogContent>
          </div>
      </DialogBrick>
    </>
  )
}
