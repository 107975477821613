import {
  FormFieldBrick,
  AsteriskBrick,
  DateTimePickerBrick,
} from '@app/components';

import { FieldDateProps } from './field-datetime.props';


export const FieldDatetimeBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  required,
  counted,
  prompt,
  disabled,
}: FieldDateProps) => {

  return (
    <FormFieldBrick error={error} prompt={prompt}>
      <DateTimePickerBrick
        label={<>{label}<AsteriskBrick required={required} counted={counted} /></>}
        value={value}
        onChange={onChange}
        name={name}
        ampm={false}
        ampmInClock={false}
        disabled={disabled}
      />
    </FormFieldBrick>
  );
};
