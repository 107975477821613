import React from 'react';

import {
  MainContextInterface,
  useMainHook,
} from './main.hook';


const MainContext = React.createContext<MainContextInterface | undefined>(undefined);

export const useMainApi = () => {
  const context = React.useContext(MainContext);

  if (context === undefined) {
    throw new Error('useMainApi must be used within MainProvider');
  }

  return context;
}

export interface MainProviderProps {
  children: React.ReactNode,
  value: string;
  onChange: (value: string) => void; 
};

export const MainProvider = ({
  children,
  value,
  onChange,
}: MainProviderProps) => {
  const api = useMainHook(value, onChange);
  
  return (
    <MainContext.Provider value={api}>
      {children}
    </MainContext.Provider>
  )
}
