import {
  FormFieldBrick,
  FormGroupBrick,
  CheckboxWithLabelBrick,
} from '@app/components';

import { FieldBooleanCheckProps } from './field-boolean-check.props';


export const FieldBooleanCheckBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  hideError,
  required,
  counted,
  prompt,
  disabled,
}: FieldBooleanCheckProps) => {
  return (
    <FormFieldBrick error={error} hideError={hideError} prompt={prompt}>
      <FormGroupBrick>
        <CheckboxWithLabelBrick
          name={name}
          checked={!!value}
          onChange={(e) => onChange({ target: { name, value: e.target.checked } })}
          label={label}
          disabled={disabled}
        />
      </FormGroupBrick>
    </FormFieldBrick>
  );
};
