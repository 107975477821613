import { createAsyncThunk } from '@reduxjs/toolkit';
import { HandbkDocumentApi, PrintFormApi } from '@app/api';

import {
  callNotifyAction,
} from '@app/stores';

import { PrintFormService } from '@app/services';

import {
  PrintFormType,
  PrintFormStateInterface,
  PrintFormRefreshTemplateImagesResult,
  PrintFormRefreshTemplateImagesPayload,
  DocumentStateInterface,
  ConfigurationStateInterface,
} from '@app/types';

import {
  PrintFormBuildTemplateRequest,
  PrintFormCreateRequest,
  PrintFormDeleteRequest,
  PrintFormDeleteResponse,
  PrintFormListRequest,
  PrintFormListResponse,
  PrintFormUpdateRequest,
  UpdateMarginsRequest,
  UpdateTemplateRequest,
} from '@app/api';

import { PrintFormUploadDocxTemplatePayload } from '@app/types/print-form/print-form-upload-docx-template.payload';
import { PrintFormUploadDocxTemplateResult } from '@app/types/print-form/print-form-upload-docx-template.result';
import { findDocumentHandbkIds } from '@app/helpers/find-document-handbk-ids.helper';


export const openPrintFormWindowAction = createAsyncThunk<
  PrintFormListResponse,
  PrintFormListRequest,
  { state: { printForm: PrintFormStateInterface } }
>(
  '@@print-form/open-print-form-window',
  async (payload: PrintFormListRequest, { rejectWithValue, dispatch }) => {
    const response = await PrintFormApi.getListByDocumentConfig(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка чтения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data;
  },
  {
    condition: (request, { getState }) => {
      const { printForm } = getState();

      if (printForm.status === 'loading') {
        return false;
      }

      return true;
    }
  },
);

export const createPrintFormAction = createAsyncThunk<
  PrintFormType,
  PrintFormCreateRequest,
  { state: { printForm: PrintFormStateInterface } }
>(
  '@@print-form/create',
  async (payload: PrintFormCreateRequest, { rejectWithValue, dispatch }) => {
    const response = await PrintFormApi.create(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data.one;
  },
  {
    condition: (_, { getState }) => {
      const { printForm } = getState();

      if (printForm.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const updatePrintFormAction = createAsyncThunk<
  PrintFormType,
  PrintFormUpdateRequest,
  { state: { printForm: PrintFormStateInterface } }
>(
  '@@print-form/update',
  async (payload: PrintFormUpdateRequest, { rejectWithValue, dispatch }) => {
    const response = await PrintFormApi.update(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data.one;
  },
  {
    condition: (_, { getState }) => {
      const { printForm } = getState();

      if (printForm.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const updatePrintFormMarginsAction = createAsyncThunk<
  PrintFormType,
  UpdateMarginsRequest,
  { state: { printForm: PrintFormStateInterface } }
>(
  '@@print-form/update-margins',
  async (payload: UpdateMarginsRequest, { rejectWithValue, dispatch }) => {
    const response = await PrintFormApi.updateMargins(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data.one;
  },
  {
    condition: (_, { getState }) => {
      const { printForm } = getState();

      if (printForm.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const updatePrintFormTemplateAction = createAsyncThunk<
  PrintFormType,
  UpdateTemplateRequest,
  { state: { printForm: PrintFormStateInterface } }
>(
  '@@print-form/update-template',
  async (payload: UpdateTemplateRequest, { rejectWithValue, dispatch }) => {
    const response = await PrintFormApi.updateTemplate(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data.one;
  },
  {
    condition: (_, { getState }) => {
      const { printForm } = getState();

      if (printForm.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const deletePrintFormAction = createAsyncThunk<
  PrintFormDeleteResponse,
  PrintFormDeleteRequest,
  { state: { printForm: PrintFormStateInterface } }
>(
  '@@print-form/delete',
  async (payload: PrintFormDeleteRequest, { rejectWithValue, dispatch }) => {
    dispatch(callNotifyAction({ type: 'info', message: 'loading' }));
    const response = await PrintFormApi.delete(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка удаления данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успешно удалены!' }));

    return response.data;
  },
);

export const printFormBuildTemplateAction = createAsyncThunk<
  void,
  PrintFormBuildTemplateRequest,
  {
    state: {
      printForm: PrintFormStateInterface,
      document: DocumentStateInterface,
      configuration: ConfigurationStateInterface,
    },
  }

>(
  '@@print-form/build-temlpate',
  async (payload: PrintFormBuildTemplateRequest, { getState, rejectWithValue, dispatch }) => {
    const { configuration } = getState();
    const buildResponse = await PrintFormApi.buildTemplate(payload);
    
    if (!buildResponse.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка чтения данных!' }));
      return rejectWithValue(buildResponse.errors);
    }

    const ids = findDocumentHandbkIds(
      configuration,
      payload.printForm.documentConfigId,
    );

    const result = await HandbkDocumentApi.list({
      documentConfigIdList: ids,
    });

    PrintFormService.build(
      {
        ...payload.printForm,
        template: buildResponse.data.template,
      },
      buildResponse.data.queries,
      configuration,
      result.data.handbkList,
    );
  },
);

export const openTemplateEditorAction = createAsyncThunk<
  PrintFormRefreshTemplateImagesResult,
  PrintFormRefreshTemplateImagesPayload,
  { state: { printForm: PrintFormStateInterface } }
>(
  '@@print-form/open-template-editor',
  async (payload: PrintFormRefreshTemplateImagesPayload, { rejectWithValue, dispatch }) => {
    return payload;
    // if (payload.printForm.template.indexOf('<img') === -1) {
    //   return payload;
    // }

    // const response = await PrintFormApi.refreshTemplateImages(payload.printForm.template);
    
    // if (!response.success) {
    //   dispatch(callNotifyAction({
    //     type: 'error',
    //     message: 'Ошибка получения данных!',
    //   }));

    //   return rejectWithValue(response.errors);
    // }

    // return {
    //   printForm: {
    //     ...payload.printForm,
    //     template: response.data.template,
    //   },
    // };
  },
);

export const uploadDocxTemplateAction = createAsyncThunk<
  PrintFormUploadDocxTemplateResult,
  PrintFormUploadDocxTemplatePayload,
  { state: { printForm: PrintFormStateInterface } }
>(
  '@@print-form/upload-docx-template',
  async (payload: PrintFormUploadDocxTemplatePayload, { rejectWithValue, dispatch }) => {
    const response = await PrintFormApi.uploadDocxTemplate(
      payload.printFormId,
      payload.file,
    );

    if (!response.success) {
      dispatch(callNotifyAction({
        type: 'error',
        message: 'Ошибка загрузки шаблона!',
      }));

      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Шаблон успешно загружен.' }));

    return response.data;
  },
);
