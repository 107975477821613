import {
  TransitionOpacityBrick,
  TableContainerBrick,
  TableBrick,
  TableHeadBrick,
  TableBodyBrick,
  TableRowBrick,
  TableCellBrick,
} from '@app/components';

import {
  dateTranslate,
} from '@app/helpers';

import {
  useMainApi,
} from '../../context';

export const TableComponent = () => {
  const {
    isEditing,
    departmentCurrent,
  } = useMainApi();

  return (
    <>
      {departmentCurrent && !isEditing && (
        <TransitionOpacityBrick>
          <TableContainerBrick>
            <TableBrick aria-label="department-detail">
              <TableHeadBrick>
                <TableRowBrick>
                  <TableCellBrick>ID</TableCellBrick>
                  <TableCellBrick>Название</TableCellBrick>
                  <TableCellBrick>Дата создания</TableCellBrick>
                  <TableCellBrick>Дата обновления</TableCellBrick>
                </TableRowBrick>
              </TableHeadBrick>
              <TableBodyBrick>
                <TableRowBrick
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCellBrick component="th" scope="row">
                    {departmentCurrent.id}
                  </TableCellBrick>
                  <TableCellBrick>{departmentCurrent.name}</TableCellBrick>
                  <TableCellBrick>{dateTranslate(departmentCurrent.createdAt)}</TableCellBrick>
                  <TableCellBrick>{dateTranslate(departmentCurrent.updatedAt)}</TableCellBrick>
                </TableRowBrick>
              </TableBodyBrick>
            </TableBrick>
          </TableContainerBrick>
        </TransitionOpacityBrick>
      )}
    </>
  );
};
