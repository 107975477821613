import { MainComponent } from './components';

import { MainProvider } from './context';
import { ImageLibraryProps } from './image-library.props';


export const ImageLibrarySmart = ({
  opened,
  onSelect,
  onClose,
}: ImageLibraryProps) => {
  return (
    <MainProvider
      opened={opened}
      onSelect={onSelect}
      onClose={onClose}
    >
      <MainComponent />
    </MainProvider>
  );
}
