import { createAsyncThunk } from '@reduxjs/toolkit';

import { callNotifyAction } from '@app/stores';

import {
  ConfigurationStateInterface,
} from '@app/types';

import {
  FieldConfigApi,
  FieldConfigCreateRequest,
  FieldConfigCreateResponse,
  FieldConfigDeleteRequest,
  FieldConfigDeleteResponse,
  FieldConfigUpdateRequest,
  FieldConfigUpdateResponse,
} from '@app/api';


export const fieldConfigCreateAction = createAsyncThunk<
  FieldConfigCreateResponse,
  FieldConfigCreateRequest,
  { state: { configuration: ConfigurationStateInterface } }
>(
  '@@configuration/field-config-create',
  async (payload: FieldConfigCreateRequest, { rejectWithValue, dispatch }) => {
    const response = await FieldConfigApi.create(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { configuration } = getState();

      if (configuration.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const fieldConfigUpdateAction = createAsyncThunk<
  FieldConfigUpdateResponse,
  FieldConfigUpdateRequest,
  { state: { configuration: ConfigurationStateInterface } }
>(
  '@@configuration/field-config-update',
  async (payload: FieldConfigUpdateRequest, { rejectWithValue, dispatch }) => {
    const response = await FieldConfigApi.update(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));
    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { configuration } = getState();

      if (configuration.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const fieldConfigDeleteAction = createAsyncThunk<
  FieldConfigDeleteResponse,
  FieldConfigDeleteRequest,
  { state: { configuration: ConfigurationStateInterface } }
>(
  '@@configuration/field-config-delete',
  async (payload: FieldConfigDeleteRequest, { rejectWithValue, dispatch }) => {
    dispatch(callNotifyAction({ type: 'info', message: 'loading' }));
    const response = await FieldConfigApi.delete(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка удаления данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено удалены!' }));
    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { configuration } = getState();

      if (configuration.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);
