import { useModalsApi } from '../../context';
import { PrintFormMarginsSmart } from '@app/components';


export const MarginsComponent = () => {
  const {
    marginsDialogOpened,
    marginsDialogClose,
  } = useModalsApi();

  return (
    <PrintFormMarginsSmart
      opened={marginsDialogOpened}
      onClose={marginsDialogClose}
    />
  );
}
