import { FieldRadioBlock } from '@app/components';
import { HandbkRadioProps } from './handbk-radio.props';


export const HandbkRadioField = ({
  fieldConfig,
  handbk,
  value,
  onChange,
  error,
  disabled,
}: HandbkRadioProps) => {
  if (!handbk) return (<p key={fieldConfig.key} >Ошибка словаря</p>);
  const variants: {
    value: number;
    label: string;
  }[] = handbk.sortable.map((variantId) => {
    const variant = handbk.variants.find((v) => v.id === variantId);

    return {
      value: variant!.id,
      label: variant!.label,
    };
  }).filter((v) => v !== null);

  return (
    <FieldRadioBlock
      key={fieldConfig.key}
      name={fieldConfig.key}
      label={fieldConfig.label}
      value={value}
      onChange={onChange}
      error={error}
      items={variants}
      counted={fieldConfig.counted}
      required={fieldConfig.required}
      prompt={fieldConfig.prompt}
      disabled={disabled}
    />
  );
};
