import React from 'react';

import { handbkUpdateAction } from '@app/stores';

import {
  ChangeFieldEventType,
  HandbkConfigType,
} from '@app/types';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

import { errorTranslate } from '@app/helpers';
import { useMainApi } from '../../context';

export interface GeneralContextInterface {
  formValue: HandbkConfigType | null;
  formDirty: boolean;
  onSubmit: (e?: React.SyntheticEvent) => Promise<void>;
  changeFormValue: (e: ChangeFieldEventType) => void;
  closeForm: () => void;
  setError: (fieldname: string) => string | null;
};

export const useGeneralHook = (
): GeneralContextInterface => {
  const {
    handbk,
    isEdited,
    isEditedSet,
  } = useMainApi();

  const dispatch = useAppDispatch();
  const [formValue, formValueSet] = React.useState<HandbkConfigType | null>(null);
  const [formDirty, formDirtySet] = React.useState(false);
  const [sendedForm, sendedFormSet] = React.useState(false);
  const errors = useAppSelector((s) => s.configuration.errors);
  
  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      if (state === null) return null;

      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const closeForm = React.useCallback(() => {
    sendedFormSet(false);
    formDirtySet(false);
    isEditedSet(false);
  }, [
    sendedFormSet,
    isEditedSet,
  ]);

  const onSubmit = React.useCallback(async (e?: React.SyntheticEvent) => {
    if (e) e.preventDefault();
    if (formValue === null) return; 
    
    sendedFormSet(true);

    const result = await dispatch(handbkUpdateAction({
      id: formValue.handbkId,
      name: formValue.name,
      description: formValue.description,
    }));

    if (result.type === '@@configuration/handbk-update/fulfilled') {
      closeForm();
    }
  }, [
    formValue,
    dispatch,
    sendedFormSet,
    closeForm,
  ]);

  const setError = React.useCallback((fieldName: string) => {
    return sendedForm
      ? errors && errors[fieldName] && errorTranslate(errors[fieldName][0])
      : '';
  }, [
    errors,
    sendedForm,
  ]);

  React.useEffect(() => {
    if (isEdited) formValueSet(handbk);
  }, [
    handbk,
    isEdited,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    onSubmit,
    changeFormValue,
    closeForm,
    setError,
  }), [
    formValue,
    formDirty,
    onSubmit,
    changeFormValue,
    closeForm,
    setError,
  ]);
};
