import { ImageLibrarySmart } from '@app/components';

import { useMainApi, useModalsApi } from '../../context';


export const ImageLibraryComponent = () => {
  const {
    imageLibraryDialogOpened,
    imageLibraryDialogClose,
  } = useModalsApi();

  const { addImage } = useMainApi();
  

  return (
    <ImageLibrarySmart
      opened={imageLibraryDialogOpened}
      onClose={imageLibraryDialogClose}
      onSelect={addImage}
    />
  );
}
