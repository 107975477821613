import React from 'react';

import {
  UserAccessContextInterface,
  useUserAccessHook,
} from './user-access.hook';

const UserAccessContext = React.createContext<UserAccessContextInterface | undefined>(undefined);

export const useUserAccessApi = () => {
  const context = React.useContext(UserAccessContext);

  if (context === undefined) {
    throw new Error('useUserAccessApi must be used within UserAccessProvider');
  }

  return context;
}

export const UserAccessProvider = (
  props: React.HTMLAttributes<HTMLDivElement>
) => {
  const api = useUserAccessHook();

  return (
    <UserAccessContext.Provider value={api}>
      {props.children}
    </UserAccessContext.Provider>
  );
};
