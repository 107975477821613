import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CSS } from '@dnd-kit/utilities';
import { DndContext } from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';

import {
  setBreadcrumbs,
  documentConfigSortableChildrensAction,
} from '@app/stores';

import {
  DocumentConfigType,
} from '@app/types';

import {
  DragIcon,
  TransitionOpacityBrick,
  PageBaseLayout,
  DocumentListFeature,
  DocumentCreateFeature,
  DocumentOneFeature,
  DocumentConfigCreateFeature,
  PrintFormListFeature,
  PrintFormEditorFeature,
} from '@app/components';

import { getBreacrumbsByPathElements, getPathElementsByPathname } from '@app/helpers';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

import styles from './document.module.scss';


const SortableItem = ({ documentConfig, parentId }: { documentConfig: DocumentConfigType, parentId: number }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: documentConfig.documentConfigId});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if (documentConfig.type === 'form-list') {
    return (
      <div
        className={styles['document__sortable-item']}
        {...attributes}
        style={{...style}}
        ref={setNodeRef}
      >
        <DocumentListFeature
          documentConfig={documentConfig}
          parentId={parentId}
          dragHandle={
            <div className={styles['document__drag']} {...listeners}>
              <DragIcon />
            </div>
          }
        />
      </div>
    );
  }

  if (documentConfig.type === 'form-one') {
    return (
      <div
        className={styles['document__sortable-item']}
        {...attributes}
        style={{...style}}
        ref={setNodeRef}
      >
        <DocumentOneFeature
          documentConfig={documentConfig}
          parentId={parentId}
          isChildren
          dragHandle={
            <div className={styles['document__drag']} {...listeners}>
              <DragIcon />
            </div>
          }
        />
      </div>
    );
  }

  return null;
}

export const DocumentPage = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const configuration = useAppSelector((state) => state.configuration);
  const currentRole = useAppSelector((state) => state.auth.user!.role);
  const pathElements = getPathElementsByPathname(location.pathname);
  const breadcrumbs = getBreacrumbsByPathElements(pathElements, configuration);
  const currentElement = pathElements[pathElements.length - 1];

  const currentViewType = pathElements[pathElements.length - 1].value;
  const viewIsList = currentViewType === 'list';
  const viewIsCreate = currentViewType === 'create';
  const viewIsDetail = (currentViewType !== 'create' && currentViewType !== 'list');

  const currentDocumentKey = pathElements[pathElements.length - 1].key;
  
  const currentDocumentConfig = configuration.documents.find((document) => document.key === currentDocumentKey);
  const parentId = currentDocumentConfig!.parentKey === 'root' ? undefined : Number(pathElements[pathElements.length - 2].value);
  
  const onDragEnd = async (e: any) => {
    if (
      e.over === null ||
      e.active.data.current.sortable.index === e.over.data.current.sortable.index
    ) return;

    const sortable = [...currentDocumentConfig!.sortableChildrens];

    const sortableToUp = e.active.data.current.sortable.index > e.over.data.current.sortable.index;
    const dragableIndex = e.active.data.current.sortable.index;
    const endIndex = e.over.data.current.sortable.index;

    sortable.splice(endIndex + (sortableToUp ? 0 : 1), 0, sortable[dragableIndex]);
    sortable.splice(dragableIndex + (sortableToUp ? 1 : 0), 1);

    await dispatch(documentConfigSortableChildrensAction({
      documentConfigId: currentDocumentConfig!.documentConfigId,
      sortable,
    }));
  }

  useEffect(() => {
    dispatch(setBreadcrumbs(breadcrumbs));
  }, [dispatch, location.pathname, breadcrumbs]);

  return (
    <div className={styles['document']}>
      <PageBaseLayout breadcrumbs={breadcrumbs}>
        <TransitionOpacityBrick>
          {viewIsList && (
            <DocumentListFeature documentConfig={currentDocumentConfig!} />
          )}
          {viewIsCreate && (
            <DocumentCreateFeature documentConfig={currentDocumentConfig!} parentId={parentId} />
          )}
          {viewIsDetail && (
            <>
              <div className={styles['document__feature-wrapper']}>
                <DocumentOneFeature documentConfig={currentDocumentConfig!} id={Number(currentElement.value)} />
              </div>

              <DndContext onDragEnd={onDragEnd}>
                <SortableContext items={currentDocumentConfig!.sortableChildrens}>
                  {
                    currentDocumentConfig!.sortableChildrens.map((childrenId) => {
                      const documentConfig = configuration.documents.find((docuemntConfig) => docuemntConfig.documentConfigId === childrenId);

                      if (!documentConfig) {
                        return null;
                      }

                      const invisible = currentRole[`${documentConfig.key}_READ`] === 'NEVER' && currentRole[`${documentConfig.key}_CREATE`] === 'NEVER';

                      if (invisible) {
                        return null;
                      }

                      return (
                        <SortableItem
                          key={documentConfig.key}
                          documentConfig={documentConfig}
                          parentId={Number(currentElement.value)}
                        />
                      );
                    })
                  }
                </SortableContext>
              </DndContext>

              {currentRole.CONFIGURATOR_ACCESS && (
                <DocumentConfigCreateFeature parentKey={currentDocumentConfig!.key} />
              )}
            </>
          )}
        </TransitionOpacityBrick>

        <PrintFormListFeature />
        <PrintFormEditorFeature />
      </PageBaseLayout>
    </div>
  );
};
