import cs from 'classnames';

import { ButtonsBrick } from '@app/components';

import { FormFullProps } from './form-centered.props';
import styles from './form-centered.module.scss';


export const FormCenteredBlock = ({
 children,
 buttons,
 onSubmit,
}: FormFullProps) => {

  const csCol = cs(
    styles['form-centered'],
    ['col'],
  );

  return (
    <div className={csCol}>
      <div className="row justify-content-lg-center">
        <div className="col col-lg-6">
          <form onSubmit={onSubmit}>
            <div className={styles['form-centered__content']}>
              {children}
            </div>
            <div className={styles['form-centered__actions']}>
              <ButtonsBrick>
                {buttons}
              </ButtonsBrick>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}