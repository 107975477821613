import {
  TransitionOpacityBrick,
  ButtonCancelBlock,
  ButtonSaveBlock,
  FieldBooleanBlock,
  FieldSelectBlock,
  FieldTextBlock,
} from '@app/components';

import {
  useMainApi,
  useUpdateApi,
} from '../../context';

import styles from './update.module.scss';


export const UpdateComponent = () => {
  const {
    isEditing,
    editorClose,
  } = useMainApi();

  const {
    formValue,
    formDirty,
    formIsSubmitting,
    roleDictionary,
    departmentDictionary,
    onSubmit,
    changeFormValue,
    setError,
  } = useUpdateApi();

  if  (formValue === null || !isEditing) return null;

  return (
    <TransitionOpacityBrick>
      <div className="row justify-content-lg-center">
        <div className="col col-lg-6">
          <div className={styles['update']}>
            <form onSubmit={onSubmit}>
              <FieldTextBlock
                name="lastName"
                label="Фамилия"
                value={formValue.lastName}
                onChange={changeFormValue}
                error={setError('lastName')}
                required
              />

              <FieldTextBlock
                name="firstName"
                label="Имя"
                value={formValue.firstName}
                onChange={changeFormValue}
                error={setError('firstName')}
                required
              />

              <FieldTextBlock
                name="secondName"
                label="Отчество"
                value={formValue.secondName}
                onChange={changeFormValue}
                error={setError('secondName')}
              />

              <FieldTextBlock
                name="email"
                label="Электронная почта"
                value={formValue.email}
                onChange={changeFormValue}
                error={setError('email')}
                required
              />

              <FieldSelectBlock
                name="roleId"
                label="Роль"
                value={formValue.roleId}
                onChange={changeFormValue}
                error={setError('roleId')}
                required
                items={roleDictionary.map((item) => ({ value: item.id, label: item.name }))}
              />

              <FieldSelectBlock
                name="departmentId"
                label="Подразделение"
                value={formValue.departmentId}
                onChange={changeFormValue}
                error={setError('departmentId')}
                required
                items={departmentDictionary.map((item) => ({ value: item.id, label: item.name }))}
              />

              <FieldBooleanBlock
                name="blocked"
                label="Заблокирован"
                value={formValue.blocked}
                onChange={changeFormValue}
                error={setError('blocked')}
              />

              <ButtonSaveBlock
                loading={formIsSubmitting}
                onClick={onSubmit}
                disabled={!formDirty}
              />
              <ButtonCancelBlock onClick={editorClose} />
            </form>
          </div>
        </div>
      </div>
    </TransitionOpacityBrick>
  );    
};
