import { FieldMulticheckBlock } from '@app/components';
import { MulticheckProps } from './multicheck.props';


export const MulticheckField = ({
  fieldConfig,
  handbk,
  value,
  onChange,
  error,
  disabled,
}: MulticheckProps) => {
  if (!handbk) return (<p key={fieldConfig.key} >Ошибка словаря</p>);
  const variants = handbk.sortable.map((variantId) => {
    const variant = handbk.variants.find((v) => v.id === variantId);
    return {
      value: variant!.id,
      label: variant!.label,
    };
  });

  return (
    <FieldMulticheckBlock
      key={fieldConfig.key}
      name={fieldConfig.key}
      label={fieldConfig.label}
      value={value}
      onChange={onChange}
      error={error}
      items={variants}
      counted={fieldConfig.counted}
      required={fieldConfig.required}
      prompt={fieldConfig.prompt}
      disabled={disabled}
    />
  );
};
