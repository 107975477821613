import {
  FormFieldBrick,
  AsteriskBrick,
  SelectBrick,
  MenuItemBrick,
} from '@app/components';

import { FieldGenderProps } from './field-gender.props';


export const FieldGenderBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  hideError,
  required,
  counted,
  prompt,
  disabled
}: FieldGenderProps) => {
  return (
    <FormFieldBrick error={error} hideError={hideError} prompt={prompt}>
      <SelectBrick
        name={name}
        value={String(value)}
        onChange={onChange}
        disabled={disabled}
        label={<>{label}<AsteriskBrick required={required} counted={counted} /></>}
      >
        <MenuItemBrick value='м'>Мужской</MenuItemBrick>
        <MenuItemBrick value='ж'>Женский</MenuItemBrick>
      </SelectBrick>
    </FormFieldBrick>
  );
};
