import { HttpService } from '@app/services';

import {
  BaseResponseInterface,
  RoleType,
} from '@app/types';

import { RoleCreateRequest } from './types';


export class RoleApi {
  public static async create(
    payload: RoleCreateRequest,
  ): Promise<BaseResponseInterface<{ role: RoleType }>> {
    return await HttpService.sendRequest('POST', '/role/create', {
      role: payload,
    });
  };

  public static async getList(
  ): Promise<BaseResponseInterface<{ roleList: RoleType[] }>> {
    return await HttpService.sendRequest('GET', '/role/list');
  };

  public static async getDictionary(
  ): Promise<BaseResponseInterface<{ roleList: RoleType[] }>> {
    return await HttpService.sendRequest('GET', '/role/dictionary');
  };

  public static async getOne(
    id: number,
  ): Promise<BaseResponseInterface<{ role: RoleType }>> {
    return await HttpService.sendRequest('GET', '/role/one/' + id);
  };

  public static async update(
    payload: RoleType,
  ): Promise<BaseResponseInterface<{ role: RoleType }>> {
    return await HttpService.sendRequest('PUT', '/role/update', {
      role: payload,
    });
  };
};
