import { FormControl, InputLabel, Select } from '@mui/material';

import { SelectProps } from './select.props';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const SelectBrick = ({
  children,
  name,
  value,
  onChange,
  disabled,
  label,
  multiple,
  renderValue,
}: SelectProps) => {

  return (
    <FormControl variant="filled" fullWidth>
      <InputLabel id={name + '-label'}>{label}</InputLabel>
      <Select
        labelId={name + '-label'}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        multiple={multiple}
        renderValue={renderValue}
        MenuProps={renderValue ? MenuProps : undefined}
      >
        {children}
      </Select>
    </FormControl>
  );
}
