import {
  TransitionOpacityBrick,
  TableBrick,
  TableBodyBrick,
  TableCellBrick,
  TableContainerBrick,
  TableHeadBrick,
  TableRowBrick,
} from '@app/components';

import {
  useMainApi,
} from '../../context';

import {
  accessTranslate,
} from '@app/helpers';

import styles from './user-access-table.module.scss';


export const UserAccessTableComponent = () => {
  const {
    roleCurrent,
    isEditingUserAccess,
  } = useMainApi();

  if (roleCurrent === null) return null;

  return (
    <>
      {roleCurrent && !isEditingUserAccess && (
        <TransitionOpacityBrick>
          <TableContainerBrick className={styles['user-access-table__table-container']}>
            <TableBrick aria-label="role-detail" >
              <TableHeadBrick>
                <TableRowBrick>
                  <TableCellBrick>Таблица</TableCellBrick>
                  <TableCellBrick>Создание</TableCellBrick>
                  <TableCellBrick>Чтение</TableCellBrick>
                  <TableCellBrick>Обновление</TableCellBrick>
                  <TableCellBrick>Удаление</TableCellBrick>
                </TableRowBrick>
              </TableHeadBrick>
              <TableBodyBrick>
                <TableRowBrick>
                  <TableCellBrick component="th" scope="row">
                    Подразделения
                  </TableCellBrick>
                  <TableCellBrick>{accessTranslate(roleCurrent.DEPARTMENT_CREATE)}</TableCellBrick>
                  <TableCellBrick>{accessTranslate(roleCurrent.DEPARTMENT_READ)}</TableCellBrick>
                  <TableCellBrick>{accessTranslate(roleCurrent.DEPARTMENT_UPDATE)}</TableCellBrick>
                  <TableCellBrick>{accessTranslate(roleCurrent.DEPARTMENT_DELETE)}</TableCellBrick>
                </TableRowBrick>
                <TableRowBrick>
                  <TableCellBrick component="th" scope="row">
                    Роли
                  </TableCellBrick>
                  <TableCellBrick>{accessTranslate(roleCurrent.ROLE_CREATE)}</TableCellBrick>
                  <TableCellBrick>{accessTranslate(roleCurrent.ROLE_READ)}</TableCellBrick>
                  <TableCellBrick>{accessTranslate(roleCurrent.ROLE_UPDATE)}</TableCellBrick>
                  <TableCellBrick>{accessTranslate(roleCurrent.ROLE_DELETE)}</TableCellBrick>
                </TableRowBrick>
                <TableRowBrick sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCellBrick component="th" scope="row">
                    Пользователи
                  </TableCellBrick>
                  <TableCellBrick>{accessTranslate(roleCurrent.USER_CREATE)}</TableCellBrick>
                  <TableCellBrick>{accessTranslate(roleCurrent.USER_READ)}</TableCellBrick>
                  <TableCellBrick>{accessTranslate(roleCurrent!.USER_UPDATE)}</TableCellBrick>
                  <TableCellBrick>{accessTranslate(roleCurrent!.USER_DELETE)}</TableCellBrick>
                </TableRowBrick>
              </TableBodyBrick>
            </TableBrick>
          </TableContainerBrick>
        </TransitionOpacityBrick>
      )}
    </>
  );
};
