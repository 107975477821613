import {
  CardContentBrick,
  CardDataComposition,
  ChipBrick,
  CopyIcon,
} from '@app/components';

import { useMainApi } from '../../context';
import styles from './var-list.module.scss';

export const VarListComponent = () => {
  const {
    varList,
    getChildrenList,
    keySelect,
  } = useMainApi();

  if (varList === null) return null;

  return (
    <>
      {varList.map((chip) => {
        const childrens = getChildrenList(chip);
        return (
          <div
            key={chip!.key}
            className={styles['var-list']}
          >
            <CardDataComposition
              title={chip!.label.singular}
            >
              <CardContentBrick>
                <div className={styles['var-list__chips']}>
                  <ChipBrick
                    icon={<CopyIcon fontSize="small" />}
                    label="ID"
                    variant="outlined"
                    onClick={() => keySelect(`${chip!.key}:id`)}
                  />
                  <ChipBrick
                    icon={<CopyIcon fontSize="small" />}
                    label="Uuid"
                    variant="outlined"
                    onClick={() => keySelect(`${chip!.key}:uuid`)}
                  />
                  <ChipBrick
                    icon={<CopyIcon fontSize="small" />}
                    label="QR-ссылка"
                    variant="outlined"
                    onClick={() => keySelect(`func_qr-link:${chip!.key}`)}
                  />
                  {chip!.sortableFields.map((fieldConfigId) => {
                    const fieldConfig = chip!.fields.find((fieldConfig) => fieldConfig.fieldConfigId === fieldConfigId);
                    
                    if (!fieldConfig) return null;
                    
                    if (fieldConfig.type === 'file' && fieldConfig.fileType !== 'image') return null;

                    return (
                      <ChipBrick
                        key={fieldConfig!.fieldConfigId}
                        icon={<CopyIcon fontSize="small" />}
                        label={fieldConfig.label + (fieldConfig.type === 'file' ? ' (Изображение)' : '')}
                        variant="outlined"
                        onClick={() => keySelect(`${chip!.key}:${fieldConfig?.key}`, fieldConfig)}
                      />
                    );
                  })}
                  {childrens.map((children) => {
                    if (!children) return null;

                    const fieldsStr = children.fields.map((field) => field.key).join(',');

                    return (
                      <ChipBrick
                        key={children.key}
                        icon={<CopyIcon fontSize="small" />}
                        label={'Таблица: ' + children.label.singular}
                        variant="outlined"
                        onClick={() => keySelect(`func_var-table:${chip.key}:${children.key}(${fieldsStr})`)}
                      />
                    );
                  })}
                </div>
              </CardContentBrick>
            </CardDataComposition>
          </div>
        );
      })}
    </>
  );
};
