import { createAsyncThunk } from '@reduxjs/toolkit';

import { callNotifyAction } from '@app/stores';

import {
  UserStateInterface,
  UserType,
} from '@app/types';

import {
  UserApi,
  UserChangePasswordRequest,
  UserCreateRequest,
  UserUpdateRequest,
} from '@app/api';


export const getUserListAction = createAsyncThunk<
  UserType[],
  undefined,
  { state: { user: UserStateInterface } }
>(
  '@@user/get-list',
  async (_, { rejectWithValue, dispatch }) => {
    const response = await UserApi.getList();
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка получения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data.users;
  },
  {
    condition: (_, { getState }) => {
      const { user } = getState();

      if (user.status === 'loading') {
        return false;
      }

      return true;
    }
  },
);

export const getUserDictionaryAction = createAsyncThunk<
  UserType[],
  undefined,
  { state: { user: UserStateInterface } }
>(
  '@@user/get-dictionary',
  async (_, { rejectWithValue, dispatch }) => {
    const response = await UserApi.getDictionary();
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка получения словаря!' }));
      return rejectWithValue(response.errors);
    }

    return response.data.users;
  },
  {
    condition: (_, { getState }) => {
      const { user } = getState();

      if (user.status === 'loading') {
        return false;
      }

      return true;
    }
  },
);

export const getUserOneAction = createAsyncThunk<
  UserType,
  number,
  { state: { user: UserStateInterface } }
>(
  '@@user/get-one',
  async (id: number, { rejectWithValue, dispatch }) => {
    const response = await UserApi.getOne(id);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка получения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data.user;
  },
  {
    condition: (_, { getState }) => {
      const { user } = getState();

      if (user.status === 'loading') {
        return false;
      }

      return true;
    }
  },
);

export const createUserAction = createAsyncThunk<
  UserType,
  UserCreateRequest,
  { state: { user: UserStateInterface } }
>(
  '@@user/create',
  async (payload: UserCreateRequest, { rejectWithValue, dispatch }) => {
    const response = await UserApi.create(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data.user;
  },
  {
    condition: (_, { getState }) => {
      const { user } = getState();

      if (user.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const updateUserAction = createAsyncThunk<
  UserType,
  UserUpdateRequest,
  { state: { user: UserStateInterface } }
>(
  '@@user/update',
  async (payload: UserUpdateRequest, { rejectWithValue, dispatch }) => {
    const response = await UserApi.update(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено обновлены!' }));

    return response.data.user;
  },
  {
    condition: (_, { getState }) => {
      const { user } = getState();

      if (user.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const changeUserPasswordAction = createAsyncThunk<
  UserType,
  UserChangePasswordRequest,
  { state: { user: UserStateInterface } }
>(
  '@@user/update-password',
  async (payload: UserChangePasswordRequest, { rejectWithValue, dispatch }) => {
    const response = await UserApi.changeUserPassword(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено обновлены!' }));

    return response.data.user;
  },
  {
    condition: (_, { getState }) => {
      const { user } = getState();

      if (user.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);
