import { useEffect, useState } from 'react';

import { ChangeFieldEventType } from '@app/types';

import {
  FormFieldBrick,
  SelectBrick,
  AsteriskBrick,
  MenuItemBrick,
  InputBrick,
} from '@app/components';

import { hadnbkMonthsGetHelper } from '@app/helpers';

import { FieldMonthYearProps } from './field-month-year.props';
import styles from './field-month-year.module.scss';


const items = hadnbkMonthsGetHelper();

export const FieldMonthYearBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  required,
  counted,
  prompt,
  disabled,
}: FieldMonthYearProps) => {
  const [inputMonth, setInputMonth] = useState<string>('');
  const [inputYear, setInputYear] = useState<string>('');

  const changeMonthHandler = (e: ChangeFieldEventType) => {
    setInputMonth(e.target.value ? String(e.target.value) : '');
    changeValueHandler(String(e.target.value), inputYear);
  }

  const changeYearHandler = (e: ChangeFieldEventType) => {
    if (String(e.target.value).length > 4) return;
    e.target.value = (e.target.value as string).replace(/\D/g, '');

    setInputYear(e.target.value ? String(e.target.value) : '');
    changeValueHandler(inputMonth, String(e.target.value));
  }

  const changeValueHandler = (month: string, year: string) => {
    if (!month || (!year || year.length < 4)) {
      onChange({ target: { name, value: '' } });
      return
    };

    const date = new Date(Number(year), Number(month) - 1, 2);

    onChange({ target: { name, value: date } });
  }

  useEffect(() => {
    if (!value) return;
    
    const date = new Date(value);

    setInputMonth(String(date.getUTCMonth() + 1));
    setInputYear(String(date.getFullYear()));
  // eslint-disable-next-line
  }, [setInputMonth, setInputYear])

  return (
    <div className={styles['field-month-year']}>
      <FormFieldBrick error={error} prompt={prompt}>
        <div className={styles['field-month-year__container']}>
          <div className={styles['field-month-year__month-input']}>
            <SelectBrick
              name={name + '-month'}
              value={String(inputMonth) || ''}
              onChange={changeMonthHandler}
              label={<>{label}<AsteriskBrick required={required} counted={counted} /></>}
              disabled={disabled}
            >
              {items.map((item) => (
                <MenuItemBrick
                  key={item.value}
                  value={item.value}
                >
                  {item.label}
                </MenuItemBrick>
              ))}
            </SelectBrick>
          </div>

          <div className={styles['field-month-year__year-input']}>
            <InputBrick
              label={<>Год<AsteriskBrick required={required} counted={counted} /></>}
              name={name + '-year'}
              value={String(inputYear) || ''}
              onChange={changeYearHandler}
              disabled={disabled}
            />
          </div>
        </div>
      </FormFieldBrick>
    </div>
  );
};
