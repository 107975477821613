import { useNavigate } from 'react-router-dom';

import {
  BackspaceIcon,
  ButtonBrick,
  ButtonIconBrick,
} from '@app/components';

import { ButtonBackProps } from './button-back.props';


export const ButtonBackBlock = ({
  onClick,
  buttonIcon,
}: ButtonBackProps) => {
  const navigate = useNavigate();

  const handlerClick = () => {
    if (onClick) onClick();
    else navigate(-1);
  };

  if (buttonIcon) return (
    <ButtonIconBrick
      onClick={handlerClick}
    >
      <BackspaceIcon />
    </ButtonIconBrick>
  );

  return (
    <ButtonBrick
      variant='outlined'
      startIcon={<BackspaceIcon />}
      onClick={handlerClick}
      size="large"
    >
      Назад
    </ButtonBrick>
  );
};
