import { OutlinedInput, FormControl, InputLabel } from '@mui/material';

import { InputOutlinedProps } from './input-outlined.props';


export const InputOutlinedBrick = ({
  label,
  type,
  value,
  onChange,
  name,
  endAdornment,
}: InputOutlinedProps) => {
  return (
    <FormControl variant="outlined">
    <InputLabel htmlFor={name}>{label}</InputLabel>
    <OutlinedInput
      id={name}
      type={type}
      value={value}
      onChange={onChange}
      name={name}
      label={label}
      endAdornment={endAdornment}
    />
  </FormControl>
  );
};
