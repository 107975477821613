import {
  HttpService,
} from '@app/services';

import {
  BaseResponseInterface,
} from '@app/types';

import {
  FieldConfigCreateRequest,
  FieldConfigCreateResponse,
  FieldConfigDeleteRequest,
  FieldConfigDeleteResponse,
  FieldConfigUpdateRequest,
  FieldConfigUpdateResponse,
} from './types';


export class FieldConfigApi {
  public static async create(
    payload: FieldConfigCreateRequest,
  ): Promise<BaseResponseInterface<FieldConfigCreateResponse>> {
    return await HttpService.sendRequest('POST', '/field-config/create', {
      fieldConfig: payload,
    });
  };

  public static async update(
    payload: FieldConfigUpdateRequest,
  ): Promise<BaseResponseInterface<FieldConfigUpdateResponse>> {
    return await HttpService.sendRequest('PUT', '/field-config/update', {
      fieldConfig: payload,
    });
  };

  public static async delete(
    payload: FieldConfigDeleteRequest,
  ): Promise<BaseResponseInterface<FieldConfigDeleteResponse>> {
    return await HttpService.sendRequest('DELETE', '/field-config/delete', payload);
  };
};
