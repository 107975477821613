import { ChangeEvent, useRef } from 'react';

import {
  ButtonLoadingBrick,
  UploadIcon,
} from '@app/components';

import { ButtonUploadProps } from './button-upload.props';
import styles from './button-upload.module.scss';


export const ButtonUploadBlock = ({
  loading,
  disabled,
  size,
  variant,
  accept = '*',
  label = 'Загрузить',
  handleFile,
}: ButtonUploadProps) => {
  const hiddenFileInput = useRef(null);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const fileUploaded = e.target.files[0];
    handleFile(fileUploaded);
    (hiddenFileInput.current as any).value = null;
  };

  const handleClick = () => {
    (hiddenFileInput.current as any).click();
  };
 
  return (
    <div className={styles['button-upload']}>
      <ButtonLoadingBrick
        loading={loading}
        type="submit"
        onClick={handleClick}
        size={size || 'large'}
        startIcon={<UploadIcon />}
        variant={variant || 'contained'}
        color="primary"
        disabled={disabled}
      >
        {label}
      </ButtonLoadingBrick>
      <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        className={styles['button-upload__input-file']} // Make the file input element invisible
        accept={accept}
      />
    </div>
  );
};
