import React from 'react';

import { handbkVariantCreateAction } from '@app/stores';

import {
  ChangeFieldEventType,
} from '@app/types';

import { errorTranslate } from '@app/helpers';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';

import { useMainApi } from '../../context';

const initialformValue = {
  label: '',
};

export interface VariantCreateContextInterface {
  formValue: InitialFormValue | null;
  formDirty: boolean;
  changeFormValue: (e: ChangeFieldEventType) => void;
  closeForm: () => void;
  onSubmit: (e?: React.SyntheticEvent) => Promise<void>;
  setError: (fieldname: string) => string | null;
};

type InitialFormValue = {
  label: string;
}

export const useVariantCreateHook = (
): VariantCreateContextInterface => {
  const {
    handbk,
    createFormOpenedSet,
  } = useMainApi();

  const dispatch = useAppDispatch();
  const [formValue, formValueSet] = React.useState(initialformValue);
  const [formDirty, formDirtySet] = React.useState(false);
  const [sendedForm, sendedFormSet] = React.useState(false);
  const errors = useAppSelector((s) => s.configuration.errors);

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
       return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const closeForm = React.useCallback(() => {
    createFormOpenedSet(false);
    formValueSet(initialformValue);
    sendedFormSet(false);
    formDirtySet(false);
  }, [
    createFormOpenedSet,
  ]);

  const onSubmit = React.useCallback(async (e?: React.SyntheticEvent) => {
    if (e) e.preventDefault();
    if (formValue === null) return; 

    sendedFormSet(true);

    const result = await dispatch(handbkVariantCreateAction({
      handbkId: handbk!.handbkId,
      label: formValue.label,
    }));

    if (result.type === '@@configuration/handbk-variant-create/fulfilled') {
      closeForm();
    }
  }, [
    formValue,
    handbk,
    dispatch,
    sendedFormSet,
    closeForm,
  ]);

  const setError = React.useCallback((fieldName: string) => {
    return sendedForm
      ? errors && errors[fieldName] && errorTranslate(errors[fieldName][0])
      : '';
    }, [
      errors,
      sendedForm,
    ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    changeFormValue,
    closeForm,
    onSubmit,
    setError,
  }), [
    formValue,
    formDirty,
    changeFormValue,
    closeForm,
    onSubmit,
    setError,
  ]);
};
