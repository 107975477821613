import React from 'react';

import {
  VariantUpdateContextInterface,
  useVariantUpdateHook,
} from './variant-update.hook';

const VariantUpdateContext = React.createContext<VariantUpdateContextInterface | undefined>(undefined);

export const useVariantUpdateApi = () => {
  const context = React.useContext(VariantUpdateContext);

  if (context === undefined) {
    throw new Error('useVariantUpdateApi must be used within VariantUpdateProvider');
  }

  return context;
}

export const VariantUpdateProvider = (
  props: React.HTMLAttributes<HTMLDivElement>
) => {
  const api = useVariantUpdateHook();

  return (
    <VariantUpdateContext.Provider value={api}>
      {props.children}
    </VariantUpdateContext.Provider>
  );
};
