import {
  PrintIcon,
  ButtonLoadingBrick,
} from '@app/components';

import { ButtonPrintProps } from './button-print.props';
import styles from './button-print.module.scss';


export const ButtonPrintBlock = ({
  onClick,
  loading,
  disabled,
}: ButtonPrintProps) => {
  return (
    <ButtonLoadingBrick
      className={styles['button-print']}
      loading={loading}
      type='submit'
      onClick={onClick}
      size='large'
      startIcon={<PrintIcon />}
      variant="contained"
      color="primary"
      disabled={disabled}
    >
      Печать
    </ButtonLoadingBrick>
  );
};
