import { ChangeFieldEventType } from '@app/types';

import {
  FormFieldBrick,
  AsteriskBrick,
  InputBrick,
} from '@app/components';

import { FieldNumberProps } from './field-number.props';


export const FieldNumberBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  required,
  counted,
  prompt,
  disabled,
}: FieldNumberProps) => {
  const onChangeHandler = (e: ChangeFieldEventType) => {
    if (e.target.value === '') {
      e.target.value = NaN;
      onChange(e);
      return;
    }
    e.target.value = Number((e.target.value as string).replace(/\D/g, ''));
    onChange(e);
  }

  const preparedValue = 
    isNaN(value)
      ? ''
      : value === 0
        ? ''
        : String(value);

  return (
    <FormFieldBrick error={error} prompt={prompt}>
      <InputBrick
        label={<>{label}<AsteriskBrick required={required} counted={counted} /></>}
        name={name}
        value={preparedValue}
        onChange={onChangeHandler}
        disabled={disabled}
      />
    </FormFieldBrick>
  );
};
