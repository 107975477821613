import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  callNotifyAction,
  createDocumentStore,
  updateCurrentUserRoleAfterCreateDocument,
} from '@app/stores';

import {
  ConfigurationStateInterface,
} from '@app/types';

import {
  DocumentConfigCreateRequest,
  DocumentConfigCreateResponse,
  DocumentConfigDeleteRequest,
  DocumentConfigDeleteResponse,
  DocumentConfigSortableChildrensRequest,
  DocumentConfigSortableChildrensResponse,
  DocumentConfigSortableFieldsRequest,
  DocumentConfigUpdateRequest,
  DocumentConfigUpdateResponse,
} from '@app/api';

import { DocumentConfigApi } from '@app/api';


export const documentConfigCreateAction = createAsyncThunk<
  DocumentConfigCreateResponse,
  DocumentConfigCreateRequest,
  { state: { configuration: ConfigurationStateInterface } }
>(
  '@@configuration/document-config-create',
  async (payload: DocumentConfigCreateRequest, { rejectWithValue, dispatch }) => {
    const response = await DocumentConfigApi.create(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(createDocumentStore(payload.key));
    dispatch(updateCurrentUserRoleAfterCreateDocument(payload.key));
    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { configuration } = getState();

      if (configuration.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const documentConfigUpdateAction = createAsyncThunk<
  DocumentConfigUpdateResponse,
  DocumentConfigUpdateRequest,
  { state: { configuration: ConfigurationStateInterface } }
>(
  '@@configuration/document-config-update',
  async (payload: DocumentConfigUpdateRequest, { rejectWithValue, dispatch }) => {
    const response = await DocumentConfigApi.update(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { configuration } = getState();

      if (configuration.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const documentConfigDeleteAction = createAsyncThunk<
  DocumentConfigDeleteResponse,
  DocumentConfigDeleteRequest,
  { state: { configuration: ConfigurationStateInterface } }
>(
  '@@configuration/document-config-delete',
  async (payload: DocumentConfigDeleteRequest, { rejectWithValue, dispatch }) => {
    dispatch(callNotifyAction({ type: 'info', message: 'loading' }));
    const response = await DocumentConfigApi.delete(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка удаления данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено удалены!' }));
    return response.data;
  },
);

export const documentConfigSortableFieldsAction = createAsyncThunk<
  DocumentConfigUpdateResponse,
  DocumentConfigSortableFieldsRequest,
  { state: { configuration: ConfigurationStateInterface } }
>(
  '@@configuration/document-config-sortable-fields',
  async (payload: DocumentConfigSortableFieldsRequest, { rejectWithValue, dispatch }) => {
    const response = await DocumentConfigApi.sortableFields(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { configuration } = getState();

      if (configuration.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);

export const documentConfigSortableChildrensAction = createAsyncThunk<
  DocumentConfigSortableChildrensResponse,
  DocumentConfigSortableChildrensRequest,
  { state: { configuration: ConfigurationStateInterface } }
>(
  '@@configuration/update-sortable-childrens',
  async (payload: DocumentConfigSortableChildrensRequest, { rejectWithValue, dispatch }) => {
    const response = await DocumentConfigApi.sortableChildrens(payload);
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка записи данных!' }));
      return rejectWithValue(response.errors);
    }

    dispatch(callNotifyAction({ type: 'success', message: 'Данные успено записаны!' }));

    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { configuration } = getState();

      if (configuration.status === 'submitting') {
        return false;
      }

      return true;
    }
  },
);
