import { DialogBrick } from '@app/components';

import { useMainApi } from '../../context';


export const ModalComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    opened,
    onClose,
  } = useMainApi();

  return (
    <DialogBrick
      size="medium"
      opened={opened}
      onClose={() => onClose()}
    >
      {children}
    </DialogBrick>
  );
};
