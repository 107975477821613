import { createSlice } from '@reduxjs/toolkit';

import {
  DepartmentStateInterface,
} from '@app/types';

import {
  getDepartmentDictionaryAction,
} from './department.actions';


const initialState: DepartmentStateInterface = {
  dictionary: [],
  status: 'idle',
  errors: null,
}

const departmentSlice = createSlice({
  name: '@@department',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDepartmentDictionaryAction.pending, (state) => {
        state.status = 'loading';
        state.errors = null;
        state.dictionary = [];
      })
      .addCase(getDepartmentDictionaryAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.dictionary = action.payload;
      })
      .addCase(getDepartmentDictionaryAction.rejected, (state) => {
        state.status = 'idle';
      });
  },
});

export default departmentSlice.reducer;
