import * as React from 'react';


export type ModalsContextInterface = {
  varsDialogOpened: boolean;
  varsDialogOpen: () => void;
  varsDialogClose: () => void;
  marginsDialogOpened: boolean;
  marginsDialogOpen: () => void;
  marginsDialogClose: () => void;
  imageLibraryDialogOpened: boolean;
  imageLibraryDialogOpen: () => void;
  imageLibraryDialogClose: () => void;
}

export const useModalsHook = (): ModalsContextInterface => {
  const [varsDialogOpened, setVarsDialogOpened] = React.useState<boolean>(false);
  const varsDialogOpen = React.useCallback(() => setVarsDialogOpened(true), [setVarsDialogOpened]);
  const varsDialogClose = React.useCallback(() => setVarsDialogOpened(false), [setVarsDialogOpened]);

  const [marginsDialogOpened, setMarginsDialogOpened] = React.useState<boolean>(false);
  const marginsDialogOpen = React.useCallback(() => setMarginsDialogOpened(true), [setMarginsDialogOpened]);
  const marginsDialogClose = React.useCallback(() => setMarginsDialogOpened(false), [setMarginsDialogOpened]);
  
  const [imageLibraryDialogOpened, setImageLibraryDialogOpened] = React.useState<boolean>(false);
  const imageLibraryDialogOpen = React.useCallback(() => setImageLibraryDialogOpened(true), [setImageLibraryDialogOpened]);
  const imageLibraryDialogClose = React.useCallback(() => setImageLibraryDialogOpened(false), [setImageLibraryDialogOpened]);
  
  return React.useMemo(() => ({
    varsDialogOpened,
    varsDialogOpen,
    varsDialogClose,
    marginsDialogOpened,
    marginsDialogOpen,
    marginsDialogClose,
    imageLibraryDialogOpened,
    imageLibraryDialogOpen,
    imageLibraryDialogClose,
  }), [
    varsDialogOpened,
    varsDialogOpen,
    varsDialogClose,
    marginsDialogOpened,
    marginsDialogOpen,
    marginsDialogClose,
    imageLibraryDialogOpened,
    imageLibraryDialogOpen,
    imageLibraryDialogClose,
  ]);
}
