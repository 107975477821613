import {
  PageCenteringLayout,
  SigninFormFeature,
} from '@app/components';


export const SigninPage = () => {  
  return (
    <PageCenteringLayout>
      <SigninFormFeature />
    </PageCenteringLayout>
  );
};
