import { SyntheticEvent } from 'react';

import {
  TransitionOpacityBrick,
  ButtonSaveBlock,
  ButtonCancelBlock,
  FieldSelectBlock,
  TableBrick,
  TableBodyBrick,
  TableCellBrick,
  TableContainerBrick,
  TableRowBrick,
} from '@app/components';

import {
  useMainApi,
  useUserAccessApi,
} from '../../context';

import styles from './user-access-update.module.scss';


export const UserAccessUpdateComponent = () => {
  const {
    roleDetailIsSubmitting,
    roleCurrent,
    isEditingUserAccess,
  } = useMainApi();

  const {
    formValue,
    formDirty,
    changeFormValue,
    onSubmit,
    onDone,
  } = useUserAccessApi();

  if (formValue === null) return null;

  return (
    <>
      {roleCurrent && isEditingUserAccess && (
        <TransitionOpacityBrick>
          <div className={styles['user-access-update']}>
            <form onSubmit={(e: SyntheticEvent) => onSubmit(e)}>
              <TableContainerBrick className={styles['user-access-update__table-container']}>
                <TableBrick aria-label="department-detail">
                  <TableBodyBrick>
                    <TableRowBrick>
                      <TableCellBrick component="th" scope="row">Подразделения</TableCellBrick>
                      <TableCellBrick>
                        <FieldSelectBlock
                          value={formValue.DEPARTMENT_CREATE}
                          name="DEPARTMENT_CREATE"
                          label="Создание"
                          onChange={changeFormValue}
                          items={[
                            { value: 'NEVER', label: 'Нет' },
                            { value: 'ALL', label: 'Да' },
                          ]}
                          hideError
                        />
                      </TableCellBrick>
                      <TableCellBrick>
                        <FieldSelectBlock
                          value={formValue.DEPARTMENT_READ}
                          name="DEPARTMENT_READ"
                          label="Чтение"
                          onChange={changeFormValue}
                          items={[
                            { value: 'NEVER', label: 'Нет' },
                            { value: 'ALL', label: 'Да' },
                          ]}
                          hideError
                        />
                      </TableCellBrick>
                      <TableCellBrick>
                        <FieldSelectBlock
                          value={formValue.DEPARTMENT_UPDATE}
                          name="DEPARTMENT_UPDATE"
                          label="Обновление"
                          onChange={changeFormValue}
                          items={[
                            { value: 'NEVER', label: 'Нет' },
                            { value: 'ALL', label: 'Да' },
                          ]}
                          hideError
                        />
                      </TableCellBrick>
                      <TableCellBrick>
                        <FieldSelectBlock
                          value={formValue.DEPARTMENT_DELETE}
                          name="DEPARTMENT_DELETE"
                          label="Удаление"
                          onChange={changeFormValue}
                          items={[
                            { value: 'NEVER', label: 'Нет' },
                            { value: 'ALL', label: 'Да' },
                          ]}
                          hideError
                        />
                      </TableCellBrick>
                    </TableRowBrick>
                    <TableRowBrick>
                      <TableCellBrick component="th" scope="row">Роли</TableCellBrick>
                      <TableCellBrick>
                        <FieldSelectBlock
                          value={formValue.ROLE_CREATE}
                          name="ROLE_CREATE"
                          label="Создание"
                          onChange={changeFormValue}
                          items={[
                            { value: 'NEVER', label: 'Нет' },
                            { value: 'ALL', label: 'Да' },
                          ]}
                          hideError
                        />
                      </TableCellBrick>
                      <TableCellBrick>
                        <FieldSelectBlock
                          value={formValue.ROLE_READ}
                          name="ROLE_READ"
                          label="Чтение"
                          onChange={changeFormValue}
                          items={[
                            { value: 'NEVER', label: 'Нет' },
                            { value: 'ALL', label: 'Да' },
                          ]}
                          hideError
                        />
                      </TableCellBrick>
                      <TableCellBrick>
                        <FieldSelectBlock
                          value={formValue.ROLE_UPDATE}
                          name="ROLE_UPDATE"
                          label="Обновление"
                          onChange={changeFormValue}
                          items={[
                            { value: 'NEVER', label: 'Нет' },
                            { value: 'ALL', label: 'Да' },
                          ]}
                          hideError
                        />
                      </TableCellBrick>
                      <TableCellBrick>
                        <FieldSelectBlock
                          value={formValue.ROLE_DELETE}
                          name="ROLE_DELETE"
                          label="Удаление"
                          onChange={changeFormValue}
                          items={[
                            { value: 'NEVER', label: 'Нет' },
                            { value: 'ALL', label: 'Да' },
                          ]}
                          hideError
                        />
                      </TableCellBrick>
                    </TableRowBrick>
                    <TableRowBrick>
                      <TableCellBrick component="th" scope="row">Пользователи</TableCellBrick>
                      <TableCellBrick>
                        <FieldSelectBlock
                          value={formValue.USER_CREATE}
                          name="USER_CREATE"
                          label="Создание"
                          onChange={changeFormValue}
                          items={[
                            { value: 'NEVER', label: 'Нет' },
                            { value: 'ALL', label: 'Да' },
                          ]}
                          hideError
                        />
                      </TableCellBrick>
                      <TableCellBrick>
                        <FieldSelectBlock
                          value={formValue.USER_READ}
                          name="USER_READ"
                          label="Чтение"
                          onChange={changeFormValue}
                          items={[
                            { value: 'NEVER', label: 'Нет' },
                            { value: 'ALL', label: 'Да' },
                          ]}
                          hideError
                        />
                      </TableCellBrick>
                      <TableCellBrick>
                        <FieldSelectBlock
                          value={formValue.USER_UPDATE}
                          name="USER_UPDATE"
                          label="Обновление"
                          onChange={changeFormValue}
                          items={[
                            { value: 'NEVER', label: 'Нет' },
                            { value: 'ALL', label: 'Да' },
                          ]}
                          hideError
                        />
                      </TableCellBrick>
                      <TableCellBrick>
                        <FieldSelectBlock
                          value={formValue.USER_DELETE}
                          name="USER_DELETE"
                          label="Удаление"
                          onChange={changeFormValue}
                          items={[
                            { value: 'NEVER', label: 'Нет' },
                            { value: 'ALL', label: 'Да' },
                          ]}
                          hideError
                        />
                      </TableCellBrick>
                    </TableRowBrick>
                  </TableBodyBrick>
                </TableBrick>
              </TableContainerBrick>
              <div className={styles['user-access-update__buttons']}>
                <ButtonSaveBlock
                  loading={roleDetailIsSubmitting}
                  onClick={onSubmit}
                  disabled={!formDirty}
                />
                <ButtonCancelBlock onClick={() => onDone()} />
              </div>
            </form>
          </div>
        </TransitionOpacityBrick>
      )}
    </>
  );
};
  