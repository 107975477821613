import { FieldFileFeature } from '@app/components';
import { FileProps } from './file.props';


export const FileField = ({
  fieldConfig,
  documentConfig,
  value,
  onChange,
  error,
  disabled,
}: FileProps) => {
  return (
    <FieldFileFeature
      key={fieldConfig.key}
      name={fieldConfig.key}
      label={fieldConfig.label}
      type={fieldConfig.fileType!}
      required={fieldConfig.required}
      counted={fieldConfig.counted}
      onChange={onChange}
      value={value}
      variant="outlined"
      error={error}
      fieldConfigId={fieldConfig.fieldConfigId}
      documentConfigKey={documentConfig.key}
      fieldConfigKey={fieldConfig.key}
      disabled={disabled}
    />
  );
};
