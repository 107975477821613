import { MainComponent } from './components';
import { MainProvider } from './context'
import { TemplateEditorHtmlProps } from './template-editor-html.props';


export const TemplateEditorHtmlSmart = ({
  value,
  onChange,
}: TemplateEditorHtmlProps) => {
  return (
    <MainProvider value={value} onChange={onChange}>
      <MainComponent />
    </MainProvider>
  );
};
