import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import "moment/locale/ru";
import { LocalizationProviderProps } from './localization-provider.props';


export const LocalizationProviderPart = ({
  children,
}: LocalizationProviderProps) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={'ru'}
    >
      {children}
    </LocalizationProvider>
  );
};
