import cs from 'classnames';
import { Editor } from 'draft-js';

import { useTheme } from '@mui/material';

import { BLOCK_RENDER_MAP, CUSTOM_STYLE_MAP } from '../../config';

import styles from './text-area.module.scss';
import { useMainApi } from '../../context';


export const TextAreaComponent = () => {
  const {
    editorState,
    editorStateChange,
  } = useMainApi();

  const { palette } = useTheme();

  const classnames = cs(
    styles['text-area'],
    { [styles['text-area--dark']]: palette.mode === 'dark' },
  );
  
  return (
    <div className={classnames}>
      <Editor
        editorState={editorState}
        onChange={editorStateChange}
        blockRenderMap={BLOCK_RENDER_MAP}
        customStyleMap={CUSTOM_STYLE_MAP}
      />
    </div>
  );
}
