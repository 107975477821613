export const dateTranslate = (date: Date | undefined): string => {
  if (!date) {
    return '';
  }

  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();

  return `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;
}
