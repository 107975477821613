import {
  Button,
  ButtonGroup,
} from '@mui/material';

import {
  ButtonConfirmComposition,
} from '@app/components';

import { ButtonsProps } from './buttons.props';
import { useMainApi } from '../../../../context';


export const ButtonsComponent = ({
  printImageId
}: ButtonsProps) => {
  const {
    printImageSelect,
    printImageDelete,
  } = useMainApi();

  return (
    <ButtonGroup
      orientation="vertical"
      aria-label="image control buttons"
    >
      <Button
        onClick={() => printImageSelect(printImageId)}
        variant="contained"
        size="small"
      >
        Выбрать
      </Button>

      <ButtonConfirmComposition
        onClick={() => printImageDelete(printImageId)}
        size="small"
        type="button"
        variant="contained"
      />
    </ButtonGroup>
  );
};
