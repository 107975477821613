import { PageCenteringProps } from './page-centering.props';
import styles from './page-centering.module.scss';
import { TransitionOpacityBrick } from '@app/components';


export const PageCenteringLayout = ({
  children,
}: PageCenteringProps) => {
  return (
    <TransitionOpacityBrick>
      <div className={styles['page-centering']}>
        {children}
      </div>
    </TransitionOpacityBrick>
  );
};
