import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';

import styles from './text-area.module.scss';
import { useAppSelector } from '@app/hooks';
import { useMainApi } from '../../context';


export const TextAreaComponent = () => {
  const themeMode = useAppSelector((s) => s.app.themeMode);
  const { value, onChange } = useMainApi();
  
  return (
    <div className={styles['text-area']}>
      <CodeMirror
        value={value}
        height="100%"
        extensions={[html()]}
        onChange={onChange}
        theme={themeMode}
      />
    </div>
  );
}
