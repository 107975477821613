import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from '@app/hooks';

import { AnonGuardProps } from './anon-guard.props';


export const AnonGuardHoc = ({ children }: AnonGuardProps) => {
  const location = useLocation();
  const auth = useAppSelector((state) => state.auth);
  const fromPage = location.state?.from?.pathname || '/';

  if (auth.isLoggedIn) {
    return (<Navigate to={fromPage} />);
  }

  return children;
}
