import { useEffect, useRef } from 'react';

import {
  FormFieldBrick,
  AsteriskBrick,
  InputBrick,
} from '@app/components';

import { FieldTimeProps } from './field-time.props';


export const FieldTimeBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  required,
  counted,
  prompt,
  disabled,
}: FieldTimeProps) => {
  const inputTime = useRef<HTMLInputElement>(null);

  const handleChange = () => {
    if (!inputTime.current) {
      return;
    }

    const input = inputTime.current.querySelectorAll('input')[0];
    const currentFocus = input.selectionStart
    const inputValue = input.value
      .replace(/\D/g, '')
      .match(/(\d{0,2})(\d{0,2})/);

    if (inputValue && Number(inputValue[1]) > 23) {
      inputValue[1] = '23'
    }

    if (inputValue && Number(inputValue[2]) > 59) {
      inputValue[2] = '59'
    }

    const newValue = !inputValue![2]
    ? inputValue![1]
    : `${inputValue![1]}:${inputValue![2]}`;

    input.value = newValue

    const focusInText = currentFocus && currentFocus < newValue.length;
    
    if (focusInText) {
      if ((currentFocus === 3) && input.value.length > value.length ) {
        input.setSelectionRange(currentFocus + 1, currentFocus + 1);
      } else {
        input.setSelectionRange(currentFocus, currentFocus);
      }
    }

    onChange({ target: { name, value: newValue } });
  };

  useEffect(
    () => {
      handleChange();
    },
    // eslint-disable-next-line
    [value]
  );
  
  return (
    <FormFieldBrick error={error} prompt={prompt}>
      <InputBrick
        label={<>{label}<AsteriskBrick required={required} counted={counted} /></>}
        name={name}
        value={value}
        onChange={handleChange}
        refEl={inputTime}
        disabled={disabled}
      />
    </FormFieldBrick>
  );
};
