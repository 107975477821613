import { useTheme } from '@mui/material';
import cs from 'classnames';
import styles from './divider-vertical.module.scss';


export const DividerVerticalBrick = () => {
  const { palette } = useTheme();

  const classname = cs(
    styles['divider-vertical'],
    { [styles['divider-vertical--dark']]: palette.mode === 'dark'},
  );

  return (
    <div
      className={classname}
    />
  )
};
