import { useState, forwardRef } from 'react';
import { AppBar, Dialog, DialogContent, IconButton, Slide, Toolbar } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import { documentConfigDeleteAction } from '@app/stores';

import {
  CloseIcon,
  SettingsIcon,
  ButtonCancelBlock,
  ButtonEditBlock,
  CardDataComposition,
  ButtonConfirmComposition,
} from '@app/components';

import { useAppDispatch } from '@app/hooks';

import { DocumentConfigDetailTableComponent } from './components/document-config-detail-table';
import { DocumentConfigUpdateFormComponent } from './components/document-config-update-form';
import { DocumentConfigFieldListComponent } from './components/document-config-field-list';
import { DocumentConfigUpdateProps } from './document-config-update.props';
import styles from './document-config-update.module.scss';


const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DocumentConfigUpdateFeature = ({ documentConfig }: DocumentConfigUpdateProps) => {
  const [opened, setOpened] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const dispatch = useAppDispatch();

  const onRemove = async () => {
    await dispatch(documentConfigDeleteAction({ documentConfigId: documentConfig.documentConfigId }));
  }

  return (
    <>
      <IconButton
        aria-label="settings"
        onClick={() => setOpened(true)}
      >
        <SettingsIcon />
      </IconButton>

      <Dialog
        fullScreen
        TransitionComponent={Transition}
        open={opened}
        onClose={() => setOpened(false)}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpened(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Настройки
            </Typography> */}
          </Toolbar>
        </AppBar>
        <div className={styles['document-config-update']}>
          <DialogContent>
            <div className="container">
              <div className={styles['document-config-update__form']}>
                <CardDataComposition
                  title="Коллекция"
                  extra={(
                    <>
                      {isEdited ? <ButtonCancelBlock buttonIcon onClick={() => setIsEdited(false)} /> : <ButtonEditBlock onClick={() => setIsEdited(true)} />}
                      <ButtonConfirmComposition
                        onClick={onRemove}
                        message="Текущая колекция документов и все связанные с ней данные будут удалены."
                      />
                    </>
                  )}
                >
                  {isEdited ? (
                    <DocumentConfigUpdateFormComponent
                      documentConfig={documentConfig}
                      setOpened={setIsEdited}
                    />
                  ) : (
                    <DocumentConfigDetailTableComponent documentConfig={documentConfig} />
                  )}
                  
                </CardDataComposition>
              </div>

              <DocumentConfigFieldListComponent documentConfig={documentConfig} />
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </>
  )
}

export { DocumentConfigUpdateFeature };
