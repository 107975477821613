import { ChangeFieldEventType } from '@app/types';

import {
  FormFieldBrick,
  AsteriskBrick,
  InputBrick,
} from '@app/components';

import { FieldDecimalProps } from './field-decimal.props';


const handleWheelPrevent = (e: any) => e.preventDefault();

export const FieldDecimalBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  required,
  counted,
  prompt,
  disabled,
}: FieldDecimalProps) => {
  const onChangeHandler = (e: ChangeFieldEventType) => {
    e.target.value = (e.target.value as string).replace(/[^\d,.]/g, '');
    e.target.value = (e.target.value as string).replace('.', ',');

    if (e.target.value.split(',').length > 2) {
      if (e.target.value[e.target.value.length - 1] === ',') {
        e.target.value = e.target.value.slice(0, -1);
      }
    }

    onChange(e);
  }

  return (
    <FormFieldBrick error={error} prompt={prompt}>
      <InputBrick
        label={<>{label}<AsteriskBrick required={required} counted={counted} /></>}
        name={name}
        value={value.replace('.', ',')}
        onChange={onChangeHandler}
        onFocus={(e) => e.target.addEventListener('wheel', handleWheelPrevent)}
        onBlur={(e) => e.target.removeEventListener('wheel', handleWheelPrevent)}
        disabled={disabled}
      />
    </FormFieldBrick>
  );
};
