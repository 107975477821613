import { createAsyncThunk } from '@reduxjs/toolkit';

import { callNotifyAction } from '@app/stores';
import {
  DepartmentType,
  DepartmentStateInterface,
} from '@app/types';

import {
  DepartmentApi,
} from '@app/api';


export const getDepartmentDictionaryAction = createAsyncThunk<
  DepartmentType[],
  undefined,
  { state: { department: DepartmentStateInterface } }
>(
  '@@department/get-dictionary',
  async (_, { rejectWithValue, dispatch }) => {
    const response = await DepartmentApi.getDictionary();
    
    if (!response.success) {
      dispatch(callNotifyAction({ type: 'error', message: 'Ошибка получения данных!' }));
      return rejectWithValue(response.errors);
    }

    return response.data.departments;
  },
  {
    condition: (_, { getState }) => {
      const { department } = getState();

      if (department.status === 'loading') {
        return false;
      }

      return true;
    }
  },
);
