import {
  ButtonCloseBlock,
  ButtonUploadBlock,
} from '@app/components';

import { useMainApi } from '../../context';

export const ButtonsComponent = () => {
  const {
    onClose,
    printImageUpload,
    printImageIsUploading
  } = useMainApi()
  
  return (
    <>
      <ButtonUploadBlock
        loading={printImageIsUploading}
        size="small"
        handleFile={printImageUpload}
        accept="image/*"
      />
      <ButtonCloseBlock onClick={onClose} />
    </>
  );
};
