import React from 'react';

import { callNotifyAction } from '@app/stores';

import { useMainApi } from '..';

import {
  RoleType,
  ChangeFieldEventType,
} from '@app/types';

import { errorTranslate } from '@app/helpers';
import { useAppDispatch } from '@app/hooks';
import { RoleApi } from '@app/api';


export interface GeneralContextInterface {
  formValue: RoleType | null;
  formDirty: boolean;
  changeFormValue: (e: ChangeFieldEventType) => void;
  onDone: () => void;
  onSubmit: (e?: React.SyntheticEvent) => Promise<void>;
  setError: (fieldName: string) => string | null;
};

export const useGeneralHook = (
): GeneralContextInterface => {
  const dispatch = useAppDispatch();
  const {
    roleCurrent,
    errors,
    isEditingGeneralSet,
    roleDetailIsSubmittingSet,
    roleCurrentUpdate,
  } = useMainApi();

  const [formValue, formValueSet] = React.useState<RoleType | null>(null);
  const [formDirty, formDirtySet] = React.useState(false);

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      if (state === null) return null;
      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
  }, []);

  const onDone = React.useCallback(() => {
    isEditingGeneralSet(false);
  }, [
    isEditingGeneralSet,
  ]);

  const onSubmit = React.useCallback(async (e?: React.SyntheticEvent) => {
    roleDetailIsSubmittingSet(true);
    if (e) e.preventDefault();
    if (formValue === null) return; 

    const response = await RoleApi.update(formValue);

    if (!response.success) {
      dispatch(callNotifyAction({
        type: 'error',
        message: 'Не удалось обновить роль',
      }));
      roleDetailIsSubmittingSet(false);
      return;
    }

    dispatch(callNotifyAction({
      type: 'success',
      message: 'Роль успешно обновлена',
    }));

    roleCurrentUpdate(response.data.role);

    roleDetailIsSubmittingSet(false);
    onDone();
  }, [
    formValue,
    dispatch,
    roleDetailIsSubmittingSet,
    onDone,
    roleCurrentUpdate,
  ]);

  const setError = React.useCallback((fieldName: string) => {
    return errors && errors[fieldName] && errorTranslate(errors[fieldName][0]);
  }, [
    errors,
  ]);

  React.useEffect(() => {
    formValueSet(roleCurrent);
  }, [
    roleCurrent,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    changeFormValue,
    onDone,
    onSubmit,
    setError,
  }), [
    formValue,
    formDirty,
    changeFormValue,
    onDone,
    onSubmit,
    setError,
  ]);
};
