import React from 'react';

import { PrintFormType } from '@app/types';
import { useAppDispatch, useAppSelector } from '@app/hooks';

import {
  printFormBuildTemplateAction,
  deletePrintFormAction,
  closePrintWindowAction,
  openTemplateEditorAction,
} from '@app/stores';
import { PrintFormService } from '@app/services';


export interface MainContextInterface {
  printFormCurrent: PrintFormType | null;
  printFormIdCurrent: 'create' | number;
  printFormAccess: boolean;
  printFormIsEdited: boolean;
  printFormListIsLoading: boolean;
  printFormIsSubmitting: boolean;
  printFormIdSet: (id: 'create' | number) => void;
  printFormDelete: () => Promise<void>;
  printFormBuild: () => void;
  printFormModalClose: () => void;
  printFormEditorModalOpen: () => void;
  printFormIsEditedSet: (value: boolean) => void;
};

export const useMainHook = (
): MainContextInterface => {
  const dispatch = useAppDispatch();

  const printFormList = useAppSelector((s) => s.printForm.list);
  const documentState = useAppSelector((s) => s.document);
  const userCurrent = useAppSelector((s) => s.auth.user);
  const printFormListIsLoading = useAppSelector((s) => s.printForm.status === 'loading');
  const printFormIsSubmitting = useAppSelector((state) => state.printForm.status === 'submitting');
  
  const [printFormIdCurrent, printFormIdCurrentSet] = React.useState<'create' | number>('create');
  const [printFormIsEdited, printFormIsEditedSet] = React.useState(false);

  const printFormCurrent = React.useMemo<PrintFormType | null>(() => {
    if (printFormIdCurrent === 'create') return null;

    const printFormFinded = printFormList.find((printForm) => printForm.id === printFormIdCurrent);

    if (!printFormFinded) return null;

    return printFormFinded;
  }, [
    printFormList,
    printFormIdCurrent,
  ]);

  const printFormAccess = React.useMemo(() => {
    return !!userCurrent?.role.PRINT_FORM_ACCESS;
  }, [
    userCurrent,
  ]);
  
  const printFormIdSet = React.useCallback((id: 'create' | number) => {
    printFormIdCurrentSet(id);
  }, []);

  const printFormDelete = React.useCallback(async () => {
    if (printFormIdCurrent === 'create') return;

    const result = await dispatch(deletePrintFormAction({ id: printFormIdCurrent }));

    if (result.type === '@@print-form/delete/fulfilled') {
      printFormIdCurrentSet('create');
    }
  }, [
    dispatch,
    printFormIdCurrent,
  ]);

  const printFormBuild = React.useCallback(() => {
    if (!printFormCurrent) return;
    const printDataQueries = PrintFormService.parseTemplate(printFormCurrent!.template, documentState)
    dispatch(printFormBuildTemplateAction({
      printForm: printFormCurrent,
      printDataQueries,
    }));
  }, [
    dispatch,
    documentState,
    printFormCurrent,
  ]);

  const printFormModalClose = React.useCallback(() => {
    dispatch(closePrintWindowAction());
  }, [dispatch]);

  const printFormEditorModalOpen = React.useCallback(() => {
    if (!printFormCurrent) return;

    dispatch(openTemplateEditorAction({ printForm: printFormCurrent }));
  }, [
    printFormCurrent,
    dispatch,
  ]);

  React.useEffect(() => {
    if (!printFormListIsLoading && printFormList.length) {
      const id = printFormList[0].id;
      printFormIdCurrentSet(id);
    }
  }, [
    printFormListIsLoading,
    printFormList,
  ]);

  return React.useMemo(() => ({
    printFormIdCurrent,
    printFormCurrent,
    printFormAccess,
    printFormIsEdited,
    printFormListIsLoading,
    printFormIsSubmitting,
    printFormIdSet,
    printFormDelete,
    printFormBuild,
    printFormModalClose,
    printFormEditorModalOpen,
    printFormIsEditedSet,
  }), [
    printFormIdCurrent,
    printFormCurrent,
    printFormAccess,
    printFormIsEdited,
    printFormListIsLoading,
    printFormIsSubmitting,
    printFormIdSet,
    printFormDelete,
    printFormBuild,
    printFormModalClose,
    printFormEditorModalOpen,
    printFormIsEditedSet,
  ]);
};
