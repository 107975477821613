import { HttpService } from '@app/services';

import {
  BaseResponseInterface,
  UserType,
} from '@app/types';

import {
  UserChangePasswordRequest,
  UserCreateRequest,
  UserUpdateRequest,
} from './types';


export class UserApi {
  public static async create(
    payload: UserCreateRequest,
  ): Promise<BaseResponseInterface<{ user: UserType }>> {
    return await HttpService.sendRequest('POST', '/user/create', {
      user: payload,
    });
  };

  public static async getList(): Promise<BaseResponseInterface<{ users: UserType[] }>> {
    return await HttpService.sendRequest('GET', '/user/list');
  };

  public static async getDictionary(): Promise<BaseResponseInterface<{ users: UserType[] }>> {
    return await HttpService.sendRequest('GET', '/user/dictionary');
  };

  public static async getOne(id: number): Promise<BaseResponseInterface<{ user: UserType }>> {
    return await HttpService.sendRequest('GET', '/user/one/' + id);
  };

  public static async update(
    payload: UserUpdateRequest,
  ): Promise<BaseResponseInterface<{ user: UserType }>> {
    return await HttpService.sendRequest('PUT', '/user/update', {
      user: payload,
    });
  };

  public static async changeUserPassword(
    user: UserChangePasswordRequest,
  ): Promise<BaseResponseInterface<{ user: UserType }>> {
    return await HttpService.sendRequest('PUT', '/user/update-password', {
      user,
    });
  };
};
