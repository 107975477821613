import {
  DividerVerticalBrick,
} from '@app/components';

import {
  BlockTypeSelectComponent,
  // ButtonAlignComponent,
  // ButtonBlockquoteComponent,
  ButtonBoldComponent,
  ButtonImageComponent,
  ButtonItalicComponent,
  ButtonMarginsComponent,
  ButtonOlComponent,
  // ButtonTableComponent,
  ButtonUlComponent,
  ButtonUnderlineComponent,
  ButtonVarsComponent,
} from './components';

import styles from './tool-panel.module.scss';


export const ToolPanelComponent = () => {  
  return (
    <div className={styles['tool-panel']}>
      <BlockTypeSelectComponent />
      <DividerVerticalBrick />
      {/* <ButtonAlignComponent /> */}
      <ButtonBoldComponent />
      <ButtonItalicComponent />
      <ButtonUnderlineComponent />
      <DividerVerticalBrick />
      <ButtonUlComponent />
      <ButtonOlComponent />
      {/* <ButtonBlockquoteComponent /> */}
      <DividerVerticalBrick />
      <ButtonImageComponent />
      <ButtonVarsComponent />
      {/* <ButtonTableComponent /> */}
      <ButtonMarginsComponent />
    </div>
  );
};
