import {
  ButtonBackBlock,
  ButtonSaveBlock,
  FieldTextBlock,
  CardDataComposition,
} from '@app/components';

import styles from './main.module.scss';
import { useMainApi } from '../../context';


export const MainComponent = () => {
  const {
    formValue,
    formDirty,
    formIsSubmitting,
    changeFormValue,
    setError,
    onSubmit
  } = useMainApi();

  return (
    <div className={styles['main']}>
      <CardDataComposition
        title="Добавить подразделение"
        extra={<ButtonBackBlock buttonIcon />}
      >
        <div className="row justify-content-lg-center">
          <div className="col col-lg-6">
            <div className={styles['main__form']}>
              <form onSubmit={onSubmit}>
                <FieldTextBlock
                  name="name"
                  label="Название"
                  value={formValue.name}
                  onChange={changeFormValue}
                  error={setError('name')}
                  required
                />

                <ButtonSaveBlock
                  loading={formIsSubmitting}
                  onClick={onSubmit}
                  disabled={!formDirty}
                />
                <ButtonBackBlock />
              </form>
            </div>
          </div>
        </div>
      </CardDataComposition>
    </div>
  );
};
