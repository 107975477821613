import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import {
  EditIcon,
  DragIcon,
  TableRowBrick,
  TableCellBrick,
  ButtonIconBrick,
  ButtonsBrick,
  ButtonConfirmComposition,
} from '@app/components';

import { useVariantListApi } from '../../context';

import { VariantOneProps } from './variant-one.props';
import styles from './variant-one.module.scss';


export const VariantOneComponent = ({
  handbkVariant,
  openUpdateDialog,
}: VariantOneProps) => {
  const { handleClick } = useVariantListApi();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: handbkVariant.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <TableRowBrick
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      {...attributes} style={{...style}}
      className={styles['variant-one']}
    >
      <TableCellBrick ref={setNodeRef} {...listeners}>
        <div className={styles['variant-one__drag']}>
          <DragIcon />
        </div>
      </TableCellBrick>
      <TableCellBrick component="th" scope="row">{handbkVariant.id}</TableCellBrick>
      <TableCellBrick>{handbkVariant.label}</TableCellBrick>
      <TableCellBrick>
        <ButtonsBrick>
          <ButtonIconBrick size="small" onClick={() => openUpdateDialog(handbkVariant)}>
            <EditIcon/>
          </ButtonIconBrick>
          <ButtonConfirmComposition
            size="small"
            onClick={() => handleClick(handbkVariant)}
            message='Удалить вариант?'
          />
        </ButtonsBrick>
      </TableCellBrick>
    </TableRowBrick>
  );
}
