import React from 'react';

import { UpdateContextInterface, useUpdateHook } from './update.hook';


const UpdateContext = React.createContext<UpdateContextInterface | undefined>(undefined);

export const useUpdateApi = () => {
  const context = React.useContext(UpdateContext);

  if (context === undefined) {
    throw new Error('useUpdateContextApi must be used within UpdateProvider');
  }

  return context;
}

export const UpdateProvider = (
  props: React.HTMLAttributes<HTMLDivElement>
) => {
  const api = useUpdateHook();
  
  return (
    <UpdateContext.Provider value={api}>
      {props.children}
    </UpdateContext.Provider>
  )
}
