import { useNavigate } from 'react-router-dom';

import {
  SearchDocumentIcon,
  BadgeBrick,
  ButtonBrick,
  ButtonIconBrick,
} from '@app/components';

import { ButtonSearchProps } from './button-search.props';


export const ButtonSearchBlock = ({
  onClick,
  count,
  disabled,
  contained,
  type,
  buttonIcon,
}: ButtonSearchProps) => {
  const navigate = useNavigate();

  const handlerClick = () => {
    if (onClick) onClick();
    else navigate(-1);
  };

  if (buttonIcon) return (
    <ButtonIconBrick
      onClick={handlerClick}
    >
      <SearchDocumentIcon />
    </ButtonIconBrick>
  );

  return (
    <BadgeBrick color="warning" badgeContent={count}>
      <ButtonBrick
        color="primary"
        variant={contained ? 'contained' : 'outlined'}
        startIcon={<SearchDocumentIcon />}
        onClick={onClick}
        size="large"
        disabled={disabled}
        type={type}
      >
        Поиск
      </ButtonBrick>
    </BadgeBrick>
  );
};
