import { DocumentStateInterface, PrintQueryType, PrintVarType } from '@app/types';
import { getCurrentDocumentIdHelper } from '.';

export const buildPrintQueriesHelper = (
  keys: string[],
  documentState: DocumentStateInterface,
  isDocx?: boolean,
): PrintQueryType[] => {
  const printDataQueries: Array<PrintQueryType | null> =
      keys
        .map((key) => {
          const isFuncPrintImageSrc = key.indexOf('func_print-image') !== -1;
          const isFuncQrLinkSrc = key.indexOf(isDocx ? 'fqr' : 'func_qr-link') !== -1;
          const isFuncDocumentImageSrc = key.indexOf(isDocx ? 'fdi' : 'func_document-image') !== -1;
          const isFuncVarTable = key.indexOf(isDocx ? 'fvt' : 'func_var-table') !== -1;

          if (isFuncVarTable) {
            const documentConfigKey = key.split(isDocx ? '__' : ':')[1].replaceAll('_', '-');
            const part2 = key.split(isDocx ? '__' : ':')[2];
            const div = part2.indexOf(isDocx ? '___' : '(');
            const childrenDocumentConfigKey = part2.slice(0, div);
            const documentId = getCurrentDocumentIdHelper(
              documentConfigKey,
              documentState,
            );


            return {
              key,
              type: 'func_var-table',
              documentConfigKey: childrenDocumentConfigKey.replaceAll('_', '-'),
              documentId,
            }
          }
          
          if (isFuncPrintImageSrc) {
            const printImageId = key.split(':')[1];

            if (!printImageId) return null;

            return {
              key,
              type: ('func_print-image' as PrintVarType),
              printImageId: Number(printImageId),
            };
          }

          if (isFuncQrLinkSrc) {
            const documentConfigKey = key.split(isDocx ? '__' : ':')[1].replaceAll('_', '-');
            const documentId = getCurrentDocumentIdHelper(
              documentConfigKey,
              documentState,
            );

            if (!documentId) return null;

            return {
              key,
              type: ('func_qr-link' as PrintVarType),
              documentConfigKey,
              documentId,
            };
          }

          if (isFuncDocumentImageSrc) {
            const fieldConfigKey = key.split(isDocx ? '__' : ':')[2].replaceAll('_', '-');
            const documentConfigKey = key.split(isDocx ? '__' : ':')[1].replaceAll('_', '-');
            const documentId = getCurrentDocumentIdHelper(
              documentConfigKey,
              documentState,
            );
            
            if (
              !documentConfigKey
              || !fieldConfigKey
              || !documentId
            ) return null;

            return {
              key,
              type: ('func_document-image' as PrintVarType),
              documentConfigKey,
              fieldConfigKey,
              documentId,
            };
          }

          const [documentConfigKey, fieldConfigKey] = key.split(isDocx ? '__' : ':');
          const documentId = getCurrentDocumentIdHelper(
            documentConfigKey.replaceAll('_', '-'),
            documentState,
          );
            
          if (
            !documentConfigKey
            || !fieldConfigKey
            || !documentId
          ) return null;

          return {
            key,
            documentConfigKey: documentConfigKey.replaceAll('_', '-'),
            fieldConfigKey: fieldConfigKey.replaceAll('_', '-'),
            documentId,
            type: ('var' as PrintVarType),
          };
        });

    return (printDataQueries.filter((query) => query !== null) as PrintQueryType[]);
};
