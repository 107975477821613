import {
  PlusIcon,
  DocumentIcon,
  ListItemButtonBrick,
  ListItemIconBrick,
  ListItemTextBrick,
  CardBrick,
} from '@app/components';

import {
  useAppSelector,
} from '@app/hooks';

import { useMainApi } from '../../context';
import styles from './sidebar.module.scss';


export const SidebarComponent = () => {
  const printFormListLength = useAppSelector((s) => s.printForm.list.length);
  const printFormList = useAppSelector((s) => s.printForm.list);
  const {
    printFormIdCurrent,
    printFormAccess,
    printFormIdSet,
    printFormIsEdited,
  } = useMainApi();

  const sidebarVisible = (
    printFormAccess === true
    || printFormListLength !== 0
  );
  
  return (
    <>
      {(sidebarVisible) && (
        <CardBrick className={styles['sidebar']}>
          {printFormAccess === true && (
            <ListItemButtonBrick
              selected={printFormIdCurrent === 'create'}
              onClick={() => printFormIdSet('create')}
              disabled={printFormIsEdited}
            >
              <ListItemIconBrick>
                <PlusIcon />
              </ListItemIconBrick>
              <ListItemTextBrick primary="Новая форма" />
            </ListItemButtonBrick>
          )}

          {printFormList.map((printForm) => (
            <ListItemButtonBrick
              selected={printFormIdCurrent === printForm.id}
              key={printForm.id}
              onClick={() => printFormIdSet(printForm.id)}
              disabled={printFormIsEdited}
            >
              <ListItemIconBrick>
                <DocumentIcon />
              </ListItemIconBrick>
              <ListItemTextBrick primary={printForm.name} />
            </ListItemButtonBrick>
          ))}
        </CardBrick>
      )}
    </>
  );
};
