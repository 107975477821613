import {
  useMainApi,
  UpdateProvider,
  CreateProvider,
  DocxProvider,
} from '../../context';

import {
  ControlTemplateComponent,
  ControlDocxComponent,
  CreateComponent,
  DetailComponent,
  ModalComponent,
  NothingComponent,
  ProgressComponent,
  SidebarComponent,
  UpdateComponent,
} from '..';

import styles from './main.module.scss';


export const MainComponent = () => {
  const { printFormListIsLoading } = useMainApi();
  
  return (
    <ModalComponent>
      <div className={styles['main']}>
        <ProgressComponent />
        {!printFormListIsLoading && (
          <>
            <SidebarComponent />
            <div className={styles['main__content']}>
              <NothingComponent />
              <DetailComponent>
                <ControlTemplateComponent />

                <DocxProvider>
                  <ControlDocxComponent />
                </DocxProvider>
              </DetailComponent>

              <CreateProvider>
                <CreateComponent />
              </CreateProvider>

              <UpdateProvider>
                <UpdateComponent />
              </UpdateProvider>
            </div>
          </>
        )}
      </div>
    </ModalComponent>
  );
};
