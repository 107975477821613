import { FieldPhoneBlock } from '@app/components';
import { PhoneProps } from './phone.props';


export const PhoneField = ({
  fieldConfig,
  value,
  onChange,
  error,
  disabled,
}: PhoneProps) => {
  return (
    <FieldPhoneBlock
      key={fieldConfig.key}
      name={fieldConfig.key}
      label={fieldConfig.label}
      value={value}
      onChange={onChange}
      error={error}
      counted={fieldConfig.counted}
      required={fieldConfig.required}
      prompt={fieldConfig.prompt}
      disabled={disabled}
    />
  );
};
