import cs from 'classnames';

import {
  CardBrick,
  TemplateEditorSmart,
  TemplateEditorHtmlSmart,
} from '@app/components';

import { useMainApi } from '../../context';
import styles from './editor.module.scss';


export const EditorComponent = () => {
  const {
    printForm,
    editorValue,
    editorOnChange,
  } = useMainApi();

  const colClassnames = cs(
    styles['col col-md-12'],
    // eslint-disable-next-line
    { ['col-lg-10 col-xl-8 col-xxl-7']: printForm?.type === 'editor' },
  );

  return (
    <div className="container">
      <div className={styles['editor']}>
        <CardBrick className={styles['editor__card']}>
          <div className="row justify-content-lg-center">
            <div className={colClassnames}>
              {(printForm && printForm.type === 'html') && (
                <TemplateEditorHtmlSmart
                  value={editorValue}
                  onChange={editorOnChange}
                />
              )}
              {(printForm && printForm.type === 'editor') && (
                <TemplateEditorSmart
                  value={editorValue}
                  onChange={editorOnChange}
                />
              )}
            </div>
          </div>
        </CardBrick>
      </div>
    </div>
  );
};
