import { FieldTextBlock } from '@app/components';
import { TextProps } from './text.props';


export const TextField = ({
  fieldConfig,
  value,
  onChange,
  error,
  disabled,
}: TextProps) => {
  return (
    <FieldTextBlock
      key={fieldConfig.key}
      name={fieldConfig.key}
      label={fieldConfig.label}
      value={value}
      onChange={onChange}
      error={error}
      counted={fieldConfig.counted}
      required={fieldConfig.required}
      prompt={fieldConfig.prompt}
      disabled={disabled}
    />
  );
};
