import { FilledInput, FormControl, InputLabel } from '@mui/material';

import { InputFilledProps } from './input-filled.props';


export const InputFilledBrick = ({
  labelField,
  type,
  value,
  onChange,
  name,
  startAdornment,
  endAdornment,
  disabled,
}: InputFilledProps) => {
  return (
    <FormControl variant="filled" fullWidth>
      <InputLabel htmlFor={name}>{labelField}</InputLabel>
      <FilledInput
        id={name}
        type={type}
        value={value}
        onChange={onChange}
        name={name}
        fullWidth
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        disabled={disabled}
      />
    </FormControl>
  );
};
