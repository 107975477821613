import { Provider } from 'react-redux';
import { store } from '@app/stores';
import { StoreProviderProps } from './store-provider.props';


export const StoreProviderPart = ({
  children,
}: StoreProviderProps) => {
  return (
    <Provider store={store}>
      {children}
    </Provider>
  ); 
}