import * as React from 'react';
import copy from 'copy-to-clipboard';
import {
  EditorState,
  RichUtils,
  DraftEntityMutability,
  Modifier,
  CompositeDecorator,
} from 'draft-js';


import { BlockType, EntityType, InlineStyle } from '../../config';
import { HTMLtoState, stateToHTML } from '../../helpers';
import ImageDecorator from '../../entities/image.decorator';
import LinkDecorator from '../../entities/link.decorator';
import { PrintFormApi } from '@app/api';
import { callNotifyAction } from '@app/stores';
import { useModalsApi } from '../modals';
import { useAppDispatch } from '@app/hooks';
import { FieldConfigType } from '@app/types';



export type MainContextInterface = {
  editorState: EditorState;
  currentBlockType: BlockType;
  editorStateChange: (newEditorState: EditorState) => void;
  toggleBlockType: (blockType: BlockType) => void;
  toggleInlineStyle: (inlineStyle: InlineStyle) => void;
  hasInlineStyle: (inlineStyle: InlineStyle) => boolean;
  addImage: (id: number) => void;
  addLink: (src: string) => void;
  setEntityData: (entityKey: string, data: any) => void;
  selectVar: (key: string, fieldConfig?: FieldConfigType) => void;
}

const decorator = new CompositeDecorator([
  ImageDecorator,
  LinkDecorator,
]);

export const useMainHook = (
  value: string,
  onChange: (value: string) => void,
): MainContextInterface => {
  const dispatch = useAppDispatch();
  const { varsDialogClose } = useModalsApi();
  const [editorState, editorStateSet] = React.useState(EditorState.createEmpty(decorator));
  
  const editorStateChange = React.useCallback((
    newEditorState: EditorState,
  ) => {
    const newHtml = stateToHTML(newEditorState.getCurrentContent());

    onChange(newHtml);
    editorStateSet(newEditorState);
  }, [
    onChange,
  ]);

  const toggleBlockType = React.useCallback((blockType: BlockType) => {
    editorStateChange(RichUtils.toggleBlockType(editorState, blockType));
  }, [
    editorStateChange,
    editorState,
  ]);

  const currentBlockType = React.useMemo(() => {
    const selection = editorState.getSelection();
    const content = editorState.getCurrentContent();
    const block = content.getBlockForKey(selection.getStartKey());
    return block.getType() as BlockType;
  }, [editorState]);

  const toggleInlineStyle = React.useCallback((inlineStyle: InlineStyle) => {
    editorStateChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  }, [
    editorStateChange,
    editorState,
  ]);

  const hasInlineStyle = React.useCallback((inlineStyle: InlineStyle) => {
    const currentStyle = editorState.getCurrentInlineStyle();
    return currentStyle.has(inlineStyle);
  }, [editorState]);

  const addEntity = React.useCallback((
    entityType: EntityType,
    data: Record<string, string>,
    mutability: DraftEntityMutability,
  ) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const contentStateWithEntity = contentState.createEntity(entityType, mutability, data);
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    // const editorStateWithEntity = EditorState.set(editorState, {
    //   currentContent: contentStateWithEntity
    // });

    const textWithEntity = Modifier.insertText(
      contentState,
      selectionState,
      ' ',
      undefined,
      entityKey
    );

    // const newEditorState = AtomicBlockUtils.insertAtomicBlock(
    //   editorStateWithEntity,
    //   entityKey,
    //   ' '//can't remove this character because the cursor jumps before it....
    // );
    
    const newEditorState = EditorState.push(
      editorState,
      textWithEntity,
      "insert-characters"
    );

    editorStateChange(
      EditorState.forceSelection(
        newEditorState,
        textWithEntity.getSelectionAfter()
      )
    );
  }, [
    editorStateChange,
    editorState,
  ]);

  const setEntityData = React.useCallback((
    entityKey: string,
    data: any,
  ) => {
    /* Получаем текущий контент */
    const content = editorState.getCurrentContent();
    /* Объединяем текущие данные Entity с новыми */
    const contentStateUpdated = content.mergeEntityData(
      entityKey,
      data,
    );

    editorStateChange(EditorState.push(editorState, contentStateUpdated, 'apply-entity'))
  }, [
    editorStateChange,
    editorState,
  ]);

  const addImage = React.useCallback(async (id: number) => {
    const { data } = await PrintFormApi.getPrintImageOneById(id);

    if (!data.printImage) {
      console.log('ERorr')
      return;
    }

    const { printImage } = data;
    const src = printImage.src + '&printImageId=' + printImage.id;
    addEntity(EntityType.image, { src }, 'IMMUTABLE')
  }, [
    addEntity,
  ]);

  const addLink = React.useCallback((url: string) => {
    addEntity(EntityType.link, { url }, 'MUTABLE')
  }, [addEntity]);

  const selectVar = React.useCallback((
    key: string,
    fieldConfig?: FieldConfigType,
  ) => {
    const isDocumentImage = fieldConfig && fieldConfig.type === 'file';
    if (isDocumentImage) {
      key = 'func_document-image:' + key;
    }
    copy(`{{~${key}~}}`);
    varsDialogClose();
    dispatch(callNotifyAction({
      type: 'info',
      message: 'Ключ скопирован в буфер обмена.'
    }))
  }, [
    dispatch,
    varsDialogClose,
  ]);

  React.useEffect(() => {
    const current = stateToHTML(editorState.getCurrentContent());
    if (current === value) return;

    editorStateSet(
      EditorState.createWithContent(HTMLtoState(value), decorator),
    );
  }, [
    value,
    editorState,
  ]);
  
  return React.useMemo(() => ({
    editorState,
    currentBlockType,
    editorStateChange,
    toggleBlockType,
    toggleInlineStyle,
    hasInlineStyle,
    addImage,
    addLink,
    setEntityData,
    selectVar,
  }), [
    editorState,
    currentBlockType,
    editorStateChange,
    addImage,
    addLink,
    hasInlineStyle,
    setEntityData,
    toggleBlockType,
    toggleInlineStyle,
    selectVar,
  ]);
}
