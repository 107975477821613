import { MainComponent } from './components';
import {
  ModalsProvider,
  MainProvider,
} from './context';

import { TemplateEditorProps } from './template-editor.props';


export const TemplateEditorSmart = ({
  value,
  onChange,
}: TemplateEditorProps) => {
  return (
    <ModalsProvider>
      <MainProvider
        value={value}
        onChange={onChange}
      >
        <MainComponent />
      </MainProvider>
    </ModalsProvider>
  );
};

// - редактор
// - права полей
// - файл белый

