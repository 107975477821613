import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CardContent } from '@mui/material';

import { DocumentApi } from '@app/api';
import { setBreadcrumbs } from '@app/stores';

import {
  PageBaseLayout,
  CardDataComposition,
} from '@app/components';

import { useAppDispatch, useAppSelector } from '@app/hooks';

import { DocumentSearchPageParams } from './document-search.page-params';


export const DocumentSearchPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)
  const { uuid } = useParams<DocumentSearchPageParams>();
  const { breadcrumbs } = useAppSelector((s) => s.app);

  const documentSearch = useCallback(async (
    uuid: string,
  ): Promise<void> => {
    const result = await DocumentApi.documentSearch({ uuid });
    
    if (result.success) {
      navigate(result.data.path);
    } else {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { title: 'Главная', link: '/' },
      { title: 'Поиск документа', link: '' },
    ]));

    if (uuid) documentSearch(uuid);
  }, [
    dispatch,
    documentSearch,
    uuid,
  ]);

  return (
    <PageBaseLayout breadcrumbs={breadcrumbs}>
      <CardDataComposition
        title="Поиск документа"
        loading={loading && !!uuid}
      >
        <CardContent>
          <p>Документ не найден</p>
        </CardContent>
      </CardDataComposition>
    </PageBaseLayout>
  );
};
