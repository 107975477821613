import {
  DialogBrick,
  DialogContentBrick,
} from '@app/components';

import {
  HeaderComponent,
} from '..';

import { useMainApi } from '../../context';

export const HandbkDetailDialogComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    handbkId,
    closeDialog,
  } = useMainApi();

  return (
    <DialogBrick
      opened={!!handbkId}
      onClose={closeDialog}
      size="fullscreen"
    >
      <HeaderComponent />
      
      <DialogContentBrick>
        <div className="container">
          {children}
        </div>
      </DialogContentBrick>
    </DialogBrick>
  );
};
