import {
  CloseIcon,
  ButtonIconBrick,
} from '@app/components';

import { ButtonCloseProps } from './button-close.props';


export const ButtonCloseBlock = ({
  onClick,
}: ButtonCloseProps) => {
  return (
    <ButtonIconBrick onClick={onClick}>
      <CloseIcon />
    </ButtonIconBrick>
  );
};
