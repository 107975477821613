import FormatItalicIcon from '@mui/icons-material/FormatItalic';

import {
  ButtonIconSquareBrick,
} from '@app/components';

import { useMainApi } from '../../../../context';
import { InlineStyle } from '../../../../config';


export const ButtonItalicComponent = () => {
  const {
    toggleInlineStyle,
    hasInlineStyle,
  } = useMainApi();

  const active = hasInlineStyle(InlineStyle.ITALIC);
  
  return (
    <ButtonIconSquareBrick
      icon={<FormatItalicIcon />}
      onClick={() => toggleInlineStyle(InlineStyle.ITALIC)}
      active={active}
    />
  );
};
