import { PrintFormVarsSmart } from '@app/components';

import { useMainApi, useModalsApi } from '../../context';


export const VarsComponent = () => {
  const {
    varsDialogOpened,
    varsDialogClose,
  } = useModalsApi();

  const { selectVar } = useMainApi();

  return (
    <PrintFormVarsSmart
      opened={varsDialogOpened}
      onClose={varsDialogClose}
      onSelect={selectVar}
    />
  );
}
