import cs from 'classnames';
import { Divider, useTheme } from '@mui/material';

import styles from './card-header.module.scss';
import { CardHeaderProps } from './card-header.props';


export const CardHeaderBrick = ({ title, extra }: CardHeaderProps) => {
  const { palette } = useTheme();
  const classnames = cs(
    styles['card-header'],
    { [styles['card-header--dark']]: palette.mode === 'dark' },
  );
  
  return (
    <>
      <div className={classnames}>
        <div>{title}</div>
        <div className={styles['card-header__buttons']}>
          { extra }
        </div>
      </div>
      <Divider />
    </>
  );
}
