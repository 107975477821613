import {
  FlashIcon,
  ButtonIconSquareBrick,
} from '@app/components';

import { useModalsApi } from '../../../../context';


export const ButtonVarsComponent = () => {
  const { varsDialogOpen } = useModalsApi();

  return (
    <ButtonIconSquareBrick
      icon={<FlashIcon />}
      onClick={varsDialogOpen}
    />
  );
};
