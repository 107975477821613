import {
  BreadcrumbType,
  ConfigurationType,
} from '@app/types';

type PathElement = {
  key: string;
  value: 'list' | 'create' | string;
}

export const getPathElementsByPathname = (pathname: string): PathElement[] => {
  const pathList = pathname.split('/').slice(1);
  return pathList.map((item) => {
    const separatorIndex = item.lastIndexOf('-');

    return {
      key: item.slice(0, separatorIndex),
      value: item.slice(separatorIndex + 1),
    };
  })
  .filter((element) => element.key && element.value);
}

export const getBreacrumbsByPathElements = (
  pathElements: PathElement[],
  configuration: ConfigurationType
): BreadcrumbType[] => {
  const documents = configuration.documents;
  const breadcrumbs = pathElements.map((element, index) => {
    const document = documents.find((document) => document.key === element.key);

    if (!document) console.warn('Document not found! getBreacrumbsByPathElements()')

    const title = element.value === 'list'
      ? document!.label.plural
      : element.value === 'create'
        ? `Добавить ${document!.label.genitive.toLowerCase()}`
        : `${document!.label.singular} №${element.value}`;
    const link = '/' + pathElements.slice(0, index + 1).map((element) => element.key + '-' + element.value).join('/')

    return { title, link };
  });

  return [{ title: 'Главная', link: '/' }, ...breadcrumbs];
}
