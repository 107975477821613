import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  PrintFormStateInterface,
  BackendErrorsType,
} from '@app/types';

import {
  printFormBuildTemplateAction,
  createPrintFormAction,
  deletePrintFormAction,
  openPrintFormWindowAction,
  updatePrintFormAction,
  updatePrintFormMarginsAction,
  updatePrintFormTemplateAction,
  openTemplateEditorAction,
  uploadDocxTemplateAction,
} from './print-form.actions';


const initialState: PrintFormStateInterface = {
  list: [],
  status: 'idle',
  errors: null,
  printFormListModalOpened: false,
  openedTemplateEditor: false,
  documentConfigId: null,
  currentPrintForm: null,
};

const printFormSlice = createSlice({
  name: '@@print-form',
  initialState,
  reducers: {
    closePrintWindowAction: () => {
      return initialState;
    },
    // setCurrentPrintFormAction: (state, action: PayloadAction<number | 'create'>) => {
    //   return {
    //     ...state,
    //     printFormId: action.payload,
    //   };
    // },
    // openTemplateEditorAction: (state) => ({ ...state, openedTemplateEditor: true }),
    closeTemplateEditorAction: (state) => ({ ...state, openedTemplateEditor: false, templateDirty: false, editorReady: false, currentPrintForm: null }),
    openImageLibraryAction: (state) => ({ ...state, openedImageLibrary: true }),
    closeImageLibraryAction: (state) => ({ ...state, openedImageLibrary: false }),
    setDirtyTemplateAction: (state, action: PayloadAction<boolean>) => ({ ...state, templateDirty: action.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(openPrintFormWindowAction.pending, (state, action) => {
        state.status = 'loading';
        state.printFormListModalOpened = true;
        state.documentConfigId = action.meta.arg.query.documentConfigId;
      })
      .addCase(openPrintFormWindowAction.fulfilled, (state, action) => {
        state.list = action.payload.list;
        state.status = 'idle';
      })
      .addCase(openPrintFormWindowAction.rejected, (state, action) => {
        state.status = 'idle';
        // state[key].errors = action.payload as BackendErrorsType;
      })

      .addCase(openTemplateEditorAction.pending, (state, action) => {
        state.openedTemplateEditor = true;
      })
      .addCase(openTemplateEditorAction.fulfilled, (state, action) => {
        state.currentPrintForm = {
          ...action.payload.printForm
        };
        state.status = 'idle';
      })
      .addCase(openTemplateEditorAction.rejected, (state, action) => {
        state.status = 'idle';
        // state[key].errors = action.payload as BackendErrorsType;
      })

      .addCase(createPrintFormAction.pending, (state, action) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(createPrintFormAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list.unshift(action.payload);
      })
      .addCase(createPrintFormAction.rejected, (state, action) => {
        state.status = 'error';
        state.errors = action.payload as BackendErrorsType;
      })
      
      .addCase(updatePrintFormAction.pending, (state, action) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(updatePrintFormAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = state.list.map((item) => action.payload.id === item.id ? action.payload : item);
      })
      .addCase(updatePrintFormAction.rejected, (state, action) => {
        state.status = 'error';
        state.errors = action.payload as BackendErrorsType;
      })

      .addCase(updatePrintFormMarginsAction.pending, (state, action) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(updatePrintFormMarginsAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = state.list.map((item) => action.payload.id === item.id ? action.payload : item);
      })
      .addCase(updatePrintFormMarginsAction.rejected, (state, action) => {
        state.status = 'error';
        state.errors = action.payload as BackendErrorsType;
      })

      .addCase(uploadDocxTemplateAction.pending, (state, action) => {
        state.errors = null;
      })
      .addCase(uploadDocxTemplateAction.fulfilled, (state, action) => {
        state.status = 'idle';
        const { printForm } = action.payload
        state.list = state.list.map((item) => printForm.id === item.id ? printForm : item);
      })
      .addCase(uploadDocxTemplateAction.rejected, (state, action) => {
        state.status = 'error';
        state.errors = action.payload as BackendErrorsType;
      })

      .addCase(updatePrintFormTemplateAction.pending, (state, action) => {
        state.status = 'submitting';
        state.errors = null;
      })
      .addCase(updatePrintFormTemplateAction.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = state.list.map((item) => action.payload.id === item.id ? action.payload : item);
        state.currentPrintForm = action.payload;
      })
      .addCase(updatePrintFormTemplateAction.rejected, (state, action) => {
        state.status = 'error';
        state.errors = action.payload as BackendErrorsType;
      })
      
      .addCase(deletePrintFormAction.pending, (state, action) => {
      })
      .addCase(deletePrintFormAction.fulfilled, (state, action) => {
        state.list = state.list.filter((item) => Number(action.payload.id) === item.id ? false : true);
      })
      .addCase(deletePrintFormAction.rejected, (state, action) => {
      })

      .addCase(printFormBuildTemplateAction.pending, (state, action) => {
        state.status = 'building';
      })
      .addCase(printFormBuildTemplateAction.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addCase(printFormBuildTemplateAction.rejected, (state, action) => {
        state.status = 'idle'
      })
  },
});

export default printFormSlice.reducer;

export const {
  closePrintWindowAction,
  // setCurrentPrintFormAction,
  closeTemplateEditorAction,
  setDirtyTemplateAction,
  openImageLibraryAction,
  closeImageLibraryAction,
} = printFormSlice.actions;
