import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  callNotifyAction,
  setDocumentsStores,
} from '@app/stores';

import { ConfigurationStateInterface } from '@app/types';

import {
  ConfigurationApi,
  ConfigurationBuildResponse,
} from '@app/api';


export const configurationBuildAction = createAsyncThunk<
  ConfigurationBuildResponse,
  undefined,
  { state: { configuration: ConfigurationStateInterface } }
>(
  '@@configuration/build',
  async (_, { rejectWithValue, dispatch }) => {
    const response = await ConfigurationApi.build();
    
    if (!response.success) {
      if ((response.errors as string) !== 'Not authorized') {
        dispatch(callNotifyAction({ type: 'error', message: 'Ошибка получения данных!' }));
      }
      return rejectWithValue(response.errors);
    }

    dispatch(setDocumentsStores(response.data.configuration));

    return response.data;
  },
  {
    condition: (_, { getState }) => {
      const { configuration } = getState();

      if (configuration.status === 'loading') {
        return false;
      }

      return true;
    }
  },
);
