import { FieldRadioBlock } from '@app/components';
import { HandbkRemoteRadioProps } from './handbk-remote-radio.props';


export const HandbkRemoteRadioField = ({
  fieldConfig,
  handbkRemote,
  value,
  onChange,
  error,
  disabled,
}: HandbkRemoteRadioProps) => {
  if (!handbkRemote) return (<p key={fieldConfig.key}>Ошибка словаря</p>);
  
  return (
    <FieldRadioBlock
      key={fieldConfig.key}
      name={fieldConfig.key}
      label={fieldConfig.label}
      value={value}
      onChange={onChange}
      error={error}
      items={handbkRemote.variants.map((v) => ({ value: v.id, label: v.label }))}
      counted={fieldConfig.counted}
      required={fieldConfig.required}
      prompt={fieldConfig.prompt}
      disabled={disabled}
    />
  );
};
