import React from 'react';

import {
  DocumentAccessContextInterface,
  useDocumentAccessHook,
} from './document-access.hook';

const DocumentAccessContext = React.createContext<DocumentAccessContextInterface | undefined>(undefined);

export const useDocumentAccessApi = () => {
  const context = React.useContext(DocumentAccessContext);

  if (context === undefined) {
    throw new Error('useDocumentAccessApi must be used within DocumentAccessProvider');
  }

  return context;
}

export const DocumentAccessProvider = (
  props: React.HTMLAttributes<HTMLDivElement>
) => {
  const api = useDocumentAccessHook();

  return (
    <DocumentAccessContext.Provider value={api}>
      {props.children}
    </DocumentAccessContext.Provider>
  );
};
