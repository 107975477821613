import { FieldTypesType } from '@app/types';;

export type FieldDictType = {
  value: FieldTypesType;
  label: string;
  deperecated?: boolean;
}


export const getFieldTypesDictHelper = (): FieldDictType[] => ([
  { value: 'text', label: 'Текст' },
  { value: 'number', label: 'Целое число' },
  { value: 'decimal', label: 'Дробное число' },
  { value: 'big-int', label: 'Большое число' },
  { value: 'number-string', label: 'Целое число (Строка)' },
  { value: 'decimal-string', label: 'Дробное число (Строка)' },
  { value: 'date', label: 'Дата' },
  { value: 'datetime', label: 'Дата и время' },
  { value: 'time', label: 'Время' },
  { value: 'time-seconds', label: 'Время и секунды' },
  { value: 'month', label: 'Месяц' },
  { value: 'year', label: 'Год' },
  { value: 'month-year', label: 'Месяц и год' },
  { value: 'boolean', label: 'Да/Нет' },
  { value: 'boolean-check', label: 'Да/Нет - Чекбокс' },
  { value: 'select', label: 'Выбор из списка', deperecated: true },
  { value: 'multiselect', label: 'Множественный выбор из списка', deperecated: true },
  { value: 'document-select', label: 'Выбор документа' },
  { value: 'document-multiselect', label: 'Множественный выбор документа' },
  { value: 'handbk-select', label: 'Выбор из справочника' },
  { value: 'handbk-radio', label: 'Радио-кнопки из справочника' },
  { value: 'handbk-multiselect', label: 'Множественный выбор из справочника' },
  { value: 'handbk-check', label: 'Чек-боксы из справочника' },
  { value: 'handbk-remote-select', label: 'Выбор из уд. справочника' },
  { value: 'handbk-remote-radio', label: 'Радио-кнопки из уд. справочника' },
  { value: 'handbk-remote-multiselect', label: 'Множественный выбор из уд. справочника' },
  { value: 'handbk-remote-check', label: 'Чек-боксы из уд. справочника' },
  { value: 'gender', label: 'Пол' },
  { value: 'phone', label: 'Телефон' },
  { value: 'snils', label: 'СНИЛС' },
  { value: 'passport', label: 'Паспорт' },
  { value: 'long-text', label: 'Длинный текст' },
  { value: 'file', label: 'Файл' },
]);
