import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';

import {
  ButtonIconSquareBrick,
} from '@app/components';

import { useMainApi } from '../../../../context/main/main.provider';
import { InlineStyle } from '../../../../config';


export const ButtonUnderlineComponent = () => {
  const {
    toggleInlineStyle,
    hasInlineStyle,
  } = useMainApi();

  const active = hasInlineStyle(InlineStyle.UNDERLINE);
  
  return (
    <ButtonIconSquareBrick
      icon={<FormatUnderlinedIcon />}
      onClick={() => toggleInlineStyle(InlineStyle.UNDERLINE)}
      active={active}
    />
  );
};
