export const keysParseHelper = (
  template: string,
  [delimiterLeft, delimiterRight]: [string, string],
): string[] => {
  const keys: string[] = [];

  while(true) {
    const openVarPos = template.indexOf(delimiterLeft);
    const closeVarPos = template.indexOf(delimiterRight);

    if (openVarPos === -1 || closeVarPos === -1) {
      break;
    }

    keys.push(template.slice(openVarPos + delimiterLeft.length, closeVarPos));
    template = template.substring(closeVarPos + delimiterRight.length);
  }

  const filteredKeys =
    keys.filter((value, i) => keys.indexOf(value) === i);

  return filteredKeys;
}