import {
  FieldConfigType,
  ConfigurationStateInterface,
} from '@app/types';

import { getFieldTypesDictHelper } from './get-field-types-dict.helper';


export const fieldTypeTranslateHelper = (
  fieldConfig: FieldConfigType,
  configuration: ConfigurationStateInterface,
): string => {
  const fieldTypes = getFieldTypesDictHelper();

  const fieldType = fieldTypes.find((fieldType) => fieldType.value === fieldConfig.type);

  if (!fieldType) {
    return fieldConfig.type;
  }
  
  if (
    fieldConfig.type === 'document-multiselect' ||
    fieldConfig.type === 'document-select'
  ) {
    const documentConfig =
      configuration.documents
        .find((d) => d.documentConfigId === fieldConfig.documentConfigLinkId);

    if (!documentConfig) return fieldType.label;
    
    return fieldType.label + ` (${documentConfig.label.singular})`
  }

  if (
    fieldConfig.type === 'handbk-select' ||
    fieldConfig.type === 'handbk-radio' ||
    fieldConfig.type === 'handbk-multiselect' ||
    fieldConfig.type === 'handbk-check'
  ) {
    const handbk =
      configuration.handbk
        .find((d) => d.handbkId === fieldConfig.handbkId);

    if (!handbk) return fieldType.label;
    
    return fieldType.label + ` (${handbk.name})`
  }

  if (
    fieldConfig.type === 'handbk-remote-select' ||
    fieldConfig.type === 'handbk-remote-radio' ||
    fieldConfig.type === 'handbk-remote-multiselect' ||
    fieldConfig.type === 'handbk-remote-check'
  ) {
    const handbkRemote =
      configuration.handbkRemote
        .find((d) => d.id === fieldConfig.handbkRemoteId);

    if (!handbkRemote) return fieldType.label;
    
    return fieldType.label + ` (${handbkRemote.name})`
  }
  
  return fieldType.label;
}
