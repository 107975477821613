import { ChangeFieldEventType } from '@app/types';

import {
  FormFieldBrick,
  AsteriskBrick,
  InputBrick,
} from '@app/components';

import { FieldYearProps } from './field-year.props';


export const FieldYearBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  required,
  counted,
  prompt,
  disabled,
}: FieldYearProps) => {
  const onChangeHandler = (e: ChangeFieldEventType) => {
    e.target.value = (e.target.value as string).replace(/\D/g, '');

    if (e.target.value.length > 4) {
      return;
    }

    onChange(e);
  }

  return (
    <FormFieldBrick error={error} prompt={prompt}>
      <InputBrick
        label={<>{label}<AsteriskBrick required={required} counted={counted} /></>}
        name={name}
        value={value}
        onChange={onChangeHandler}
        disabled={disabled}
      />
    </FormFieldBrick>
  );
};
