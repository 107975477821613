import {
  ButtonSaveBlock,
  ButtonCancelBlock,
  FieldTextBlock,
  FieldLongTextBlock,
  FieldSelectBlock,
  CardBrick,
  CardContentBrick,
  CardActionsBrick,
  DividerHorizontalBrick,
} from '@app/components';

import { useMainApi, useUpdateApi } from '../../context';


export const UpdateComponent = () => {
  

  const {
    printFormIsEdited,
    printFormCurrent,
    printFormIsSubmitting,
  } = useMainApi();

  const {
    formValue,
    formDirty,
    changeFormValue,
    onSubmit,
    setError,
    closeForm,
  } = useUpdateApi();

  if (!printFormIsEdited) return null;

  return (formValue && printFormCurrent) && (
    <CardBrick>
      <form onSubmit={onSubmit}>
        <CardContentBrick style={{ paddingBottom: 0 }}>
          <FieldTextBlock
            name="name"
            label="Название"
            value={formValue.name}
            onChange={changeFormValue}
            error={setError('name')}
            required
          />

          <FieldSelectBlock
            name="type"
            label="Тип"
            value={formValue.type}
            onChange={changeFormValue}
            error={setError('type')}
            items={[
              { label: 'Визуальный редактор', value: 'editor' },
              { label: 'HTML Редактор', value: 'html' },
              { label: 'Docx Шаблон', value: 'docx' },
            ]}
            required
            disabled={printFormCurrent.type === 'html' || printFormCurrent.type === 'docx'}
          />

          <FieldLongTextBlock
            name="description"
            label="Описание"
            value={formValue.description || ''}
            onChange={changeFormValue}
            error={setError('description')}
          />
        </CardContentBrick>
        <DividerHorizontalBrick />
        <CardActionsBrick>
          <ButtonSaveBlock
            disabled={!formDirty}
            loading={printFormIsSubmitting}
            onClick={onSubmit}
          />
          <ButtonCancelBlock onClick={closeForm}/>
        </CardActionsBrick>
      </form>
    </CardBrick>
  );
}
