import {
  SelectBrick,
  MenuItemBrick,
  AsteriskBrick,
  FormFieldBrick,
} from '@app/components';

import { FieldBooleanProps } from './field-boolean.props';


export const FieldBooleanBlock = ({
  value,
  onChange,
  name,
  error,
  label,
  hideError,
  required,
  counted,
  prompt,
  disabled,
}: FieldBooleanProps) => {
  return (
    <FormFieldBrick error={error} hideError={hideError} prompt={prompt}>
      <SelectBrick
        name={name}
        value={value === true ? '1' : value === false ? '0' : ''}
        onChange={(e) => {
          onChange({ target:  { name, value: e.target.value === '1' ? true : false } })
        }}
        disabled={disabled}
        label={<>{label}<AsteriskBrick required={required} counted={counted} /></>}
      >
        <MenuItemBrick value="0">Нет</MenuItemBrick>
        <MenuItemBrick value="1">Да</MenuItemBrick>
      </SelectBrick>
    </FormFieldBrick>
  );
};
