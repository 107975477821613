import React from 'react';

import { PrintFormService } from '@app/services';

import {
  closeTemplateEditorAction,
  printFormBuildTemplateAction,
  updatePrintFormTemplateAction,
} from '@app/stores';

import {
  useAppDispatch,
  useAppSelector,
} from '@app/hooks';
import { PrintFormType } from '@app/types';


export interface MainContextInterface {
  printForm: PrintFormType | null,
  editorValue: string,
  editorDirty: boolean;
  printFormEditorOpened: boolean;
  editorOnChange: (value: string) => void;
  printFormEditorClose: () => void;
  printFormPreview: () => void;
  printFormSave: () => void;
}

export const useMainHook = (
): MainContextInterface => {
  const dispatch = useAppDispatch();

  const printForm = useAppSelector((state) => state.printForm.currentPrintForm);
  const documentState = useAppSelector((state) => state.document);

  const [editorValue, editorValueSet] = React.useState('');
  const [editorDirty, editorDirtySet] = React.useState(false);

  const editorOnChange = React.useCallback((
    value: string,
  ) => {
    const isDirty = !editorDirty && printForm && printForm.template !== value;
    if (isDirty) {
      editorDirtySet(true);
    }

    editorValueSet(value);
  }, [
    printForm,
    editorDirty,
  ]);

  const printFormEditorOpened = React.useMemo((): boolean => {
    if (printForm === null) return false;
    return true;
  }, [printForm]);

  const printFormEditorClose = React.useCallback((): void => {
    editorValueSet(printForm?.template || '');
    dispatch(closeTemplateEditorAction());
    editorDirtySet(false);
  }, [
    printForm,
    dispatch,
  ]);

  const printFormPreview = React.useCallback(async (): Promise<void> => {
    const printDataQueries = PrintFormService.parseTemplate(editorValue, documentState);

    await dispatch(printFormBuildTemplateAction({
      printForm: {
        ...printForm!,
        template: editorValue,
      },
      printDataQueries,
    }));
  }, [
    dispatch,
    documentState,
    editorValue,
    printForm,
  ]);

  const printFormSave = React.useCallback(async (): Promise<void> => {
    await dispatch(updatePrintFormTemplateAction({
      printForm: {
        id: printForm!.id,
        template: editorValue,
      },
    }));

    editorDirtySet(false);
  }, [
    dispatch,
    editorValue,
    printForm,
  ]);

  React.useEffect(() => {
    if (!printForm) return;

    editorValueSet(printForm.template);
  }, [
    printForm,
  ]);

  return React.useMemo(() => ({
    printForm,
    editorValue,
    editorDirty,
    editorOnChange,
    printFormEditorOpened,
    printFormEditorClose,
    printFormPreview,
    printFormSave,
  }), [
    printForm,
    editorValue,
    editorDirty,
    editorOnChange,
    printFormEditorOpened,
    printFormEditorClose,
    printFormPreview,
    printFormSave,
  ]);
};
