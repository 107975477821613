import { Breadcrumbs, Card } from '@mui/material';

import { BreadcrumbsProps } from './breadcrumbs.props';
import styles from './breadcrumbs.module.scss';
import { LinkBrick } from '@app/components';


export const BreadcrumbsBlock = ({
  items,
}: BreadcrumbsProps) => {
  return (
    <Card className={styles['breadcrumbs']}>
      <div className={styles['breadcrumbs__body']}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {items.map((item, index) => {
            const lastItem = index === items.length - 1

            return (
              lastItem
                ? (
                  <span key={item.title}>{item.title}</span>
                )
                : (
                  <LinkBrick
                    key={item.title}
                    to={item.link}
                    label={item.title}
                  />
                )
            )
          })}
        </Breadcrumbs>
      </div>
    </Card>
  );
};
