import { FieldNumberBlock } from '@app/components';
import { NumberProps } from './number.props';


export const NumberField = ({
  fieldConfig,
  value,
  onChange,
  error,
  disabled,
}: NumberProps) => {
  return (
    <FieldNumberBlock
      key={fieldConfig.key}
      name={fieldConfig.key}
      label={fieldConfig.label}
      value={value}
      onChange={onChange}
      error={error}
      counted={fieldConfig.counted}
      required={fieldConfig.required}
      prompt={fieldConfig.prompt}
      disabled={disabled}
    />
  );
};
