import { ContentState } from 'draft-js';

type ImageEntityProps = {
  contentState: ContentState;
  entityKey: string;
}

export const ImageEntity = ({
  contentState,
  entityKey,
}: ImageEntityProps) => {
  const { src } = contentState.getEntity(entityKey).getData();

  return (
    <img src={src} alt="print_image" />
  );
}
