import {
  ButtonBrick,
  ButtonEditBlock,
  ButtonCancelBlock,
  CardDataComposition,
} from '@app/components';

import {
  useMainApi,
  useDocumentAccessApi,
} from '../../context';


export const DocumentAccessComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    roleDetailIsLoading,
    hasUpdateAccess,
    isEditingDocumentAccess,
    isEditingDocumentAccessSet,
  } = useMainApi();

  const {
    allowAllDisabled,
    disallowAllDisabled,
    allowAll,
    disallowAll,
  } = useDocumentAccessApi();

  return (
    <CardDataComposition
      title='Матрица доступа к документам'
      extra={
        <>
          {isEditingDocumentAccess
            ? (
              <>
                <ButtonBrick
                  variant="text"
                  onClick={allowAll}
                  disabled={allowAllDisabled}
                >
                  Разрешить все
                </ButtonBrick>
                <ButtonBrick
                  variant="text"
                  onClick={disallowAll}
                  disabled={disallowAllDisabled}
                >
                  Запретить все
                </ButtonBrick>
                <ButtonCancelBlock buttonIcon onClick={() => isEditingDocumentAccessSet(false)} />
              </>
            )
            : hasUpdateAccess &&(
              <ButtonEditBlock onClick={() => isEditingDocumentAccessSet(true)} />
            )
          }
        </>
      }
      loading={roleDetailIsLoading}
    >
      {children}
    </CardDataComposition>
  );
};
