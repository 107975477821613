import { useAppSelector } from '@app/hooks';

import { useMainApi } from '../../context';
import { TypographyBrick } from '@app/components';


export const NothingComponent = () => {
  const printFormListLength = useAppSelector((s) => s.printForm.list.length);
  const { printFormAccess } = useMainApi();

  return (
    <>
      {(printFormAccess === false && printFormListLength === 0) && (
        <TypographyBrick>
          Нет доступных печтных форм.
        </TypographyBrick>
      )}
    </>
  );
};
