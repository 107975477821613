import { FormControlLabel, Radio } from '@mui/material';

import { RadioProps } from './radio.props';


export const RadioBrick = ({
  value,
  label,
}: RadioProps) => {
  return (
    <FormControlLabel
      value={value}
      control={<Radio />}
      label={label}
    />
  );
}
