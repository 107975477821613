import React from 'react';

import {
  ButtonIconSquareBrick,
  FormatAlignCenterIcon,
  FormatAlignLeftIcon,
  FormatAlignRightIcon,
} from '@app/components';

import styles from './button-align.module.scss';


type AlignTypesType = 'left' | 'center' | 'right';

const icons = {
  left: <FormatAlignLeftIcon />,
  center: <FormatAlignCenterIcon />,
  right: <FormatAlignRightIcon />,
};

export const ButtonAlignComponent = () => {
  const [align, setAlign] = React.useState<AlignTypesType>('left');
  const [opened, setOpened] = React.useState<boolean>(false);

  const selectHandler = (
    align: AlignTypesType,
  ): void => {
    setAlign(align);
    setOpened(false);
  }

  return (
    <div className={styles['button-align']}>
      <ButtonIconSquareBrick
        icon={icons[align]}
        onClick={() => setOpened((prev) => !prev)}
      />
      {opened && (
        <div className={styles['button-align__list']}>
          <ButtonIconSquareBrick
            icon={icons.left}
            onClick={() => selectHandler('left')}
            active={align === 'left'}
          />
          <ButtonIconSquareBrick
            icon={icons.center}
            onClick={() => selectHandler('center')}
            active={align === 'center'}
          />
          <ButtonIconSquareBrick
            icon={icons.right}
            onClick={() => selectHandler('right')}
            active={align === 'right'}
          />
        </div>
      )}
    </div>
  );
};
