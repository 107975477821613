import React from 'react';

import {
  MainContextInterface,
  useMainHook,
} from './main.hook';


const MainContext = React.createContext<MainContextInterface | undefined>(undefined);

export const useMainApi = () => {
  const context = React.useContext(MainContext);

  if (context === undefined) {
    throw new Error('useMainApi must be used within MainProvider');
  }

  return context;
}

export const MainProvider = (
  props: React.HTMLAttributes<HTMLDivElement>
) => {
  const api = useMainHook();
  
  return (
    <MainContext.Provider value={api}>
      {props.children}
    </MainContext.Provider>
  )
}

