import { FormGroup, InputLabel } from '@mui/material';

import { FormGroupProps } from './form-group.props';


export const FormGroupBrick = ({
  children,
  labelId,
  labelField,
}: FormGroupProps) => {

  const labelElement = (labelId && labelField) ? (
    <InputLabel id={labelId}>{labelField}</InputLabel>
  ) : null;

  return (
    <>
      {labelElement}
      <FormGroup>
        {children}
      </FormGroup>
    </>
  );
}
