import React from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';


import { DatePickerProps } from './date-picker.props';

export const DatePickerBrick = ({
  label,
  value,
  name,
  disabled,
  onChange,
}: DatePickerProps) => {
  const [validDate, setValidDate] = React.useState(true);
  const [fieldValue, setFieldValue] = React.useState(moment(value));
  
  const fieldChange = (e: any | null) => {
    if (e) {
      setValidDate(e._isValid)
      setFieldValue(e);
      onChange({ target: { name, value: e._d } });
    } else {
      setValidDate(true);
    }
  }

  return (
    <DesktopDatePicker
      label={label}
      value={fieldValue}
      onChange={fieldChange}
      disabled={disabled}
      slotProps={{ textField: { fullWidth: true, error: !validDate, variant: 'filled' } }}
    />
  );
}
