import React from 'react';

import { CreateContextInterface, useCreateHook } from './create.hook';


const CreateContext = React.createContext<CreateContextInterface | undefined>(undefined);

export const useCreateApi = () => {
  const context = React.useContext(CreateContext);

  if (context === undefined) {
    throw new Error('useCreateContextApi must be used within CreateProvider');
  }

  return context;
}

export const CreateProvider = (
  props: React.HTMLAttributes<HTMLDivElement>
) => {
  const api = useCreateHook();
  
  return (
    <CreateContext.Provider value={api}>
      {props.children}
    </CreateContext.Provider>
  )
}
