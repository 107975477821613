import {
  ButtonIconSquareBrick,
  ScannerIcon,
} from '@app/components';

import { useModalsApi } from '../../../../context';


export const ButtonMarginsComponent = () => {
  const { marginsDialogOpen } = useModalsApi();

  return (
    <ButtonIconSquareBrick
      icon={<ScannerIcon />}
      onClick={marginsDialogOpen}
    />
  );
};
