import {
  HttpService,
} from '@app/services';

import {
  DocumentSearchOneByUuidResponse,
  DocumentSearchOneByUuidRequest,
  BaseResponseInterface,
} from '@app/types';

import {
  DocumentCreateRequest,
  DocumentCreateResponse,
  DocumentDeleteRequest,
  DocumentDeleteResponse,
  DocumentGetListRequest,
  DocumentGetListResponse,
  DocumentGetOneRequest,
  DocumentGetOneResponse,
  DocumentUpdateRequest,
  DocumentUpdateResponse,
} from './types';


export class DocumentApi {
  public static async create(
    payload: DocumentCreateRequest,
  ): Promise<BaseResponseInterface<DocumentCreateResponse>> {
    return await HttpService.sendRequest('POST', '/document/create', {
      document: payload,
    });
  };

  public static async update(
    payload: DocumentUpdateRequest,
  ): Promise<BaseResponseInterface<DocumentUpdateResponse>> {
    return await HttpService.sendRequest('POST', '/document/update', {
      document: payload,
    });
  };

  public static async getList(
    payload: DocumentGetListRequest,
  ): Promise<BaseResponseInterface<DocumentGetListResponse>> {
    return await HttpService.sendRequest('GET', '/document/list', {...payload.query, ...payload.searchQuery});
  };

  public static async getOne(
    payload: DocumentGetOneRequest,
  ): Promise<BaseResponseInterface<DocumentGetOneResponse>> {
    return await HttpService.sendRequest('GET', '/document/one', payload);
  };

  public static async delete(
    payload: DocumentDeleteRequest,
  ): Promise<BaseResponseInterface<DocumentDeleteResponse>> {
    return await HttpService.sendRequest('DELETE', '/document/delete', payload);
  };

  public static async documentSearch(
    payload: DocumentSearchOneByUuidRequest,
  ): Promise<BaseResponseInterface<DocumentSearchOneByUuidResponse>> {
    return await HttpService.sendRequest('GET', '/document/search-one-by-uuid', payload);
  }
};
