import React from 'react';

import {
  VariantListContextInterface,
  useVariantListHook,
} from './variant-list.hook';

const VariantListContext = React.createContext<VariantListContextInterface | undefined>(undefined);

export const useVariantListApi = () => {
  const context = React.useContext(VariantListContext);

  if (context === undefined) {
    throw new Error('useVariantListApi must be used within VariantListProvider');
  }

  return context;
}

export const VariantListProvider = (
  props: React.HTMLAttributes<HTMLDivElement>
) => {
  const api = useVariantListHook();

  return (
    <VariantListContext.Provider value={api}>
      {props.children}
    </VariantListContext.Provider>
  );
};
